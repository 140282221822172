import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/analytics";
import router from "./router.js";

var firebaseConfig = {
  apiKey: "AIzaSyAG394bTQ16biRfOk57Od4CBnLkxFB3AQY",
  authDomain: "online-offerte-tool.firebaseapp.com",
  projectId: "online-offerte-tool",
  storageBucket: "online-offerte-tool.appspot.com",
  messagingSenderId: "77386817224",
  appId: "1:77386817224:web:1ebfd3dd08832227aa0ffd",
  measurementId: "G-HRJNBDRTEX",
};
firebase.initializeApp(firebaseConfig);
firebase.analytics();

const db = firebase.firestore();

if (firebase.apps.length === 0) {
  console.log("Should send email");
  fetch(
    "https://us-central1-online-offerte-tool.cloudfunctions.net/sendErrorMessage",
    { method: "GET", mode: "no-cors" }
  ).then((res) => {
    console.log(res.status);
    router.push("/404");
  });
} else {
  console.log("everything is working fine!");
}

db.settings({ timestampsInSnapshots: true, merge: true });
export default db;
