<template>
  <div class="rootDiv">
    <teleport to="body">
      <base-dialog
        @close="tryClose"
        @download="download"
        :open="show"
        :imgDialog="true"
        :showButtons="true"
      >
      <inner-image-zoom :hideHint="true" :src="img" />
        <!-- <img :src="img" alt="" /> -->
      </base-dialog>
    </teleport>

    <div class="columns-3">
      <div class="column">
        <h4>Jouw gegevens</h4>
        <p>
          <strong>{{ naam }}</strong>
          <br />
          {{ adres }}
          <br />
          {{ offerte.klantPostcode }} {{ offerte.klantStad }} <br /><br />
          {{ offerte.klantTelefoon }}
          <br />
          {{ offerte.klantEmail }}
        </p>
      </div>
      <div class="column">
        <div class="list">
          <h4 class="onlySmallMediumLarge">&nbsp;</h4>
          <ul class="checkmarkList">
            <li>
              <font-awesome-icon id="check" class="icon-check" icon="check" />
              <span>Type dak: {{ offerte.offerteTypeDak }} </span>
            </li>
            <li>
              <font-awesome-icon id="check" class="icon-check" icon="check" />
              <span>Bedekking: {{ offerte.offerteDakbedekking }} </span>
            </li>
            <li>
              <font-awesome-icon id="check" class="icon-check" icon="check" />
              <span>Oriëntatie: {{ offerte.offerteOrientatieDak }}</span>
            </li>
            <li>
              <font-awesome-icon id="check" class="icon-check" icon="check" />
              <span>Opstelling: {{ offerte.offerteOpstelling }} </span>
            </li>
          </ul>
        </div>
      </div>
      <div class="column">
        <a class="sateliteImagePakket" @click="openDialogDak">
          <img :src="img" @error="changeUrl" />
          <font-awesome-icon id="search" class="icon-zoom" icon="search-plus" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import "vue-inner-image-zoom/lib/vue-inner-image-zoom.css";
import InnerImageZoom from "vue-inner-image-zoom";
export default {
  data() {
    return {
      show: null,
      img: "",
    };
  },
  components: {
    "inner-image-zoom": InnerImageZoom,
  },
  computed: {
    offerte() {
      return this.$store.getters.getCurrentOfferte;
    },
    naam() {
      return this.offerte.klantVoorNaam + " " + this.offerte.klantAchterNaam;
    },
    adres() {
      return this.offerte.klantAdres + " " + this.offerte.klantHuisNummer;
    },
    pages() {
      return this.$store.getters.getPages;
    },
    imageUrl() {
      return this.offerte.offerteQuoteImageLegPlan;
    },
    backUpImg() {
      return this.pages.LegPlan.backupImg;
    },
  },
  methods: {
    changeUrl() {
      this.img = this.backUpImg;
    },
    openDialogDak() {
      this.show = true;
      this.$emit("showBar", false);
    },
    tryClose() {
      this.show = false;
      this.$emit("showBar", true);
    },
    download() {
      fetch(this.offerte.offerteQuoteImageLegPlan, {
        method: "GET",
      })
        .then((response) => response.blob())
        .then((blob) => {
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement("a");
          a.href = url;
          a.download = `legplan.png`;
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();
          a.remove(); //afterwards we remove the element again
        });
      this.show = false;
    },
  },
  created() {
    this.img = this.imageUrl;
  },
};
</script>

<style scoped>
@media (min-width: 749px) {
  .columns-3 .column {
    padding-right: 0rem;
  }
  .columns-3 .column:nth-child(3n + 3) {
    margin-right: 0;
    padding-right: 0;
  }
}

@media (min-width: 749px) and (min-width: 749px) and (max-width: 960px) {
  .columns-3 .column {
    width: calc((4 * (100% - (11 * 3rem)) / 12) + 9rem);
  }
}
@media (min-width: 749px) and (min-width: 1101px) {
  .columns-3 .column {
    width: calc((4 * (100% - (11 * 4rem)) / 12) + 12rem);
    margin-right: 2rem;
  }
}

@media (max-width: 748px) {
  .onlySmallMediumLarge {
    display: none;
  }
  .columns-3 .column {
    margin-bottom: 1rem;
    width: 100%;
  }
  .sateliteImagePakket {
    pointer-events: none;
  }
  h4,
  span,
  p {
    font-size: 1rem !important;
  }
}

.columns-3 {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
}

h4,
.h4 {
  margin: 0;
  font-family: "Karla", sans-serif;
  text-transform: uppercase;
  margin-bottom: 1rem;
  color: #00a7e3;
  font-size: 1.6rem;
  line-height: 1.3;
  font-weight: 700;
}
*,
*:before,
*:after {
  box-sizing: border-box;
}
h4 {
  display: block;
  margin-block-start: 1.33em;
  margin-block-end: 1.33em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  text-align: left;
}

p {
  margin: 0;
  margin-bottom: 2rem;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  text-align: left;
}
strong,
b {
  font-weight: 700;
}

.list {
  margin-bottom: 2.5rem;
}

.list ul {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-bottom: 0.5rem;
  text-align: left;
}

ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}

.list ul li #check {
  position: absolute;
  top: 0.6rem;
  left: 0;
  width: 0.6rem;
  height: 0.6rem;
  fill: #999999;
  color: #999999;
}

[class^="icon-"],
[class*=" icon-"] {
  position: relative;
  display: inline-block;
  width: 2rem;
  height: 2rem;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}

.list ul li {
  position: relative;
  display: block;
  padding: 0 0 0.3rem 2rem;
  line-height: 1.2;
}
li {
  text-align: -webkit-match-parent;
}

a:not(.button) {
  color: inherit;
  text-decoration: none;
  outline: none;
}
.sateliteImagePakket {
  display: block;
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  cursor: pointer;
}
a,
button,
.button {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
a {
  background-color: transparent;
}
*,
*:before,
*:after {
  box-sizing: border-box;
}
a:-webkit-any-link {
  color: -webkit-link;
  cursor: pointer;
  text-decoration: underline;
}

.sateliteImagePakket img {
  position: absolute;
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

img {
  display: block;
  width: 100%;
  height: auto;
  border: 0;
}

.sateliteImagePakket #search {
  position: absolute;
  right: 0.5rem;
  bottom: 0.2rem;
  width: 1rem;
  color: white;
}

/* CSS FOR DIALOG */

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 10;
}

dialog {
  position: fixed;
  top: 20vh;
  left: 10%;
  width: 100%;
  z-index: 100;
  border-radius: 2px;
  border: none;
  /* box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26); */
  margin: 0;
  overflow: hidden;
  background-color: transparent;
}

.dialog-enter-from,
.dialog-leave-to {
  opacity: 0;
  transform: scale(0.8);
}

.dialog-enter-active {
  transition: all 0.3s ease-out;
}

.dialog-leave-active {
  transition: all 0.3 ease-in;
}

.dialog-enter-to,
.dialog-leave-from {
  opacity: 1;
  transform: scale(1);
}

@media (min-width: 768px) {
  dialog {
    left: calc(50% - 30rem);
    width: 60rem;
  }
}

/* END CSS FOR DIALOG */
</style>
