<template>
  <div class="rootDiv">
    <header>
      <div class="mainBar">
        <div class="containerWide">
          <div class="brand" v-if="!editor">
            <img :src="mainBar.Logo" alt="" />
          </div>
          <div class="rating" v-if="wrapperStatus">
            <div class="klantenVertellen">
              <div :class="{ active: editor }">
                <img
                  @click="
                    edit(
                      mainBar.KlantenVertellenImg,
                      'MainBar',
                      'KlantenVertellenImg'
                    )
                  "
                  :src="mainBar.KlantenVertellenImg"
                  alt=""
                />
              </div>
              <div class="ratingNumber">{{ rating }}</div>
            </div>
          </div>
          <a
            class="button small blueGhost serviceButton"
            :class="{ isActive: showWrapper }"
            @click="toggleButton"
          >
            <span>Hulp en advies</span>
          </a>
          <div class="serviceInfo" v-if="wrapperStatus">
            <div class="wrapper">
              <div>
                <font-awesome-icon
                  id="mobile"
                  class="icon-phone"
                  icon="mobile-alt"
                />
                <div :class="{ active: editor }">
                  <a
                    v-if="editor"
                    @click="
                      edit(mainBar.Telefoonnummer, 'MainBar', 'Telefoonnummer')
                    "
                    >{{ mainBar.Telefoonnummer }}</a
                  >
                </div>
                <a v-if="!editor" :href="callTo">{{
                  mainBar.Telefoonnummer
                }}</a>
                <span class="status" :class="statusOpen"></span>
              </div>
              <div
                class="empty-icon"
                :class="{ active: editor }"
                @click="
                  edit(mainBar.Openingstijden, 'MainBar', 'Openingstijden')
                "
              >
                {{ mainBar.Openingstijden }}
              </div>
              <div>
                <font-awesome-icon
                  id="mail"
                  class="icon-mail"
                  icon="paper-plane"
                />
                <a v-if="!editor" :href="mailTo">
                  {{ mainBar.Email }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showWrapper: false,
      wrapperStatus: true,
      width: 0,
      showEdit: false,
      valueToEdit: "",
      editedValue: "",
      subColToEdit: "",
      fieldToEdit: "",
      indexToEdit: null,
      imgUrl: "",
      uploadValue: "",
      imageData: "",
    };
  },

  computed: {
    statusOpen() {
      const time = new Date().getHours();
      const day = new Date().getDay();
      if (day !== 0 && day !== 6) {
        if (time < this.openTime || time > this.closeTime) {
          return "is-closed";
        } else {
          return "is-open";
        }
      } else {
        return "is-closed";
      }
    },
    offerte() {
      return this.$store.getters.getCurrentOfferte;
    },
    genData() {
      return this.$store.getters.getGenData;
    },
    KlantenVertellen() {
      return this.genData.klantenVertellen;
    },
    rating() {
      const r =  this.KlantenVertellen.rate;
      const re = r.toFixed(1);
      return re.replace(".", ",");

    },
    Pages() {
      return this.$store.getters.getPages;
    },
    mainBar() {
      return this.Pages.MainBar;
    },
    openTime() {
      return parseInt(this.mainBar.Openingstijden.substring(7, 9));
    },
    closeTime() {
      return parseInt(this.mainBar.Openingstijden.substring(13, 15));
    },
    callTo() {
      return "tel:" + "+31" + this.mainBar.Telefoonnummer.substring(1);
    },
    mailTo() {
      return "mailto:" + this.mainBar.Email;
    },
    editor() {
      return this.$store.getters.getEditor;
    },
    admin() {
      return this.$store.getters.getAdmin;
    },
  },
  methods: {
    toggleButton() {
      this.showWrapper = !this.showWrapper;
      if (this.showWrapper) {
        this.wrapperStatus = true;
      } else {
        this.wrapperStatus = false;
      }
    },

    wrapperVisible() {
      if (window.innerWidth >= 935 || !!this.showWrapper) {
        this.wrapperStatus = true;
      }
      if (window.innerWidth < 934 && !this.showWrapper) {
        this.wrapperStatus = false;
      }
    },

    edit(value, subCol, field, index = null) {
      if (this.editor) {
        this.valueToEdit = value;
        this.subColToEdit = subCol;
        this.fieldToEdit = field;
        this.indexToEdit = index;
        this.showEdit = true;
      }
    },
    updateImg(event) {
      this.uploadValue = 0;
      this.imgUrl = null;
      this.imageData = event.target.files[0];
      this.onUpload();
    },

    onUpload() {
      // Hier wordt een upload
      this.imgUrl = null;
      const storageRef = firebase
        .storage()
        .ref(
          `Pages/${this.subColToEdit}/${this.fieldToEdit}/${this.imageData.name}`
        )
        .put(this.imageData);
      storageRef.on(
        `state_changed`,
        (snapshot) => {
          this.uploadValue =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          console.log(error.message);
        },
        () => {
          this.uploadValue = 100;
          storageRef.snapshot.ref.getDownloadURL().then((url) => {
            this.imgUrl = url;
            this.editedValue = this.imgUrl;
          });
        }
      );
    },
    async confirm(res) {
      if (res === "Yes") {
        let i = "";
        let field = "";
        if (this.indexToEdit === null) {
          i = null;
        } else {
          i = this.indexToEdit;
        }
        if (
          this.fieldToEdit.toLowerCase().includes("img") ||
          this.fieldToEdit.toLowerCase().includes("logo")
        ) {
          field = this.imageData.name;
        } else {
          field = this.editedValue;
        }

        const ad = {
          ...this.admin,
        };
        const payload = {
          index: i,
          collection: this.subColToEdit,
          field: this.fieldToEdit,
          value: this.editedValue,
          newValue: field,
          admin: ad,
          oldVal: this.valueToEdit,
        };
        this.showEdit = false;
        await this.$store.dispatch("editRecord", payload);

        this.valueToEdit = "";
        this.editedValue = "";
        this.subColToEdit = "";
        this.fieldToEdit = "";
        this.indexToEdit = null;
        this.showEdit = false;
        this.imgUrl = null;
        this.uploadValue = "";
        this.imageData = "";
      } else {
        this.valueToEdit = "";
        this.editedValue = "";
        this.subColToEdit = "";
        this.fieldToEdit = "";
        this.indexToEdit = null;
        this.showEdit = false;
        this.imgUrl = null;
        this.uploadValue = "";
        this.imageData = "";
      }
    },
  },
  created() {
    this.width = window.innerWidth;
    if (window.innerWidth < 869 && !this.showWrapper) {
      this.wrapperStatus = false;
    }
    window.addEventListener("resize", this.wrapperVisible);
  },
  destroyed() {
    window.addEventListener("resize", this.wrapperVisible);
  },
};
</script>

<style scoped>
.icon-user {
  margin: auto;
  color: #00abde;
  border: 1px solid #00abde;
  padding: 0.5rem 1rem;
  border-radius: 12px;
  transition: ease-in-out 0.3s all;
  cursor: pointer;
}

.icon-user:hover {
  margin: auto;
  color: #fff;
  background-color: #00abde;
  padding: 0.5rem 1rem;
  border-radius: 12px;
}

.active {
  border: 1px dashed green;
  border-radius: 12px;
  transition: 0.3s all ease-in-out;
  padding: 0.5rem;
}

.active:hover {
  border: 1px solid green;
  cursor: pointer;
  border-radius: 12px;
  padding: 0.5rem;
  /* width: inherit; */
  /* height: inherit; */
}

input {
  display: block;
  width: 95%;
  border: 1px solid #cccccc;
  padding: 1rem 1rem 1rem;
  border-radius: 0.4rem;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#fafafa),
    to(white)
  );
  background-image: linear-gradient(to bottom, #fafafa 0%, white 100%);
  -webkit-transition: border-color 0.15s ease-in-out 0s;
  transition: border-color 0.15s ease-in-out 0s;
}

@media (max-width: 934px) {
  .empty-icon {
    margin-left: 0 !important;
  }
  .icon-mail {
    display: none !important;
  }
  .icon-phone {
    display: none !important;
  }
  header .mainBar .containerWide .rating {
    display: none;
  }
  header .mainBar .containerWide .brand {
    margin-top: -4rem;
    margin-bottom: -3.6rem;
    width: 40%;
  }
  header .mainBar .containerWide .serviceInfo .wrapper {
    padding-left: 1rem !important;
    font-size: 0.9rem;
  }
}

@media (min-width: 935px) {
  header .mainBar .containerWide .brand {
    width: 20rem;
    margin-top: -4rem;
    margin-bottom: -4rem;
  }
  header .mainBar .containerWide .serviceButton {
    display: none;
  }
  header .mainBar .containerWide .serviceInfo {
    border-left: 1px solid #e6e6e6;
    padding-left: 3rem;
    margin-left: 3rem;
  }
}

@media (min-width: 749px) {
  .containerWide {
    padding-right: 4rem;
  }
}

a:not(.button) {
  color: inherit;
  text-decoration: none;
  outline: none;
}

a,
button,
.button {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
a {
  background-color: transparent;
  transition: all 0.2s ease-in-out;
}

a:hover {
  color: #83c63c;
  text-decoration: underline;
}

header .mainBar .containerWide .rating .klantenVertellen {
  width: 9rem;
  position: relative;
}

header .mainBar .containerWide .rating .klantenVertellen .ratingNumber {
  position: absolute;
  top: 30%;
  left: 10%;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  text-align: center;
  letter-spacing: -0.05rem;
  border-radius: 50%;
  font-weight: 700;
}

header .mainBar .containerWide .serviceInfo {
  font-size: 0.9em;
}

header .mainBar .containerWide .serviceInfo .wrapper {
  padding: 0.7rem 0;
}

header .mainBar .containerWide .serviceInfo .wrapper > div {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

header .mainBar .containerWide .serviceInfo .wrapper > div .icon-phone {
  color: #b3b3b3;
  margin-right: 0.5rem;
}

header .mainBar .containerWide .serviceInfo .wrapper > div .icon-mail {
  color: #b3b3b3;
  margin-right: 0.5rem;
}
.empty-icon {
  margin-left: 2.5rem;
}

header .mainBar .containerWide .serviceInfo .wrapper > div a {
  -webkit-transition: all 0.15s ease-in-out 0s;
  transition: all 0.15s ease-in-out 0s;
  font-weight: 700;
  font-family: "Karla";
}

header .mainBar .containerWide .serviceInfo .wrapper > div .status.is-open {
  background-color: #83c63c;
}

header .mainBar .containerWide .serviceInfo .wrapper > div .status.is-closed {
  background-color: red;
}

header .mainBar .containerWide .serviceInfo .wrapper > div .status {
  width: 0.6rem;
  height: 0.6rem;
  margin-left: 0.5rem;
  border-radius: 50%;
}

[class^="icon-"],
[class*=" icon-"] {
  position: relative;
  display: inline-block;
  width: 2rem;
  height: 2rem;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}

header .mainBar .containerWide .rating {
  margin-left: 2rem;
}

img {
  display: block;
  width: 100%;
  height: auto;
  border: 0;
}

header .mainBar .containerWide .brand {
  margin-right: auto;
}

header {
  display: block;
  width: 100%;
}

header .mainBar {
  background: white;
  border-bottom: 1px solid #e6e6e6;
  border-top: 1px solid #e6e6e6;
  padding: 1rem 0;
}

header .mainBar .containerWide {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-align: center;
  align-items: center;
}

.containerWide {
  max-width: 160rem;
  margin: auto;
}

header .mainBar .containerWide .serviceButton.isActive {
  background: #44bace;
  color: white;
}

.button.blueGhost {
  color: #44bace;
  background-color: transparent;
  border: 2px solid #44bace;
}
.button.small {
  padding: 0.8rem 1rem 0.8rem;
  text-transform: uppercase;
  font-size: 1rem;
  line-height: 1;
  margin: auto;
}
.button {
  display: -webkit-inline-box;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  vertical-align: bottom;
  position: relative;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  cursor: pointer;
  touch-action: manipulation;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-radius: 0.5rem;
  font-family: "Karla", sans-serif;
  width: auto;
  white-space: normal;
  text-align: center !important;
  font-weight: 700;
  -webkit-transition: all 0.15s ease-in-out 0s;
  transition: all 0.15s ease-in-out 0s;
}

.editBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 10;
}

.editDialogView {
  position: absolute;
  margin: auto;
  width: 30%;
  padding: 1rem;
  border-radius: 12px;
  background-color: #fff;
  left: 40%;
  right: auto;
  top: 30%;
}

.editDialogView dialog {
  max-width: 40rem;
  max-height: 60rem;
  font-family: "Karla";
  border: none;
  overflow-y: auto;
  background-color: transparent;
}

@media (min-width: 768px) {
  dialog {
    left: calc(50% - 30rem);
    width: 60rem;
  }
}
</style>
