<template>
  <div class="rootDiv">
    <font-awesome-icon
      v-if="!showAdd && !selectedIMG"
      @click="back"
      id="arrow-left"
      class="arrow-left"
      icon="arrow-left"
    />
    <div v-if="!img" class="content">
      <form @submit.prevent="create">
        <div class="group">
          <label for="voorNaam">Voornaam: </label><br />
          <input type="text" v-model="voorNaam" />
        </div>
        <div class="group">
          <label for="achterNaam">Achternaam:</label><br />
          <input type="text" v-model="achterNaam" />
        </div>
        <div class="group">
          <label for="email">Email:</label><br />
          <input type="text" v-model="email" />
        </div>
        <div class="group">
          <label for="contactId">ID-Nummer:</label><br />
          <input type="text" v-model="contactId" />
        </div>
        <div class="group" v-if="contactId.length === 19">
          <label for="photoUrl">Medewerker Foto:</label><br />
          <input type="file" @change="updateImg($event)" />
        </div>
        <base-button v-if="okToSubmit" link>Toevoegen</base-button>
      </form>
    </div>
    <img-cropper
      v-if="img && contactId"
      :img="img"
      :id="contactId"
      @image-url="getUrl"
    ></img-cropper>
  </div>
</template>

<script>
import ImgCropper from "./ImgCropper.vue";

import "vue-advanced-cropper/dist/style.css";
export default {
  components: {
    ImgCropper,
  },
  data() {
    return {
      img: null,
      imgUpload: "",
      imgUrl: "",
      voorNaam: "",
      achterNaam: "",
      email: "",
      contactId: "",
    };
  },
  computed: {
    okToSubmit() {
      if (
        this.voorNaam &&
        this.achterNaam &&
        this.email &&
        this.imgUrl &&
        this.contactId
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    updateImg(event) {
      var input = event.target;
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.img = e.target.result;
        };
        reader.readAsDataURL(input.files[0]);
      }
    },
    getUrl(emit) {
      this.imgUrl = emit.imgUrl;
      this.img = "";
    },
    back() {
      this.$emit("back-medewerker");
    },
    async create() {
      const doc = {
        id: this.contactId,
        email: this.email,
        imgUrl: this.imgUrl,
        naam: this.voorNaam + " " + this.achterNaam,
      };
      this.$store.dispatch("addMedewerker", doc);
      this.contactId = "";
      this.email = "";
      this.imgUrl = "";
      this.voorNaam = "";
      this.achterNaam = "";
      this.$emit("return-medewerker");
    },
  },
};
</script>

<style scoped>
.arrow-left {
  position: absolute;
  right: 0;
  margin-right: 4rem;
  background-color: transparent;
  border-radius: 50%;
  border: 1px solid #44bace;
  padding: 0.8rem;
  margin-top: -4rem;
  color: #44bace;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
}

.arrow-left:hover {
  position: absolute;
  right: 0;
  border-radius: 50%;
  padding: 0.8rem;
  background-color: #44bace;
  margin-right: 4rem;
  margin-top: -4rem;
  color: white;
  cursor: pointer;
}
.content {
  margin-top: 4rem;
}
.container {
  padding-top: 2rem;
  background-color: #00a7e3;
  padding-bottom: 26rem;
}

.brand {
  position: absolute;
  left: 7%;
  top: 7%;
  width: 12%;
}

.container .brand {
  position: absolute;
  width: 18rem;
}

.group {
  position: relative;
  margin-bottom: 0.2rem;
}

.group label {
  display: block;
  text-align: left;
  /* margin-bottom: 0.5rem; */
}
.errorMessage {
  font-size: smaller;
  text-align: left;
  font-style: italic;
  color: red;
  margin-left: 2%;
}
input {
  display: block;
  width: 95%;
  border: 1px solid #cccccc;
  padding: 1rem 1rem 1rem;
  border-radius: 0.4rem;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#fafafa),
    to(white)
  );
  background-image: linear-gradient(to bottom, #fafafa 0%, white 100%);
  -webkit-transition: border-color 0.15s ease-in-out 0s;
  transition: border-color 0.15s ease-in-out 0s;
}

.parsleyForm .group > div.full {
  width: calc(100% - 1rem);
}

a:not(.button) {
  text-align: left;
  color: blue;
  text-decoration: underline;
  outline: none;
}

@media (min-width: 749px) {
  .container .brand {
    position: absolute;
    width: 18rem;
  }
}

@media (min-width: 749px) {
  .energyFlashLine {
    width: 20rem;
    max-width: 28rem;
    margin: auto;
    margin-bottom: 1rem;
  }
}

@media (min-width: 1101px) {
  .block.small {
    padding: 4rem 7rem 10rem;
    width: 50rem;
  }
  .block {
    margin: 5vh auto;
  }
}

.spinner {
  height: 30rem;
  margin: auto;
}
</style>
