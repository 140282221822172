<template>
  <div class="rootDiv">
    <div class="klantenVertellenBlock container">
      <h2>Klanten over HalloStroom</h2>
      <div class="klantenVertellen">
        <img :src="genData.klantenVertellen.imgUrl" alt="" />
        <div class="ratingNumber">{{ rating }}</div>
      </div>
      <flickity class="flickity" ref="flickity" :options="flickityOptions">
        <div
          class="carousel-cell"
          v-for="review in reviews"
          :key="review.author"
        >
          <div class="date">Geschreven op: {{ reviewDate(review.date) }}</div>
          <br />
          <div class="text">
            <p>
              {{ review.rating }}
            </p>
          </div>
          <div class="name">{{ review.author }} uit {{ review.city }}</div>
        </div>
      </flickity>
    </div>
  </div>
</template>

<script>
import Flickity from "vue-flickity";
import moment from "moment";

export default {
  components: {
    Flickity,
  },
  data() {
    return {
      flickityOptions: {
        initialIndex: this.index,
        freeScroll: false,
        lazyLoad: 1,
        imagesLoaded: true,
        draggable: ">1",
        prevNextButtons: true,
        pageDots: false,
        wrapAround: true,
      },
    };
  },

  methods: {
    next() {
      this.$refs.flickity.next();
    },

    previous() {
      this.$refs.flickity.previous();
    },
    reviewDate(date) {
      return this.moment(date).locale("nl").format("DD MMMM YYYY");
    },
  },
  computed: {
    genData() {
      return this.$store.getters.getGenData;
    },
    rating() {
      const r = this.genData.klantenVertellen.rate;
      const re = r.toFixed(1);
      return re.replace(".", ",");
    },
    reviews() {
      return this.genData.selectedReviews;
    },
    index() {
      return this.reviews.length;
    },
  },
  created() {
    this.moment = moment;
  },
};
</script>

<style scoped>
.rootDiv {
  background-color: #e1eef3;
  padding-top: 2em;
  /* padding-bottom: 2rem; */
}
@media (min-width: 749px) {
  .klantenVertellenBlock {
    /* margin-bottom: 8rem; */
    padding-top: 1rem;
    /* padding-bottom: 4rem; */
  }

  :deep(.flickity-button) {
    color: #00a7e3;
    background: transparent;
    opacity: 0.2;
    margin: -2.5rem;
  }

  :deep(.flickity-button):hover {
    color: #00a7e3;
    background: transparent;
    opacity: 1;
  }

  .container {
    position: relative;
    margin: auto;
    width: 85%;
    height: 30.4rem;
    max-width: 80rem;
    display: inline-block;
  }

  .flickity {
    width: 50%;
    margin: auto;
  }

  .carousel-cell {
    padding: 3rem;
    width: calc(100% - 6rem);
    max-height: 25rem;
    background: #e1eef3;
    display: inline-block;
    font-size: 1rem;
    align-items: center;
    justify-content: center;
    text-align: left;
  }

  .carousel.is-fullscreen .carousel-cell {
    height: 100%;
  }

  .name {
    font-family: "Bad Script", cursive;
    font-size: 2em;
    margin-bottom: 2rem;
    margin-top: 1rem;
  }

  h2,
  .h2 {
    margin-bottom: 2rem;
    font-size: 3rem;
    line-height: 1.2;
  }

  p {
    margin: 0;
    margin-bottom: 2rem;
  }

  p {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }

  h2 {
    display: block;
    font-size: 2em;
    /* text-align: left; */
    margin-block-start: 0.83em;
    margin-block-end: 0.13em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
  }
  .date {
    float: right;
    color: grey;
    font-style: italic;
    font-size: smaller;
  }

  .klantenVertellen {
    display: inline-block;
    width: 10rem;
    height: auto;
    position: relative;
  }

  .klantenVertellen img {
    width: 14rem;
    display: inline;
  }

  .klantenVertellen .ratingNumber {
    position: absolute;
    top: 25%;
    font-size: 2rem;
    left: 12%;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    text-align: center;
    letter-spacing: -0.05rem;
    border-radius: 50%;
    font-weight: 700;
  }
  :deep(.flickity-button) {
    color: #00a7e3;
    background: transparent;
    opacity: 0.2;
    margin: -4rem;
  }

  :deep(.flickity-button):hover {
    color: #00a7e3;
    background: transparent;
    opacity: 1;
  }
}

@media (max-width: 748px) {
  .klantenVertellenBlock {
    margin-bottom: 4.5rem;
    /* padding-top: 2rem; */
  }

  .container {
    position: relative;
    margin: auto;
    width: 85%;
    height: 30rem;
    max-width: 80rem;
    display: inline-block;
  }
  .flickity {
    width: 100%;
    margin: auto;
  }

  .carousel-cell {
    padding: 1rem;
    width: calc(100% - 2rem);
    max-height: 25rem;
    background: #e1eef3;
    display: inline-block;
    font-size: 0.9rem;
    align-items: center;
    justify-content: center;
    text-align: left;
  }

  .carousel.is-fullscreen .carousel-cell {
    height: 100%;
  }

  .name {
    font-family: "Bad Script", cursive;
    font-size: 2em;
    margin-bottom: 2rem;
    margin-top: 1rem;
  }

  :deep(.flickity-button) {
    color: #00a7e3;
    background: transparent;
    opacity: 0.2;
    margin: -2.5rem;
  }

  :deep(.flickity-button):hover {
    color: #00a7e3;
    background: transparent;
    opacity: 1;
  }

  p {
    margin: 0;
    margin-bottom: 2rem;
  }

  p {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }

  h2,
  .h2 {
    margin: 0;
    text-transform: none;
    font-family: "Karla", sans-serif;
    font-weight: 700;
  }

  h2 {
    display: block;
    font-size: 2em;
    /* text-align: left; */
    /* margin-block-start: 0.83em;
    margin-block-end: 0.13em;
    margin-inline-start: 0px;
    margin-inline-end: 0px; */
    font-weight: bold;
  }
  .date {
    float: right;
    color: grey;
    font-style: italic;
    font-size: smaller;
  }

  .klantenVertellen {
    display: inline-block;
    width: 10rem;
    height: auto;
    position: relative;
    cursor: pointer;
  }

  .klantenVertellen img {
    width: 10rem;
    display: inline;
  }

  .klantenVertellen .ratingNumber {
    position: absolute;
    top: 25%;
    font-size: 1.5rem;
    left: 9%;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    text-align: center;
    letter-spacing: -0.05rem;
    border-radius: 50%;
    font-weight: 700;
  }
}
</style>
