<template>
  <div>
    <h3>Je bent Ingelogd!</h3>
    <p style="text-align: left">Wat wil je doen?</p>
    <div class="buttons">
      <!-- <base-button
        style="display: block; width: 100%"
        @click="changeComponent('ContentEditor')"
        >Content Editor</base-button
      ><br /> -->
      <base-button
        style="display: block; width: 100%"
        @click="changeComponent('LogViewer')"
        >Logs Bekijken</base-button
      >
      <!-- <base-button
        style="display: block; width: 100%"
        @click="changeComponent('CheckKorting')"
        >Kortingsmelding Bekijken</base-button
      > -->
      <base-button
        style="display: block; width: 100%"
        @click="changeComponent('CheckGenData')"
        >Actieve Data Bekijken</base-button
      >
      <base-button
        style="display: block; width: 100%"
        @click="changeComponent('CheckReviews')"
        >Reviews Bekijken</base-button
      >
      <base-button
        style="display: block; width: 100%"
        @click="changeComponent('CheckMedewerkers')"
        >Medewerkers Bekijken</base-button
      >
      <base-button
        style="display: block; width: 100%"
        @click="changeComponent('CheckProducten')"
        >Producten Bekijken</base-button
      >
      <base-button
        style="display: block; width: 100%"
        @click="changeComponent('CheckMeerwerk')"
        >Meerwerk Bekijken</base-button
      >
      <base-button
        style="display: block; width: 100%"
        @click="changeComponent('AddInfosheets')"
        >Infosheets Toevoegen</base-button
      >
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    changeComponent(selectedComponent) {
      this.$emit("selected-component", selectedComponent);
    },
  },
};
</script>

<style scoped>
.buttons {
  margin-top: 2rem;
}

.power-off {
  color: white;
  cursor: pointer;
  right: 0;
  transition: all 0.3s ease-in-out;
}

.power-off:hover {
  color: red;
  cursor: pointer;
  right: 0;
}

.card {
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  padding: 2rem;
  margin-top: 2rem;
  width: 85%;
  height: 35rem;
  max-height: 80rem;
  background-color: #fff;
}
</style>
