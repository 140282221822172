<template>
  <div class="rootDiv">
    <div v-if="open" class="backdrop" @click="$emit('close')">
      <div class="round" v-if="!!showButtons" @click="$emit('close')">
        <font-awesome-icon class="iconRound" icon="times-circle" />
      </div>
      <div class="round2" v-if="!!showButtons" @click="$emit('download')">
        <font-awesome-icon class="iconRound" icon="download" />
      </div>
    </div>
    <transition name="dialog">
      <dialog :class="typeClass" open v-if="open">
        <header>
          <slot name="header"></slot>
        </header>
        <slot></slot>
        <slot name="img"></slot>
      </dialog>
    </transition>
  </div>
</template>

<script>
export default {
  props: ["open", "imgDialog", "showButtons"],
  emits: ["close"],

  computed: {
    typeClass() {
      if (this.imgDialog) {
        return "imgDialog";
      } else {
        return "textDialog";
      }
    },
  },

};
</script>

<style scoped>
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.75);
}

.textDialog {
  position: fixed;
  display: grid;
  grid-column: auto;
  justify-content: center;
  align-items: center;
  top: 2vh;
  width: 50%;
  left: 25%;
  margin: 0;
  border-radius: 12px;
  background-color: white;
  z-index: 100;
  border: none;
}

.imgDialog {
  position: fixed;
  top: 20vh;
  width: 50%;
  left: calc(50% - 30rem);
  margin: 0;
  border-radius: 12px;
  background-color: transparent;
  z-index: 100;
  border: none;
}

.dialog-enter-from {
  opacity: 0;
  transform: translateY(-30px);
}
.dialog-enter-active {
  transition: all 0.3s ease-out;
}
.dialog-enter-to {
  opacity: 1;
  transform: translateY(0);
}

.dialog-leave-from {
  opacity: 1;
  transform: translateY(0);
}
.dialog-leave-active {
  transition: all 0.3s ease-in;
}
.dialog-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

.round {
  position: absolute;
  cursor: pointer;
  top: 2%;
  right: 2%;
  background-color: #00a7e3;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  transition: all 0.2s ease-in-out;
}

.round:hover {
  box-shadow: rgba(255, 255, 255, 1) 0px 0px 0px 3px;
}

.round2 {
  position: absolute;
  cursor: pointer;
  top: 10%;
  right: 2%;
  background-color: #00a7e3;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  transition: all 0.2s ease-in-out;
}
.round2:hover {
  box-shadow: rgba(255, 255, 255, 1) 0px 0px 0px 3px;
}

.iconRound {
  color: white;
  margin-left: 0.75rem;
  margin-top: 0.75rem;
  font-size: 1.5rem;
}
</style>
