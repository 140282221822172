<template>
  <space>
    <sun></sun>
    <moon></moon>
  </space>
</template>

<style scoped>
space,
space * {
  border-radius: 50%;
  display: inline-block;
}
space {
  background: #fff;
  width: 350px;
  height: 350px;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  animation: luminance 2s infinite ease-in-out;
}
sun {
  background: #fa0;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  position: absolute;
  top: 100px;
  left: 80px;
  animation: rays 2s infinite ease-in-out;
}
moon {
  background: #fff;
  width: 190px;
  height: 190px;
  position: absolute;
  top: 100px;
  left: 100px;
  animation: transit 2s infinite ease-in-out, luminance 2s infinite ease-in-out;
  transform-origin: calc(50% - 5px) 55%;
}

@keyframes transit {
  0% {
    left: 100%;
    transform: translate(0, 5px);
  }
  25% {
    left: 22%;
    top: 100px;
    transform: translate(5px, 5px) rotate(0);
    width: 190px;
    height: 190px;
  }
  50% {
    top: 95px;
    width: 200px;
    height: 200px;
  }
  75% {
    left: 25%;
    top: 100px;
    transform: translate(5px, 5px) rotate(2turn);
    width: 190px;
    height: 190px;
  }
  100% {
    left: -50%;
    transform: translate(0, 5px) rotate(2turn);
  }
}
@keyframes luminance {
  0% {
    background: #fff;
  }
  25% {
    background: #fff;
  }
  75% {
    background: #fff;
  }
  100% {
    background: #fff;
  }
}
@keyframes rays {
  0% {
    box-shadow: 0 0 100px #fa0;
  }
  25% {
    box-shadow: 0 0 0 #fa0;
  }
  75% {
    box-shadow: 0 0 0 #fa0;
  }
  100% {
    box-shadow: 0 0 100px #fa0;
  }
}
</style>
