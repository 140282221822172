import { createStore } from "vuex";
import VuexPersistence from "vuex-persist";
import offerteModule from "../store/modules/offertes/index.js";

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
});

const store = createStore({
  modules: {
    offerte: offerteModule,
  },
  plugins: [vuexLocal.plugin],
});

export default store;
