<template>
  <div class="rootDiv">
    <div class="editDialog" v-if="!!showEdit">
      <teleport to="body">
        <div class="editBackdrop">
          <div class="editDialogView">
            <h3 style="text-align: center">Edit</h3>
            <h4 style="color: grey">Huidige waarde:</h4>
            <img
              style="width: 10rem; height: auto"
              v-if="
                fieldToEdit.toLowerCase().includes('img') ||
                fieldToEdit.toLowerCase().includes('logo')
              "
              :src="valueToEdit"
              alt=""
            />
            <p
              v-else
              style="font-size: smaller; color: grey"
              v-html="valueToEdit"
            ></p>
            <h4 style="color: grey">Nieuwe waarde:</h4>

            <input
              v-if="
                fieldToEdit.toLowerCase().includes('img') ||
                fieldToEdit.toLowerCase().includes('logo')
              "
              type="file"
              @change="updateImg($event, fieldToEdit, valueToEdit)"
            />
            <input v-else type="text" v-model="editedValue" />
            <img
              style="width: 10rem; height: auto"
              v-if="imgUrl"
              :src="editedValue"
              alt=""
            />
            <base-button style="float: left" @click="confirm('No')"
              >Terug</base-button
            >
            <base-button style="float: right" @click="confirm('Yes')"
              >Opslaan</base-button
            >
          </div>
        </div>
      </teleport>
    </div>
    <footer>
      <div class="usps">
        <div class="container">
          <div class="usp">
            <font-awesome-icon id="bolt" icon="bolt" />
            <div :class="{ active: editor }" style="display: inline-block">
              <span
                @click="
                  edit(
                    footer.lightningContainer0,
                    'Footer',
                    'lightningContainer0'
                  )
                "
                >{{ footer.lightningContainer0 }}</span
              >
            </div>
          </div>
          <div class="usp">
            <font-awesome-icon id="bolt" icon="bolt" />
            <div :class="{ active: editor }" style="display: inline-block">
              <span
                @click="
                  edit(
                    footer.lightningContainer1,
                    'Footer',
                    'lightningContainer1'
                  )
                "
                >{{ footer.lightningContainer1 }}</span
              >
            </div>
          </div>
          <div class="usp">
            <font-awesome-icon id="bolt" icon="bolt" />

            <div v-if="editor">
              <strong style="color: red">
                Deze tekst verschijnt als het om een huur-contract gaat.
              </strong>
              <div :class="{ active: editor }">
                <span
                  @click="
                    edit(
                      footer.lightningContainer2a,
                      'Footer',
                      'lightningContainer2a'
                    )
                  "
                  >{{ footer.lightningContainer2a }}
                </span>
              </div>
            </div>

            <div v-if="editor">
              <strong style="color: red">
                Deze tekst verschijnt als het om een koop-contract gaat.
              </strong>
              <div :class="{ active: editor }">
                <span
                  @click="
                    edit(
                      footer.lightningContainer2b,
                      'Footer',
                      'lightningContainer2b'
                    )
                  "
                  >{{ footer.lightningContainer2b }}</span
                >
              </div>
            </div>

            <span v-if="!editor">{{ uspText }} </span>
          </div>
        </div>
      </div>
      <div class="blocks">
        <div class="container">
          <div class="block">
            <div class="klantenVertellen" @click="redirect">
              <div class="rating">
                <div class="vlak"></div>
                <div class="number">{{ rate }}</div>
                <!-- <div class="number">{{ KlantenVertellen.rate }}</div> -->
              </div>
              <div class="image" :class="{ active: editor }">
                <img
                  @click="edit(footer.klantenImg, 'Footer', 'klantenImg')"
                  :src="footer.klantenImg"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div class="block">
            <div class="number clients">{{ voorgangers }}</div>
            <span>Klanten gingen je voor</span>
          </div>
          <div class="block">
            <div class="image" :class="{ active: editor }">
              <img
                @click="edit(footer.partnerImg2, 'Footer', 'partnerImg2')"
                :src="footer.partnerImg2"
              />
              <span>Partner van HalloStroom</span>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom">
        <div class="container">
          <strong>Heb je vragen?</strong>
          <br />
          <strong
            >Bel me op
            <a :href="callTo">{{ mainBar.Telefoonnummer }}</a>
          </strong>
          <div class="openinghours">{{ mainBar.Openingstijden }}</div>
          <div class="copyright">Copyright © {{ year }} HalloStroom</div>
          <!-- <div class="image" :class="{ active: editor }">
            <img
              @click="edit(footer.img, 'Footer', 'img')"
              class="mvpImg"
              id="mvpImg"
              :src="footer.img"
              alt=""
            />
          </div> -->
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showEdit: false,
      valueToEdit: "",
      editedValue: "",
      subColToEdit: "",
      fieldToEdit: "",
      indexToEdit: null,
      imgUrl: "",
      uploadValue: "",
      imageData: "",
    };
  },
  computed: {
    year() {
      return new Date().getFullYear();
    },
    offerte() {
      return this.$store.getters.getCurrentOfferte;
    },
    koop() {
      return this.offerte.offerteKoop;
    },
    loopTijd() {
      if (this.offerte.offerteFinancieringsvorm === "Huur 15 jaar") {
        return "15";
      } else {
        return "10";
      }
    },
    genData() {
      return this.$store.getters.getGenData;
    },
    KlantenVertellen() {
      return this.genData.klantenVertellen;
    },
    rate() {
      const r = this.KlantenVertellen.rate;
      const a = r.toString().replace(".", ",");
      return a;
    },
    voorgangers() {
      return (+this.genData.voorgangers)
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    },
    pages() {
      return this.$store.getters.getPages;
    },
    footer() {
      return this.pages.Footer;
    },
    uspText() {
      if (this.koop) {
        return this.footer.lightningContainer2b;
      } else {
        return this.footer.lightningContainer2a;
      }
    },
    mainBar() {
      return this.pages.MainBar;
    },
    callTo() {
      return "tel:" + "+31" + this.mainBar.Telefoonnummer.substring(1);
    },
    mailTo() {
      return "mailto:" + this.mainBar.Email;
    },
    editor() {
      return this.$store.getters.getEditor;
    },
    admin() {
      return this.$store.getters.getAdmin;
    },
  },
  methods: {
    redirect() {
      window
        .open(this.genData.klantenVertellen.viewReviewUrl, "_blank")
        .focus();
    },
    edit(value, subCol, field, index = null) {
      if (this.editor) {
        this.valueToEdit = value;
        this.subColToEdit = subCol;
        this.fieldToEdit = field;
        this.indexToEdit = index;
        this.showEdit = true;
      }
    },
    updateImg(event) {
      this.uploadValue = 0;
      this.imgUrl = null;
      this.imageData = event.target.files[0];
      this.onUpload();
    },

    onUpload() {
      this.imgUrl = null;
      const storageRef = firebase
        .storage()
        .ref(
          `Pages/${this.subColToEdit}/${this.fieldToEdit}/${this.imageData.name}`
        )
        .put(this.imageData);
      storageRef.on(
        `state_changed`,
        (snapshot) => {
          this.uploadValue =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          console.log(error.message);
        },
        () => {
          this.uploadValue = 100;
          storageRef.snapshot.ref.getDownloadURL().then((url) => {
            this.imgUrl = url;
            this.editedValue = this.imgUrl;
          });
        }
      );
    },
    async confirm(res) {
      if (res === "Yes") {
        let i = "";
        let field = "";
        if (this.indexToEdit === null) {
          i = null;
        } else {
          i = this.indexToEdit;
        }
        if (
          this.fieldToEdit.toLowerCase().includes("img") ||
          this.fieldToEdit.toLowerCase().includes("logo")
        ) {
          field = this.imageData.name;
        } else {
          field = this.editedValue;
        }

        const ad = {
          ...this.admin,
        };
        const payload = {
          index: i,
          collection: this.subColToEdit,
          field: this.fieldToEdit,
          value: this.editedValue,
          newValue: field,
          admin: ad,
          oldVal: this.valueToEdit,
        };
        this.showEdit = false;
        await this.$store.dispatch("editRecord", payload);

        this.valueToEdit = "";
        this.editedValue = "";
        this.subColToEdit = "";
        this.fieldToEdit = "";
        this.indexToEdit = null;
        this.showEdit = false;
        this.imgUrl = null;
        this.uploadValue = "";
        this.imageData = "";
      } else {
        this.valueToEdit = "";
        this.editedValue = "";
        this.subColToEdit = "";
        this.fieldToEdit = "";
        this.indexToEdit = null;
        this.showEdit = false;
        this.imgUrl = null;
        this.uploadValue = "";
        this.imageData = "";
      }
    },
  },
};
</script>

<style scoped>
@media (max-width: 748px) {
  footer .bottom .container {
    padding-top: 1rem;
    text-align: left;
  }

  footer .bottom {
    padding-bottom: 1rem;
  }

  footer {
    width: 100%;
    margin: auto;
    border-radius: 12px;
    display: block;
  }
  footer .usps {
    color: white;
    background-color: #00a7e3;
  }
  .mvpImg {
    display: none !important;
  }
}

.active {
  border: 1px dashed green;
  border-radius: 12px;
  transition: 0.3s all ease-in-out;
  padding: 0.5rem;
}

.active:hover {
  border: 1px solid green;
  cursor: pointer;
  border-radius: 12px;
  padding: 0.5rem;
  /* width: inherit; */
  /* height: inherit; */
}

input {
  display: block;
  width: 95%;
  border: 1px solid #cccccc;
  padding: 1rem 1rem 1rem;
  border-radius: 0.4rem;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#fafafa),
    to(white)
  );
  background-image: linear-gradient(to bottom, #fafafa 0%, white 100%);
  -webkit-transition: border-color 0.15s ease-in-out 0s;
  transition: border-color 0.15s ease-in-out 0s;
}

@media (min-width: 749px) {
  footer {
    margin-top: 1rem;
    display: block;
  }
  footer .usps {
    color: white;
    background-color: #00a7e3;
  }
  footer .usps .container {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding: 2.4rem 3rem;
  }
  footer .blocks .container {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding: 1.5rem 3rem;
  }
  footer .blocks .container .block .klantenVertellen .rating .vlak {
    width: 10rem;
    height: 6.5rem;
  }
  footer .blocks .container .block .klantenVertellen .rating .number {
    top: 1.3rem;
    font-size: 3.5rem;
  }
}

.container {
  position: relative;
  margin: auto;
  max-width: 80rem;
}
footer .usps .container .usp span {
  font-size: 1.2rem;
  font-weight: 700;
}

footer .blocks .container .block .klantenVertellen {
  display: -webkit-box;
  display: inline-block;
  cursor: pointer;
}

@media (min-width: 1101px) {
  footer .blocks .container .block {
    display: -webkit-box;
    display: inline-block;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    margin: 0 2rem;
  }
}

footer .blocks .container .block .klantenVertellen .rating .vlak {
  background: #00a7e3;
  -webkit-transform: rotate(-8deg);
  transform: rotate(-8deg);
  border-radius: 0.8rem;
  box-shadow: 0 1rem 3rem rgb(0 0 0 / 20%);
}

@media (min-width: 749px) {
}
footer .blocks .container .block .klantenVertellen .rating .number {
  position: absolute;
  width: 100%;
  text-align: center;
  color: white;
}
footer .blocks .container .block .number {
  font-family: "Karla", sans-serif;
  font-size: 3.5rem;
  line-height: 1.2;
  font-weight: 700;
}

footer .blocks .container .block .klantenVertellen .rating {
  position: relative;
  margin-right: 2rem;
}

@media (min-width: 749px) {
  footer .blocks .container .block .number.clients {
    margin-top: 2.4rem;
  }
  footer .blocks .container .block .klantenVertellen .image {
    width: 12rem;
  }
}
@media (max-width: 915px) {
  #mvpImg {
    display: none !important;
  }
}

footer .blocks .container .block img {
  width: 100%;
}

img {
  display: flex;
  width: 100%;
  height: auto;
  border: 0;
}

@media (min-width: 749px) {
  footer .blocks .container .block .number.clients {
    margin-top: 2.2rem;
  }
}
footer .blocks .container .block .number {
  font-family: "Karla", sans-serif;
  font-size: 2.5rem;
  line-height: 1;
  font-weight: 700;
}

@media (min-width: 749px) {
  footer .bottom .container {
    padding-bottom: 2rem;
    padding-left: 2rem;
    text-align: left;
  }
  footer .bottom .container .copyright {
    margin-top: 3rem;
    color: #b3b3b3;
    font-size: 0.8em;
  }
  footer .bottom .container {
    padding-top: 2rem;
    text-align: left;
  }
}

footer .bottom .container .image {
  position: absolute;
  bottom: 0;
  right: 0;
}

@media (min-width: 749px) {
  .container {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}

strong {
  font-weight: 700;
}

footer .bottom .container a {
  text-align: left;
  color: #44bace;
  outline: none;
  cursor: pointer;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  transition: ease-in-out 0.3s all;
  border-radius: 12px;
}

footer .bottom .container a:hover {
  text-align: left;
  color: white;
  background-color: #44bace;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  border-radius: 12px;
  outline: none;
  cursor: pointer;
}

a,
button,
.button {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
a {
  background-color: transparent;
}
*,
*:before,
*:after {
  box-sizing: border-box;
}
a:-webkit-any-link {
  color: -webkit-link;
  cursor: pointer;
  text-decoration: underline;
}

footer .bottom .container .openingshours {
  margin-top: 0.7rem;
  color: #4d4d4d;
  font-size: 0.9em;
}

#bolt {
  vertical-align: text-bottom;
  font-size: 1.5rem;
  margin-right: 1.5rem;
}

#mvpImg {
  width: 90%;
  margin-right: 0rem;
  /* display: flex; */
}

@media (max-width: 748px) {
  footer .blocks .container {
    display: none;
  }

  footer .usps .container {
    display: none;
  }
  footer .bottom .container {
    margin-left: 1rem;
    font-size: 1rem;
  }

  footer .bottom .container .copyright {
    margin-top: 1rem;
    color: #b3b3b3;
    font-size: 0.6em;
  }
}

.editBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 10;
}

.editDialogView {
  position: absolute;
  margin: auto;
  width: 30%;
  padding: 1rem;
  border-radius: 12px;
  background-color: #fff;
  left: 40%;
  right: auto;
  top: 30%;
}

.editDialogView dialog {
  max-width: 40rem;
  max-height: 60rem;
  /* z-index: 100; */
  border: none;
  overflow-y: auto;
  background-color: transparent;
}

@media (min-width: 768px) {
  dialog {
    left: calc(50% - 30rem);
    width: 60rem;
  }
}
</style>
