<template>
  <div class="rootDiv">
    <div v-if="!backNav" class="nextStepBlockFloater is-shown">
      <div class="block">
        <p>Klik hier voor een overzicht van al je producten</p>
        <div>
          <a class="button large hasIcon green" @click="next">
            <span>Volgende stap</span>
            <div class="icon-arrow-right">
              <font-awesome-icon icon="arrow-right" class="arrow-right" />
            </div>
          </a>
        </div>
      </div>
    </div>
    <div v-if="backNav" class="nextStepBlockFloater is-shown">
      <div class="block">
        <p>Klik hier om de vorige stap nog een keer te bekijken</p>
        <div>
          <a class="button large hasIcon green" @click="back">
            <div class="icon-arrow-left">
              <font-awesome-icon icon="arrow-left" class="arrow-right" />
            </div>
            <span>Vorige stap</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["stepInfo"],
  methods: {
    next() {
      this.$router.push(this.nextStep); // router
    },
    back() {
      if (this.$route.path.includes("2")) {
        this.$router.push(this.lastStep);
      } else {
        this.next();
      }
    },
  },
  computed: {
    backNav() {
      if (this.$route.path.includes("2") || this.$route.path.includes("3")) {
        return true;
      } else {
        return false;
      }
    },
    nextStep() {
      if (this.stepInfo === "a") {
        return "/offerteDetail/stap2a";
      } else {
        return "/offerteDetail/stap2";
      }
    },
    lastStep() {
     if (this.stepInfo === "a") {
        return "/offerteDetail/stap1a";
      } else {
        return "/offerteDetail/stap1";
      }
    },
  },
};
</script>

<style scoped>
@media (max-width: 748px) {
  .nextStepBlockFloater {
    position: fixed;
    z-index: 10;
    bottom: 0;
    width: 100%;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    background: #e1eef3;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    -webkit-transition: -webkit-transform 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
    transition: -webkit-transform 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
    transition: transform 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
    transition: transform 1s cubic-bezier(0.19, 1, 0.22, 1) 0s,
      -webkit-transform 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
  }

  .nextStepBlockFloater .block {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .nextStepBlockFloater .block p {
    max-width: 35rem;
    margin: 0;
    margin-right: 1rem;
  }
  .nextStepBlockFloater .block {
    width: 100%;
  }
  .nextStepBlockFloater .block p {
    display: none;
  }
  .nextStepBlockFloater .block .button {
    width: 100%;
  }
  p {
    margin: 0;
    margin-bottom: 2rem;
  }

  p {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }

  .nextStepBlockFloater.is-shown {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  .button {
    padding: 1rem;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    vertical-align: bottom;
    margin-left: 30%;
    position: relative;
    border: none;
    outline: none;
    text-decoration: none;
    cursor: pointer;
    user-select: none;
    border-radius: 0.5rem;
    font-family: "Karla", sans-serif;
    width: auto;
    white-space: normal;
    text-align: center !important;
    font-weight: 700;
    transition: all 0.15s ease-in-out 0s;
  }

  .button.large {
    font-size: 1rem;
    font-weight: bolder;
    text-transform: none;
  }

  .button.green {
    color: white;
    background-color: #60ab2d;
    transition: all 0.2s ease-in-out;
  }

  .icon-arrow-right, .icon-arrow-left {
    width: 5rem;
    height: 2rem;
    font-size: 1.5rem;
    margin-left: 1rem;
    transition: 0.3s all ease-in-out;
  }
}

@media (min-width: 749px) {
  .nextStepBlockFloater {
    position: fixed;
    z-index: 10;
    bottom: 0;
    width: 100%;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    background: #e1eef3;
    padding: 0.5rem;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    -webkit-transition: -webkit-transform 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
    transition: -webkit-transform 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
    transition: transform 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
    transition: transform 1s cubic-bezier(0.19, 1, 0.22, 1) 0s,
      -webkit-transform 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
  }
  .nextStepBlockFloater .block {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
  }

  .nextStepBlockFloater .block p {
    max-width: 35rem;
    margin: 0;
    margin-right: 2rem;
  }
  p {
    margin: 0;
    margin-bottom: 2rem;
  }

  p {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }

  .nextStepBlockFloater.is-shown {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  .button {
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    vertical-align: bottom;
    position: relative;
    margin: 0;
    border: none;
    outline: none;
    text-decoration: none;
    cursor: pointer;
    touch-action: manipulation;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-radius: 0.5rem;
    font-family: "Karla", sans-serif;
    width: auto;
    white-space: normal;
    text-align: center !important;
    font-weight: 700;
    -webkit-transition: all 0.15s ease-in-out 0s;
    transition: all 0.15s ease-in-out 0s;
  }

  .button.large {
    padding: 0rem 1rem 0rem;
    text-transform: none;
    font-size: 1.2rem;
    font-weight: bolder;
    line-height: 1.7;
  }

  .button.green {
    color: white;
    background-color: #60ab2d;
    transition: all 0.2s ease-in-out;
  }

  .button.green:hover {
    background-color: #83c51c;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .button.large.hasIcon {
    padding-right: 2rem !important;
  }

  .icon-arrow-right,
  .icon-arrow-left {
    width: 5rem;
    height: 5rem;
    font-size: 3rem;
    margin-left: 1rem;
    transition: 0.3s all ease-in-out;
  }

  .button.green:hover .icon-arrow-right,
  .button.green:hover .icon-arrow-left {
    width: 5rem;
    height: 5rem;
    font-size: 3rem;
    margin-left: 1rem;
    transform: translateX(10px);
  }

  .button.green:hover .icon-arrow-left,
  .button.green:hover .icon-arrow-left {
    width: 5rem;
    height: 5rem;
    font-size: 3rem;
    margin-left: 1rem;
    transform: translateX(-10px);
  }
}
</style>
