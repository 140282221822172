<template>
  <div class="container">
    <div class="wrapper">
      <h1>
        404 Oeps!
        <p>De pagina die je zoekt kon helaas niet gevonden worden.</p>
        <p>Probeer het later nog een keer!</p>
        <img :src="img" alt="" />
      </h1>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      img: require("../assets/Page404/404 - website 01.png"),
    };
  },
};
</script>

<style scoped>
.container {
  margin: 0;
  padding: 0;
  display: absolute;
  overflow: hidden;
  background-color: #00a7e3;
  height: 100vh;
  width: 100vw;
  color: white;
}
.wrapper {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 100%;
  width: 100%;
}

img {
  display: flex;
  margin-bottom: -1.5rem;
}

p {
  font-size: clamp(1rem, 2vw, 1.5rem);
  font-weight: 100;
}
</style>
