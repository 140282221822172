<template>
  <div class="rootDiv">
    <div class="agreeButtonBar">
      <div class="korting">
        <p>
          Deze offerte is helaas verlopen! Je kunt een nieuwe offerte afspraak
          maken door op de onderstaande knop te klikken. Het is natuurlijk ook
          mogelijk om ons direct te bellen:
          <a style="color: white" href="tel:+31307670078">030 767 00 78</a>
        </p>
      </div>
      <input
        @click="openCalendly"
        type="submit"
        name="BTNAkkoord"
        value="Plan een nieuwe afspraak"
        id="BTNAkkoord"
        class="button large wide green"
      />
      <div class="list green">
        <ul class="checkmarkList" v-if="loopTijd !== 'Koop'">
          <li>
            <font-awesome-icon id="check" class="icon-check" icon="check" />
            <span
              ><em>Snelle en vakkundige installatie</em> binnen een aantal
              weken</span
            >
          </li>
          <li>
            <font-awesome-icon id="check" class="icon-check" icon="check" />
            <span>Je hebt altijd <em>14 dagen bedenktijd</em></span>
          </li>
          <li>
            <font-awesome-icon id="check" class="icon-check" icon="check" />
            <span>
              Gegarandeerd <em>{{ loopTijd }} jaar lang</em> dezelfde lage
              maandhuur vastgezet</span
            >
          </li>
        </ul>
        <ul class="checkmarkList" v-else>
          <li>
            <font-awesome-icon id="check" class="icon-check" icon="check" />
            <span
              ><em>Achteraf betalen</em> nadat je systeem werkend is
              opgeleverd</span
            >
          </li>
          <li>
            <font-awesome-icon id="check" class="icon-check" icon="check" />
            <span
              ><em>10 jaar volledige garantie</em>, op de zonnepanelen en
              omvormer</span
            >
          </li>
          <li>
            <font-awesome-icon id="check" class="icon-check" icon="check" />
            <span>
              Tot 14 dagen <em>GRATIS annuleren</em> na accepteren van deze
              offerte
            </span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      checked: false,
      error: false,
      algemeneVoorwaarden:
        "https://firebasestorage.googleapis.com/v0/b/online-offerte-tool.appspot.com/o/AlgemeneVoorwaarden%2Fav_huur.pdf?alt=media&token=bc2e5054-b26e-4ee6-885a-98b20ac813dd",
      label: "",
      show: null,
    };
  },
  computed: {
    offerte() {
      return this.$store.getters.getCurrentOfferte;
    },
    korting() {
      return this.$store.getters.getKorting;
    },
    showKorting() {
      const e = new Date(this.offerte.offerteOfferteDatum);
      const f = e.toISOString().split("T")[0];
      const date = new Date(f).valueOf();
      const d = new Date().valueOf();

      if (
        this.korting.looptijd.includes(this.loopTijd) &&
        this.korting.kortingTot > d &&
        date >= this.korting.offerteCreatedAfter
      ) {
        return true;
      } else {
        return false;
      }
    },
    loopTijd() {
      if (this.offerte.offerteFinancieringsvorm === "Huur 15 jaar") {
        return "15";
      } else if (this.offerte.offerteFinancieringsvorm === "Huur 10 jaar") {
        return "10";
      } else {
        return "Koop";
      }
    },
  },
  methods: {
    dueDate(date) {
      return this.moment(date).format("DD.MM.YYYY");
    },
    openCalendly() {
      const url = `https://calendly.com/hallostroom/afspraak?name=${this.offerte.klantVoorNaam} ${this.offerte.klantAchterNaam}&email=${this.offerte.klantEmail}&a1=${this.offerte.klantTelefoon}&utm_source=offerte&utm_medium=email&utm_campaign=online-offerte-afspraak`;
      window.open(url);
    },
  },
  created() {
    this.moment = moment;
  },
};
</script>

<style scoped>
@media (max-width: 748px) {
  .checkbox {
    margin-top: 4rem;
    width: 100%;
  }
  .korting {
    width: 100%;
    padding: 0.2rem;
    background-color: #9b0000;
    border: 2px solid darkred;
    color: white;
    font-size: 0.8rem;
    font-weight: bold;
    text-align: center;
    border-radius: 12px;
    margin-top: 1rem;
    /* transition: ease-in-out all 0.3s; */
  }
  .agreeButtonBar .button {
    margin-top: 1.5rem;
  }
  .button.green {
    color: white;
    background-color: #60ab2d;
    background-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#83c63c),
      to(rgba(131, 198, 60, 0))
    );
    background-image: linear-gradient(
      to bottom,
      #83c63c 0%,
      rgba(131, 198, 60, 0) 100%
    );
    transition: all 0.2s ease-in-out;
  }

  .button.green:hover {
    color: white;
    background-color: #96c03a;
    background-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#96c03a),
      to(rgba(131, 198, 60, 0))
    );
    background-image: linear-gradient(
      to bottom,
      #96c03a 0%,
      rgba(131, 198, 60, 0) 100%
    );
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .button.large {
    width: 100%;
    padding: 1rem;
    font-size: 1rem;
  }
  button,
  html input[type="button"],
  input[type="reset"],
  input[type="submit"] {
    -webkit-appearance: button;
    cursor: pointer;
  }
  .button {
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    vertical-align: bottom;
    position: relative;
    margin: 0;
    border: none;
    outline: none;
    text-decoration: none;
    cursor: pointer;
    touch-action: manipulation;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-radius: 0.2rem;
    font-family: "Karla", sans-serif;
    width: auto;
    white-space: normal;
    text-align: center !important;
    font-weight: 700;
    -webkit-transition: all 0.15s ease-in-out 0s;
    transition: all 0.15s ease-in-out 0s;
  }

  .list {
    margin-bottom: 2.5rem;
  }
  .agreeButtonBar .list ul {
    display: inline-block;
    text-align: left;
  }
  .list ul {
    margin: 0;
    padding: 0;
    list-style: none;
    margin-bottom: 0.5rem;
  }

  ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }
  .list ul li {
    position: relative;
    display: block;
    padding: 0 0 0.3rem 2rem;
    line-height: 1.2;
    font-size: 1rem;
  }

  li {
    display: list-item;
    text-align: -webkit-match-parent;
  }

  .list.green #check {
    fill: #60ab2d;
    color: #60ab2d;
  }
  .list ul li #check {
    position: absolute;
    top: 0.2rem;
    left: 0;
    width: 0.7rem;
    height: 0.7rem;
    fill: #999999;
    color: #999999;
  }

  .list ul li em {
    font-style: normal;
    font-size: 0.9rem;
    color: #60ab2d;
  }

  span {
    font-size: 0.9rem !important;
  }
}

@media (min-width: 749px) {
  .agreeButtonBar {
    margin-top: 8rem;
  }
  .korting {
    width: 100%;
    padding: 0.5rem;
    background-color: #9b0000;
    border: 2px solid darkred;
    color: white;
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    border-radius: 12px;
    margin-bottom: 2rem;
    /* transition: ease-in-out all 0.3s; */
  }
}
.agreeButtonBar {
  text-align: center;
}

.text {
  display: block;
  position: relative;
  margin: auto;
  cursor: pointer;
  width: 60%;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.checkboxInput {
  text-align: center;
  display: inline-block;
  /* width: 5%; */
}

.akkoordText {
  font-size: 1rem;
  /* text-align: center; */
  display: inline-block;
  width: 95%;
}

.agreeButtonBar .checkbox a {
  position: relative;
  z-index: 10;
  color: #00a7e3;;
  text-decoration: underline;
}

a:not(.button) {
  color: inherit;
  text-decoration: none;
  outline: none;
}
a,
button,
.button {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
a {
  background-color: transparent;
}
*,
*:before,
*:after {
  box-sizing: border-box;
}
a:-webkit-any-link {
  color: -webkit-link;
  cursor: pointer;
  text-decoration: underline;
}

@media (min-width: 749px) {
  .list {
    margin-bottom: 2.5rem;
  }
  .agreeButtonBar .list ul {
    display: inline-block;
    text-align: left;
  }
  .list ul {
    margin: 0;
    padding: 0;
    list-style: none;
    margin-bottom: 0.5rem;
  }
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }
  ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }
  .list ul li {
    position: relative;
    display: block;
    padding: 0 0 0.3rem 2rem;
    line-height: 1.2;
  }
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }
  li {
    display: list-item;
    text-align: -webkit-match-parent;
  }

  .list.green #check {
    fill: #60ab2d;
    color: #60ab2d;
  }
  .list ul li #check {
    position: absolute;
    top: 0.2rem;
    left: 0;
    width: 1rem;
    height: 1rem;
    fill: #999999;
    color: #999999;
  }

  .list ul li em {
    font-style: normal;
    color: #60ab2d;
  }
  .button.large.wide {
    min-width: 35rem;
  }
  .agreeButtonBar .button {
    margin-top: 1.5rem;
  }
  .button.green {
    color: white;
    background-color: #60ab2d;
    background-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#83c63c),
      to(rgba(131, 198, 60, 0))
    );
    background-image: linear-gradient(
      to bottom,
      #83c63c 0%,
      rgba(131, 198, 60, 0) 100%
    );
    transition: all 0.2s ease-in-out;
  }

  .button.green:hover {
    color: white;
    background-color: #96c03a;
    background-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#96c03a),
      to(rgba(131, 198, 60, 0))
    );
    background-image: linear-gradient(
      to bottom,
      #96c03a 0%,
      rgba(131, 198, 60, 0) 100%
    );
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .button.large {
    padding: 1.5rem 1.5rem 1.5rem;
    font-size: 1.5rem;
    line-height: 1.2;
  }
  button,
  html input[type="button"],
  input[type="reset"],
  input[type="submit"] {
    -webkit-appearance: button;
    cursor: pointer;
  }
  .button {
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    vertical-align: bottom;
    position: relative;
    margin: 0;
    border: none;
    outline: none;
    text-decoration: none;
    cursor: pointer;
    touch-action: manipulation;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-radius: 0.2rem;
    font-family: "Karla", sans-serif;
    width: auto;
    white-space: normal;
    text-align: center !important;
    font-weight: 700;
    -webkit-transition: all 0.15s ease-in-out 0s;
    transition: all 0.15s ease-in-out 0s;
  }
}

a,
button,
.button {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}
.agreeButtonBar .list {
  margin: 3rem auto;
}

.formError li {
  background: rgba(233, 44, 100, 0.05);
  width: 100%;
  margin: auto;
  color: #e92c64;
  padding: 0.5rem 1rem 0.7rem;
  list-style-type: none;
  letter-spacing: -0.05rem;
  border-radius: 0.5rem;
}

/* CSS FOR DIALOG */

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 10;
}

.dialogView {
  position: absolute;
  margin: auto;
  left: 60%;
  right: auto;
  top: 2%;
}

dialog {
  max-width: 40rem;
  max-height: 60rem;
  /* z-index: 100; */
  border: none;
  overflow-y: auto;
  background-color: transparent;
}

.dialog-enter-from,
.dialog-leave-to {
  opacity: 0;
  transform: scale(0.8);
}

.dialog-enter-active {
  transition: all 0.3s ease-out;
}

.dialog-leave-active {
  transition: all 0.3 ease-in;
}

.dialog-enter-to,
.dialog-leave-from {
  opacity: 1;
  transform: scale(1);
}

@media (min-width: 768px) {
  dialog {
    left: calc(50% - 30rem);
    width: 60rem;
  }
}

.round {
  position: absolute;
  cursor: pointer;
  top: 2%;
  right: 2%;
  background-color: #00a7e3;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  transition: all 0.2s ease-in-out;
}

.round:hover {
  box-shadow: rgba(255, 255, 255, 1) 0px 0px 0px 3px;
}

.round2 {
  position: absolute;
  cursor: pointer;
  top: 10%;
  right: 2%;
  background-color: #00a7e3;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  transition: all 0.2s ease-in-out;
}
.round2:hover {
  box-shadow: rgba(255, 255, 255, 1) 0px 0px 0px 3px;
}

.iconRound {
  color: white;
  margin-left: 0.75rem;
  margin-top: 0.75rem;
  font-size: 1.5rem;
}

/* END CSS FOR DIALOG */
</style>
