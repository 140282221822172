<template>
  <div class="rootDiv">
    <div class="editDialog" v-if="!!showEdit">
      <teleport to="body">
        <div class="editBackdrop">
          <div class="editDialogView">
            <h3 style="text-align: center">Edit</h3>
            <h4 style="color: grey">Huidige waarde:</h4>
            <img
              style="width: 10rem; height: auto"
              v-if="
                fieldToEdit.toLowerCase().includes('img') ||
                fieldToEdit.toLowerCase().includes('logo')
              "
              :src="valueToEdit"
              alt=""
            />
            <p
              v-else
              style="font-size: smaller; color: grey"
              v-html="valueToEdit"
            ></p>
            <h4 style="color: grey">Nieuwe waarde:</h4>

            <input
              v-if="
                fieldToEdit.toLowerCase().includes('img') ||
                fieldToEdit.toLowerCase().includes('logo')
              "
              type="file"
              @change="updateImg($event, fieldToEdit, valueToEdit)"
            />
            <input v-else type="text" v-model="editedValue" />
            <img
              style="width: 10rem; height: auto"
              v-if="imgUrl"
              :src="editedValue"
              alt=""
            />
            <base-button style="float: left" @click="confirm('No')"
              >Terug
            </base-button>
            <base-button style="float: right" @click="confirm('Yes')"
              >Opslaan
            </base-button>
          </div>
        </div>
      </teleport>
    </div>
    <div class="dialog" v-if="!!show">
      <teleport to="body">
        <div class="backdrop">
          <div class="round">
            <font-awesome-icon
              class="iconRound"
              icon="times-circle"
              @click="tryClose"
            />
          </div>
          <div class="round2">
            <font-awesome-icon
              v-if="koop === false"
              class="iconRound"
              icon="download"
              @click="download"
            />
            <font-awesome-icon
              v-if="koop === true"
              class="iconRound"
              icon="download"
              @click="downloadKoop"
            />
          </div>
          <transition>
            <div class="dialogView">
              <dialog open>
                <vue-pdf-embed
                  v-if="koop === true"
                  :source="algemeneVoorwaardenKoop"
                />
                <vue-pdf-embed
                  v-if="koop === false"
                  :source="algemeneVoorwaarden"
                />
              </dialog>
            </div>
          </transition>
        </div>
      </teleport>
    </div>
    <div class="agreeButtonBar">
      <div class="flip-card" v-if="showKorting || acceptedInTime">
        <div class="flip-card-inner">
          <div class="flip-card-front">
            <font-awesome-layers full-width class="layer">
              <font-awesome-icon icon="certificate" class="certificate" />
              <font-awesome-layers-text
                class="textIcon"
                value="Bekijk de actie!"
              />
            </font-awesome-layers>
          </div>
          <div class="flip-card-back">
            <font-awesome-layers full-width class="layer">
              <font-awesome-icon icon="certificate" class="certificate" />
              <font-awesome-layers-text
                class="textIcon2"
                :value="korting.omschrijving"
              />
            </font-awesome-layers>
          </div>
        </div>
      </div>
      <div v-if="showKorting" class="korting">
        <font-awesome-icon
          id="exclamation-triangle"
          class="exclamation-triangle"
          icon="exclamation-triangle"
        />
        <p class="kortingText">
          Je hebt nog tot en met {{ dueDate(due) }} om deze offerte met korting te
          accepteren!
        </p>
      </div>

      <div v-if="!accepted && quoteAccepted" style="margin-bottom: 1.5rem">
        <label
          ><strong style="margin-right: 0.3rem">LET OP!</strong>
          Er is al een offerte geaccepteerd. Wanneer je ervoor kiest om deze
          offerte toch te accepteren, zullen alle andere offertes, inclusief de
          al geaccepteerde offerte, verlopen.
        </label>
      </div>
      <div
        class="checkbox-container"
        v-if="!accepted && !mobile && koop === false"
      >
        <input type="checkbox" @change="check" id="checked" />
        <label for="checked"
          ><strong style="margin-right: 0.3rem">Ja,</strong>
          ik ben akkoord met de
        </label>
        <a
          style="z-index: 10; margin-left: 0.3rem; text-decoration: underline"
          @click="openDialog('Algemene Voorwaarden')"
          >algemene voorwaarden</a
        >
      </div>

      <div
        class="checkbox-container"
        v-if="!accepted && !mobile && koop === true"
      >
        <input type="checkbox" @change="check" id="checked" />
        <label for="checked"
          ><strong style="margin-right: 0.3rem">Ja,</strong>
          ik ben akkoord met de
        </label>
        <a
          style="z-index: 10; margin-left: 0.3rem; text-decoration: underline"
          @click="openDialog('Algemene Voorwaarden Koop')"
          >algemene voorwaarden</a
        >
      </div>

      <div
        class="checkbox-container-mobile"
        v-if="!accepted && mobile && koop === false"
      >
        <input type="checkbox" @change="check" id="checked" />
        <label for="checked" :class="{ mobileLabel: mobile }"
          ><strong style="margin-right: 0.3rem">Ja,</strong>
          ik ben akkoord met de
        </label>
        <a
          style="
            z-index: 10;
            font-size: 0.9rem;
            text-decoration: underline;
            padding-left: 1.4rem;
          "
          @click="openDialog('Algemene Voorwaarden')"
          >algemene voorwaarden</a
        >
      </div>

      <div
        class="checkbox-container-mobile"
        v-if="!accepted && mobile && koop === true"
      >
        <input type="checkbox" @change="check" id="checked" />
        <label for="checked" :class="{ mobileLabel: mobile }"
          ><strong style="margin-right: 0.3rem">Ja,</strong>
          ik ben akkoord met de
        </label>
        <a
          style="
            z-index: 10;
            font-size: 0.9rem;
            text-decoration: underline;
            padding-left: 1.4rem;
          "
          @click="openDialog('Algemene Voorwaarden Koop')"
          >algemene voorwaarden</a
        >
      </div>

      <ul class="formError" v-if="!!error">
        <li>Ga akkoord met onze algemene voorwaarden om verder te gaan</li>
      </ul>
      <input
        v-if="!accepted && !quoteAccepted"
        @click="bestellen"
        type="submit"
        name="BTNAkkoord"
        value="Bestelling bevestigen"
        id="BTNAkkoord"
        class="button large wide green"
      />
      <input
        v-if="!accepted && quoteAccepted"
        @click="bestellen"
        type="submit"
        name="BTNAkkoord"
        value="Bestelling bevestigen"
        id="BTNAkkoord"
        class="button large wide green"
      />
      <input
        v-if="accepted && !mobile"
        @click="showStep3"
        type="submit"
        name="BTNAkkoord"
        :value="buttonText"
        id="BTNAkkoord"
        class="button large wide green"
      />
      <input
        v-if="accepted && mobile"
        name="BTNAkkoord"
        value="Je hebt deze offerte geaccepteerd!"
        id="BTNAkkoord"
        class="button large wide green"
      />

      <div class="list green">
        <ul class="checkmarkList" v-if="loopTijd !== 'Koop'">
          <strong style="color: red" v-if="editor">
            Deze punten verschijnen als het om een huur-contract gaat.
          </strong>
          <li>
            <font-awesome-icon id="check" class="icon-check" icon="check" />
            <span
              :class="{ active: editor }"
              @click="edit(Akkoord.bullet0a, 'Akkoord', 'bullet0a')"
              >{{ Akkoord.bullet0a }}</span
            >
            <span
              :class="{ active: editor }"
              @click="edit(Akkoord.bullet0b, 'Akkoord', 'bullet0b')"
              ><em> {{ Akkoord.bullet0b }} </em>
            </span>
            <span
              :class="{ active: editor }"
              @click="edit(Akkoord.bullet0c, 'Akkoord', 'bullet0c')"
              >{{ Akkoord.bullet0c }}
            </span>
          </li>
          <li>
            <font-awesome-icon id="check" class="icon-check" icon="check" />
            <span
              :class="{ active: editor }"
              @click="edit(Akkoord.bullet1a, 'Akkoord', 'bullet1a')"
            >
              {{ Akkoord.bullet1a }}
            </span>
            <span
              :class="{ active: editor }"
              @click="edit(Akkoord.bullet1b, 'Akkoord', 'bullet1b')"
              ><em> {{ Akkoord.bullet1b }}</em></span
            >
            <span
              :class="{ active: editor }"
              @click="edit(Akkoord.bullet1c, 'Akkoord', 'bullet1c')"
            >
              {{ Akkoord.bullet1c }}
            </span>
          </li>
          <li>
            <font-awesome-icon id="check" class="icon-check" icon="check" />
            <span
              :class="{ active: editor }"
              @click="edit(Akkoord.bullet2a, 'Akkoord', 'bullet2a')"
            >
              {{ Akkoord.bullet2a }}
            </span>
            <em>{{ loopTijd }} jaar lang</em>
            <span
              :class="{ active: editor }"
              @click="edit(Akkoord.bullet2b, 'Akkoord', 'bullet2b')"
            >
              {{ Akkoord.bullet2b }}
            </span>
          </li>
          <li>
            <font-awesome-icon id="check" class="icon-check" icon="check" />
            <span
              ><em>Direct</em> besparen en geen last meer van stijgende
              energieprijzen</span
            >
          </li>
        </ul>
        <ul class="checkmarkList" v-if="loopTijd === 'Koop' || editor">
          <strong style="color: red" v-if="editor">
            Deze punten verschijnen als het om een koop-contract gaat.
          </strong>
          <li>
            <font-awesome-icon id="check" class="icon-check" icon="check" />
            <span
              :class="{ active: editor }"
              @click="edit(Akkoord.bullet3a, 'Akkoord', 'bullet3a')"
              >{{ Akkoord.bullet3a }}
            </span>
            <span
              :class="{ active: editor }"
              @click="edit(Akkoord.bullet3b, 'Akkoord', 'bullet3b')"
              ><em>{{ Akkoord.bullet3b }}</em></span
            >
            <span
              :class="{ active: editor }"
              @click="edit(Akkoord.bullet3c, 'Akkoord', 'bullet3c')"
              >{{ Akkoord.bullet3c }}
            </span>
          </li>
          <li>
            <font-awesome-icon id="check" class="icon-check" icon="check" />
            <span
              :class="{ active: editor }"
              @click="edit(Akkoord.bullet4a, 'Akkoord', 'bullet4a')"
              >{{ Akkoord.bullet4a }}
            </span>
            <span
              :class="{ active: editor }"
              @click="edit(Akkoord.bullet4b, 'Akkoord', 'bullet4b')"
              ><em>{{ Akkoord.bullet4b }} </em></span
            >
            <span
              :class="{ active: editor }"
              @click="edit(Akkoord.bullet4c, 'Akkoord', 'bullet4c')"
              >{{ Akkoord.bullet4c }}
            </span>
          </li>
          <li>
            <font-awesome-icon id="check" class="icon-check" icon="check" />
            <span
              :class="{ active: editor }"
              @click="edit(Akkoord.bullet1a, 'Akkoord', 'bullet1a')"
              >{{ Akkoord.bullet1a }}
            </span>
            <span
              :class="{ active: editor }"
              @click="edit(Akkoord.bullet1b, 'Akkoord', 'bullet1b')"
              ><em> {{ Akkoord.bullet1b }} </em></span
            >
            <span
              :class="{ active: editor }"
              @click="edit(Akkoord.bullet1c, 'Akkoord', 'bullet1c')"
              >{{ Akkoord.bullet1c }}
            </span>
          </li>
          <li>
            <font-awesome-icon id="check" class="icon-check" icon="check" />
            <span
              ><em>Direct</em> besparen en geen last meer van stijgende
              energieprijzen</span
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import VuePdfEmbed from "vue-pdf-embed";
import moment from "moment";
import firebase from "firebase/app";
import "firebase/auth";

export default {
  props: ["version"],
  components: {
    VuePdfEmbed,
  },
  data() {
    return {
      checked: false,
      error: false,
      algemeneVoorwaarden:
        "https://firebasestorage.googleapis.com/v0/b/online-offerte-tool.appspot.com/o/AlgemeneVoorwaarden%2Fav_huur.pdf?alt=media&token=bc2e5054-b26e-4ee6-885a-98b20ac813dd",
      algemeneVoorwaardenKoop:
        "https://firebasestorage.googleapis.com/v0/b/online-offerte-tool.appspot.com/o/AlgemeneVoorwaarden%2Fav_koop.pdf?alt=media&token=19980fa3-b3e1-4ddb-bee4-f6cd55e8f7d3",
      label: "",
      show: null,
      showEdit: false,
      valueToEdit: "",
      editedValue: "",
      subColToEdit: "",
      fieldToEdit: "",
      indexToEdit: null,
      imgUrl: "",
      uploadValue: "",
      imageData: "",
      timeStampAkkoord: 0,
      updateDone: false,
    };
  },
  computed: {
    offertes() {
      return this.$store.getters.getOffertes;
    },
    quoteAccepted() {
      let result = false;
      this.offertes.forEach((val) => {
        if (val.offerteAkkoord && val.quoteStage === "Closed Won") {
          this.timeStampAkkoord = val.offerteAkkoord.offerteAkkoord;
          result = true;
        }
      });
      return result;
      // return this.$store.getters.getQuoteAccepted;
    },
    buttonText() {
      // if (this.offerte.klantKlantSegment === "Particulier") {
      return "Stap 3 nogmaals bekijken";
      // } else {
      //   return "Stap 2 nogmaals bekijken";
      // }
    },
    nextStep() {
      if (this.version === "a") {
        return "/offerteDetail/stap3a";
      } else {
        return "/offerteDetail/stap3";
      }
    },
    offerte() {
      return this.$store.getters.getCurrentOfferte;
    },
    versie() {
      return this.offerte.AB;
    },
    accepted() {
      if (
        this.offerte.offerteAkkoord &&
        this.offerte.quoteStage === "Closed Won"
      ) {
        return true;
      } else {
        return false;
      }
    },
    korting() {
      return this.offerte.korting;
    },
    acceptedInTime() {
      if (this.accepted && this.korting) {
        if (this.offerte.korting.verlengdTot) {
          if (
            this.offerte.offerteAkkoord.offerteAkkoord <=
            new Date(this.offerte.korting.verlengdTot).valueOf()
          ) {
            return true;
          } else {
            return false;
          }
        } else {
          if (
            this.offerte.offerteAkkoord.offerteAkkoord <=
            new Date(this.offerte.korting.geldigTot).valueOf()
          ) {
            return true;
          } else {
            return false;
          }
        }
      } else {
        return false;
      }
    },
    pages() {
      return this.$store.getters.getPages;
    },
    Akkoord() {
      return this.pages.Akkoord;
    },
    showKorting() {
      if (this.korting.geldigVan && !this.accepted) {
        const d = new Date().valueOf();
        let kortingGeldig = 0;
        if (this.korting.verlengdTot) {
          kortingGeldig = new Date(this.korting.verlengdTot).valueOf();
        } else {
          kortingGeldig = new Date(this.korting.geldigTot).valueOf();
        }
        if (
          d > kortingGeldig
          // ||  this.offerte.klantKlantSegment === "Zakelijk"
        ) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    },
    loopTijd() {
      if (this.offerte.offerteFinancieringsvorm === "Huur 15 jaar") {
        return "15";
      } else if (this.offerte.offerteFinancieringsvorm === "Huur 10 jaar") {
        return "10";
      } else {
        return "Koop";
      }
    },
    due() {
      const geldigTot = new Date(
        this.offerte.offerteGeldigheidsDatum
      ).valueOf();
      let kortingGeldig = 0;
      if (this.korting.verlengdTot) {
        kortingGeldig = new Date(this.korting.verlengdTot).valueOf();
      } else {
        kortingGeldig = new Date(this.korting.geldigTot).valueOf();
      }
      if (geldigTot < kortingGeldig) {
        return geldigTot;
      } else {
        return kortingGeldig;
      // kortingGeldig = new Date(this.offerte.offerteGeldigheidsDatum).valueOf();
      }
    },
    editor() {
      return this.$store.getters.getEditor;
    },
    admin() {
      return this.$store.getters.getAdmin;
    },
    medewerkerActive() {
      return this.offerte.medewerkerActive;
    },
    mederwerkerText() {
      if (this.medewerkerActive) {
        return "accepted by employee";
      } else {
        return "accepted by client";
      }
    },
    mobile() {
      if (window.innerWidth < 749) {
        return true;
      } else {
        return false;
      }
    },
    koop() {
      if (this.offerte.offerteKoop === true) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    switchOfferte() {
      let acceptedOfferte = {};
      this.offertes.forEach((val) => {
        if (val.offerteAkkoord && val.quoteStage === "Closed Won") {
          acceptedOfferte = val;
        }
      });
      const date = new Date().getTime();
      const doc = {
        id: acceptedOfferte.ID,
        timeStampOpened: date,
      };
      const currentOfferte = {
        ...acceptedOfferte,
        timeStampOpened: date,
      };
      this.$store.dispatch("setCurrentOfferte", currentOfferte);
      if (this.offerte.offerteTimeStampOpened) {
        this.$store.dispatch("updateOfferte", doc);
      } else {
        this.$store.dispatch("updateReOpened", doc);
      }
      this.$router.push("/offerteDetail/stap1");
    },
    dueDate(date) {
      return this.moment(date).locale("nl").format("DD MMMM YYYY");
    },
    openDialog(label) {
      if (!this.mobile) {
        this.label = label;
        this.show = true;
      } else if (this.koop === true) {
        window.open(this.algemeneVoorwaardenKoop);
      } else {
        window.open(this.algemeneVoorwaarden);
      }
    },
    tryClose() {
      this.show = false;
    },
    download() {
      fetch(this.algemeneVoorwaarden, {
        method: "GET",
      })
        .then((response) => response.blob())
        .then((blob) => {
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement("a");
          a.href = url;
          a.download = `${this.label}.pdf`;
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();
          a.remove(); //afterwards we remove the element again
        });
      this.show = false;
    },
    downloadKoop() {
      fetch(this.algemeneVoorwaardenKoop, {
        method: "GET",
      })
        .then((response) => response.blob())
        .then((blob) => {
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement("a");
          a.href = url;
          a.download = `${this.label}.pdf`;
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();
          a.remove(); //afterwards we remove the element again
        });
      this.show = false;
    },
    check() {
      // check-box algemene voorwaarden
      this.checked = !this.checked;
    },
    showStep3() {
      this.$router.push(this.nextStep);
    },
    async bestellen() {
      const array = [];
      let object = {};
      this.offertes.forEach((val) => {
        object = JSON.parse(JSON.stringify(val));
        object.versie = this.versie;
        array.push(object);
      });

      if (this.checked === false) {
        // check of de checkbox is gevinkt
        this.error = true;
      } else {
        let eventMessage = "";
        if (this.versie === 0) {
          eventMessage = "OK - A - " + this.mederwerkerText;
        } else {
          eventMessage = "OK - B - " + this.mederwerkerText;
        }
        if (this.mobile) {
          eventMessage = eventMessage + " - Mob";
        } else {
          eventMessage = eventMessage + " - Desk";
        }

        firebase.analytics().logEvent(eventMessage);

        await fetch("https://api.ipify.org?format=json") // ophalen van het ip-adres
          .then((x) => x.json())
          .then(({ ip }) => {
            this.$store.dispatch("updateAkkoord", {
              id: this.offerte.ID,
              ipAdress: ip,
              Medewerker_Geaccepteerd: this.medewerkerActive,
              Tijdstempel: this.timeStampAkkoord,
            });
            this.error = false;
          })
          .then(() => {
            let newOff = [];
            let object = {};
            object = this.offerte;
            object.versie = this.versie;
            newOff.push(JSON.parse(JSON.stringify(object)));
            this.offertes.forEach((val) => {
              if (val.ID !== this.offerte.ID) {
                val.versie = this.versie;
                val.offerteGeldigheidsDatum = new Date().valueOf() - 1000;
                newOff.push(JSON.parse(JSON.stringify(val)));
              }
            });
            this.$store.dispatch("setOffertes", newOff);
          });
        if (this.versie === 1 && this.mobile) {
          this.$emit("accepted");
        } else {
          this.$router.push(this.nextStep);
        }
      }
    },
    edit(value, subCol, field, index = null) {
      if (this.editor) {
        this.valueToEdit = value;
        this.subColToEdit = subCol;
        this.fieldToEdit = field;
        this.indexToEdit = index;
        this.showEdit = true;
      }
    },
    updateImg(event) {
      this.uploadValue = 0;
      this.imgUrl = null;
      this.imageData = event.target.files[0];
      this.onUpload();
    },

    onUpload() {
      this.imgUrl = null;
      const storageRef = firebase
        .storage()
        .ref(
          `Pages/${this.subColToEdit}/${this.fieldToEdit}/${this.imageData.name}`
        )
        .put(this.imageData);
      storageRef.on(
        `state_changed`,
        (snapshot) => {
          this.uploadValue =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          console.log(error.message);
        },
        () => {
          this.uploadValue = 100;
          storageRef.snapshot.ref.getDownloadURL().then((url) => {
            this.imgUrl = url;
            this.editedValue = this.imgUrl;
          });
        }
      );
    },
    async confirm(res) {
      if (res === "Yes") {
        let i = "";
        let field = "";
        if (this.indexToEdit === null) {
          i = null;
        } else {
          i = this.indexToEdit;
        }
        if (
          this.fieldToEdit.toLowerCase().includes("img") ||
          this.fieldToEdit.toLowerCase().includes("logo")
        ) {
          field = this.imageData.name;
        } else {
          field = this.editedValue;
        }

        const ad = {
          ...this.admin,
        };
        const payload = {
          index: i,
          collection: this.subColToEdit,
          field: this.fieldToEdit,
          value: this.editedValue,
          newValue: field,
          admin: ad,
          oldVal: this.valueToEdit,
        };
        this.showEdit = false;
        await this.$store.dispatch("editRecord", payload);

        this.valueToEdit = "";
        this.editedValue = "";
        this.subColToEdit = "";
        this.fieldToEdit = "";
        this.indexToEdit = null;
        this.showEdit = false;
        this.imgUrl = null;
        this.uploadValue = "";
        this.imageData = "";
      } else {
        this.valueToEdit = "";
        this.editedValue = "";
        this.subColToEdit = "";
        this.fieldToEdit = "";
        this.indexToEdit = null;
        this.showEdit = false;
        this.imgUrl = null;
        this.uploadValue = "";
        this.imageData = "";
      }
    },
  },
  created() {
    this.moment = moment;
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.userActive = true;
      } else {
        this.userActive = false;
      }
    });
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}

.mobileLabel {
  width: 100%;
}

.checkbox-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}

.checkbox-container label {
  cursor: pointer;
  display: flex;
}

.checkbox-container input[type="checkbox"] {
  cursor: pointer;
  opacity: 0;
  position: absolute;
}

.checkbox-container label::before {
  content: "";
  width: 1em;
  height: 1em;
  border: 0.05em solid black;
  border-radius: 0.15em;
  margin-right: 0.5em;
}

.checkbox-container input[type="checkbox"]:checked + label::before {
  content: "\002714";
  background-color: #96c03a;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.layer {
  width: 100%;
  height: 100%;
}

.textIcon {
  font-size: 100%;
  color: white;
  font-weight: 700;
}

.textIcon2 {
  font-size: 80%;
  color: white;
  font-weight: 700;
}

.certificate {
  width: 100%;
  height: 100%;
  color: #60ab2d;
}

.flip-card {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;
  background-color: transparent;
  width: 170px;
  height: 170px;
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {
  background-color: white;
  color: white;
}

.flip-card-back {
  background-color: white;
  color: white;
  transform: rotateY(180deg);
}

.active {
  border: 1px dashed #60ab2d;
  border-radius: 12px;
  transition: 0.3s all ease-in-out;
}

.active:hover {
  border: 1px solid #60ab2d;
  cursor: pointer;
  border-radius: 12px;
  /* width: inherit; */
  /* height: inherit; */
}

input {
  display: block;
  width: 95%;
  border: 1px solid #cccccc;
  padding: 1rem 1rem 1rem;
  border-radius: 0.4rem;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#fafafa),
    to(white)
  );
  background-image: linear-gradient(to bottom, #fafafa 0%, white 100%);
  -webkit-transition: border-color 0.15s ease-in-out 0s;
  transition: border-color 0.15s ease-in-out 0s;
}

@media (max-width: 748px) {
  .checkbox-container-mobile {
    display: inline-block;
    text-align: left;
    /* margin-bottom: 2rem; */
  }

  .checkbox-container-mobile label {
    cursor: pointer;
    display: flex;
  }

  .checkbox-container-mobile input[type="checkbox"] {
    cursor: pointer;
    opacity: 0;
    position: absolute;
  }

  .checkbox-container-mobile label::before {
    content: "";
    width: 1em;
    height: 1em;
    border: 0.05em solid black;
    border-radius: 0.15em;
    margin-right: 0.5em;
  }

  .checkbox-container-mobile input[type="checkbox"]:checked + label::before {
    content: "\002714";
    background-color: #96c03a;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
  }

  .checkbox {
    margin-top: 1.5rem;
    width: 100%;
  }

  .kortingText {
    display: inline-block;
  }

  .korting {
    width: 100%;
    background-color: #fff;
    border: 2px solid #f39332;
    color: #f39332;
    font-weight: 700;
    font-size: 0.8rem;
    text-align: center;
    border-radius: 12px;
    transition: ease-in-out all 0.3s;
    margin-bottom: 1rem;
  }

  .exclamation-triangle {
    display: none;
  }

  .button.green {
    color: white;
    background-color: #60ab2d;
    background-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#83c63c),
      to(rgba(131, 198, 60, 0))
    );
    background-image: linear-gradient(
      to bottom,
      #83c63c 0%,
      rgba(131, 198, 60, 0) 100%
    );
    transition: all 0.2s ease-in-out;
  }

  .button.green:hover {
    color: white;
    background-color: #96c03a;
    background-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#96c03a),
      to(rgba(131, 198, 60, 0))
    );
    background-image: linear-gradient(
      to bottom,
      #96c03a 0%,
      rgba(131, 198, 60, 0) 100%
    );
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .button.large {
    margin-top: 1rem;
    width: 100%;
    padding: 1rem 1rem 1rem;
    font-size: 1rem;
    line-height: 1;
  }

  button,
  html input[type="button"],
  input[type="reset"],
  input[type="submit"] {
    -webkit-appearance: button;
    cursor: pointer;
  }

  .button {
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    vertical-align: bottom;
    position: relative;
    margin: 0;
    border: none;
    outline: none;
    text-decoration: none;
    cursor: pointer;
    touch-action: manipulation;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-radius: 12px;
    font-family: "Karla", sans-serif;
    width: auto;
    white-space: normal;
    text-align: center !important;
    font-weight: 700;
    -webkit-transition: all 0.15s ease-in-out 0s;
    transition: all 0.15s ease-in-out 0s;
  }

  a,
  button,
  .button {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    color: inherit;
    font: inherit;
    margin: 0;
  }

  .agreeButtonBar .list {
    margin: 3rem auto;
  }

  .agreeButtonBar {
    text-align: center;
  }

  .text {
    display: inline-block;
    margin: auto;
    cursor: pointer;
    width: 60%;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .checkboxInput {
    display: inline-block;
    width: 5%;
  }

  .akkoordText {
    font-size: 0.9rem;
    margin-bottom: 1rem;
    display: inline-block;
    width: 95%;
  }

  .agreeButtonBar .checkbox a {
    position: relative;
    z-index: 10;
    color: #00a7e3;
    text-decoration: underline;
  }

  a:not(.button) {
    color: inherit;
    text-decoration: none;
    outline: none;
  }

  a,
  button,
  .button {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  a {
    background-color: transparent;
  }

  a:-webkit-any-link {
    color: -webkit-link;
    cursor: pointer;
    text-decoration: underline;
  }

  .list {
    margin-bottom: 2.5rem;
  }

  .agreeButtonBar .list ul {
    display: inline-block;
    text-align: left;
  }

  .list ul {
    margin: 0;
    padding: 0;
    list-style: none;
    margin-bottom: 0.5rem;
  }

  ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }

  .list ul li {
    position: relative;
    display: block;
    padding: 0 0 0.3rem 2rem;
    line-height: 1.2;
    font-size: 1rem;
  }

  li {
    display: list-item;
    text-align: -webkit-match-parent;
  }

  .list.green #check {
    fill: #60ab2d;
    color: #60ab2d;
  }

  .list ul li #check {
    position: absolute;
    top: 0.2rem;
    left: 0;
    width: 0.7rem;
    height: 0.7rem;
    fill: #999999;
    color: #999999;
  }

  .list ul li em {
    font-style: normal;
    font-size: 0.9rem;
    color: #60ab2d;
  }

  span,
  label {
    font-size: 0.9rem !important;
  }
}

@media (min-width: 749px) {
  .button.large.wide {
    min-width: 35rem;
  }

  .agreeButtonBar {
    margin-top: 0rem;
  }

  .kortingText {
    display: inline-block;
  }

  .korting {
    width: 100%;
    padding: 0.5rem;
    background-color: #fff;
    border: 2px solid #f39332;
    color: #f39332;
    font-weight: 700;
    font-size: 1.1rem;
    text-align: center;
    border-radius: 12px;
    margin-bottom: 2rem;
    transition: ease-in-out all 0.3s;
  }

  .exclamation-triangle {
    font-size: 3rem;
    color: #f39332;
    vertical-align: middle;
    text-align: center;
    margin-right: 3rem;
  }

  .agreeButtonBar .button {
    margin-top: 1.5rem;
  }

  .button.green {
    color: white;
    background: #60ab2d;
    transition: all 0.2s ease-in-out;
  }

  .button.green:hover {
    color: white;
    background-color: #83c51c;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 8px 16px;
  }

  .button.large {
    padding: 1.5rem 1.5rem 1.5rem;
    font-size: 1.5rem;
    line-height: 1.2;
  }

  button,
  html input[type="button"],
  input[type="reset"],
  input[type="submit"] {
    -webkit-appearance: button;
    cursor: pointer;
  }

  .button {
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    vertical-align: bottom;
    position: relative;
    margin: 0;
    border: none;
    outline: none;
    text-decoration: none;
    cursor: pointer;
    touch-action: manipulation;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-radius: 12px;
    font-family: "Karla", sans-serif;
    width: auto;
    white-space: normal;
    text-align: center !important;
    font-weight: 700;
    -webkit-transition: all 0.15s ease-in-out 0s;
    transition: all 0.15s ease-in-out 0s;
  }

  a,
  button,
  .button {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    color: inherit;
    font: inherit;
    margin: 0;
  }

  .agreeButtonBar .list {
    margin: 3rem auto;
  }

  .agreeButtonBar {
    text-align: center;
  }

  .text {
    display: inline-block;
    margin: auto;
    cursor: pointer;
    width: 60%;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .checkboxInput {
    display: inline-block;
    width: 5%;
  }

  .akkoordText {
    font-size: 1rem;
    display: inline-block;
    width: 95%;
  }

  .agreeButtonBar .checkbox a {
    position: relative;
    z-index: 10;
    color: #00a7e3;
    text-decoration: underline;
  }

  a:not(.button) {
    color: inherit;
    text-decoration: none;
    outline: none;
  }

  a,
  button,
  .button {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  a {
    background-color: transparent;
  }

  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  a:-webkit-any-link {
    color: -webkit-link;
    cursor: pointer;
    text-decoration: underline;
  }

  .list {
    margin-bottom: 2.5rem;
  }

  .agreeButtonBar .list ul {
    display: inline-block;
    text-align: left;
  }

  .list ul {
    margin: 0;
    padding: 0;
    list-style: none;
    margin-bottom: 0.5rem;
  }

  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }

  .list ul li {
    position: relative;
    display: block;
    padding: 0 0 0.3rem 2rem;
    line-height: 1.2;
  }

  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  li {
    display: list-item;
    text-align: -webkit-match-parent;
  }

  .list.green #check {
    fill: #60ab2d;
    color: #60ab2d;
  }

  .list ul li #check {
    position: absolute;
    top: 0.2rem;
    left: 0;
    width: 1rem;
    height: 1rem;
    fill: #999999;
    color: #999999;
  }

  .list ul li em {
    font-style: normal;
    color: #60ab2d;
  }
}

strong {
  font-style: normal;
  color: #60ab2d;
}

.formError li {
  background: rgba(233, 44, 100, 0.05);
  width: 100%;
  margin: auto;
  color: #e92c64;
  padding: 0.5rem 1rem 0.7rem;
  list-style-type: none;
  letter-spacing: -0.05rem;
  border-radius: 12px;
}

/* CSS FOR DIALOG */

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 10;
}

.dialogView {
  position: absolute;
  margin: auto;
  left: 60%;
  right: auto;
  top: 2%;
}

dialog {
  max-width: 40rem;
  max-height: 60rem;
  /* z-index: 100; */
  border: none;
  overflow-y: auto;
  background-color: transparent;
}

.dialog-enter-from,
.dialog-leave-to {
  opacity: 0;
  transform: scale(0.8);
}

.dialog-enter-active {
  transition: all 0.3s ease-out;
}

.dialog-leave-active {
  transition: all 0.3 ease-in;
}

.dialog-enter-to,
.dialog-leave-from {
  opacity: 1;
  transform: scale(1);
}

@media (min-width: 768px) {
  dialog {
    left: calc(50% - 30rem);
    width: 60rem;
  }
}

.round {
  position: absolute;
  cursor: pointer;
  top: 2%;
  right: 2%;
  background-color: #00a7e3;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  transition: all 0.2s ease-in-out;
}

.round:hover {
  box-shadow: rgba(255, 255, 255, 1) 0px 0px 0px 3px;
}

.round2 {
  position: absolute;
  cursor: pointer;
  top: 10%;
  right: 2%;
  background-color: #00a7e3;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  transition: all 0.2s ease-in-out;
}

.round2:hover {
  box-shadow: rgba(255, 255, 255, 1) 0px 0px 0px 3px;
}

.iconRound {
  color: white;
  margin-left: 0.75rem;
  margin-top: 0.75rem;
  font-size: 1.5rem;
}

.editBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 10;
}

.editDialogView {
  position: absolute;
  margin: auto;
  width: 30%;
  padding: 1rem;
  border-radius: 12px;
  background-color: #fff;
  left: 40%;
  right: auto;
  top: 30%;
}

.editDialogView dialog {
  max-width: 40rem;
  max-height: 60rem;
  /* z-index: 100; */
  border: none;
  overflow-y: auto;
  background-color: transparent;
}

@media (min-width: 768px) {
  dialog {
    left: calc(50% - 30rem);
    width: 60rem;
  }
}

/* END CSS FOR DIALOG */
</style>
