<template>
  <div class="rootDiv">
    <div class="dialog" v-if="!!show">
      <teleport to="body">
        <div class="backdrop">
          <div class="round" @click="tryClose">
            <font-awesome-icon class="iconRound" icon="times-circle" />
          </div>
          <div class="round2" @click="download">
            <font-awesome-icon class="iconRound" icon="download" />
          </div>
          <transition>
            <div class="dialogView">
              <dialog open>
                <vue-pdf-embed :source="productBladUrl" />
              </dialog>
            </div>
          </transition>
        </div>
      </teleport>
    </div>

    <div class="textueel">
      <div class="columns-2">
        <div class="column">
          <div class="list">
            <strong>Persoonlijke offerte</strong><br />
            <span>Offertenummer: {{ offerte.offerteOfferteNummer }}</span
            ><br /><br />
            <strong>Type pakket</strong>
            <ul class="checkmarkList">
              <li>
                <font-awesome-icon id="check" class="icon-check" icon="check" />
                <span>{{ offerte.productPakketNaam }} </span>
              </li>
              <li v-if="!koop">
                <font-awesome-icon id="check" class="icon-check" icon="check" />
                <span>Huurperiode {{ loopTijdMaanden }} maanden</span>
              </li>
            </ul>
          </div>
          <div class="list">
            <strong>Zonnepanelen</strong>
            <ul class="checkmarkList">
              <li>
                <font-awesome-icon id="check" class="icon-check" icon="check" />
                <span
                  >{{ offerte.offerteAantalPanelen }}
                  {{ offerte.offerteMerkZonnepanelen }}</span
                >
              </li>
              <li>
                <font-awesome-icon id="check" class="icon-check" icon="check" />
                <span>Kleur {{ offerte.productKleurPanelen }} </span>
              </li>
              <!-- <li>
                <font-awesome-icon id="check" class="icon-check" icon="check" />
                <span>{{ offerte.productZonnepaneelpag2 }} </span>
              </li> -->
            </ul>
            <a
              class="downloadLink"
              @click="
                openDialog(
                  offerte.productInfosheetZonnepanelen,
                  'Info ZonnePanelen'
                )
              "
            >
              <font-awesome-icon id="download" icon="download" />
              <span>Download productblad</span>
            </a>
            <br />
            <a
              v-if="offerte.productSpecSheets"
              class="downloadLink"
              @click="
                openDialog(offerte.productSpecSheets, 'Info ZonnePanelen')
              "
            >
              <font-awesome-icon id="download" icon="download" />
              <span>Download Specificatieblad</span>
            </a>
          </div>
        </div>
        <div class="column" style="margin-right: 2rem">
          <div class="list">
            <strong>Omvormer {{ offerte.productOptimizer }} </strong>
            <ul class="checkmarklist">
              <li>
                <font-awesome-icon id="check" class="icon-check" icon="check" />
                <span> {{ offerte.offerteMerkOmvormer }} </span>
              </li>
            </ul>
            <a
              class="downloadLink"
              @click="
                openDialog(offerte.productInfosheetOmvormer, 'Info Omvormer')
              "
            >
              <font-awesome-icon id="download" icon="download" />
              <span>Download productblad</span>
            </a>
          </div>
          <!-- <div class="list">
            <strong>Service</strong>
            <ul class="checkmarkList">
              <li>
                <font-awesome-icon id="check" class="icon-check" icon="check" />
                <span
                  :class="{ active: editor }"
                  @click="
                    edit(
                      bestelOverzichtPage.service0,
                      'BestelOverzicht',
                      'service0'
                    )
                  "
                  >{{ bestelOverzichtPage.service0 }}
                </span>
              </li>
              <li>
                <font-awesome-icon id="check" class="icon-check" icon="check" />
                <span
                  :class="{ active: editor }"
                  @click="
                    edit(
                      bestelOverzichtPage.service1,
                      'BestelOverzicht',
                      'service1'
                    )
                  "
                  >{{ bestelOverzichtPage.service1 }}</span
                >
              </li>
              <li>
                <font-awesome-icon id="check" class="icon-check" icon="check" />
                <span
                  :class="{ active: editor }"
                  @click="
                    edit(
                      bestelOverzichtPage.service2,
                      'BestelOverzicht',
                      'service2'
                    )
                  "
                  >{{ bestelOverzichtPage.service2 }}</span
                >
              </li>
            </ul>
          </div> -->
        </div>
      </div>

      <div v-if="showInfo">
        <div class="extraInfo" v-for="(extra, index) in extraInfo" :key="index">
          <h3>{{ extra.header }}</h3>
          <p>{{ extra.paragraph }}</p>
          <hr />
        </div>
      </div>
      <div class="listExpandBar" v-if="koop" @click="toggleInfo">
        <a class="button small hasIcon blue js-triggerCollapsable">
          <span>Alle details van jouw pakket</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import VuePdfEmbed from "vue-pdf-embed";

export default {
  components: {
    VuePdfEmbed,
  },
  data() {
    return {
      show: null,
      productBladUrl: "",
      label: "",
      showInfo: false,
      showEdit: false,
      valueToEdit: "",
      editedValue: "",
      subColToEdit: "",
      fieldToEdit: "",
      indexToEdit: null,
      imgUrl: "",
      uploadValue: "",
      imageData: "",
      specSheet: "",
    };
  },
  computed: {
    offerte() {
      return this.$store.getters.getCurrentOfferte;
    },
    multiple() {
      if (this.offerte.productSpecSheets) {
        return true;
      } else {
        return false;
      }
    },
    koop() {
      // return true;
      return this.offerte.offerteKoop;
    },
    loopTijdMaanden() {
      if (this.offerte.offerteFinancieringsvorm === "Huur 15 jaar") {
        return "180";
      } else {
        return "120";
      }
    },
    pages() {
      return this.$store.getters.getPages;
    },
    bestelOverzichtPage() {
      return this.pages.BestelOverzicht;
    },
    extraInfo() {
      return this.pages.ExtraInfo;
    },
    editor() {
      return this.$store.getters.getEditor;
    },
    admin() {
      return this.$store.getters.getAdmin;
    },
    pakketUrl() {
      return this.offerte.productPakketImage;
    },
    mobile() {
      if (window.innerWidth < 749) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    toggleInfo() {
      this.showInfo = !this.showInfo;
    },
    openDialog(v, label) {
      if (!this.mobile) {
        this.productBladUrl = v;
        this.label = label;
        this.show = true;
      } else {
        window.open(
          v,
          "directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no"
        );
      }
    },
    tryClose() {
      this.show = false;
    },
    download() {
      fetch(this.productBladUrl, {
        method: "GET",
      })
        .then((response) => response.blob())
        .then((blob) => {
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement("a");
          a.href = url;
          a.download = `${this.label}.pdf`;
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();
          a.remove(); //afterwards we remove the element again
        });
      this.show = false;
    },
  },
};
</script>

<style scoped>
.active {
  border: 1px dashed green;
  border-radius: 12px;
  transition: 0.3s all ease-in-out;
  /* padding: 0.5rem; */
}

.active:hover {
  border: 1px solid green;
  cursor: pointer;
  border-radius: 12px;
  /* padding: 0.5rem; */
}

@media (min-width: 749px) {
  .pakketUrl {
    width: 40%;
    margin-top: -15rem;
    margin-left: 18rem;
  }
  select {
    display: block;
    width: 94%;
    border: 1px solid #cccccc;
    padding: 0.4rem;
    /* padding: 1rem 1rem 1rem 1rem; */
    /* border-radius: 0.4rem; */
    background-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#fafafa),
      to(white)
    );
    background-image: linear-gradient(to bottom, #fafafa 0%, white 100%);
    -webkit-transition: border-color 0.15s ease-in-out 0s;
    transition: border-color 0.15s ease-in-out 0s;
  }
}

@media (max-width: 748px) {
  h3,
  .h3 {
    margin: 0;
    font-family: "Karla", sans-serif;
    text-transform: uppercase;
    font-weight: 700;
  }
  h3 {
    display: block;
    font-size: 1.2rem;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
  }
  .pakketUrl {
    width: 40%;
    display: block;
    margin: auto;
    margin-top: -7rem;
    /* margin-left: 18rem; */
  }

  .columns-2 {
    display: block;
  }
  .columns-2 .column {
    display: block;
    text-align: left;
  }
  .columns-2 .column .list {
    display: block;
    width: 100%;
    font-size: 0.8rem;
  }

  .textueel {
    margin-top: 1rem;
  }

  .round {
    position: absolute;
    cursor: pointer;
    top: 2%;
    right: 2%;
    background-color: #00a7e3;
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    transition: all 0.2s ease-in-out;
  }

  .round:hover {
    box-shadow: rgba(255, 255, 255, 1) 0px 0px 0px 3px;
  }

  .round2 {
    position: absolute;
    cursor: pointer;
    top: 10%;
    right: 2%;
    background-color: #00a7e3;
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    transition: all 0.2s ease-in-out;
  }
  .round2:hover {
    box-shadow: rgba(255, 255, 255, 1) 0px 0px 0px 3px;
  }

  .iconRound {
    color: white;
    margin-left: 0.75rem;
    margin-top: 0.75rem;
    font-size: 1.5rem;
  }

  .list {
    margin-bottom: 2.5rem;
  }
  .list ul {
    margin: 0;
    padding: 0;
    list-style: none;
    margin-bottom: 0.5rem;
  }

  .list ul li {
    position: relative;
    display: block;
    padding: 0.4rem 0 0 2rem;
    line-height: 1.3;
  }

  .list ul li #check {
    position: absolute;
    top: 0.6rem;
    left: 0;
    width: 0.5rem;
    height: 1rem;
    fill: #999999;
  }

  #check {
    color: grey;
    font-size: 1em;
  }

  .listExpandBar {
    text-align: center;
  }

  .button.blue {
    color: white;
    background-color: #00a7e3;
  }
  .button.small {
    padding: 1.1rem 1.5rem 1rem;
    text-transform: uppercase;
    font-size: 0.9rem;
    line-height: 1;
  }

  .button.small.hasIcon #blueButtonIcon {
    /* padding-left: 3rem; */
    margin-left: 1rem;
  }

  p {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    margin: 0;
    margin-bottom: 2rem;
    font-size: 0.9rem;
    padding: 0.4rem;
  }

  .button {
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    vertical-align: bottom;
    position: relative;
    margin: 0;
    border: none;
    outline: none;
    text-decoration: none;
    cursor: pointer;
    touch-action: manipulation;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-radius: 0.5rem;
    font-family: "Karla", sans-serif;
    width: auto;
    white-space: normal;
    text-align: center !important;
    font-weight: 700;
    -webkit-transition: all 0.15s ease-in-out 0s;
    transition: all 0.15s ease-in-out 0s;
  }
  a,
  button,
  .button {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    cursor: pointer;
  }
  a {
    background-color: transparent;
  }

  .list .downloadLink span {
    text-decoration: underline;
  }

  .list .downloadLink {
    position: relative;
    display: inline-block;
    padding: 0 0 0.3rem 4rem;
    line-height: 1.2;
    font-size: 0.9em;
    color: gray;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }

  .list .downloadLink:hover {
    position: relative;
    display: inline-block;
    padding: 0 0 0.3rem 4rem;
    line-height: 1.2;
    font-size: 0.9em;
    color: #00a7e3;
  }

  .list .downloadLink #download {
    position: absolute;
    left: 2rem;
    fill: #999999;
  }

  .extraInfo {
    max-width: 40rem;
    margin: 1rem auto;
    text-align: left;
  }
  select {
    display: block;
    width: 100%;
    border: 1px solid #cccccc;
    margin: auto;
    padding: 0.2rem;
    /* border-radius: 0.4rem; */
    background-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#fafafa),
      to(white)
    );
    background-image: linear-gradient(to bottom, #fafafa 0%, white 100%);
    -webkit-transition: border-color 0.15s ease-in-out 0s;
    transition: border-color 0.15s ease-in-out 0s;
  }
}

@media (min-width: 961px) {
  hr {
    margin: 2.5rem 0;
  }
}
hr {
  border: none;
  border-top: 1px solid #e6e6e6;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

.extraInfo h3 {
  color: #00a7e3;
  text-transform: none;
}
@media (min-width: 961px) and (max-width: 1100px) {
  h3,
  .h3 {
    margin-bottom: 2rem;
    font-size: 2.5rem;
    line-height: 1.3;
  }
}

@media (min-width: 749px) {
  h3,
  .h3 {
    margin: 0;
    font-family: "Karla", sans-serif;
    text-transform: uppercase;
    font-weight: 700;
  }
  h3 {
    display: block;
    font-size: 1.17em;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
  }
  .columns-2 .column {
    width: calc((6 * (100% - (11 * 4rem)) / 12) + 20rem);
    margin-right: 4rem;
  }
  .columns-2 {
    display: -webkit-box;
    display: flex;
  }
  .columns-2 .column {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    text-align: left;
  }
  .columns-2 .column:nth-child(2n + 2) {
    margin-right: 0;
  }

  .textueel {
    margin-top: 4rem;
  }

  .round {
    position: absolute;
    cursor: pointer;
    top: 2%;
    right: 2%;
    background-color: #00a7e3;
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    transition: all 0.2s ease-in-out;
  }

  .round:hover {
    box-shadow: rgba(255, 255, 255, 1) 0px 0px 0px 3px;
  }

  .round2 {
    position: absolute;
    cursor: pointer;
    top: 10%;
    right: 2%;
    background-color: #00a7e3;
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    transition: all 0.2s ease-in-out;
  }
  .round2:hover {
    box-shadow: rgba(255, 255, 255, 1) 0px 0px 0px 3px;
  }

  .iconRound {
    color: white;
    margin-left: 0.75rem;
    margin-top: 0.75rem;
    font-size: 1.5rem;
  }

  .list {
    margin-bottom: 2.5rem;
  }
  .list ul {
    margin: 0;
    padding: 0;
    list-style: none;
    margin-bottom: 0.5rem;
  }

  .list ul li {
    position: relative;
    display: block;
    padding: 0.4rem 0 0 2rem;
    line-height: 1.3;
  }

  .list ul li #check {
    position: absolute;
    top: 0.6rem;
    left: 0;
    width: 0.5rem;
    height: 1rem;
    fill: #999999;
  }

  #check {
    color: grey;
    font-size: 1em;
  }

  .listExpandBar {
    text-align: center;
  }

  .button.blue {
    color: white;
    background-color: #00a7e3;
  }
  .button.small {
    padding: 1.1rem 1.5rem 1rem;
    text-transform: uppercase;
    font-size: 0.9rem;
    line-height: 1;
  }

  .button.small.hasIcon #blueButtonIcon {
    /* padding-left: 3rem; */
    margin-left: 1rem;
  }

  p {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    margin: 0;
    margin-bottom: 2rem;
  }

  .button {
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    vertical-align: bottom;
    position: relative;
    margin: 0;
    border: none;
    outline: none;
    text-decoration: none;
    cursor: pointer;
    touch-action: manipulation;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-radius: 0.5rem;
    font-family: "Karla", sans-serif;
    width: auto;
    white-space: normal;
    text-align: center !important;
    font-weight: 700;
    -webkit-transition: all 0.15s ease-in-out 0s;
    transition: all 0.15s ease-in-out 0s;
  }
  a,
  button,
  .button {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    cursor: pointer;
  }
  a {
    background-color: transparent;
  }

  .list .downloadLink span {
    text-decoration: underline;
  }

  .list .downloadLink {
    position: relative;
    display: inline-block;
    padding: 0 0 0.3rem 4rem;
    line-height: 1.2;
    font-size: 0.9em;
    color: gray;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }

  .list .downloadLink:hover {
    position: relative;
    display: inline-block;
    padding: 0 0 0.3rem 4rem;
    line-height: 1.2;
    font-size: 0.9em;
    color: #00a7e3;
  }

  .list .downloadLink #download {
    position: absolute;
    left: 2rem;
    fill: #999999;
  }

  .extraInfo {
    max-width: 40rem;
    margin: 5rem auto;
    text-align: left;
  }
}

/* CSS FOR DIALOG */

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 15;
}

.dialogView {
  /* position: absolute; */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  /* margin: auto; */
  /* left: 65%; */
  /* right: auto; */
  /* top: 0%; */
}

dialog {
  max-width: 35vw;
  max-height: 45rem;
  /* z-index: 100; */
  border: none;
  overflow-y: auto;
  background-color: transparent;
}

.dialog-enter-from,
.dialog-leave-to {
  opacity: 0;
  transform: scale(0.8);
}

.dialog-enter-active {
  transition: all 0.3s ease-out;
}

.dialog-leave-active {
  transition: all 0.3 ease-in;
}

.dialog-enter-to,
.dialog-leave-from {
  opacity: 1;
  transform: scale(1);
}

.editBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 10;
}

.editDialogView {
  position: absolute;
  margin: auto;
  width: 30%;
  padding: 1rem;
  border-radius: 12px;
  background-color: #fff;
  left: 40%;
  right: auto;
  top: 30%;
}

.editDialogView dialog {
  max-width: 40rem;
  max-height: 60rem;
  /* z-index: 100; */
  border: none;
  overflow-y: auto;
  background-color: transparent;
}

@media (min-width: 768px) {
  dialog {
    left: calc(50% - 30rem);
    width: 60rem;
  }
}
@media (min-width: 768px) {
  dialog {
    left: calc(50% - 30rem);
    width: 60rem;
  }
}

/* END CSS FOR DIALOG */
</style>
