<template>
  <div class="rootDiv">
    <font-awesome-icon
      v-if="!showAdd && !selectedIMG"
      @click="showAdd = true"
      id="plus"
      class="plus"
      icon="plus"
    />
    <font-awesome-icon
      v-if="!showAdd && !selectedIMG"
      @click="changeComponent('Check-Main')"
      id="arrow-left"
      class="arrow-left"
      icon="arrow-left"
    />
    <div v-if="!showAdd && !selectedIMG">
      <h3>Medewerker Overzicht</h3>

      <table v-for="(medewerker, index) in medewerkers" :key="index">
        <div class="container">
          <tr>
            <th rowspan="3">
              <img v-if="medewerker.imgUrl !== ''" :src="medewerker.imgUrl" />
              <div v-else class="questionDiv" @click="inputFile(medewerker.id)">
                <font-awesome-icon
                  id="question"
                  class="question"
                  icon="question"
                />
              </div>
              <input
                type="file"
                id="theFileInput"
                style="display: none"
                @change="updateImg($event)"
              />
            </th>
            <td>Naam: {{ medewerker.naam }}</td>
          </tr>
          <tr>
            <td>Email: {{ medewerker.email }}</td>
          </tr>
          <tr>
            <td>Gebruikers ID: {{ medewerker.id }}</td>
          </tr>
        </div>
      </table>
    </div>
    <add-medewerker
      v-if="showAdd && !selectedIMG"
      @return-medewerker="toCheckMedewerker"
      @back-medewerker="back"
    ></add-medewerker>
    <img-cropper
      :img="selectedIMG"
      :id="selectedID"
      @image-url="getUrl"
    ></img-cropper>
  </div>
</template>

<script>
import AddMedewerker from "./AddMedewerker.vue";
import ImgCropper from "./ImgCropper.vue";
export default {
  components: { AddMedewerker, ImgCropper },
  data() {
    return {
      img: "",
      imgUpload: "",
      showAdd: null,
      selectedID: "",
      selectedIMG: "",
    };
  },

  methods: {
    changeComponent(selectedComponent) {
      this.$emit("selected-component", selectedComponent);
    },
    toCheckMedewerker() {
      this.showAdd = null;
      this.$store.dispatch("fetchMedewerkers");
    },
    inputFile(id) {
      this.selectedID = id;

      const file = document.getElementById("theFileInput");
      file.click();
    },
    updateImg(event) {
      // this.selectedID = id;
      var input = event.target;
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.selectedIMG = e.target.result;
        };
        reader.readAsDataURL(input.files[0]);
      }
    },
    async getUrl(event) {
      await this.$store.dispatch("updateImgUrl", event);
      this.selectedID = "";
      this.selectedIMG = "";
    },
    back() {
      this.img = "";
      this.imgUpload = "";
      this.showAdd = null;
      this.selectedID = "";
      this.selectedIMG = "";
    },
  },

  computed: {
    medewerkers() {
      return this.$store.getters.getMedewerkers;
    },
  },

  created() {
    this.$store.dispatch("fetchMedewerkers");
  },
};
</script>

<style scoped>
.container {
  border: 1px solid lightgray;
  border-radius: 12px;
  vertical-align: middle;
  padding: 1rem;
}

img {
  width: 7rem;
  height: 7rem;
  margin-right: 3rem;
  border-radius: 50%;
}

.questionDiv {
  width: 7rem;
  height: 7rem;
  margin-right: 3rem;
  border-radius: 50%;
  background-color: grey;
  cursor: pointer;
}

.question {
  font-size: 4rem;
  color: white;
  margin-top: 1.5rem;
}

td {
  text-align: left;
}

.arrow-left {
  position: absolute;
  right: 0;
  margin-right: 4rem;
  background-color: transparent;
  border-radius: 50%;
  border: 1px solid #44bace;
  padding: 0.8rem;
  margin-top: -1rem;
  color: #44bace;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
}

.arrow-left:hover {
  position: absolute;
  right: 0;
  border-radius: 50%;
  padding: 0.8rem;
  background-color: #44bace;
  margin-right: 4rem;
  margin-top: -1rem;
  color: white;
  cursor: pointer;
}

.plus {
  position: absolute;
  right: 0;
  border-radius: 50%;
  padding: 0.8rem;
  background-color: transparent;
  margin-right: 4rem;
  margin-top: 3rem;
  border: 1px solid #60ab2d;
  color: #60ab2d;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
}

.plus:hover {
  position: absolute;
  right: 0;
  border-radius: 50%;
  padding: 0.8rem;
  background-color: #60ab2d;
  margin-right: 4rem;
  margin-top: 3rem;
  border: 1px solid #60ab2d;
  color: white;
  cursor: pointer;
  transform: rotate(180deg);
}

table {
  margin: auto;
}
</style>
