import mutations from "./mutations.js";
import actions from "./actions.js";
import getters from "./getters.js";

export default {
  state() {
    return {
      ID: "",
      Voorgangers: "",
      KlantenVertellen: "",
      contactMedewerkerEmail: "",
      contactMedewerkerFoto: "",
      contactMedewerkerNaam: "",
      klantAchterNaam: "",
      klantAdres: "",
      klantEmail: "",
      klantHuisNummer: "",
      // klantKlantNummer: "",
      klantPostcode: "",
      klantTelefoon: "",
      klantVoorNaam: "",
      klantStad: "",
      offertes: [],
      offerteBesparingIn30J: "",
      offerteGeldigheidsDatum: "",
      offerteOfferteNummer: "",
      offerteOpwekkingPerJaar: "",
      offerteTimeStampOpened: "",
      offerteTimeStampReOpened: "",
      offerteAantalPanelen: "",
      offerteBijdrage: "",
      offerteDakbedekking: "",
      offerteElektraJaarVerbruik: "",
      offerteExtraTekstOpOfferte: "",
      offerteFinancieringsvorm: "",
      offerteGekozenBijdrage: "",
      offerteGekozenBijdrageTotaal: "",
      offerteGekozenHuurPrijs: "",
      offerteHuurPrijs: "",
      offerteJaarproductie: "",
      offerteKoop: "",
      offerteOfferteDatum: "",
      offerteOpstelling: "",
      offerteOrientatieDak: "",
      offerteQuoteImageLegPlan: "",
      offerteSchaduwvrij: "",
      offerteTotaalBijdrage: "",
      offerteTypeDak: "",
      offerteMerkZonnepanelen: "",
      offerteMeerwerk: "",
      offerteUrl: "",
      offerteMerkOmvormer: "",
      productPakketImage: "",
      productPakketNaam: "",
      productOptimizer: "",
      productZonnepaneelpag2: "",
      productInfosheetOmvormer: "",
      productInfosheetZonnepanelen: "",
      productKleurPanelen: "",
      reviews: [],
      reviewDate: "",
      reviewRecs: "",
      genData: "",
      medewerkers: "",
      products: [],
      infosheets: "",
      LoginPage: "",
      Pages: "",
      Admin: "",
      Logs: "",
      Korting: "",
      Kortingen: "",
      Editor: "",
      // aangepasteOfferte: "",
      offerteAkkoord: "",
      productSpecSheets: "",
      AB: "",
      korting: "",
      request: "",
      medewerkerActive: false,
      QuoteAccepted: false,
      AllMeerwerk: "",
    };
  },
  mutations,
  actions,
  getters,
};
