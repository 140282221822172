<template>
  <div class="card">
    <slot></slot>
  </div>
</template>

<style scoped>
.card {
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  padding: 1rem;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
  margin-right: auto;
  margin-left: auto;
  /* max-width: 32rem; */
  /* width: 30rem;  */
  background-color: #fff;
}
</style>
