<template>
  <div class="rootDiv">
    <div v-if="loading">
      <base-spinner></base-spinner>
    </div>
    <div v-if="!loading">
      <h3>Product Toevoegen</h3>
      <form @submit.prevent="upload">
        <div class="group">
          <label for="pakketID">Pakket ID: </label><br />

          <input placeholder="spv17_test_" type="text" v-model="pakketID" />
        </div>
        <div class="group">
          <label for="pakketNaam">Pakket Naam: </label><br />
          <input
            type="text"
            placeholder="HuurZon Pakket"
            v-model="pakketNaam"
          />
        </div>
        <div class="group">
          <label for="kleurPanelen">Kleur Panelen: </label><br />
          <input placeholder="Zwart" type="text" v-model="kleurPanelen" />
        </div>
        <div class="group">
          <label for="Optimizer">Optimizer: </label><br />
          <input
            placeholder="Solar Edge Power Optimizer S440"
            type="text"
            v-model="Optimizer"
          />
        </div>
        <div class="group">
          <label for="Zonnepaneelpag2">Extra text: </label><br />
          <input type="text" v-model="Zonnepaneelpag2" />
        </div>
        <div class="group">
          <label for="pakketImage">PakketImage: </label><br />
          <input type="file" @change="updateImg($event)" />
        </div>
        <div class="group">
          <label for="infosheetOmvormerUrl">Infosheet Omvormer: </label><br />
          <select v-model="infosheetOmvormerUrl">
            <option disabled value="">Please select one</option>
            <option
              v-for="io in infosheetOmvormers"
              :key="io.name"
              :value="io.url"
            >
              {{ io.name }}
            </option>
          </select>
        </div>
        <div class="group">
          <label for="infosheetZonnepanelenUrl">Infosheet Zonnepaneel: </label
          ><br />
          <select v-model="infosheetZonnepanelenUrl">
            <option disabled value="">Please select one</option>
            <option
              v-for="io in infosheetZonnepanelen"
              :key="io.name"
              :value="io.url"
            >
              {{ io.name }}
            </option>
          </select>
        </div>
        <div class="group">
          <label for="specsheetZonnepanelenUrl">Specsheet Zonnepaneel: </label
          ><br />
          <select v-model="specsheetZonnepanelenUrl">
            <option disabled value="">Please select one</option>
            <option
              v-for="so in specsheetZonnepanelen"
              :key="so.name"
              :value="so.url"
            >
              {{ so.name }}
            </option>
          </select>
        </div>
        <i
          >Staat het juiste infosheet er niet bij? Voeg het dan toe via het
          hoofdmenu "Infosheets Toevoegen".</i
        >
        <br />
        <base-button>Toevoegen</base-button>
      </form>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/app";

export default {
  props: ["type", "product"],
  data() {
    return {
      // pakketNaam: "",
      pakketID: "",
      kleurPanelen: "",
      Optimizer: "",
      Zonnepaneelpag2:
        "A-kwaliteit zonnepanelen die voor jou meteen energie opwekken. Zo bespaar je direct",
      infosheetOmvormerUrl: "",
      infosheetZonnepanelenUrl: "",
      specsheetZonnepanelenUrl: "",
      pakketImage: "",
      img: "",
      loading: null,
      uploadedPdf: "",
    };
  },
  computed: {
    okToSubmit() {
      if (
        this.pakketNaam !== "" &&
        this.pakketID !== "" &&
        this.kleurPanelen !== "" &&
        this.pakketImage !== "" &&
        this.Zonnepaneelpag2 !== "" &&
        this.infosheetOmvormerUrl !== "" &&
        this.infosheetZonnepanelenUrl !== "" &&
        this.img !== ""
      ) {
        return true;
      } else {
        return false;
      }
    },
    infosheets() {
      return this.$store.getters.getInfosheets;
    },
    infosheetOmvormers() {
      return this.infosheets.omvormer;
    },
    infosheetZonnepanelen() {
      return this.infosheets.zonnepanelen;
    },
    specsheetZonnepanelen() {
      return this.infosheets.specsheetZon;
    },
  },
  methods: {
    convertData() {
      if (this.type === "edit") {
        this.pakketNaam = this.product.PakketNaam;
        this.pakketID = this.product.id;
        this.kleurPanelen = this.product.KleurPanelen;
        this.Optimizer = this.product.Optimizer;
        this.Zonnepaneelpag2 = this.product.Zonnepaneelpag2;
        this.infosheetOmvormerUrl = this.product.InfosheetOmvormer;
        this.infosheetZonnepanelenUrl = this.product.InfosheetZonnepanelen;
        this.specsheetZonnepanelenUrl = this.product.SpecSheets;
        this.pakketImage = this.product.PakketImage;
      }
    },
    setType(type) {
      const file = document.getElementById(type);
      file.click();
    },

    uploadInfo(event, type) {
      this.loading = true;

      var input = event.target;
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = (e) => {
          if (type) this.uploadedPdf = e.target.result;
          this.uploadPDF(this.uploadedPdf, type);
        };
        reader.readAsDataURL(input.files[0]);
      }
    },

    async upload() {
      this.loading = true;
      if (this.img) {
        var s = this.pakketID.toLocaleUpperCase();
        var id = s.replace(/_/g, "");
        let storageRef = firebase.storage().ref();
        let imageRef = storageRef.child("PakketImage/" + `${id}.png`);
        await imageRef.putString(this.img, "data_url").then((snapshot) => {
          snapshot.ref.getDownloadURL().then((downloadURL) => {
            this.pakketImage = downloadURL;
            this.create();
          });
        });
      } else {
        this.create();
      }
    },
    updateImg(event) {
      var input = event.target;
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.img = e.target.result;
        };
        reader.readAsDataURL(input.files[0]);
      }
    },
    async create() {
      const doc = {
        PakketNaam: this.pakketNaam,
        id: this.pakketID,
        KleurPanelen: this.kleurPanelen,
        PakketImage: this.pakketImage,
        Zonnepaneelpag2: this.Zonnepaneelpag2,
        InfosheetOmvormer: this.infosheetOmvormerUrl,
        InfosheetZonnepanelen: this.infosheetZonnepanelenUrl,
        SpecSheets: this.specsheetZonnepanelenUrl,
        Optimizer: this.Optimizer,
      };
      await this.$store.dispatch("addProduct", doc);
      this.pakketNaam = "";
      this.pakketID = "";
      this.kleurPanelen = "";
      this.Optimizer = "";
      this.Zonnepaneelpag2 = "";
      this.infosheetOmvormerUrl = "";
      this.infosheetZonnepanelenUrl = "";
      this.specsheetZonnepanelenUrl = "";
      this.pakketImage = "";
      this.img = "";
      this.loading = false;
      this.$emit("close-add");
    },
  },

  created() {
    this.convertData();

    const data = this.$store.getters.getInfosheets;
    if (data) {
      return;
    } else {
      this.$store.dispatch("getInfosheets");
    }
  },
};
</script>

<style scoped>
.group {
  position: relative;
  margin-bottom: 1rem;
}

.group label {
  display: block;
  text-align: left;
}

input {
  display: block;
  width: 90%;
  border: 1px solid #cccccc;
  padding: 1rem 1rem 1rem;
  border-radius: 0.4rem;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#fafafa),
    to(white)
  );
  background-image: linear-gradient(to bottom, #fafafa 0%, white 100%);
  -webkit-transition: border-color 0.15s ease-in-out 0s;
  transition: border-color 0.15s ease-in-out 0s;
}

select {
  display: block;
  width: 94%;
  border: 1px solid #cccccc;
  padding: 1rem 1rem 1rem;
  border-radius: 0.4rem;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#fafafa),
    to(white)
  );
  background-image: linear-gradient(to bottom, #fafafa 0%, white 100%);
  -webkit-transition: border-color 0.15s ease-in-out 0s;
  transition: border-color 0.15s ease-in-out 0s;
}

.parsleyForm .group > div.full {
  width: calc(100% - 1rem);
}

a:not(.button) {
  text-align: left;
  color: blue;
  text-decoration: underline;
  outline: none;
}

@media (min-width: 749px) {
  .container .brand {
    position: absolute;
    width: 18rem;
  }
}

@media (min-width: 749px) {
  .energyFlashLine {
    width: 20rem;
    max-width: 28rem;
    margin: auto;
    margin-bottom: 1rem;
  }
}

@media (min-width: 1101px) {
  .block.small {
    padding: 4rem 7rem 10rem;
    width: 50rem;
  }
  .block {
    margin: 5vh auto;
  }
}

.spinner {
  height: 30rem;
  margin: auto;
}
</style>
