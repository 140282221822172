<template>
  <div class="rootDiv">
    <font-awesome-icon
      @click="back"
      id="arrow-left"
      class="arrow-left"
      icon="arrow-left"
    />

    <div class="content">
      <form @submit.prevent="create">
        <div class="group">
          <label for="name">Naam: </label><br />
          <input type="text" v-model="name" />
        </div>
        <div class="group">
          <label for="description">Beschrijving:</label><br />
          <input type="text" v-model="description" />
        </div>
        <base-button v-if="okToSubmit">Toevoegen</base-button>
      </form>
    </div>
    <base-button class="warning" v-if="showDelete" @click="deleteMeerwerk"
      >Delete</base-button
    >
  </div>
</template>

<script>
export default {
  props: ["meerwerk"],
  data() {
    return {
      name: "",
      description: "",
      id: "",
      showDelete: null,
    };
  },
  computed: {
    okToSubmit() {
      if (this.name && this.description) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    deleteMeerwerk() {
      this.$store.dispatch("deleteMeerwerk", this.id);
      this.$emit("return-meerwerk");
    },
    back() {
      this.$emit("back-meerwerk");
    },
    async create() {
      const doc = {
        name: this.name,
        description: this.description,
        id: this.id,
      };
      this.$store.dispatch("addMeerwerk", doc);
      this.name = "";
      this.description = "";
      this.id = "";
      this.$emit("return-meerwerk");
    },
  },
  created() {
    if (this.meerwerk) {
      this.name = this.meerwerk.name;
      this.description = this.meerwerk.description;
      this.id = this.meerwerk.id;
      this.showDelete = true;
    }
  },
};
</script>

<style scoped>
.warning {
  display: flex;
  margin: auto;
  margin-top: 3rem;
  background-color: darkred !important;
}
.arrow-left {
  position: absolute;
  right: 0;
  margin-right: 4rem;
  background-color: transparent;
  border-radius: 50%;
  border: 1px solid #44bace;
  padding: 0.8rem;
  margin-top: -4rem;
  color: #44bace;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
}

.arrow-left:hover {
  position: absolute;
  right: 0;
  border-radius: 50%;
  padding: 0.8rem;
  background-color: #44bace;
  margin-right: 4rem;
  margin-top: -4rem;
  color: white;
  cursor: pointer;
}
.content {
  margin-top: 4rem;
}
.container {
  padding-top: 2rem;
  background-color: #00a7e3;
  padding-bottom: 26rem;
}

.brand {
  position: absolute;
  left: 7%;
  top: 7%;
  width: 12%;
}

.container .brand {
  position: absolute;
  width: 18rem;
}

.group {
  position: relative;
  margin-bottom: 0.2rem;
}

.group label {
  display: block;
  text-align: left;
  /* margin-bottom: 0.5rem; */
}
.errorMessage {
  font-size: smaller;
  text-align: left;
  font-style: italic;
  color: red;
  margin-left: 2%;
}
input {
  display: block;
  width: 95%;
  border: 1px solid #cccccc;
  padding: 1rem 1rem 1rem;
  border-radius: 0.4rem;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#fafafa),
    to(white)
  );
  background-image: linear-gradient(to bottom, #fafafa 0%, white 100%);
  -webkit-transition: border-color 0.15s ease-in-out 0s;
  transition: border-color 0.15s ease-in-out 0s;
}

.parsleyForm .group > div.full {
  width: calc(100% - 1rem);
}

a:not(.button) {
  text-align: left;
  color: blue;
  text-decoration: underline;
  outline: none;
}

@media (min-width: 749px) {
  .container .brand {
    position: absolute;
    width: 18rem;
  }
}

@media (min-width: 749px) {
  .energyFlashLine {
    width: 20rem;
    max-width: 28rem;
    margin: auto;
    margin-bottom: 1rem;
  }
}

@media (min-width: 1101px) {
  .block.small {
    padding: 4rem 7rem 10rem;
    width: 50rem;
  }
  .block {
    margin: 5vh auto;
  }
}

.spinner {
  height: 30rem;
  margin: auto;
}
</style>
