<template>
  <div class="rootDiv">
    <div class="dialog" v-if="!!show">
      <teleport to="body">
        <div class="backdrop">
          <div class="round" @click="tryClose">
            <font-awesome-icon class="iconRound" icon="times-circle" />
          </div>
          <div class="round2" v-print="'#toPrint'">
            <font-awesome-icon class="iconRound" icon="print" />
          </div>
          <transition>
            <div class="dialogView">
              <dialog open>
                <div class="printDialog" ref="printMe" id="toPrint">
                  <section
                    class="print-page page-a4"
                    style="margin-bottom: 15%"
                  >
                    <header>
                      <div class="mainBar">
                        <div class="containerWide">
                          <div class="brand" style="width: 25%">
                            <img :src="hallogoUrl" alt="" />
                          </div>
                          <div class="rating">
                            <div class="klantenVertellen">
                              <img :src="klantenVertellenUrl" alt="" />
                              <div class="ratingNumber">
                                {{ KlantenVertellen }}
                              </div>
                            </div>
                          </div>
                          <div class="serviceInfo">
                            <div class="wrapper">
                              <div>
                                <font-awesome-icon
                                  id="mobile"
                                  class="icon-phone"
                                  icon="mobile-alt"
                                />
                                <a href="tel:+31307670078">030 767 00 78</a>
                                <span class="status" :class="statusOpen"></span>
                              </div>
                              <div class="empty-icon">ma-vr, 09:00-17:30</div>
                              <div>
                                <font-awesome-icon
                                  id="mail"
                                  class="icon-mail"
                                  icon="paper-plane"
                                />
                                <a href="mailto:klantenservice@hallostroom.nl">
                                  klantenservice@hallostroom.nl
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </header>

                    <div
                      style="
                        margin-top: 2rem;
                        text-align: left;
                        font-size: 1rem;
                      "
                    >
                      <div style="display: inline-block">
                        Offerte
                        <strong>{{ offerte.offerteOfferteNummer }}</strong>
                      </div>
                      &nbsp;&nbsp;&nbsp;
                      <div style="display: inline-block">
                        Datum <strong>{{ datum }} </strong>
                      </div>
                      <br /><br />
                      <strong>Hallo {{ offerte.klantVoorNaam }}!</strong>
                      <p>
                        {{ welkom.paragraph }} Heb je nog vragen? Neem dan
                        gerust contact met mij op.
                      </p>

                      <strong>{{ offerte.contactMedewerkerNaam }}</strong>
                      <div>
                        {{ offerte.contactMedewerkerEmail }} / 030 767 00 78
                      </div>
                    </div>

                    <div class="wrapper">
                      <div class="columnLeft">
                        <h2>
                          <strong>{{ offerte.productPakketNaam }}</strong>
                        </h2>
                        <h5 style="color: #00a7e3">INHOUD PAKKET</h5>
                        <div class="list">
                          <strong>Type pakket</strong>
                          <ul class="checkmarkList">
                            <li style="display: block">
                              <font-awesome-icon
                                id="check"
                                class="icon-check"
                                icon="check"
                              />
                              <span>{{ offerte.productPakketNaam }} </span>
                            </li>
                            <li style="display: block" v-if="!koop">
                              <font-awesome-icon
                                id="check"
                                class="icon-check"
                                icon="check"
                              />
                              <span
                                >Huurperiode {{ loopTijdMaanden }} maanden</span
                              >
                            </li>
                          </ul>
                        </div>
                        <div class="list">
                          <strong>Zonnepanelen</strong>
                          <ul class="checkmarkList">
                            <li style="display: block">
                              <font-awesome-icon
                                id="check"
                                class="icon-check"
                                icon="check"
                              />
                              <span
                                >{{ offerte.offerteAantalPanelen }}
                                {{ offerte.offerteMerkZonnepanelen }}</span
                              >
                            </li>
                            <li style="display: block">
                              <font-awesome-icon
                                id="check"
                                class="icon-check"
                                icon="check"
                              />
                              <span
                                >Kleur {{ offerte.productKleurPanelen }}
                              </span>
                            </li>
                            <li style="display: block">
                              <font-awesome-icon
                                id="check"
                                class="icon-check"
                                icon="check"
                              />
                              <span>A-kwaliteit zonnepanelen</span>
                            </li>
                          </ul>
                          <div class="list">
                            <strong
                              >Omvormer {{ offerte.productOptimizer }}
                            </strong>
                            <ul class="checkmarklist">
                              <li style="display: block">
                                <font-awesome-icon
                                  id="check"
                                  class="icon-check"
                                  icon="check"
                                />
                                <span> {{ offerte.offerteMerkOmvormer }} </span>
                              </li>
                              <li style="display: block">
                                <font-awesome-icon
                                  id="check"
                                  class="icon-check"
                                  icon="check"
                                />
                                <span>
                                  Omvormer {{ offerte.productOptimizer }}
                                </span>
                              </li>
                              <li style="display: block">
                                <font-awesome-icon
                                  id="check"
                                  class="icon-check"
                                  icon="check"
                                />
                                <span
                                  >Inzage in de opbrengst van je panelen met iOS
                                  & Android app
                                </span>
                              </li>
                            </ul>
                          </div>
                          <div class="list">
                            <strong>Service</strong>
                            <ul class="checkmarkList">
                              <li style="display: block">
                                <font-awesome-icon
                                  id="check"
                                  class="icon-check"
                                  icon="check"
                                />
                                <span> Gratis vakkundige installatie </span>
                              </li>
                              <li style="display: block">
                                <font-awesome-icon
                                  id="check"
                                  class="icon-check"
                                  icon="check"
                                />
                                <span v-if="!koop">
                                  Garantie & verzekering
                                </span>
                                <span v-if="koop"> 10 jaar garantie </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div class="columnRight">
                        <div class="besparing">
                          <img
                            style="
                              width: 50%;
                              margin-left: auto;
                              margin-right: auto;
                            "
                            :src="offerte.productPakketImage"
                            alt=""
                          />

                          <strong style="font-weight: 900; color: #00a7e3">
                            Nauwkeurige indicatie van de opbrengst van jouw
                            zonnepanelen</strong
                          >

                          <h5 v-if="besparing && !koop">
                            €{{ besparing }} heb je al in
                            {{ loopTijdJaar }} verdiend
                          </h5>
                          <h5 v-if="!besparing || koop">
                            {{ percentage }}% van je energieverbruik wek je zelf
                            op
                          </h5>
                          <h5>{{ targetKwh }} kWh opwekking per jaar</h5>
                          <h5 v-if="koop">
                            in {{ terugVerdienTijd }} jaar je zonnepanelen
                            terugverdiend
                          </h5>
                          <!-- <p style="font-size: 0.8rem">
                            Hiermee wek je jaarlijks een deel van jouw
                            energiebehoefte volledig duurzaam op.
                          </p> -->
                        </div>
                        <div class="legPlan">
                          <img :src="offerte.offerteQuoteImageLegPlan" alt="" />
                        </div>
                      </div>
                    </div>
                  </section>
                  <section class="print-page page-a4">
                    <header>
                      <div class="mainBar">
                        <div class="containerWide">
                          <div class="brand" style="width: 25%">
                            <img :src="hallogoUrl" alt="" />
                          </div>
                          <div class="rating">
                            <div class="klantenVertellen">
                              <img :src="klantenVertellenUrl" alt="" />
                              <div class="ratingNumber">
                                {{ KlantenVertellen }}
                              </div>
                            </div>
                          </div>
                          <div class="serviceInfo">
                            <div class="wrapper">
                              <div>
                                <font-awesome-icon
                                  id="mobile"
                                  class="icon-phone"
                                  icon="mobile-alt"
                                />
                                <a href="tel:+31307670078">030 767 00 78</a>
                                <span class="status" :class="statusOpen"></span>
                              </div>
                              <div class="empty-icon">ma-vr, 08:00-20:00</div>
                              <div>
                                <font-awesome-icon
                                  id="mail"
                                  class="icon-mail"
                                  icon="paper-plane"
                                />
                                <a href="mailto:klantenservice@hallostroom.nl">
                                  klantenservice@hallostroom.nl
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </header>

                    <div
                      style="
                        margin-top: 2rem;
                        text-align: left;
                        font-size: 1rem;
                      "
                    >
                      <h4
                        style="
                          color: #00a7e3;
                          font-weight: 700;
                          margin-top: 3rem;
                        "
                      >
                        OVERZICHT
                      </h4>
                      <div class="priceSummary" v-if="!koop">
                        <div class="line">
                          <div class="description">
                            <strong>Huurbedrag per maand</strong>
                            <p>
                              {{ offerte.offerteAantalPanelen }} zonnepanelen
                              inclusief omvormers {{ offerte.productOptimizer }}
                              <br />
                              Huurperiode {{ loopTijdMaanden }} maanden
                            </p>
                          </div>
                          <div class="price">
                            {{ offerte.offerteGekozenHuurPrijs }},-
                          </div>
                        </div>
                        <div class="line">
                          <div class="description">
                            <strong>Installatie</strong>
                          </div>
                          <div class="price">0,-</div>
                        </div>
                        <div class="line">
                          <div class="description">
                            <strong>Garantie & verzekering</strong>
                          </div>
                          <div class="price">0,-</div>
                        </div>
                        <div class="priceLine"></div>
                        <div class="line hasNoLine isBlue">
                          <div class="description">
                            <strong>Totaal per maand</strong>
                          </div>
                          <div class="price">
                            <strong
                              >{{ offerte.offerteGekozenHuurPrijs }},-</strong
                            >
                          </div>
                        </div>
                      </div>
                      <div class="priceSummary" v-if="koop">
                        <div class="line">
                          <div class="description">
                            <strong>Totaalbedrag</strong>
                            <p>
                              {{ offerte.offerteAantalPanelen }} zonnepanelen
                              inclusief omvormer
                              {{ offerte.productOptimizer }}
                              <br />
                            </p>
                          </div>
                          <div class="price">€ {{ prijsKoopIncBTW }}</div>
                        </div>
                        <div class="line">
                          <div class="description">
                            <strong>Installatie</strong>
                          </div>
                          <div class="price">€ 0,-</div>
                        </div>
                        <div class="line">
                          <div class="description">
                            <strong>Btw 0%</strong>
                            <!-- <strong>Btw-teruggave</strong>
                            (21% Btw à €{{ btwKoop }} minus forfaitbedrag à €{{
                              forfait
                            }}
                            ) -->
                          </div>
                          <div class="price">
                            <strong>€ {{ BtwTeruggave }}</strong>
                          </div>
                        </div>
                        <div class="priceLine"></div>
                        <div class="line hasNoLine isBlue">
                          <div class="description">
                            <strong>Netto investering</strong>
                          </div>
                          <div class="price">
                            <strong>€ {{ nettoBedrag }}</strong>
                          </div>
                        </div>
                      </div>

                      <div class="benefits">
                        <div class="list green">
                          <ul class="checkmarkList">
                            <li>
                              <font-awesome-icon
                                id="check"
                                class="icon-check"
                                icon="check"
                              />
                              <span v-if="!koop"
                                ><em>Snelle en vakkundige installatie</em>
                              </span>
                              <span v-if="koop"
                                ><em>Achteraf betalen</em> nadat je systeem
                                werkend is opgeleverd
                              </span>
                            </li>
                            <li>
                              <font-awesome-icon
                                id="check"
                                class="icon-check"
                                icon="check"
                              />
                              <span
                                >Je hebt altijd
                                <em>14 dagen bedenktijd</em></span
                              >
                            </li>
                            <li>
                              <font-awesome-icon
                                id="check"
                                class="icon-check"
                                icon="check"
                              />
                              <span v-if="!koop">
                                <em>Gegarandeerd {{ loopTijdJaar }} lang</em>
                                dezelfde lage maandhuur vastgezet
                              </span>
                              <span v-if="koop">
                                <em>10 jaar volledige garantie</em> op de
                                zonnepanelen en omvormer
                              </span>
                            </li>
                            <li>
                              <font-awesome-icon
                                id="check"
                                class="icon-check"
                                icon="check"
                              />
                              <span>
                                <em>Direct </em> besparen en geen last meer van
                                stijgende energieprijzen
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="infoAccept">
                        <h4 style="color: #00a7e3; font-weight: 700">
                          OFFERTE ACCEPTEREN
                        </h4>
                        <p>
                          Om gebruik te maken van onze aanbieding, kan je nu
                          online deze offerte accepteren waarna alles in gang
                          wordt gezet of deze offerte hieronder ondertekenen en
                          mailen naar
                          <strong>administratie@hallostroom.nl</strong> of per
                          post opsturen naar
                          <strong
                            >HalloStroom, t.a.v. Afdeling Administratie,
                            Bouwmeesterplein 1, 2801 BX Gouda</strong
                          >
                        </p>
                      </div>
                    </div>

                    <div class="wrapperSign">
                      <p style="text-align: left; font-weight: 600">
                        JOUW NAAM
                      </p>
                      <p style="font-weight: 300; text-align: left">
                        {{ offerte.klantVoorNaam }}
                        {{ offerte.klantAchterNaam }}
                      </p>
                      <div class="columnLeft signature">
                        <h5>JOUW HANDTEKENING</h5>
                      </div>
                      <div class="columnRight signature">
                        <h5>DATUM EN PLAATS</h5>
                      </div>
                    </div>
                    <p
                      style="
                        font-size: smaller;
                        text-align: left;
                        margin-top: 3rem;
                      "
                    >
                      HalloStroom Markt & Klant B.V., Geregistreerd te Gouda,
                      KvK-nummer: 71180907, BTW-nummer: NL858611545801
                    </p>
                  </section>
                </div>
              </dialog>
            </div>
          </transition>
        </div>
      </teleport>
    </div>
    <div class="printBanner">
      <span class="nalezen">Liever rustig op papier nalezen?</span>
      <a
        class="button small isPrintButton grayGhost js-printPage"
        @click="open"
      >
        <font-awesome-icon id="print" class="icon-printer" icon="print" />
        <span>Print deze offerte</span>
      </a>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import print from "vue3-print-nb";
export default {
  directives: {
    print,
  },
  data() {
    return {
      start: false,
      show: null,
      showPrint: false,
      showButton: null,
      showWrapper: false,
      wrapperStatus: true,
      width: 0,
      // hallogoUrl: require("../../../assets/MainBar/hallostroom-logo-kleur.svg"),
      klantenVertellenUrl: require("../../../assets/MainBar/klantenvertellen-dynamic.svg"),
    };
  },
  methods: {
    open() {
      this.show = true;
    },
    tryClose() {
      this.show = false;
    },
    buttonVisible() {
      if (window.innerWidth >= 749) {
        this.showButton = false;
      }
      if (window.innerWidth < 749) {
        this.showButton = true;
      }
    },
  },
  computed: {
    offerte() {
      return this.$store.getters.getCurrentOfferte;
    },
    koop() {
      return this.offerte.offerteKoop;
    },
    Pages() {
      return this.$store.getters.getPages;
    },
    mainBar() {
      return this.Pages.MainBar;
    },
    hallogoUrl() {
      return this.mainBar.Logo
    },
    datum() {
      return this.moment(this.offerte.offerteOfferteDatum)
        .locale("nl")
        .format("DD MMMM YYYY");
    },
    loopTijdMaanden() {
      if (this.offerte.offerteFinancieringsvorm === "Huur 15 jaar") {
        return "180";
      } else {
        return "120";
      }
    },
    loopTijdJaar() {
      if (this.offerte.offerteFinancieringsvorm === "Huur 15 jaar") {
        return "15 jaar";
      } else {
        return "10 jaar";
      }
    },
    KlantenVertellen() {
      return this.offerte.genData.klantenVertellen.rate;
    },
    pages() {
      return this.$store.getters.getPages;
    },
    welkom() {
      return this.pages.Welkom;
    },
    besparing() {
      if (this.offerte.offerteFinancieringsvorm === "Huur 15 jaar") {
        return formatNumber(this.offerte.offerteBesparing * 180);
      } else if (this.offerte.offerteFinancieringsvorm === "Huur 10 jaar") {
        return formatNumber(this.offerte.offerteBesparing * 120);
      } else {
        return 0;
      }
      function formatNumber(num) {
        return num
          .toString()
          .replace(".", ",")
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
      } // Zet een punt bij duizendtallen
    },
    percentage() {
      const perc = this.offerte.percentageEigenOpwek;
      if (perc === 0) {
        return Math.ceil((3500 / this.offerte.offerteJaarproductie) * 100);
      } else {
        return this.offerte.percentageEigenOpwek;
      }
    },
    terugVerdienTijd() {
      return Math.ceil(
        this.offerte.offertePrijsKoopInclBTW /
          (this.offerte.offerteJaarproductie * 0.31)
      );
    },
    targetKwh() {
      const t = +this.offerte.offerteJaarproductie;
      return formatNumber(t);
      function formatNumber(num) {
        return num
          .toString()
          .replace(".", ",")
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
      } // Zet een punt bij duizendtallen
    },

    // KOOPVELDEN

    prijsKoopIncBTW() {
      const inc = +this.offerte.offertePrijsKoopInclBTW;
      const toString = inc.toFixed(2);
      const commaFormat = toString
        .replace(".", ",")
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
      return commaFormat;
    },

    gekozenHuurPrijsExBTW() {
      return this.offerte.offerteGekozenHuurPrijs * 0.79;
    },
    BTW() {
      return this.offerte.offerteGekozenHuurPrijs * 0.21;
    },
    btwKoop() {
      const btw = (this.offerte.offertePrijsKoopInclBTW * 0.21).toFixed(2);
      return btw.replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    },
    forfait() {
      const f = (+this.offerte.offerteForfait).toFixed(2);
      const commaFormat = f
        .replace(".", ",")
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
      return commaFormat;
    },
    BtwTeruggave() {
      // const f = (
      //   this.offerte.offertePrijsKoopInclBTW * 0.21 -
      //   +this.offerte.offerteForfait
      // ).toFixed(2);
      const f = "0,-";
      const commaFormat = f
        .replace(".", ",")
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
      return commaFormat;
    },
    nettoBedrag() {
      const inc = +this.offerte.offertePrijsKoopInclBTW;
      const toString = inc.toFixed(2);
      const commaFormat = toString
        .replace(".", ",")
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
      // const f = (
      //   this.offerte.offertePrijsKoopInclBTW -
      //   (this.offerte.offertePrijsKoopInclBTW * 0.21 -
      //     +this.offerte.offerteForfait)
      // ).toFixed(2);
      // const commaFormat = f
      //   .replace(".", ",")
      //   .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
      return commaFormat;
    },
  },
  created() {
    this.moment = moment;
    if (window.innerWidth < 749) {
      this.showButton = true;
    } else {
      this.showButton = false;
    }
    window.addEventListener("resize", this.buttonVisible);
  },
  destroyed() {
    window.addEventListener("resize", this.buttonVisible);
  },
};
</script>

<style scoped>
@media (max-width: 748px) {
  .nalezen {
    display: none;
  }
  .rootDiv {
    display: none;
  }
}

/* @media (min-width: 768px) {
  dialog {
    left: calc(50% - 30rem);
    width: 60rem;
  }
} */

@media (min-width: 749px) {


  .printBanner {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
  }
  .printBanner .button {
    margin-left: 2rem;
  }
  .dialogView {
    position: absolute;
    margin: auto;
    left: 25%;
    right: auto;
    top: 0%;
  }

  dialog {
    max-width: 230mm;
    max-height: 100vh;
    /* max-height: 60rem; */
    border: none;
    overflow-y: auto;
    background-color: transparent;
  }
}

.content {
  width: 90%;
  margin: auto;
}

.button.small.isPrintButton {
  padding-left: 1.5rem !important;
}
.button.small.isPrintButton #print {
  margin-right: 1rem;
  font-size: 1.5rem;
}

.button.grayGhost {
  color: gray;
  background-color: transparent;
  border: 2px solid gray;
  transition: all 0.2s ease-in-out;
}

.button.grayGhost:hover {
  color: #f4f9fa;
  background-color: gray;
  border: 2px solid gray;
}

.button.small {
  padding: 1.1rem 1.5rem 1rem;
  text-transform: none;
  font-size: 1rem;
  line-height: 1;
}
.button {
  display: -webkit-inline-box;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  vertical-align: bottom;
  position: relative;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  cursor: pointer;
  touch-action: manipulation;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-radius: 0.2rem;
  font-family: "Karla", sans-serif;
  width: auto;
  white-space: normal;
  text-align: center !important;
  font-weight: 700;
  -webkit-transition: all 0.15s ease-in-out 0s;
  transition: all 0.15s ease-in-out 0s;
}
a,
button,
.button {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
a {
  background-color: transparent;
}

span {
  font-size: 0.9rem;
}

.besparing {
  border: 1px solid lightgray;
  padding: 1rem;
}

.columnLeft {
  width: 50%;
  display: inline-block;
  text-align: left;
}

.columnRight {
  vertical-align: top;
  width: 50%;
  display: inline-block;
  text-align: left;
}

.columnLeft.signature {
  width: 47%;
  margin-right: 3%;
  padding-bottom: 6rem;
  border-bottom: 2px solid black;
}

.columnRight.signature {
  width: 47%;
  margin-right: 3%;
  padding-bottom: 6rem;
  border-bottom: 2px solid black;
}

.printDialog {
  width: 230mm;
  background-color: #fff;
}

.page-a4 {
  width: 210mm;
  margin-top: 10mm;
  position: relative;
}

.print-page {
  width: 210mm;
  height: 293mm;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 14mm;
}

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

/* MAINBAR CSS */

.mainBar {
  width: 100%;
}

a:not(.button) {
  color: inherit;
  text-decoration: none;
  outline: none;
}

a,
button,
.button {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
a {
  background-color: transparent;
  transition: all 0.2s ease-in-out;
}

a:hover {
  color: #83c63c;
  text-decoration: underline;
}

header .mainBar .containerWide .rating .klantenVertellen {
  width: 8rem;
  position: relative;
  margin-right: 2rem;
}

header .mainBar .containerWide .rating .klantenVertellen .ratingNumber {
  position: absolute;
  top: 26%;
  left: 10%;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  text-align: center;
  letter-spacing: -0.05rem;
  border-radius: 50%;
  font-weight: 700;
}

header .mainBar .containerWide .serviceInfo {
  font-size: 0.6em;
  border-left: 1px solid #bbb;
  padding-left: 2rem;
  padding-top: 0.5rem;
}

header .mainBar .containerWide .serviceInfo .wrapper {
  padding: 0.7rem 0;
}

header .mainBar .containerWide .serviceInfo .wrapper > div {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.empty-icon {
  margin-left: 1.3rem;
}

header .mainBar .containerWide .serviceInfo .wrapper > div a {
  -webkit-transition: all 0.15s ease-in-out 0s;
  transition: all 0.15s ease-in-out 0s;
  font-weight: 700;
}

header .mainBar .containerWide .serviceInfo .wrapper > div .status.is-open {
  background-color: #83c63c;
}

header .mainBar .containerWide .serviceInfo .wrapper > div .status.is-closed {
  background-color: red;
}

header .mainBar .containerWide .serviceInfo .wrapper > div .status {
  width: 0.6rem;
  height: 0.6rem;
  margin-left: 0.5rem;
  border-radius: 50%;
}

[class^="icon-"],
[class*=" icon-"] {
  position: relative;
  display: inline-block;
  width: 0.8rem;
  color: #b3b3b3;
  margin-right: 0.5rem;
  height: 0.8rem;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}

header .mainBar .containerWide .rating {
  margin-left: 2rem;
}

img {
  display: block;
  width: 100%;
  height: auto;
  border: 0;
}

header .mainBar .containerWide .brand {
  margin-right: auto;
}

header {
  display: block;
  width: 100%;
}

header .mainBar {
  background: white;
  padding: 1rem 0;
}

header .mainBar .containerWide {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-align: center;
  align-items: center;
}

.containerWide {
  max-width: 90%;
  margin: auto;
}

.description {
  text-align: left;
}

.priceSummary {
  position: relative;
}
.priceSummary .line {
  display: -webkit-box;
  display: flex;
  border-bottom: 1px solid #e6e6e6;
  padding: 0.8rem 0;
}

.priceSummary .line .description .customSelect {
  margin: 1rem 0 0;
}

.customSelect {
  position: relative;
}

strong,
b {
  font-weight: 700;
}

.priceSummary .line .price.alignBottom {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-pack: end;
  justify-content: flex-end;
  padding-bottom: 1rem;
}
.priceSummary .line .price {
  margin-left: auto;
  padding-left: 2rem;
}

.priceSummary .priceLine {
  position: absolute;
  z-index: 1;
  margin-top: -1px;
  right: 0;
  background: #00a7e3;
  height: 2px;
  width: 25%;
}

.priceSummary .line.isBlue {
  color: #00a7e3;
}
.priceSummary .line.hasNoLine {
  border: 0;
  padding-bottom: 0;
}

.list ul {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-bottom: 0.5rem;
}

ul {
  display: block;
  list-style-type: disc;
  /* margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px; */
}
.list ul li {
  position: relative;
  display: block;
  /* padding: 0 0 0.3rem 2rem; */
  line-height: 1.2;
}

.benefits {
  margin-top: 3rem;
}

.wrapperSign {
  margin-top: 4rem;
}

.infoAccept {
  margin-top: 3rem;
}

.round {
  position: absolute;
  cursor: pointer;
  top: 2%;
  right: 2%;
  background-color: #00a7e3;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  transition: all 0.2s ease-in-out;
}

.round:hover {
  box-shadow: rgba(255, 255, 255, 1) 0px 0px 0px 3px;
}

.round2 {
  position: absolute;
  cursor: pointer;
  top: 10%;
  right: 2%;
  background-color: #00a7e3;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  transition: all 0.2s ease-in-out;
}
.round2:hover {
  box-shadow: rgba(255, 255, 255, 1) 0px 0px 0px 3px;
}

.iconRound {
  color: white;
  margin-left: 0.75rem;
  margin-top: 0.75rem;
  font-size: 1.5rem;
}

/* CSS FOR DIALOG */

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 10;
  transition: all, 0.5s ease-in-out;
}

.dialog-enter-from,
.dialog-leave-to {
  opacity: 0;
  transform: scale(0.8);
}

.dialog-enter-active {
  transition: all 0.3s ease-out;
}

.dialog-leave-active {
  transition: all 0.3 ease-in;
}

.dialog-enter-to,
.dialog-leave-from {
  opacity: 1;
  transform: scale(1);
}

/* END CSS FOR DIALOG */
</style>
