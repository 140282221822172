<template>
  <div class="rootDiv">
    <div class="login">
      <div class="top">
        <font-awesome-icon
          v-if="showUser"
          @click="toggleAdmin"
          id="cog"
          class="cog"
          icon="cog"
        />
        <font-awesome-icon
          v-if="!showUser"
          @click="toggleAdmin"
          id="arrow-left"
          class="arrow-left"
          icon="arrow-left"
        />
        <div class="container">
          <div class="brand">
            <img :src="logo" alt="" />
          </div>
          <user-auth v-if="showUser" @show-data="emitData"></user-auth>
          <admin-auth v-if="!showUser"></admin-auth>
          <div class="meisje" v-if="showUser">
            <img :src="img" alt="" />
          </div>
        </div>
      </div>
      <finder-footer v-if="showUser"></finder-footer>
    </div>
  </div>
</template>

<script>
import UserAuth from "../userAuth/UserAuth.vue";
import AdminAuth from "../userAuth/AdminAuth.vue";
import FinderFooter from "./FinderFooter.vue";
export default {
  data() {
    return {
      showUser: true,
      showFooter: false,
    };
  },
  components: {
    UserAuth,
    FinderFooter,
    AdminAuth,
  },
  methods: {
    emitData(event) {
      this.$emit("show-data", event);
    },
    toggleAdmin() {
      this.showUser = !this.showUser;
    },
  },
  computed: {
    loginPage() {
      return this.$store.getters.getLoginPage;
    },
    logo() {
      return this.loginPage.logo;
    },
    img() {
      return this.loginPage.img;
    },
  },
};
</script>

<style scoped>
.cog {
  position: absolute;
  left: 0;
  margin-left: 1rem;
  margin-top: 1rem;
  color: #00a7e3;
  cursor: pointer;
  transition: 0.3s all ease-in-out;
}

.cog:hover {
  position: absolute;
  left: 0;
  margin-left: 1rem;
  margin-top: 1rem;
  width: 1.5rem;
  height: 1.5rem;
  color: white;
  cursor: pointer;
  transform: rotate(1800deg);
}

.arrow-left {
  position: absolute;
  right: 0;
  margin-right: 1rem;
  margin-top: 1rem;
  color: white;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
  padding: 1px;
}

@media (min-width: 749px) {
  .login .top .container {
    padding-top: 0rem;
    padding-bottom: 0rem;
  }

  .login .top .container .meisje {
    width: 33rem;
    display: flex;
  }
}

@media (max-width: 1100px) {
  .login .top .container .meisje {
    display: none;
  }
  .brand img {
    display: block;
    width: 50% !important;
    height: auto;
    margin: auto;
    text-align: center;
    margin-top: -1rem;
    margin-bottom: -3rem;
    /* align-content: center; */
  }
  .container {
    padding-left: 0.2rem;
    padding-right: 0.2rem;
  }
}

@media (min-width: 1101px) {
  .container {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  h1,
  .h1 {
    font-family: "Karla";

    margin-bottom: 3rem;
    font-size: 2.5rem;
    line-height: 1.3;
  }
  .login .top .container .brand {
    width: 17rem;
    margin: auto;
  }
}

.login {
  position: relative;
}

.login .top {
  background-color: #00a7e3;
  background-size: cover;
}

.container {
  position: relative;
  margin: auto;
  max-width: 70rem;
}

.login .top .container {
  position: relative;
  overflow: hidden;
}

.login .top .container .block h1 {
  text-align: center;
}

.login .top .container .meisje {
  position: relative;
  width: 50%;
  margin-top: -32rem;
  /* bottom: -3rem; */
  left: 59%;
  transform: translateX(-10%);
  pointer-events: none;
}

h1,
.h1 {
  margin: 0;
  font-family: "Karla", sans-serif;
  font-weight: 700;
}
h1 {
  font-size: 2em;
  margin: 0.67em 0;
  font-family: "Karla";

}
*,
*:before,
*:after {
  box-sizing: border-box;
}
h1 {
  display: block;
  font-size: 2em;
  font-family: "Karla";

  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

p {
  margin: 0;
  margin-bottom: 2rem;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

p {
  display: block;
  font-family: "Karla";

  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

@media (min-width: 749px) {
  .brand {
    display: block;
  }
  /* .brand img {
    display: flex;
    width: 100%;
    height: auto;
    align-content: center;
  } */
}

img {
  display: block;
  width: 100%;
  border: 0;
  height: auto;
}
</style>
