<template>
  <div class="rootDiv">
    <div class="hero background-1">
      <div class="container">
        <div class="columns-2">
          <div class="column">
            <div style="margin-left: 1rem">
              <div>
                <h2>Hallo {{ offerte.klantVoorNaam }}!</h2>
              </div>
              <div class="welkom">
                <div :class="{ active: editor }">
                  <p>
                    <strong @click="edit(welkom.header, 'Welkom', 'header')">
                      {{ welkom.header }}</strong
                    ><br />
                  </p>
                </div>
                <div :class="{ active: editor }">
                  <p @click="edit(welkom.paragraph, 'Welkom', 'paragraph')">
                    {{ welkom.paragraph }}
                  </p>
                </div>
                <p>
                  Je offertenummer is
                  <strong> {{ offerte.offerteOfferteNummer }}</strong> en
                  <strong> {{ status }}. </strong>
                </p>
                <p>Heb je nog vragen? Bel me gerust.</p>
                <p>
                  Je kunt ook een belafspraak plannen door op de onderstaande
                  knop te drukken.
                </p>
                <button @click="openCalendly" class="afspraak">
                  Afspraak inplannen
                </button>
              </div>
              <contact-persoon></contact-persoon>
            </div>
            <div style="margin-bottom: 4rem"></div>
            <client-benefits
              @showBar="toggleExpireBar"
              :startCount="startCount"
            ></client-benefits>
          </div>
          <div class="column">
            <div class="hasShade">
              <div class="card">
                <client-info @next-step="nextStep"></client-info>
              </div>

              <leg-plan @showBar="toggleExpireBar"></leg-plan>
            </div>
            <div class="buttonWrapper">
              <!-- <a class="button large hasIcon green" @click="nextStep">
                <span>Volgende stap</span>
                <div class="icon-arrow-right">
                  <font-awesome-icon icon="arrow-right" class="arrow-right" />
                </div>
              </a> -->
              <p style="text-align: center; color: transparent">
                Klik hier voor een overzicht van al je producten
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="container low">
        <component :is="selectedComponent"></component>
        <klanten-kaart></klanten-kaart>
        <klanten-vertellen></klanten-vertellen>
      </div>
    </div>
    <div class="footer">
      <step-footer></step-footer>
    </div>
    <step-floater @next-step="nextStep"></step-floater>
  </div>
</template>

<script>
import ContactPersoon from "./ContactPersoon.vue";
import ClientBenefits from "./ClientBenefits.vue";
import LegPlan from "./LegPlan.vue";
import ClientInfo from "./ClientInfo.vue";
import StepFloater from "./StepFloater.vue";
import BesparingBlock from "./BesparingBlock.vue";
import BesparingKoop from "./BesparingKoop.vue";
import KlantenKaart from "./KlantenKaart.vue";
import KlantenVertellen from "./KlantenVertellen.vue";
import StepFooter from "../../steps/StepFooter.vue";
import KlantGegevens from "../stepTwo/KlantGegevens.vue";
import firebase from "firebase/app";
import moment from "moment";

export default {
  data() {
    return {
      // floaterStatus: false,
      dialogOpen: false,
      dialogTitle: "",
      dialogImg: "",
      dialogText: "",
      startCount: false,
      showEdit: false,
      valueToEdit: "",
      editedValue: "",
      subColToEdit: "",
      fieldToEdit: "",
      indexToEdit: null,
    };
  },
  components: {
    ContactPersoon,
    KlantGegevens,
    ClientBenefits,
    LegPlan,
    ClientInfo,
    StepFloater,
    BesparingBlock,
    BesparingKoop,
    KlantenKaart,
    KlantenVertellen,
    StepFooter,
  },

  computed: {
    offerte() {
      return this.$store.getters.getCurrentOfferte;
    },
    fullAccess() {
      if (
        this.offerte.offerteAkkoord &&
        this.offerte.quoteStage === "Closed Won"
      ) {
        return true;
      } else {
        return false;
      }
    },
    status() {
      const d = new Date().valueOf();
      if (
        this.offerte.offerteAkkoord &&
        this.offerte.quoteStage === "Closed Won"
      ) {
        return `is geaccepteerd op ${this.moment(
          this.offerte.offerteAkkoord.offerteAkkoord
        )
          .locale("nl")
          .format("DD MMMM YYYY")}`;
      } else if (this.offerte.offerteGeldigheidsDatum < d) {
        return `is helaas verlopen op ${this.moment(
          this.offerte.offerteGeldigheidsDatum
        )
          .locale("nl")
          .format("DD MMMM YYYY")}`;
      } else {
        return `is geldig tot ${this.moment(
          this.offerte.offerteGeldigheidsDatum
        )
          .locale("nl")
          .format("DD MMMM YYYY")}`;
      }
    },
    koop() {
      return this.offerte.offerteKoop;
    },
    pages() {
      return this.$store.getters.getPages;
    },
    welkom() {
      return this.pages.Welkom;
    },
    editor() {
      return this.$store.getters.getEditor;
    },
    admin() {
      return this.$store.getters.getAdmin;
    },
    selectedComponent() {
      if (this.koop === true) {
        return "besparing-koop";
      } else {
        return "besparing-block";
      }
    },
  },
  methods: {
    openCalendly() {
      firebase.analytics().logEvent("Calendly");
      const url = `https://calendly.com/hallostroom/afspraak?name=${this.offerte.klantVoorNaam} ${this.offerte.klantAchterNaam}&email=${this.offerte.klantEmail}&a1=${this.offerte.klantTelefoon}&utm_source=offerte&utm_medium=email&utm_campaign=online-offerte-afspraak`;
      window.open(url);
    },
    toggleExpireBar(value) {
      this.$emit("showBar", value);
    },
    countCheck() {
      this.startCount = window.scrollY > 400;
    },
    nextStep() {
      this.$router.push("/offerteDetail/stap2");
    },
    closeDialog() {
      this.dialogOpen = true;
      this.dialogTitle = "";
    },
    openDialog(data) {
      this.dialogTitle = data.title;
      this.dialogText = data.text;
      if (data.image) {
        this.dialogImg = data.image;
      }
    },
    edit(value, subCol, field) {
      if (this.editor) {
        this.valueToEdit = value;
        this.subColToEdit = subCol;
        this.fieldToEdit = field;
        this.showEdit = true;
      }
    },
    async confirm(res) {
      if (res === "Yes") {
        let i = "";
        if (this.indexToEdit === null) {
          i = null;
        } else {
          i = this.indexToEdit;
        }

        const ad = {
          ...this.admin,
        };
        const payload = {
          index: i,
          collection: this.subColToEdit,
          field: this.fieldToEdit,
          value: this.editedValue,
          newValue: this.editedValue,
          admin: ad,
          oldVal: this.valueToEdit,
        };
        this.showEdit = false;
        await this.$store.dispatch("editRecord", payload);

        this.valueToEdit = "";
        this.editedValue = "";
        this.subColToEdit = "";
        this.fieldToEdit = "";
        this.indexToEdit = null;
        this.showEdit = false;
      } else {
        this.valueToEdit = "";
        this.editedValue = "";
        this.subColToEdit = "";
        this.fieldToEdit = "";
        this.indexToEdit = null;
        this.showEdit = false;
      }
    },
  },
  beforeRouteLeave(to, _, next) {
    if (
      ((to.path === "/offerteDetail/stap3" ||
        to.path === "/offerteDetail/stap3b") &&
        this.fullAccess === true) ||
      to.path === "/offerteDetail/stap2" ||
      to.path === "/offerteDetail/stap2b"
    ) {
      next();
    } else {
      next(false);
    }
  },
  created() {
    firebase.analytics().logEvent("page_view - stap 1");
    this.moment = moment;

    window.addEventListener("scroll", this.toggleStatus);
    window.addEventListener("scroll", this.countCheck);
  },
  destroyed() {
    window.removeEventListener("scroll", this.toggleStatus);
    window.removeEventListener("scroll", this.countCheck);
  },
};
</script>

<style scoped>
.afspraak {
  border: none;
  color: white;
  padding: 1rem;
  border-radius: 12px;
  background-color: #60ab2d;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 700;
}

@media (max-width: 748px) {
  .footer {
    padding-bottom: 3rem;
  }
  .columns-2 {
    margin: auto;
    width: min(98%, 75rem);
    display: grid;
    grid-auto-flow: row;
  }

  .hero.background-1 {
    width: 100%;
    background-color: #00a7e3;
  }
}

@media (min-width: 749px) {
  .columns-2 {
    margin: 1em auto;
    width: min(98%, 75rem);
    height: min(98%, 75rem);
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 49% 49%;
    gap: 2%;
  }

  .hero.background-1 {
    width: 100%;
    background-color: #00a7e3;
  }
}

.card {
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  padding: 2rem;
  max-width: 40rem;
  background-color: #fff;
}

@media (max-width: 748px) {
  .buttonWrapper {
    display: none;
  }
}

@media (min-width: 749px) {
  .buttonWrapper {
    margin-top: 3rem;
  }
}

@media (min-width: 749px) {
  .hero.background-1 {
    padding-top: 5rem;
    margin-bottom: 0rem;
  }
  .columns-2 {
    display: -webkit-box;
    display: flex;
  }
}

h2 {
  color: white;
  font-weight: bold;
  text-align: left;
}

p {
  color: white;
  text-align: left;
}

.hero .container .columns-2 .column .nextStepBlock {
  width: 90%;
  margin: auto;
  margin-top: 2rem;
  text-align: center;
}

@media (min-width: 749px) {
  .hero .container .columns-2 .column .nextStepBlock .button {
    margin: 5rem 0 2rem;
  }
}

.button {
  display: -webkit-inline-box;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  vertical-align: bottom;
  position: relative;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  cursor: pointer;
  touch-action: manipulation;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-radius: 0.5rem;
  font-family: "Karla", sans-serif;
  width: auto;
  white-space: normal;
  text-align: center !important;
  font-weight: 700;
  -webkit-transition: all 0.15s ease-in-out 0s;
  transition: all 0.15s ease-in-out 0s;
}

.button.large {
  padding: 1rem 1rem 1rem;
  font-size: 1.7rem;
  font-weight: bolder;
  line-height: 1.7;
}

.button.green {
  color: white;
  background-color: #60ab2d;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#83c63c),
    to(rgba(131, 198, 60, 0))
  );
  background-image: linear-gradient(
    to bottom,
    #83c63c 0%,
    rgba(131, 198, 60, 0) 100%
  );
  transition: all 0.2s ease-in-out;
}

.button.green:hover {
  background-color: #83c51c;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#83c51c),
    to(rgba(131, 198, 60, 0))
  );
  background-image: linear-gradient(
    to bottom,
    #83c51c 0%,
    rgba(131, 198, 60, 0) 100%
  );
  box-shadow: rgba(0, 0, 0, 0.34) 0px 3px 8px;
}

.button.large.hasIcon {
  padding-right: 2rem !important;
}

.icon-arrow-right {
  width: 5rem;
  height: 5rem;
  font-size: 3rem;
  margin-left: 1rem;
  transition: 0.3s all ease-in-out;
}

.icon-arrow-right:hover {
  width: 5rem;
  height: 5rem;
  font-size: 3rem;
  margin-left: 1rem;
  transform: translateX(15px);
}
</style>
