<template>
  <div class="rootDiv">
    <div class="actieveReviews">
      <font-awesome-icon
        v-if="pushedReviews.length > 0"
        @click="setReviews"
        class="file-import"
        icon="file-import"
      />
      Aantal Actieve Reviews: #{{ pushedReviews.length }}
    </div>
    <div>
      <font-awesome-icon
        @click="back"
        id="arrow-left"
        class="arrow-left"
        icon="arrow-left"
      />
      <h3>Review Overzicht</h3>
      <div v-if="showLoading">
        <base-card class="spinner">
          <p>
            ...Een moment geduld alstublieft jouw gegevens worden opgehaald...
          </p>
          <base-spinner></base-spinner>
        </base-card>
      </div>
      <div v-if="!showLoading && !selectedReview">
        <table>
          <tr v-for="review in reviewRecords" :key="review.datum">
            <td class="review" @click="checkReview(review.reviews)">
              Bekijk Reviews opgehaald op
              {{ reviewDate(review.datum) }} ({{ review.reviews.length }}
              reviews )
            </td>
          </tr>
        </table>

        <base-button @click="getReviews"
          >Haal nieuwe reviews op
          <font-awesome-icon id="plus" icon="plus" class="plus" />
        </base-button>
      </div>
      <div v-if="selectedReview">
        <table v-for="(review, index) in selectedReview" :key="index">
          <div
            class="reviewBox"
            :class="{ activeReviewBox: review.Active }"
            @click="setReview(review, index)"
          >
            <tr>
              Datum:
              {{
                reviewDate(review.date)
              }}
            </tr>
            <tr>
              Door:
              {{
                review.author
              }}
            </tr>
            <tr>
              Uit:
              {{
                review.city
              }}
            </tr>
            <tr>
              Review:
              {{
                review.rating
              }}
            </tr>
            <tr>
              Cijfer: 
              {{
                review.grade
              }}
            </tr>
          </div>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  data() {
    return {
      showLoading: true,
      selectedReview: null,
      pushedReviews: [],
    };
  },
  methods: {
    back() {
      if (this.selectedReview) {
        this.selectedReview = null;
      } else {
        this.changeComponent("Check-Main");
      }
    },
    changeComponent(selectedComponent) {
      this.$emit("selected-component", selectedComponent);
    },
    async getReviews() {
      this.showLoading = true;
      await this.$store.dispatch("fetchReviews");
      this.showLoading = false;
    },
    reviewDate(date) {
      return this.moment(date).format("DD.MM.YYYY, HH:mm:ss");
    },
    checkReview(review) {
      this.selectedReview = review;
    },
    setReview(review) {
      if (review.Active === true) {
        review.Active = false;
        this.pushedReviews = this.pushedReviews.filter(
          (val) => val.author !== review.author
        );
      } else {
        if (this.pushedReviews.length <= 10) {
          review.Active = true;
          this.pushedReviews.push(review);
        } else {
          return;
        }
      }
    },
    async setReviews() {
      let rev = [];
      this.pushedReviews.forEach((review) => {
        const myStr = review.author;
        const first = myStr.split(" ")[0];
        review.author = first.charAt(0).toUpperCase() + first.slice(1);
        rev.push(review);
      });
      await this.$store.dispatch("updateReviews", rev);
      this.pushedReviews = [];
      this.selectedReview = null;
    },
  },
  computed: {
    reviewRecords() {
      return this.$store.getters.getReviewRecs;
    },
    reviews() {
      return this.reviewRecords.reviews;
    },
  },
  async created() {
    await this.$store.dispatch("getReviews");
    this.moment = moment;
    this.showLoading = false;
  },
};
</script>

<style scoped>
.load {
  margin-top: 27%;
}

.arrow-left {
  position: absolute;
  right: 0;
  margin-right: 4rem;
  background-color: transparent;
  border-radius: 50%;
  border: 1px solid #44bace;
  padding: 0.8rem;
  margin-top: -1rem;
  color: #44bace;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
}

.arrow-left:hover {
  position: absolute;
  right: 0;
  border-radius: 50%;
  padding: 0.8rem;
  background-color: #44bace;
  margin-right: 4rem;
  margin-top: -1rem;
  color: white;
  cursor: pointer;
}

tr {
  text-align: left;
  margin: 0, 1rem;
  font-size: 1rem;
}

.plus {
  margin-top: -0.1rem;
  margin-left: 1rem;
}

.review {
  font-size: 1.2rem;
  transition: 0.2s all ease-in-out;
  cursor: pointer;
}

.review:hover {
  font-size: 1.2rem;
  text-decoration: underline;
  color: #44bace;
}

.reviewBox {
  width: 53rem;
  border-radius: 10px;
  border: 1px solid #44bace;
  transition: 0.2s all ease-in-out;
  cursor: pointer;
}

.activeReviewBox {
  width: 53rem;
  border-radius: 10px;
  cursor: pointer;
  background-color: #60ab2d;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#83c63c),
    to(rgba(131, 198, 60, 0))
  );
  background-image: linear-gradient(
    to bottom,
    #83c63c 0%,
    rgba(131, 198, 60, 0) 100%
  );
}

.reviewBox:hover {
  width: 53rem;
  border: 1px solid #44bace;
  border-radius: 10px;
  background-color: #44bace;
}

.checkBox {
  width: 2rem;
}

.actieveReviews {
  color: white;
  font-size: 1.5rem;
  position: absolute;
  margin-right: 3rem;
  margin-top: -6rem;
  right: 0;
}

.file-import {
  cursor: pointer;
  color: #60ab2d;
  border-radius: 50%;
  background-color: #fff;
  width: 1.2rem;
  padding: 0.5rem;
  transition: 0.2s all ease-in-out;
}

.file-import:hover {
  color: #fff;
  border-radius: 50%;
  background-color: #60ab2d;
  padding: 0.5rem;
  width: 1.2rem;
}
</style>
