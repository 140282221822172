<template>
  <div class="rootDiv">
    <font-awesome-icon
      v-if="!showAdd"
      @click="showAdd = true"
      id="plus"
      class="plus"
      icon="plus"
    />
    <font-awesome-icon
      v-if="!showAdd"
      @click="changeComponent('Check-Main')"
      id="arrow-left"
      class="arrow-left"
      icon="arrow-left"
    />
    <div v-if="!showAdd">
      <h3>Meerwerk Overzicht</h3>

      <table v-for="(meerwerk, index) in meerwerkAll" :key="index">
        <div class="container" @click="editMeerwerk(meerwerk)">
          <tr>
            <td>Naam: {{ meerwerk.name }}</td>
          </tr>
          <tr>
            <td>Beschrijving: {{ meerwerk.description }}</td>
          </tr>
        </div>
      </table>
    </div>
    <add-meerwerk
      v-if="showAdd"
      :meerwerk="selectedMeerwerk"
      @return-meerwerk="toCheckMeerwerk"
      @back-meerwerk="back"
    ></add-meerwerk>
  </div>
</template>

<script>
import AddMeerwerk from "./AddMeerwerk.vue";
export default {
  data() {
    return {
      showAdd: null,
      id: "",
      selectedMeerwerk: "",
    };
  },
  components: { AddMeerwerk },

  methods: {
    editMeerwerk(m) {
      this.selectedMeerwerk = m;
      this.showAdd = true;
    },
    changeComponent(selectedComponent) {
      this.$emit("selected-component", selectedComponent);
    },
    toCheckMeerwerk() {
      this.showAdd = null;
      this.$store.dispatch("fetchMeerwerkAll");
    },
    back() {
      this.showAdd = null;
    },
  },

  computed: {
    meerwerkAll() {
      return this.$store.getters.getMeerwerkAll;
    },
  },

  created() {
    this.$store.dispatch("fetchMeerwerkAll");
  },
};
</script>

<style scoped>
.container {
  margin-bottom: 1rem;
  border: 1px solid lightgray;
  border-radius: 12px;
  background-color: transparent;
  color: black;
  vertical-align: middle;
  padding: 1rem;
  transition: 0.3s all ease-in-out;
}

.container:hover {
  border: 1px solid transparent;
  background-color: #44bace;
  color: white;
  border-radius: 12px;
  vertical-align: middle;
  padding: 1rem;
  cursor: pointer;
}

img {
  width: 7rem;
  height: 7rem;
  margin-right: 3rem;
  border-radius: 50%;
}

.questionDiv {
  width: 7rem;
  height: 7rem;
  margin-right: 3rem;
  border-radius: 50%;
  background-color: grey;
  cursor: pointer;
}

.question {
  font-size: 4rem;
  color: white;
  margin-top: 1.5rem;
}

td {
  text-align: left;
}

.arrow-left {
  position: absolute;
  right: 0;
  margin-right: 4rem;
  background-color: transparent;
  border-radius: 50%;
  border: 1px solid #44bace;
  padding: 0.8rem;
  margin-top: -1rem;
  color: #44bace;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
}

.arrow-left:hover {
  position: absolute;
  right: 0;
  border-radius: 50%;
  padding: 0.8rem;
  background-color: #44bace;
  margin-right: 4rem;
  margin-top: -1rem;
  color: white;
  cursor: pointer;
}

.plus {
  position: absolute;
  right: 0;
  border-radius: 50%;
  padding: 0.8rem;
  background-color: transparent;
  margin-right: 4rem;
  margin-top: 3rem;
  border: 1px solid #60ab2d;
  color: #60ab2d;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
}

.plus:hover {
  position: absolute;
  right: 0;
  border-radius: 50%;
  padding: 0.8rem;
  background-color: #60ab2d;
  margin-right: 4rem;
  margin-top: 3rem;
  border: 1px solid #60ab2d;
  color: white;
  cursor: pointer;
  transform: rotate(180deg);
}

table {
  width: 80%;
  margin: auto;
}
</style>
