<template>
  <div class="rootDiv">
    <div class="dialog" v-if="!!show">
      <teleport to="body">
        <div class="backdrop" @click="tryClose">
          <div v-if="!showPDF">
            <div class="round" @click="tryClose">
              <font-awesome-icon class="iconRound" icon="times-circle" />
            </div>
            <div class="dialogView2 card">
              <div v-for="info in selectedInfo" :key="info">
                <div class="items" @click="addItem(info.url)">
                  {{ info.name }}
                </div>
              </div>
            </div>
          </div>
          <div v-if="showPDF">
            <div class="round" @click="tryClose">
              <font-awesome-icon class="iconRound" icon="times-circle" />
            </div>
            <div class="round2" @click="download">
              <font-awesome-icon class="iconRound" icon="download" />
            </div>
            <transition>
              <div class="dialogView">
                <dialog open>
                  <vue-pdf-embed :source="productBladUrl" />
                </dialog>
              </div>
            </transition>
          </div>
        </div>
      </teleport>
    </div>

    <div v-if="loading" class="loader">
      <base-spinner></base-spinner>
    </div>

    <div v-if="!loading">
      <font-awesome-icon
        v-if="!showAdd"
        @click="(showAdd = true), (type = 'add')"
        id="plus"
        class="plus"
        icon="plus"
      />
      <font-awesome-icon
        @click="changeComponent('Check-Main')"
        id="arrow-left"
        class="arrow-left"
        icon="arrow-left"
      />

      <div v-if="!showAdd">
        <h3>Product Overzicht</h3>
        <!-- <button @click="check">Check</button> -->
        <table
          style="margin-left: 3rem"
          v-for="(product, index) in products"
          :key="index"
        >
          <div class="borderProduct">
            <font-awesome-icon
              id="pen"
              class="pen"
              icon="pen"
              @click="
                (showAdd = true), (type = 'edit'), (selectedProduct = product)
              "
            />
            <!-- <button
              v-if="showUpdate(product.id)"
              @click="updateRecords(product)"
            >
              update records
            </button> -->
            <tr>
              <th rowspan="8">
                <img
                  v-if="product.PakketImage !== ''"
                  :src="product.PakketImage"
                />
                <div v-else class="questionDiv" @click="inputFile(product.id)">
                  <font-awesome-icon
                    id="question"
                    class="question"
                    icon="question"
                  />
                </div>
                <input
                  type="file"
                  id="theFileInput"
                  style="display: none"
                  @change="updateImg($event, product.id)"
                />
              </th>
              <td>Pakket Naam: {{ product.PakketNaam }}</td>
            </tr>
            <tr>
              <td>Pakket ID: {{ product.id }}</td>
            </tr>
            <tr>
              <td>Kleur Panelen: {{ product.KleurPanelen }}</td>
            </tr>
            <tr>
              <td>Optimizer: {{ product.Optimizer }}</td>
            </tr>
            <tr>
              <td>Extra Text: {{ product.Zonnepaneelpag2 }}</td>
            </tr>
            <tr>
              <td>
                Infosheet Omvormer:
                <font-awesome-icon
                  v-if="product.InfosheetOmvormer"
                  @click="
                    openDialog(
                      product.InfosheetOmvormer,
                      'Info Omvormer',
                      'show'
                    )
                  "
                  id="check-circle"
                  class="check-circle"
                  icon="check-circle"
                />
                <font-awesome-icon
                  v-if="!product.InfosheetOmvormer"
                  @click="openDialog(product.id, 'Info Omvormer', 'add')"
                  id="file-import"
                  class="file-import"
                  icon="file-import"
                />
              </td>
            </tr>
            <tr>
              <td>
                Infosheet Zonnepanelen:
                <font-awesome-icon
                  v-if="product.InfosheetZonnepanelen"
                  @click="
                    openDialog(
                      product.InfosheetZonnepanelen,
                      'Info Omvormer',
                      'show'
                    )
                  "
                  id="check-circle"
                  class="check-circle"
                  icon="check-circle"
                />
                <font-awesome-icon
                  v-if="!product.InfosheetZonnepanelen"
                  @click="openDialog(product.id, 'Info Zon', 'add')"
                  id="file-import"
                  class="file-import"
                  icon="file-import"
                />
              </td>
            </tr>
            <tr>
              <td>
                Specsheets Zon:
                <font-awesome-icon
                  v-if="product.SpecSheets"
                  @click="
                    openDialog(product.SpecSheets, 'Info Omvormer', 'show')
                  "
                  id="check-circle"
                  class="check-circle"
                  icon="check-circle"
                />
                <font-awesome-icon
                  v-if="!product.SpecSheets"
                  @click="openDialog(product.id, 'Spec Zon', 'add')"
                  id="file-import"
                  class="file-import"
                  icon="file-import"
                />
              </td>
            </tr>

            <br />
          </div>
        </table>
      </div>
      <add-products
        :type="type"
        :product="selectedProduct"
        v-if="showAdd"
        @close-add="(showAdd = null), (selectedProduct = '')"
      ></add-products>
    </div>
  </div>
</template>

<script>
import AddProducts from "./AddProducts.vue";
import VuePdfEmbed from "vue-pdf-embed";
import firebase from "firebase/app";
import "firebase/storage";

export default {
  components: {
    VuePdfEmbed,
    AddProducts,
  },
  data() {
    return {
      show: null,
      productBladUrl: "",
      label: "",
      showAdd: null,
      loading: null,
      selectedIMG: "",
      selectedID: "",
      showPDF: false,
      selectedInfo: "",
      showExample: false,
      selectedProduct: "",
      type: "",
    };
  },
  methods: {
    showUpdate(val) {
      if (val.includes("spv")) {
        return true;
      }
    },
    updateRecords(prod) {
      this.$store.dispatch("updateRecordsTemp", prod);
    },
    updateImg(event) {
      var input = event.target;
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.selectedIMG = e.target.result;
          this.upload(this.selectedIMG, this.selectedID);
        };
        reader.readAsDataURL(input.files[0]);
      }
    },

    async upload(img, prodId) {
      this.loading = true;

      var s = prodId.toLocaleUpperCase();
      var id = s.replace(/_/g, "");
      let storageRef = firebase.storage().ref();
      let imageRef = storageRef.child("PakketImage/" + `${id}.png`);
      await imageRef.putString(img, "data_url").then((snapshot) => {
        snapshot.ref.getDownloadURL().then((downloadURL) => {
          this.pakketImage = downloadURL;
          const doc = {
            id: prodId,
            imgUrl: this.pakketImage,
          };
          this.$store.dispatch("updateProductUrl", doc);
        });
      });
      this.loading = false;
    },

    addItem(url) {
      let field = "";
      if (this.label === "Info Omvormer") {
        field = "InfosheetOmvormer";
      } else if (this.label === "Info Zon") {
        field = "InfosheetZonnepanelen";
      } else if (this.label === "Spec Zon") {
        field = "SpecSheets";
      }
      const payload = {
        id: this.selectedID,
        field: field,
        value: url,
      };
      this.$store.dispatch("addInfosheetToProduct", payload);
    },

    inputFile(id) {
      this.selectedID = id;
      const file = document.getElementById("theFileInput");
      file.click();
    },

    changeComponent(selectedComponent) {
      if (this.showAdd === true) {
        this.showAdd = false;
      } else {
        this.$emit("selected-component", selectedComponent);
      }
    },
    openDialog(v, label, type) {
      this.label = label;
      if (type === "show") {
        this.productBladUrl = v;
        this.showPDF = true;
      } else {
        this.selectedID = v;
        if (this.label === "Info Omvormer") {
          this.selectedInfo = this.infoOmv;
        } else if (this.label === "Info Zon") {
          this.selectedInfo = this.infoZon;
        } else if (this.label === "Spec Zon")
          [(this.selectedInfo = this.infoSpecZon)];
      }
      this.show = true;
    },
    tryClose() {
      this.show = false;
      this.showPDF = false;
    },
    download() {
      fetch(this.productBladUrl, {
        method: "GET",
      })
        .then((response) => response.blob())
        .then((blob) => {
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement("a");
          a.href = url;
          a.download = `${this.label}.pdf`;
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();
          a.remove(); //afterwards we remove the element again
        });
      this.show = false;
    },
  },
  computed: {
    products() {
      return this.$store.getters.getProducts;
    },
    infoSheets() {
      return this.$store.getters.getInfosheets;
    },
    infoZon() {
      return this.infoSheets.zonnepanelen;
    },
    infoOmv() {
      return this.infoSheets.omvormer;
    },
    infoSpecZon() {
      if (this.infoSheets.specsheetZon) {
        return this.infoSheets.specsheetZon;
      } else {
        return [];
      }
    },
  },
  created() {
    this.$store.dispatch("fetchProducts");
  },
};
</script>

<style scoped>
.pen {
  float: right;
  margin-right: 2rem;
  padding: 0.5rem;
  border: 1px solid #44bace;
  border-radius: 50%;
  color: #44bace;
  transition: 0.5s all ease-in-out;
}

.pen:hover {
  float: right;
  margin-right: 2rem;
  padding: 0.5rem;
  border: 1px solid #44bace;
  background-color: #44bace;
  color: #fff;
  border-radius: 50%;
  cursor: pointer;
}

img {
  width: 8rem;
  height: 8rem;
  margin-right: 2rem;
  margin-left: 2rem;
}

th {
  vertical-align: middle;
}

.card {
  padding: 1rem;
  margin: auto;
  display: grid;
  grid-auto-rows: auto;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  width: 60%;
  height: 80%;
}
.questionDiv {
  width: 8rem;
  height: 8rem;
  margin-right: 2rem;
  border-radius: 50%;
  background-color: grey;
  cursor: pointer;
  margin-left: 2rem;
}

.question {
  font-size: 4rem;
  color: white;
  margin-top: 2rem;
}

.borderProduct {
  border: 1px solid lightgrey;
  border-radius: 12px;
  width: 46.5rem;
  padding-top: 1.2rem;
}

td {
  text-align: left;
}

.arrow-left {
  position: absolute;
  right: 0;
  margin-right: 4rem;
  background-color: transparent;
  border-radius: 50%;
  border: 1px solid #44bace;
  padding: 0.8rem;
  margin-top: -1rem;
  color: #44bace;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
}

.arrow-left:hover {
  position: absolute;
  right: 0;
  border-radius: 50%;
  padding: 0.8rem;
  background-color: #44bace;
  margin-right: 4rem;
  margin-top: -1rem;
  color: white;
  cursor: pointer;
}

.plus {
  position: absolute;
  right: 0;
  margin-right: 4rem;
  background-color: transparent;
  border-radius: 50%;
  border: 1px solid #60ab2d;
  padding: 0.8rem;
  margin-top: 3rem;
  color: #60ab2d;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
}

.plus:hover {
  position: absolute;
  right: 0;
  border-radius: 50%;
  padding: 0.8rem;
  background-color: #60ab2d;
  margin-right: 4rem;
  margin-top: 3rem;
  color: white;
  cursor: pointer;
  transform: rotate(180deg);
}

.check-circle {
  color: #60ab2d;
  cursor: pointer;
}

.round {
  position: absolute;
  cursor: pointer;
  top: 2%;
  right: 2%;
  background-color: #00a7e3;
  /* background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#44bace),
    to(rgba(68, 186, 206, 0))
  );
  background-image: linear-gradient(
    to bottom,
    #44bace 0%,
    rgba(68, 186, 206, 0) 100%
  ); */
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  transition: all 0.2s ease-in-out;
}

.round:hover {
  box-shadow: rgba(255, 255, 255, 1) 0px 0px 0px 3px;
}

.round2 {
  position: absolute;
  cursor: pointer;
  top: 10%;
  right: 2%;
  background-color: #00a7e3;

  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  transition: all 0.2s ease-in-out;
}
.round2:hover {
  box-shadow: rgba(255, 255, 255, 1) 0px 0px 0px 3px;
}

.items {
  border: 1px solid grey;
  border-radius: 12px;
  padding: 1rem;
  background-color: #fff;
  color: black;
  transition: all 0.2s ease-in-out;
}

.items:hover {
  cursor: pointer;
  border: 1px solid grey;
  border-radius: 12px;
  padding: 1rem;
  background-color: #00a7e3;
  color: #fff;
}

.iconRound {
  color: white;
  margin-left: 0.75rem;
  margin-top: 0.75rem;
  font-size: 1.5rem;
}

.loader {
  margin-top: 15rem;
}

/* CSS FOR DIALOG */

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 10;
}

.dialogView {
  position: absolute;
  margin: auto;
  left: 60%;
  right: auto;
  top: 2%;
}
.dialogView2 {
  position: absolute;
  margin: auto;
  left: 20%;
  right: auto;
  top: 2%;
}

dialog {
  max-width: 40rem;
  max-height: 60rem;
  /* z-index: 100; */
  border: none;
  overflow-y: auto;
  background-color: transparent;
}

.dialog-enter-from,
.dialog-leave-to {
  opacity: 0;
  transform: scale(0.8);
}

.dialog-enter-active {
  transition: all 0.3s ease-out;
}

.dialog-leave-active {
  transition: all 0.3 ease-in;
}

.dialog-enter-to,
.dialog-leave-from {
  opacity: 1;
  transform: scale(1);
}

@media (min-width: 768px) {
  dialog {
    left: calc(50% - 30rem);
    width: 60rem;
  }
}

/* END CSS FOR DIALOG */
</style>
