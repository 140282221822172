<template>
  <div class="rootDiv">
    <div class="employee">
      <div class="image">
        <img :src="imgUrl" alt="" />
      </div>
      <div class="text">
        <strong>{{ naam }} </strong>
        <br />
        <a :href="mailto"> {{ email }} </a>
        <br />
        <a href="tel:+31307670078">030 767 00 78</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    mailto() {
      return "mailto:" + this.email;
    },
    offerte() {
      return this.$store.getters.getCurrentOfferte;
    },
    naam() {
      return this.offerte.contactMedewerkerNaam;
    },
    email() {
      return this.offerte.contactMedewerkerEmail;
    },
    imgUrl() {
      return this.offerte.contactMedewerkerFoto;
    },
  },
};
</script>

<style scoped>


@media (max-width: 748px) {
  .employee {
    display: block !important;
    align-items: center !important;
    margin-bottom: 10% !important;
  }
  .image {
    border-radius: 50% !important;
    width: 100% !important;
    margin-right: 0rem !important;
    min-width: 20% !important;
    display: block !important;
  }
  img {
    display: block !important;
    width: 30% !important;
    min-width: 20% !important;
    height: auto !important;
  }
}

.image {
  border-radius: 50%;
  width: 10%;
  margin-right: 2rem;
  display: block;
}

.employee {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  /* margin-bottom: 1%; */
}

img {
  border-radius: 50%;
  display: block;
  width: 100%;
  height: auto;
}
.text,
a {
  color: white;
  text-decoration: none;
  text-align: left;
}
</style>
