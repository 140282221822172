<template>
  <div
    class="offer"
    :class="{ active: currentOfferte, expired: expired, accepted: accepted }"
    @click="openOfferte"
  >
    <div class="image">
      <img :src="offerte.productPakketImage" alt="" />
    </div>
    <div class="text">
      <a>
        <strong>{{ offerte.offerteMerkZonnepanelen }} </strong>
      </a>
      <div class="offerNumber">
        Offertenummer {{ offerte.offerteOfferteNummer }} |
        {{ offerteDate }}
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: ["offerte"],
  computed: {
    offerteDate() {
      const d = new Date().valueOf();

      if (
        this.offerte.offerteAkkoord &&
        this.offerte.quoteStage === "Closed Won"
      ) {
        return `Deze offerte is geaccepteerd op: ${this.moment(
          this.offerte.offerteAkkoord.offerteAkkoord
        )
          .locale("nl")
          .format("DD MMMM YYYY")}`;
      } else if (d < this.offerte.offerteGeldigheidsDatum) {
        return `Verloopt op: ${this.moment(this.offerte.offerteGeldigheidsDatum)
          .locale("nl")
          .format("DD MMMM YYYY")}`;
      } else {
        return `Deze offerte is verlopen op: ${this.moment(
          this.offerte.offerteGeldigheidsDatum
        )
          .locale("nl")
          .format("DD MMMM YYYY")}`;
      }
    },
    currentOfferte() {
      const co = this.$store.getters.getCurrentOfferte;
      if (this.offerte.offerteOfferteNummer === co.offerteOfferteNummer) {
        return true;
      } else {
        return false;
      }
    },
    currentQuote() {
      return this.$store.getters.getCurrentOfferte;
    },
    offertes() {
      return this.$store.getters.getOffertes;
    },
    versie() {
      return this.currentQuote.AB;
    },
    expired() {
      const d = new Date().valueOf();
      if (
        this.offerte.offerteAkkoord &&
        this.offerte.quoteStage === "Closed Won"
      ) {
        return false;
      } else {
        if (d < this.offerte.offerteGeldigheidsDatum) {
          return false;
        } else {
          return true;
        }
      }
    },
    accepted() {
      if (
        this.offerte.offerteAkkoord &&
        this.offerte.quoteStage === "Closed Won"
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    openOfferte() {
      const date = new Date().getTime();
      let update = "";
      if (this.offerte.offerteTimeStampOpened) {
        update = "offerteTimeStampReOpened";
      } else {
        update = "offerteTimeStampOpened";
      }
      const doc = {
        id: this.offerte.ID,
        timeStampOpened: date,
      };
      const currentOfferte = {
        ...this.offerte,
        [update]: date,
      };

      this.$store.dispatch("setCurrentOfferte", currentOfferte);
      if (update === "offerteTimeStampReOpened") {
        this.$store.dispatch("updateReOpened", doc);
      } else {
        this.$store.dispatch("updateOfferte", doc);
      }
      this.$store.dispatch("resetOffertes", {
        offertes: this.offertes,
        currentOfferte: currentOfferte,
      });

      this.$emit("offerte-opened");
    },
  },
  created() {
    this.moment = moment;
  },
};
</script>

<style scoped>
.expired {
  border: darkred 2px solid;
  color: darkred;
  border-radius: 12px;
  opacity: 0.5;
  transition: 0.3s all ease-in-out;
}

.expired:hover {
  border: darkred 2px solid;
  color: red;
  border-radius: 12px;
  opacity: 1;
}

.accepted {
  border: 2px solid #f39332 !important;
  border-radius: 12px;
  color: #83c63c;
}

.icon-arrow-right {
  vertical-align: top;
}

.active {
  border: 2px solid #83c63c !important;
  margin: 0.5rem;
  border-radius: 12px;
}

@media (min-width: 749px) {
  .offer {
    width: 48%;
  }
}
.offer {
  cursor: pointer;
  display: inline-block;
  -webkit-box-align: start;
  align-items: flex-start;
  border: 2px solid transparent;
  width: 47%;
  padding: 1rem 0;
  transition: 0.3s all ease-in-out;
}

.offer:hover {
  display: inline-block;
  -webkit-box-align: start;
  align-items: flex-start;
  width: 47%;
  border: 2px solid #83c63c;
  /* margin: 0.5rem; */
  border-radius: 12px;
}

.offer .image {
  width: 20%;
  padding-right: 3%;
  display: inline-block;
}

.offer .text strong {
  display: block;
  margin-bottom: 0.5rem;
  font-family: "Karla";
}

.offer .text .offerNumber,
header .offersBar .offers .offer .text .divider,
header .offersBar .offers .offer .text .date {
  display: inline-block;
  font-size: 0.9em;
  color: gray;
}

.offer .text {
  width: 75%;
  display: inline-block;
  vertical-align: top;
  text-align: left;
}

div {
  display: block;
}

img {
  display: block;
  width: 100%;
  border: 0;
  height: auto;
}

.button {
  display: -webkit-inline-box;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  vertical-align: bottom;
  position: relative;
  margin: 0;
  margin-top: 2%;
  border: none;
  outline: none;
  text-decoration: none;
  cursor: pointer;
  touch-action: manipulation;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-radius: 0.5rem;
  font-family: "Karla", sans-serif;
  width: auto;
  white-space: normal;
  text-align: center !important;
  font-weight: 700;
  -webkit-transition: all 0.15s ease-in-out 0s;
  transition: all 0.15s ease-in-out 0s;
}

.button.small {
  padding: 0.8rem 1.5rem 0.8rem;
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: bolder;
  line-height: 1;
}
.button.green {
  color: white;
  background-color: #60ab2d;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#83c63c),
    to(rgba(131, 198, 60, 0))
  );
  background-image: linear-gradient(
    to bottom,
    #83c63c 0%,
    rgba(131, 198, 60, 0) 100%
  );
  transition: all 0.2s ease-in-out;
}

.button.green:hover {
  background-color: #83c51c;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#83c51c),
    to(rgba(131, 198, 60, 0))
  );
  background-image: linear-gradient(
    to bottom,
    #83c51c 0%,
    rgba(131, 198, 60, 0) 100%
  );
  box-shadow: rgba(0, 0, 0, 0.34) 0px 3px 8px;
}

.button.small.hasIcon {
  padding-right: 2.5rem !important;
}

.button.small.hasIcon svg {
  width: 1.5rem;
  height: 1.5rem;
  right: 0.5rem;
}

.button svg {
  position: absolute;
  -webkit-transition: all 0.15s ease-in-out 0s;
  transition: all 0.15s ease-in-out 0s;
}
</style>
