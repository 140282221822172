<template>
  <div class="rootDiv">
    <div class="hero background-2">
      <div class="container">
        <div class="title backgroundcolor">
          <div class="header">
            <h2 style="margin: 0">
              <span
                >Jouw
                {{ offerte.productPakketNaam }}
              </span>
            </h2>
          </div>
        </div>
        <br />
        <div class="pakket backgroundcolor white">
          <div class="inhoudPakket">
            <visueel-overzicht></visueel-overzicht>
            <bestel-overzicht></bestel-overzicht>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="backgroundcolor white">
          <klant-gegevens @showBar="toggleExpireBar"></klant-gegevens>
        </div>
      </div>
      <div class="container">
        <div class="backgroundcolor white">
          <h4>Overzicht</h4>
          <component :is="selectedComponent"></component>
          <!-- <price-summary></price-summary> -->
          <expired-button v-if="expired"></expired-button>
          <agree-button
            version=""
            @change-step="changeStep"
            v-if="
              !expired || (quoteAccepted && offerte.quoteStage === 'Closed Won')
            "
          ></agree-button>
        </div>
        <br />
      </div>
    </div>
    <print-banner v-if="!expired && showPrintBanner"></print-banner>
    <div class="footer">
      <step-footer></step-footer>
    </div>
  </div>
</template>

<script>
import StepFooter from "../StepFooter.vue";
import BestelOverzicht from "./BestelOverzicht.vue";
import VisueelOverzicht from "./VisueelOverzicht.vue";
import KlantGegevens from "./KlantGegevens.vue";
import PrintBanner from "../PrintBanner.vue";
import PriceSummary from "./PriceSummary.vue";
import PriceKoop from "./PriceKoop.vue";
import AgreeButton from "./AgreeButton.vue";
import ExpiredButton from "./ExpiredButton.vue";
import firebase from "firebase/app";

export default {
  data() {
    return {
      showStepButtons: true,
    };
  },
  components: {
    StepFooter,
    BestelOverzicht,
    VisueelOverzicht,
    KlantGegevens,
    PrintBanner,
    PriceSummary,
    PriceKoop,
    AgreeButton,
    ExpiredButton,
  },
  computed: {
    quoteAccepted() {
      return this.$store.getters.getQuoteAccepted;
    },
    offerte() {
      return this.$store.getters.getCurrentOfferte;
    },
    expired() {
      const d = new Date().valueOf();
      if (
        this.offerte.offerteAkkoord &&
        this.offerte.quoteStage === "Closed Won"
      ) {
        return false;
      } else {
        if (this.offerte.offerteGeldigheidsDatum < d) {
          return true;
        } else {
          return false;
        }
      }
    },
    accepted() {
      if (
        this.offerte.offerteAkkoord &&
        this.offerte.quoteStage === "Closed Won"
      ) {
        return true;
      } else {
        return false;
      }
    },

    koop() {
      return this.offerte.offerteKoop;
    },
    selectedComponent() {
      if (this.koop) {
        return "price-koop";
      } else {
        return "price-summary";
      }
    },
    showPrintBanner() {
      if (window.innerWidth > 748) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    toggleExpireBar(value) {
      this.$emit("showBar", value);
    },
    changeStep(event) {
      this.$emit("change-step", event);
    },
  },
  beforeRouteLeave(to, _, next) {
    if (
      ((to.path === "/offerteDetail/stap3" ||
        to.path === "/offerteDetail/stap3b") &&
        this.accepted === true) ||
      to.path === "/offerteDetail/stap1" ||
      to.path === "/offerteDetail/stap1b"
    ) {
      next();
    } else {
      next(false);
    }
  },
  created() {
    firebase.analytics().logEvent("page_view - stap 2");
    if (window.innerWidth > 748) {
      this.showStepButtons = true;
    } else {
      this.showStepButtons = false;
    }
  },
};
</script>

<style scoped>
@media (max-width: 748px) {
  .footer {
    background: #f4f9fa;
    margin-bottom: -2rem;
  }

  .backgroundcolor.white {
    border-radius: 12px;
    background-color: white;
    box-shadow: 0 3rem 5rem rgb(0 0 0 / 10%);
    margin: 0.3rem;
  }

  .hero .container .title {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    text-align: center;
  }

  h2,
  .h2 {
    margin-bottom: 2rem;
    font-size: 2rem;
  }
  .hero.background-2 {
    padding-top: 2rem;
    margin-bottom: 2rem;
    background-color: #00a7e3;
  }
  .hero .container .title {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    text-align: center;
  }
  .hero .container .pakket {
    margin-top: 1rem;
  }
  .backgroundcolor {
    padding: 1rem;
  }
}
@media (min-width: 749px) {
  .hero.background-2 {
    padding-top: 5rem;
    margin-bottom: 1.5rem;
    background-color: #00a7e3;
  }
  .hero .container .title {
    width: 65%;
  }
  .hero .container .pakket {
    margin-top: 10rem;
  }
  .backgroundcolor {
    padding: 2rem;
    padding-top: 4rem;
  }
  .container {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  h2,
  .h2 {
    margin-bottom: 2rem;
    font-size: 4rem;
    line-height: 1.2;
  }

  .backgroundcolor.white {
    border-radius: 12px;
    background-color: white;
    box-shadow: 0 3rem 5rem rgb(0 0 0 / 10%);
    margin-bottom: 2rem;
  }
}

.container {
  position: relative;
  margin: auto;
  max-width: 72rem;
}

.hero .container .title {
  color: white;
  padding-top: 0;
}

.hero .container .title .header {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 2rem;
}

.hero .container .title h2 span {
  font-size: 1em;
}

.hero .container .title h4 span {
  font-size: 1.3em;
}

.hero .container .title h4,
.hero .container .title h2 {
  color: white;
}

h4,
.h4 {
  margin: 0;
  font-family: "Karla", sans-serif;
  text-transform: uppercase;
  margin-bottom: 1rem;
  color: #00a7e3;
  font-size: 1.6rem;
  line-height: 0;
  font-weight: 700;
  text-align: left;
}
*,
*:before,
*:after {
  box-sizing: border-box;
}

h4 {
  display: block;
  margin-block-start: 1.33em;
  margin-block-end: 1.33em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

h2,
.h2 {
  margin: 0;
  font-family: "Karla", sans-serif;
  font-weight: 700;
}
*,
*:before,
*:after {
  box-sizing: border-box;
}
h2 {
  display: inline-block;
  font-size: 2.5em;
  font-weight: bold;
  text-align: left;
}

.hero .container .title .header .offerNumber {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 3rem;
  padding: 0.5rem 1rem;
  margin-left: 1rem;
  font-weight: 700;
}
.header h2 {
  margin-bottom: 2em;
  font-size: clamp(1.5rem, 2vw, 2rem);
}
</style>
