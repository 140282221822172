import db from "../../../fb.js";

// const offerteCollectie = db.collection("Test");
const offerteCollectie = db.collection("Offertes");

export default {
  async setOffertes(context, payload) {
    await context.commit("setOffertes", payload);
  },

  async createRequest(context, payload) {
    const array = [];

    try {
      offerteCollectie
        .where("klantHuisNummer", "==", payload.huisNummer)
        .where("klantPostcode", "==", payload.postCode)
        .orderBy("offerteOfferteDatum", "desc")
        .limit(1)
        .get()
        .then((snapshot) => {
          if (snapshot.size > 0) {
            snapshot.forEach((doc) => {
              const rec = doc.data();
              array.push(rec);
              context.commit("setRequest", array);
            });
          } else {
            const error = new Error();
            throw error.message;
          }
        });
    } catch {
      const error = new Error();
      throw error.message;
    }
  },

  async saveRequest(_, payload) {
    await db.collection("Requests").add(payload);
  },

  async ClearAll(context, _) {
    await context.commit("ClearAll");
  },

  async fetchRecordByUrl(context, payload) {
    await context.commit("ClearAll");
    let rem = payload.substring(0, payload.length - 2);
    if (rem.includes("&employee")) {
      rem = rem.substring(0, rem.length - 9);
      await context.commit("setMedewerkerActive");
    }
    const array = [];

    await offerteCollectie
      .where("offerteUrl", "==", rem)
      .get()
      .then((querySnapshot) => {
        if (querySnapshot.size > 0) {
          querySnapshot.forEach((doc) => {
            const r = doc.data();
            const rec = {
              ...doc.data(),
              id: doc.id,
            };
            if (r.offerteAkkoord && r.quoteStage === "Closed Won") {
              context.commit("setQuoteAccepted");
            }
            array.push(rec);
          });
          context.commit("setOffertes", array);
        } else {
          context.dispatch("fetchRecordByUrlOUD", payload);
        }
      });
  },

  async fetchRecordByUrlOUD(context, payload) {
    let rem = payload.substring(0, payload.length - 2);
    if (rem.includes("&employee")) {
      await context.commit("setMedewerkerActive");
      rem = rem.substring(0, rem.length - 11);
    }
    const array = [];
    await offerteCollectie
      .where("offerteUrl_OUD", "==", rem)
      .get()
      .then((querySnapshot) => {
        if (querySnapshot.size > 0) {
          querySnapshot.forEach((doc) => {
            const r = doc.data();
            const rec = {
              ...doc.data(),
              id: doc.id,
            };
            array.push(rec);
          });
          context.commit("setOffertes", array);
        } else {
          console.log("No such document found!");
          // const error = new Error();
          // throw error.message;
        }
      });
  },

  updateOfferte(context, payload) {
    offerteCollectie
      .doc(payload.id)
      .update({ offerteTimeStampOpened: payload.timeStampOpened });

    context.commit("updateOfferteOpened", payload);
  },

  updateReOpened(context, payload) {
    offerteCollectie
      .doc(payload.id)
      .update({ offerteTimeStampReOpened: payload.timeStampOpened });

    context.commit("updateOfferteReOpened", payload);
  },

  updateAkkoord(context, payload) {
    let datum = 0;
    if (payload.Tijdstempel !== 0) {
      datum = payload.Tijdstempel;
    } else {
      datum = new Date().valueOf();
    }
    const doc = {
      offerteAkkoord: datum,
      ipAdressAkkoord: payload.ipAdress,
      Medewerker_Geaccepteerd: payload.Medewerker_Geaccepteerd,
    };
    offerteCollectie
      .doc(payload.id)
      .update({ offerteAkkoord: doc, quoteStage: "Closed Won" });

    context.commit("updateOfferteAkoord", datum);
  },

  async setCurrentOfferte(context, payload) {
    context.commit("setCurrentOfferte", payload);
  },

  async getKorting(context, payload) {
    let validTill = 0;
    if (payload.korting.verlengdTot) {
      validTill = getDate(payload.korting.verlengdTot);
    } else {
      validTill = getDate(payload.korting.geldigTot);
    }
    function getDate(e) {
      return new Date(e).valueOf();
    }
    const now = new Date().valueOf();
    const days = 3;
    if (validTill - 86400000 * days < now) {
      let korting = null;
      await db
        .collection("Kortingen")
        .where("omschrijving", "==", payload.korting.omschrijving)
        .limit(1)
        .get()
        .then((snapshot) => {
          if (snapshot.size > 0) {
            snapshot.forEach((doc) => {
              korting = doc.data();
            });
          }
        })
        .then(() => {
          if (korting) {
            const kort = {
              financieringsvorm: korting.financieringsvorm,
              geldigTot: korting.geldigTot,
              geldigVan: korting.geldigVan,
              kortingscode: korting.kortingsCode,
              omschrijving: korting.omschrijving,
            };
            if (korting.Korting_verlengdtot) {
              kort.verlengdTot = korting.Korting_verlengdtot;
            }
            offerteCollectie
              .doc(payload.ID)
              .update({ korting: kort })
              .then(() => {
                payload.korting = kort;
              });
          }
        })
        .then(() => {
          context.commit("setKorting", payload);
        });
    } else {
      context.commit("setKorting", payload);
    }
  },

  resetOffertes(context, payload) {
    const array = [];
    const offertes = payload.offertes;
    const current = payload.currentOfferte;
    array.push(JSON.parse(JSON.stringify(current)));
    offertes.forEach((val) => {
      if (val.ID !== current.ID) {
        array.push(JSON.parse(JSON.stringify(val)));
      }
    });
    context.commit("setOffertes", array);
  },

  // addProduct(_, payload) {
  //   console.log("STARTING TO ADD PRODUCT!");

  //   db.collection("Producten").doc(payload.id).set(payload.doc);
  // },

  addMedewerker(_, payload) {
    db.collection("Medewerkers").doc(payload.id).set(payload.doc);
  },

  addMeerwerk(_, payload) {
    if (payload.id) {
      db.collection("Meerwerk").doc(payload.id).update({
        name: payload.name,
        description: payload.description,
      });
    } else {
      db.collection("Meerwerk").add({
        name: payload.name,
        description: payload.description,
      });
    }
  },

  getGenData(context, _) {
    db.collection("GeneralData")
      .doc("Reviews")
      .get()
      .then((doc) => {
        const rec = doc.data();
        context.commit("setGenData", {
          Voorgangers: rec.voorgangers,
          KlantenVertellen: rec.klantenVertellen,
        });
      });
  },

  async fetchReviews(context) {
    const API = "16e34586-1344-4f15-b60f-78788cc6f796";
    const LocationId = "1039163";
    let headers = {
      "X-Publication-Api-Token": API,
    };
    let requestDetails = {
      headers: headers,
    };

    const url1 =
      "https://www.kiyoh.com/v1/publication/review/external?locationId=" +
      LocationId +
      "&tenantId=98&limit=10";
    const head = requestDetails;
    let processedReviews = [];
    const date = new Date().valueOf();
    await fetch(url1, head)
      .then((response) => response.json())
      .then((data) => {
        data.reviews.forEach((review) => {
          const date = new Date(review.dateSince).valueOf();
          if (review.rating >= 7) {
            const rev = {
              author: review.reviewAuthor,
              city: review.city,
              rating: review.reviewContent[2].rating,
              grade: review.rating,
              date: date,
            };
            processedReviews.push(rev);
          }
        });
        const doc = {
          datum: date,
          reviews: processedReviews,
        };
        db.collection("Reviews").add(doc);
      });
    context.dispatch("getReviews");
  },

  getReviews(context, _) {
    let reviewRecs = [];
    db.collection("Reviews")
      .orderBy("datum", "desc")
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          const rec = doc.data();
          reviewRecs.push(rec);
        });
        context.commit("setReviewRecs", reviewRecs);
      });
  },

  getGenData(context, _) {
    db.collection("GeneralData")
      .doc("Reviews")
      .get()
      .then((doc) => {
        const rec = doc.data();
        context.commit("setGenData", rec);
      });
  },

  async fetchScore(context) {
    const API = "16e34586-1344-4f15-b60f-78788cc6f796";
    const LocationId = "1039163";
    let headers = {
      "X-Publication-Api-Token": API,
    };
    let requestDetails = {
      headers: headers,
    };
    const url =
      "https://www.kiyoh.com/v1/publication/review/external/location/statistics?locationId=" +
      LocationId;

    const head = requestDetails;
    await fetch(url, head)
      .then((response) => response.json())
      .then((data) => {
        const kv = {
          rate: data.averageRating,
          numberReviews: data.numberReviews,
          viewReviewUrl: data.viewReviewUrl,
          imgUrl:
            "https://firebasestorage.googleapis.com/v0/b/online-offerte-tool.appspot.com/o/Pages%2FMainBar%2Fklantenvertellen-dynamic.svg?alt=media&token=87ef4a53-5ea6-4af5-b8ba-f5a133941443",
        };
        db.collection("GeneralData")
          .doc("Reviews")
          .update({ klantenVertellen: kv });
      })
      .then(() => {
        context.dispatch("getGenData");
      });
  },

  async fetchMedewerkers(context, _) {
    let med = [];
    await db
      .collection("Medewerkers")
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          const rec = doc.data();
          const data = {
            ...rec,
            id: doc.id,
          };
          med.push(data);
        });
        context.commit("setMedewerkers", med);
      });
  },

  async fetchProducts(context, _) {
    let med = [];
    await db
      .collection("Producten")
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          const rec = doc.data();
          const data = {
            ...rec,
            id: doc.id,
          };
          med.push(data);
        });
        context.commit("setProducts", med);
      });
  },

  updateReviews(_, payload) {
    db.collection("GeneralData")
      .doc("Reviews")
      .update({ selectedReviews: payload });
  },

  addMedewerker(_, payload) {
    db.collection("Medewerkers").doc(payload.id).set({
      email: payload.email,
      imgUrl: payload.imgUrl,
      naam: payload.naam,
    });
  },

  addProduct(context, payload) {
    const doc = {
      PakketNaam: payload.PakketNaam,
      KleurPanelen: payload.KleurPanelen,
      PakketImage: payload.PakketImage,
      Zonnepaneelpag2: payload.Zonnepaneelpag2,
      InfosheetOmvormer: payload.InfosheetOmvormer,
      InfosheetZonnepanelen: payload.InfosheetZonnepanelen,
      SpecSheets: payload.SpecSheets,
      Optimizer: payload.Optimizer,
    };
    db.collection("Producten").doc(payload.id).set(doc);
    context.dispatch("fetchProducts");
  },

  updateImgUrl(context, payload) {
    db.collection("Medewerkers")
      .doc(payload.id)
      .update({ imgUrl: payload.imgUrl });
    context.dispatch("fetchMedewerkers");
  },

  updateProductUrl(context, payload) {
    db.collection("Producten")
      .doc(payload.id)
      .update({ PakketImage: payload.imgUrl });
    context.dispatch("fetchProducts");
  },

  addInfosheetToProduct(context, payload) {
    const fieldName = payload.field;
    db.collection("Producten")
      .doc(payload.id)
      .update({ [fieldName]: payload.value });
    setTimeout(() => {
      context.dispatch("fetchProducts");
    }, 2000);
  },

  getInfosheets(context, _) {
    db.collection("GeneralData")
      .doc("infosheets")
      .get()
      .then((doc) => {
        const rec = doc.data();
        context.commit("setInfosheets", rec);
      });
  },

  async FetchPages(context, _) {
    await db
      .collection("GeneralData")
      .doc("Pages")
      .get()
      .then((doc) => {
        const rec = doc.data();
        context.commit("setPages", rec);
      });
  },

  async FetchLoginPage(context, _) {
    await db
      .collection("GeneralData")
      .doc("LoginPage")
      .get()
      .then((doc) => {
        const rec = doc.data();
        context.commit("setLoginPage", rec);
      });
  },

  updateInfoSheets(context, payload) {
    db.collection("GeneralData").doc("infosheets").set(payload);
    context.dispatch("getInfosheets");
  },

  editRecord(context, payload) {
    if (payload.collection === "Login") {
      context.dispatch("editLogin", payload);
    } else {
      db.collection("GeneralData")
        .doc("Pages")
        .get()
        .then((doc) => {
          const rec = doc.data();
          if (payload.index !== null) {
            const i = payload.index;
            rec[payload.collection][i][payload.field] = payload.value;
          } else {
            rec[payload.collection][payload.field] = payload.value;
          }
          db.collection("GeneralData").doc("Pages").set(rec);
          context.dispatch("FetchPages");
          context.dispatch("logRecord", payload);
        });
    }
  },

  editLogin(context, payload) {
    db.collection("GeneralData")
      .doc("LoginPage")
      .get()
      .then((doc) => {
        const rec = doc.data();

        rec[payload.field] = payload.value;

        db.collection("GeneralData").doc("LoginPage").set(rec);
        context.dispatch("FetchLoginPage");
        context.dispatch("logRecord", payload);
      });
  },

  fetchUser(context, payload) {
    db.collection("Users")
      .doc(payload)
      .get()
      .then((doc) => {
        const rec = doc.data();
        const data = {
          ...rec,
          id: doc.id,
        };
        context.commit("setUser", data);
      });
  },

  logRecord(context, payload) {
    const date = new Date().valueOf();
    const d = date.toString();
    if (payload.index !== null) {
      const doc = {
        user: `${payload.admin.firstName} ${payload.admin.lastName}`,
        collection: payload.collection,
        field: payload.field,
        index: payload.index,
        oldVal: payload.oldVal,
        newVal: payload.newValue,
        value: payload.value,
      };
      db.collection("Logs").doc(d).set(doc);
    }
    if (payload.index === null) {
      const doc = {
        user: `${payload.admin.firstName} ${payload.admin.lastName}`,
        collection: payload.collection,
        field: payload.field,
        oldVal: payload.oldVal,
        newVal: payload.newValue,
        value: payload.value,
      };
      db.collection("Logs").doc(d).set(doc);
    }

    context.dispatch("fetchLogs");
  },

  async fetchLogs(context) {
    let med = [];
    await db
      .collection("Logs")
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          const rec = doc.data();
          const data = {
            ...rec,
            id: parseInt(doc.id),
          };
          med.push(data);
        });
      });
    function compare(a, b) {
      if (a.id < b.id) return 1;
      if (a.id > b.id) return -1;
      return 0;
    }
    med = med.sort(compare);
    context.commit("setLogs", med);
  },

  addKorting(_, payload) {
    const d = new Date().valueOf();
    const e = d.toString();
    db.collection("Kortingen").doc(e).set(payload);
  },

  async fetchMeerwerkAll(context, _) {
    const array = [];
    await db
      .collection("Meerwerk")
      .get()
      .then((snapshot) => {
        snapshot.forEach((val) => {
          const r = {
            ...val.data(),
            id: val.id,
          };
          array.push(r);
        });
      });
    context.commit("setMeerwerkAll", array);
  },

  deleteMeerwerk(_, payload) {
    db.collection("Meerwerk").doc(payload).delete();
  },

  updateRecordsTemp(_, payload) {
    db.collection("Offertes")
      .where("productPakketPrijsName", "==", payload.id)
      .get()
      .then((snapshot) => {
        snapshot.forEach((val) => {
          const rec = val.data();
          const doc = {
            productInfosheetOmvormer: payload.InfosheetOmvormer,
            productInfosheetZonnepanelen: payload.InfosheetZonnepanelen,
            productKleurPanelen: payload.KleurPanelen,
            productOptimizer: payload.Optimizer,
            productPakketImage: payload.PakketImage,
            productPakketNaam: payload.PakketNaam,
            productSpecSheets: payload.SpecSheets,
            productZonnepaneelpag2: payload.Zonnepaneelpag2,
          };
          db.collection("Offertes").doc(rec.ID).update(doc);
        });
      });
  },
};
