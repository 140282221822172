<template>
  <div class="rootDiv">
    <div v-if="loading" class="spinner">
      <base-spinner></base-spinner>
    </div>
    <div v-if="!loading">
      <div class="dialog" v-if="!!show">
        <teleport to="body">
          <div class="backdrop">
            <!-- <base-card> -->
            <div class="dialogView">
              <h3 style="text-align: center">Wil je deze korting gebruiken?</h3>
              <p style="font-size: smaller; color: grey">
                Door "ja" te clicken, wordt de huidige korting overschreven. Je
                kunt de kortingen altijd weer terug vinden in het
                kortingenoverzicht.
              </p>
              <base-button style="float: left" @click="confirm('No')"
                >Nee</base-button
              >
              <base-button style="float: right" @click="confirm('Yes')"
                >Ja</base-button
              >
            </div>
            <!-- </base-card> -->
          </div>
        </teleport>
      </div>
      <font-awesome-icon
        @click="showAddKorting"
        id="plus"
        class="plus"
        icon="plus"
      />
      <h3>Kortingsmelding</h3>
      <div class="container">
        <tr>
          <td>Huidige Korting:</td>
        </tr>
        <tr>
          <td>Loopt van: {{ convertedDateNoHours(korting.kortingVan) }}</td>
        </tr>
        <tr>
          <td>Tot: {{ convertedDate(korting.kortingTot) }}</td>
        </tr>
        <tr>
          <td>Kortingstekst: {{ korting.kortingsTekst }}</td>
        </tr>
        <tr>
          <td>
            Voor offertes gemaakt vanaf:
            {{ convertedDateNoHours(korting.offerteCreatedAfter) }}
          </td>
        </tr>
        <tr>
          <td>
            Voor offertes met een looptijd van:
            {{ korting.looptijd }} Jaar
          </td>
        </tr>
      </div>
      <div v-if="!showEditKorting" @click="toggleRecords" class="showRecords">
        Kortingsgeschiedenis
      </div>
      <br /><br />
      <div v-if="!showEditKorting && showRecords">
        <div v-for="kort in kortingen" :key="kort.kortingsTekst">
          <div
            @click="addKorting(true, kort)"
            class="container"
            :class="{ active: kort.kortingTot > currentDate }"
          >
            <tr>
              <td class="timestampCreated">
                Gemaakt op {{ convertedDate(parseInt(kort.dateCreated)) }} door
                {{ kort.admin.firstName + " " + kort.admin.lastName }}
              </td>
            </tr>
            <tr>
              <td>Loopt van: {{ convertedDateNoHours(kort.kortingVan) }}</td>
            </tr>
            <tr>
              <td>Tot: {{ convertedDate(kort.kortingTot) }}</td>
            </tr>
            <tr>
              <td>Kortingstekst: {{ kort.kortingsTekst }}</td>
            </tr>
            <tr>
              <td>
                Voor offertes gemaakt vanaf:
                {{ convertedDateNoHours(kort.offerteCreatedAfter) }}
              </td>
            </tr>
            <tr>
              <td>
                Voor offertes met een looptijd van:
                {{ kort.looptijd }} Jaar
              </td>
            </tr>
          </div>
          <br />
        </div>
      </div>

      <div v-if="showEditKorting && !showRecords">
        <div class="valueEdit">
          <div class="content-left">Kortingstekst:</div>
          <input
            type="text"
            v-model="kortingsTekst"
            @blur="showKortingsTekst"
          />
        </div>
        <div class="valueEdit">
          <div class="content-left">Loopt van:</div>
          <input
            type="date"
            v-model="kortingVan"
            @change="showKortingVan(kortingVan)"
          />
        </div>
        <div class="valueEdit">
          <div class="content-left">Tot:</div>
          <input
            type="date"
            v-model="kortingTot"
            @change="showKortingTot(kortingTot)"
          />
        </div>
        <div class="valueEdit">
          <div class="content-left">Voor offertes gemaakt vanaf:</div>
          <input
            type="date"
            v-model="offerteCreatedAfter"
            @change="showOfferteCreatedAfter(offerteCreatedAfter)"
          />
        </div>
        <div class="valueEdit">
          <div class="content-left">Offerte Looptijd:</div>
          <select v-model="looptijd" @change="checkButton">
            <option disabled value="">Kies de looptijd</option>
            <option value="10">10 Jaar Huur</option>
            <option value="15">15 Jaar Huur</option>
            <option value="10-15">Beide</option>
          </select>
        </div>
        <base-button v-if="showButton" @click="addKorting(false)"
          >Korting Toevoegen</base-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      showEditKorting: false,
      kortingsTekst: "",
      kortingTot: "",
      kortingVan: "",
      looptijd: "",
      offerteCreatedAfter: "",
      valueKortingTot: 0,
      valueKortingVan: 0,
      valueOfferteCreatedAfter: 0,
      showButton: false,
      showRecords: false,
      show: false,
      loading: false,
      kortingen: "",
      currentDate: 0,
      reUse: false,
      dateCreated: 0,
    };
  },
  async created() {
    this.moment = moment;
    this.currentDate = new Date().valueOf();
  },

  computed: {
    korting() {
      return this.$store.getters.getKorting;
    },
    admin() {
      return this.$store.getters.getAdmin;
    },
  },
  methods: {
    async toggleRecords() {
      if (this.showRecords === false && this.kortingen === "") {
        await this.$store.dispatch("fetchKortingen");
        this.kortingen = this.$store.getters.getKortingen;
        this.showRecords = true;
      } else if (this.showRecords === false && this.kortingen !== "") {
        this.showRecords = true;
      } else {
        this.showRecords = false;
      }
    },
    convertedDate(date) {
      return this.moment(date).format("DD.MM.YYYY, HH:mm:ss");
    },
    convertedDateNoHours(date) {
      return this.moment(date).format("DD.MM.YYYY");
    },
    showAddKorting() {
      this.showEditKorting = true;
      this.showRecords = false;
    },
    showKortingsTekst() {
      this.checkButton();
    },
    showKortingVan(event) {
      const d = new Date(event);
      this.valueKortingVan = d.valueOf() - 7200000;
      this.checkButton();
    },
    showKortingTot(event) {
      const d = new Date(event);
      this.valueKortingTot = d.valueOf() + 79199000;
      this.checkButton();
    },
    showOfferteCreatedAfter(event) {
      const d = new Date(event);
      this.valueOfferteCreatedAfter = d.valueOf() - 7200000;
      this.checkButton();
    },
    checkButton() {
      if (
        this.valueKortingTot &&
        this.valueKortingVan &&
        this.valueOfferteCreatedAfter &&
        this.kortingsTekst &&
        this.looptijd
      ) {
        this.showButton = true;
      } else {
        this.showButton = false;
      }
    },
    addKorting(edit = false, value = "") {
      if (
        edit === true &&
        +value.dateCreated !== this.korting.dateCreated &&
        value.kortingTot > this.currentDate
      ) {
        this.valueKortingTot = value.kortingTot;
        this.valueKortingVan = value.kortingVan;
        this.kortingsTekst = value.kortingsTekst;
        this.valueOfferteCreatedAfter = value.looptijd;
        this.looptijd = value.offerteCreatedAfter;
        this.show = true;
        this.dateCreated = value.dateCreated;
      }
      if (edit === false) {
        const payload = {
          kortingTot: this.valueKortingTot,
          kortingVan: this.valueKortingVan,
          kortingsTekst: this.kortingsTekst,
          looptijd: this.looptijd,
          offerteCreatedAfter: this.valueOfferteCreatedAfter,
          admin: this.admin,
        };
        this.$store.dispatch("addKorting", payload);
        this.show = true;
      }
    },
    async confirm(res) {
      let date = 0;
      if (this.dateCreated) {
        date = this.dateCreated;
      } else {
        date = new Date().valueOf();
      }
      const payload = {
        kortingTot: this.valueKortingTot,
        kortingVan: this.valueKortingVan,
        kortingsTekst: this.kortingsTekst,
        offerteCreatedAfter: this.valueOfferteCreatedAfter,
        looptijd: this.looptijd,
        admin: this.admin,
        dateCreated: date,
      };
      this.showEditKorting = false;
      this.kortingsTekst = "";
      this.kortingTot = "";
      this.kortingVan = "";
      this.offerteCreatedAfter = "";
      this.valueKortingTot = 0;
      this.valueKortingVan = 0;
      this.valueOfferteCreatedAfter = 0;
      this.showButton = false;
      if (res === "No") {
        this.show = false;
      }
      if (res === "Yes") {
        this.loading = true;
        setTimeout(() => {
          this.$store.dispatch("editKorting", payload);
          this.show = false;
          this.loading = false;
          this.$emit("selected-component", "CheckMain");
        }, 2500);
      }
    },
  },
};
</script>

<style scoped>
.active:hover {
  border: 1px solid #44bace;
  opacity: 100%;
  cursor: pointer;
}

.active {
  transition: 0.3s all ease-in-out;
  opacity: 50%;
}

.timestampCreated {
  display: absolute;
  right: 0;
  font-style: italic;
  font-size: x-small;
  color: grey;
  padding-bottom: 1rem;
}

.spinner {
  margin-top: 25%;
}
.container {
  text-align: left;
  border: 1px solid lightgray;
  border-radius: 12px;
  vertical-align: middle;
  padding: 1rem;
}

.plus {
  position: absolute;
  right: 0;
  margin-right: 4rem;
  background-color: transparent;
  border-radius: 50%;
  border: 1px solid #44bace;
  padding: 0.8rem;
  margin-top: -1rem;
  color: #44bace;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
}

.plus:hover {
  position: absolute;
  right: 0;
  border-radius: 50%;
  padding: 0.8rem;
  background-color: #44bace;
  margin-right: 4rem;
  margin-top: -1rem;
  color: white;
  cursor: pointer;
}

.content-left {
  width: 20%;
  display: inline-block;
}

input,
select {
  text-align: center;
  margin-top: 1.5rem;
  font-size: 1rem;
  color: black;
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
  width: 60%;
  border: 1px solid #cccccc;
  padding: 1rem 1rem 1rem;
  border-radius: 12px;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#fafafa),
    to(white)
  );
  background-image: linear-gradient(to bottom, #fafafa 0%, white 100%);
  -webkit-transition: border-color 0.15s ease-in-out 0s;
  transition: border-color 0.15s ease-in-out 0s;
}

.showRecords {
  margin-top: 2rem;
  text-align: center;
  cursor: pointer;
  color: grey;
  transition: 0.3s all ease-in-out;
}

.showRecords:hover {
  margin-top: 2rem;
  text-align: center;
  color: #44bace;
}

/* CSS FOR DIALOG */

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 10;
}

.dialogView {
  position: absolute;
  margin: auto;
  width: 30%;
  padding: 1rem;
  border-radius: 12px;
  background-color: #fff;
  left: 40%;
  right: auto;
  top: 30%;
}

dialog {
  max-width: 40rem;
  max-height: 60rem;
  /* z-index: 100; */
  border: none;
  overflow-y: auto;
  background-color: transparent;
}

@media (min-width: 768px) {
  dialog {
    left: calc(50% - 30rem);
    width: 60rem;
  }
}

/* END CSS FOR DIALOG */
</style>
