<template>
  <div class="rootDiv">
    <div class="editDialog" v-if="!!showEdit">
      <teleport to="body">
        <div class="editBackdrop">
          <div class="editDialogView">
            <h3 style="text-align: center">Edit</h3>
            <h4 style="color: grey">Huidige waarde:</h4>
            <img
              style="width: 10rem; height: auto"
              v-if="
                fieldToEdit.toLowerCase().includes('img') ||
                fieldToEdit.toLowerCase().includes('logo')
              "
              :src="valueToEdit"
              alt=""
            />
            <p
              v-else
              style="font-size: smaller; color: grey"
              v-html="valueToEdit"
            ></p>
            <h4 style="color: grey">Nieuwe waarde:</h4>

            <input
              v-if="
                fieldToEdit.toLowerCase().includes('img') ||
                fieldToEdit.toLowerCase().includes('logo')
              "
              type="file"
              @change="updateImg($event, fieldToEdit, valueToEdit)"
            />
            <input v-else type="text" v-model="editedValue" />
            <img
              style="width: 10rem; height: auto"
              v-if="imgUrl"
              :src="editedValue"
              alt=""
            />
            <base-button style="float: left" @click="confirm('No')"
              >Terug</base-button
            >
            <base-button style="float: right" @click="confirm('Yes')"
              >Opslaan</base-button
            >
          </div>
        </div>
      </teleport>
    </div>
    <div class="fullContainer">
      <div class="container">
        <div class="image">
          <div :class="{ active: editor }">
            <img
              @click="edit(klantenKaart.img, 'KlantenKaart', 'img')"
              :src="klantenKaart.img"
              alt=""
            />
          </div>
          <div class="caption">Klanten van HalloStroom</div>
        </div>
        <div class="textBlock">
          <div :class="{ active: editor }">
            <h2
              @click="edit(klantenKaart.header, 'KlantenKaart', 'header')"
              v-html="klantenKaart.header"
            ></h2>
          </div>
          <div v-if="editor">
            {{ voorgangers }}
            <div :class="{ active: editor }">
              <p
                @click="
                  edit(klantenKaart.paragraph, 'KlantenKaart', 'paragraph')
                "
                v-html="klantenKaart.paragraph"
              ></p>
            </div>
          </div>
          <p v-if="!editor" v-html="voorgangers + klantenKaart.paragraph"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showEdit: false,
      valueToEdit: "",
      editedValue: "",
      subColToEdit: "",
      fieldToEdit: "",
      indexToEdit: null,
      imgUrl: "",
      uploadValue: "",
      imageData: "",
    };
  },

  computed: {
    offerte() {
      return this.$store.getters.getCurrentOfferte;
    },
    genData() {
      return this.$store.getters.getGenData;
    },
    pages() {
      return this.$store.getters.getPages;
    },
    klantenKaart() {
      return this.pages.KlantenKaart;
    },
    voorgangers() {
      return this.genData.voorgangers;
    },
    editor() {
      return this.$store.getters.getEditor;
    },
    admin() {
      return this.$store.getters.getAdmin;
    },
  },

  methods: {
    edit(value, subCol, field, index = null) {
      if (this.editor) {
        this.valueToEdit = value;
        this.subColToEdit = subCol;
        this.fieldToEdit = field;
        this.indexToEdit = index;
        this.showEdit = true;
      }
    },
    updateImg(event) {
      this.uploadValue = 0;
      this.imgUrl = null;
      this.imageData = event.target.files[0];
      this.onUpload();
    },

    onUpload() {
      this.imgUrl = null;
      const storageRef = firebase
        .storage()
        .ref(
          `Pages/${this.subColToEdit}/${this.fieldToEdit}/${this.imageData.name}`
        )
        .put(this.imageData);
      storageRef.on(
        `state_changed`,
        (snapshot) => {
          this.uploadValue =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          console.log(error.message);
        },
        () => {
          this.uploadValue = 100;
          storageRef.snapshot.ref.getDownloadURL().then((url) => {
            this.imgUrl = url;
            this.editedValue = this.imgUrl;
          });
        }
      );
    },
    async confirm(res) {
      if (res === "Yes") {
        let i = "";
        let field = "";
        if (this.indexToEdit === null) {
          i = null;
        } else {
          i = this.indexToEdit;
        }
        if (
          this.fieldToEdit.toLowerCase().includes("img") ||
          this.fieldToEdit.toLowerCase().includes("logo")
        ) {
          field = this.imageData.name;
        } else {
          field = this.editedValue;
        }

        const ad = {
          ...this.admin,
        };
        const payload = {
          index: i,
          collection: this.subColToEdit,
          field: this.fieldToEdit,
          value: this.editedValue,
          newValue: field,
          admin: ad,
          oldVal: this.valueToEdit,
        };
        this.showEdit = false;
        await this.$store.dispatch("editRecord", payload);

        this.valueToEdit = "";
        this.editedValue = "";
        this.subColToEdit = "";
        this.fieldToEdit = "";
        this.indexToEdit = null;
        this.showEdit = false;
        this.imgUrl = null;
        this.uploadValue = "";
        this.imageData = "";
      } else {
        this.valueToEdit = "";
        this.editedValue = "";
        this.subColToEdit = "";
        this.fieldToEdit = "";
        this.indexToEdit = null;
        this.showEdit = false;
        this.imgUrl = null;
        this.uploadValue = "";
        this.imageData = "";
      }
    },
  },
};
</script>

<style scoped>
.active {
  border: 1px dashed green;
  border-radius: 12px;
  transition: 0.3s all ease-in-out;
  padding: 0.5rem;
}

.active:hover {
  border: 1px solid green;
  cursor: pointer;
  border-radius: 12px;
  padding: 0.5rem;
}

input {
  display: block;
  width: 95%;
  border: 1px solid #cccccc;
  padding: 1rem 1rem 1rem;
  border-radius: 0.4rem;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#fafafa),
    to(white)
  );
  background-image: linear-gradient(to bottom, #fafafa 0%, white 100%);
  -webkit-transition: border-color 0.15s ease-in-out 0s;
  transition: border-color 0.15s ease-in-out 0s;
}

@media (max-width: 748px) {
  .image {
    margin: auto;
    width: 100%;
    -webkit-transform: translateY(-1rem) rotate(-4deg);
    transform: translateY(-1rem) rotate(-4deg);
  }

  .fullContainer {
    max-width: 80rem;
    margin: auto;
    color: white;
    /* border-radius: 12px; */
    text-align: center;
    background-color: #00a7e3;
  }

  .container {
    margin: auto;
    max-width: 80rem;
    display: inline-block;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 1rem;
  }

  .fullContainer .image {
    display: block;
    margin: auto;
  }

  .fullContainer h2 {
    text-transform: uppercase;
  }

  h2,
  .h2 {
    margin: 0;
    font-family: "Karla", sans-serif;
    font-weight: 700;
  }

  h2 {
    display: block;
    font-size: 1.5em;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
  }

  p {
    margin: 0;
    margin-bottom: 2rem;
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }

  .fullContainer .image img {
    box-shadow: 0 3rem 8rem rgb(0 0 0 / 20%);
  }
  img {
    display: block;
    width: 100%;
    margin: auto;
    height: auto;
    /* border-radius: 2%; */
    border: 0;
  }

  .fullContainer .image .caption {
    text-align: center;
  }
}

@media (min-width: 749px) {
  .textBlock {
    margin-top: -15rem;
  }
  .image {
    margin-bottom: -10rem;
    -webkit-transform: translateY(-15rem) rotate(-3deg);
    transform: translateY(-15rem) rotate(-3deg);
    transition: ease-in-out 0.3s all;
  }

  .fullContainer {
    /* width: 100%; */
    max-width: 80rem;
    margin: auto;
    margin-top: 20rem;
    color: white;
    text-align: center;
    background-color: #00a7e3;
  }

  .fullContainer .image .caption {
    padding-top: 1rem;
  }

  .container {
    /* position: relative; */
    margin: auto;
    max-width: 80rem;
    display: inline-block;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 1rem;
  }

  .fullContainer .image {
    display: block;
    margin: auto;
  }

  .fullContainer h2 {
    text-transform: uppercase;
  }

  h2,
  .h2 {
    margin: 0;
    font-family: "Karla", sans-serif;
    font-weight: 700;
  }

  h2 {
    display: block;
    font-size: 1.5em;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
  }

  p {
    margin: 0;
    margin-bottom: 2rem;
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }

  .fullContainer .image img {
    box-shadow: 0 3rem 8rem rgb(0 0 0 / 20%);
  }
  img {
    display: block;
    width: 100%;
    margin: auto;
    height: auto;
    border-radius: 2%;
  }
  img {
    border: 0;
  }

  .fullContainer .image .caption {
    padding-top: 1rem;
    text-align: center;
  }
}

.editBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 10;
}

.editDialogView {
  position: absolute;
  margin: auto;
  width: 30%;
  padding: 1rem;
  border-radius: 12px;
  background-color: #fff;
  left: 40%;
  right: auto;
  top: 30%;
}

.editDialogView dialog {
  max-width: 40rem;
  max-height: 60rem;
  /* z-index: 100; */
  border: none;
  overflow-y: auto;
  background-color: transparent;
}

@media (min-width: 768px) {
  dialog {
    left: calc(50% - 30rem);
    width: 60rem;
  }
}
</style>
