<template>
  <div class="rootDiv">
    <div class="offersBar" @click="toggleShowOffertes">
      <a :class="classSelector"
        >Bekijk jouw offertes
        <div class="noticon">
          {{ offertes.length }}
        </div>
      </a>
    </div>
    <div v-if="isActive" class="container">
      <div class="offers">
        <offerte-list
          v-for="(offerte, index) in offertes"
          :key="offerte.offerteOfferteNummer"
          :offerte="offerte"
          :index="index"
          @offerte-opened="toggleOpened"
        >
        </offerte-list>
      </div>
    </div>
  </div>
</template>

<script>
import OfferteList from "./OfferteList.vue";

export default {
  components: { OfferteList },
  props: ["amount"],
  data() {
    return {
      isActive: false,
      classSelector: "moreOffersButton",
    };
  },
  methods: {
    toggleShowOffertes() {
      this.isActive = !this.isActive;
      if (this.isActive) {
        this.classSelector = "moreOffersButton isActive";
      } else {
        this.classSelector = "moreOffersButton";
      }
    },
    toggleOpened(offerte) {
      this.isActive = false;
      this.classSelector = "moreOffersButton";
      this.$emit("offerte-opened", offerte);
    },
  },
  computed: {
    offertes() {
      return this.$store.getters.getOffertes;
    },
  },
};
</script>

<style scoped>
@media (min-width: 749px) and (max-width: 960px) {
  .container {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}

.container {
  background-color: #f4f9fa;
  position: relative;
  width: 100%;
}

.offersBar {
  background: #f4f9fa;
  color: #00a7e3;;
  font-family: "Karla";
}

.offersBar .moreOffersButton {
  border: none;
  outline: none;
  text-decoration: none;
  cursor: pointer;
  touch-action: manipulation;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  width: 100%;
  background: #f4f9fa;
  text-transform: none;
  padding: 0.4rem 0;
  font-size: 1.2rem;
  line-height: 1;
  font-weight: 700;
  -webkit-transition: all 0.15s ease-in-out 0s;
  transition: all 0.15s ease-in-out 0s;
}

.offersBar .moreOffersButton .noticon {
  display: -webkit-inline-box;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  text-align: center;
  -webkit-box-pack: center;
  justify-content: center;
  background: #60ab2d;
  color: white;
  font-size: 1.2rem;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  margin-left: 0.5rem;
}
.offersBar .moreOffersButton.isActive {
  background: #44bace;
  color: white;
}

.offersBar .moreOffersButton.is-active ~ .container .offers {
  max-height: 100rem !important;
}
.offersBar .offers {
  background-color: #f4f9fa;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  flex-wrap: wrap;
  font-size: 0.9em;
  overflow: hidden;
  max-height: 0;
  -webkit-transition: all 0.15s ease-in-out 0s;
  transition: all 0.15s ease-in-out 0s;
}

.offers {
  max-width: 70rem;
  margin: auto;
}
.offersBar .offers .offer {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: start;
  align-items: flex-start;
  padding: 2rem 0;
  border-bottom: 1px solid #e6e6e6;
}
</style>
