<template>
  <div class="rootDiv">
    <div class="block small">
      <div v-if="loggedIn">
        <admin-dashboard @log-out="loggedIn = false"></admin-dashboard>
      </div>
      <div v-if="!loggedIn">
        <base-card>
          <h1>Admin Login</h1>
          <div class="info">
            Je bevindt je nu op een Admin Pagina. Deze is uitsluitend bedoelt
            voor medewerkers van hallostroom. <br />
            Klik <a @click="toLogin">hier</a> om terug te gaan.
          </div>
          <form @submit.prevent="login">
            <div class="group">
              <label for="userCredentials">Medewerker Email: </label><br />
              <input
                type="text"
                id="userCredentials"
                v-model.trim="email"
                @input="cancelStateUserCredentials"
              />
            </div>
            <div class="group">
              <label for="password">Medewerker Wachtwoord:</label><br />
              <input
                type="password"
                id="postcode"
                v-model.trim="password"
                @input="cancelPostCodeError"
              />
              <p class="errorMessage" v-if="showError">
                {{ errorMsg }}
              </p>
            </div>
            <base-button>Login</base-button>
          </form>
        </base-card>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/auth";
import AdminDashboard from "../admin/AdminDashboard.vue";

export default {
  components: { AdminDashboard },
  data() {
    return {
      email: "",
      password: "",
      userCredentialsValid: true,
      loggedIn: false,
      showError: false,
      errorMsg: "",
    };
  },
  methods: {
    async login() {
      await firebase
        .auth()
        .signInWithEmailAndPassword(this.email, this.password)
        .catch((error) => {
          this.loggedIn = false;
          this.showError = true;
          this.errorMsg = error.message;
        })
        .then((cred) => {
          if (cred) {
            this.$store.dispatch("FetchPages");
            this.$store.dispatch("fetchUser", cred.uid);
            this.loggedIn = true;
            this.showError = false;
          }
        });
      this.email = "";
      this.password = "";
    },
    toLogin() {
      window.location.reload();
    },
  },
  created() {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.loggedIn = true;
        this.$store.dispatch("FetchPages");
        this.showError = false;
        this.$store.dispatch("fetchUser", user.uid);
      } else {
        console.log("No user is logged in!");
      }
    });
  },
};
</script>

<style scoped>
h1 {
  color: #60ab2d;
}

.info {
  color: grey;
  font-style: italic;
  font-size: 1rem;
  margin-bottom: 2rem;
}

.container {
  padding-top: 2rem;
  background-color: #00a7e3;
  padding-bottom: 26rem;
}

.group {
  position: relative;
  margin-bottom: 1rem;
}

.group label {
  display: block;
  text-align: left;
  /* margin-bottom: 0.5rem; */
}
.errorMessage {
  font-size: smaller;
  text-align: left;
  font-style: italic;
  color: red;
  margin-left: 2%;
}
input {
  display: block;
  width: 95%;
  border: 1px solid #cccccc;
  padding: 1rem 1rem 1rem;
  border-radius: 0.4rem;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#fafafa),
    to(white)
  );
  background-image: linear-gradient(to bottom, #fafafa 0%, white 100%);
  -webkit-transition: border-color 0.15s ease-in-out 0s;
  transition: border-color 0.15s ease-in-out 0s;
}

a:not(.button) {
  text-align: left;
  color: blue;
  text-decoration: underline;
  outline: none;
  cursor: pointer;
}

@media (min-width: 1101px) {
  .block.small {
    padding: 4rem 7rem 10rem;
    width: 100%;
  }
  .block {
    margin: 5vh auto;
  }
}
</style>
