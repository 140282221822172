<template>
  <div class="card">
    <teleport to="body">
      <info-berekening v-if="!!show" @close="tryClose"></info-berekening>
      <!-- <berekening-dialog
        v-if="!!show"
        :open="show"
        :showButtons="false"
        @close="tryClose"
      ></berekening-dialog> -->
    </teleport>
    <div class="dialog" v-if="!!showPDF">
      <teleport to="body">
        <div class="backdrop">
          <div class="round" @click="tryClose">
            <font-awesome-icon class="iconRound" icon="times-circle" />
          </div>
          <div class="round2" @click="download">
            <font-awesome-icon class="iconRound" icon="download" />
          </div>
          <transition>
            <div class="dialogView">
              <dialog open>
                <vue-pdf-embed :source="salderenUrl" />
              </dialog>
            </div>
          </transition>
        </div>
      </teleport>
    </div>

    <div class="benefits">
      <div class="image">
        <img :src="pakketImage" alt="" />
      </div>
      <h1>Dit leveren je nieuwe zonnepanelen voor je op</h1>
      <h4 v-if="showEstimate">
        We hebben momenteel geen exacte data van je. We zijn daarom uitgegaan
        van een schatting van een jaarverbruik van 3500 kWh.
      </h4>
      <div class="energyFlashLine">
        <img :src="energyFlashUrl" alt="" />
      </div>
      <div class="columnR">
        <div class="wrapperTime">
          <div
            class="timeSelector"
            :class="{ activeTime: selectedTime === '1M' }"
            @click="changeSelectedTime('1M')"
          >
            1 Maand
          </div>
          <div
            class="timeSelector"
            :class="{ activeTime: selectedTime === '1Y' }"
            @click="changeSelectedTime('1Y')"
          >
            1 Jaar
          </div>
          <div
            class="timeSelector"
            :class="{ activeTime: selectedTime === '10Y' }"
            @click="changeSelectedTime('10Y')"
          >
            10 Jaar
          </div>
          <div
            class="timeSelector"
            :class="{ activeTime: selectedTime === '15Y' }"
            @click="changeSelectedTime('15Y')"
          >
            15 Jaar
          </div>
          <div
            class="timeSelector"
            :class="{ activeTime: selectedTime === '30Y' }"
            @click="changeSelectedTime('30Y')"
          >
            30 Jaar
          </div>
        </div>
      </div>
      <div
        v-if="besparing && !koop"
        class="benefit js-revealMe revealZoom"
        data-counter-name="js-counter-1"
      >
        <font-awesome-icon
          id="info"
          class="icon-info"
          icon="info-circle"
          @click="openDialog(0)"
        />

        <div class="h2">
          €
          <div class="priceCounter">{{ startPrice }}</div>
          ,-
        </div>
        <strong>Bespaar je na {{ selectedTimeLabel }}. </strong>
        <br />
        <i>De huurprijs voor de zonnepanelen is hier al mee verrekend.</i>
        <br />
        <i>
          Je houdt dus €{{ startPrice }},- in {{ selectedTimeLabel }} over.</i
        >
      </div>
      <div
        v-if="!besparing"
        class="benefit js-revealMe revealZoom"
        data-counter-name="js-counter-1"
      >
        <!-- <font-awesome-icon
          id="info"
          class="icon-info"
          icon="info-circle"
          @click="openDialog(0)"
        /> -->

        <div class="h2">
          <div class="priceCounter">{{ startPrice }}</div>
          %
        </div>
        <strong>van je energieverbruik wek je zelf op</strong>
      </div>
      <div
        class="benefit js-revealMe revealZoom"
        data-counter-name="js-counter-2"
      >
        <!-- <font-awesome-icon
          id="info"
          class="icon-info"
          icon="info-circle"
          @click="openDialog(1)"
        /> -->

        <div class="h2">
          <div class="kwhCounter">{{ startKwh }}</div>
          kWh
        </div>
        <strong>opwekking na {{ selectedTimeLabel }}</strong>
      </div>
      <div
        v-if="koop"
        class="benefit js-revealMe revealZoom"
        data-counter-name="js-counter-1"
      >
        <!-- <font-awesome-icon
          id="info"
          class="icon-info"
          icon="info-circle"
          @click="openDialog(0)"
        /> -->

        <div class="h2">
          in
          <div class="priceCounter">{{ terugVerdienTijd }}</div>
          jaar
        </div>
        <strong>je zonnepanelen terugverdiend</strong>
      </div>
      <small v-if="koop">
        Hiermee wek je jaarlijks een groot deel van je energiebehoefte volledig
        duurzaam op.
      </small>
      <small v-else
        >Hiermee wek je jaarlijks
        {{ hoeveelheidOpwek }}
        <!-- een deel van je  -->
        energiebehoefte duurzaam op.<br />
        Wij gaan in onze berekeningen uit van de huidige salderingsregeling.
        Klik
        <a @click="openDialogLink" style="cursor: pointer"><u>hier</u></a>
        <!-- <a @click="openDialogPDF" style="cursor: pointer"><u>hier</u></a> -->
        voor meer informatie.
      </small>
      <div class="spacingSmall"></div>
      <!-- <a href="#uspBlock" class="button small blue">
        <span>Bekijk meer voordelen</span>
      </a> -->
    </div>
  </div>
</template>

<script>
// import BerekeningDialog from "./BerekeningDialog.vue";
import VuePdfEmbed from "vue-pdf-embed";
import InfoBerekening from "./InfoBerekening.vue";
export default {
  components: {
    VuePdfEmbed,
    // BerekeningDialog,
    InfoBerekening,
  },
  props: ["startCount", "open", "showButtons"],
  data() {
    return {
      show: null,
      showPDF: null,
      startBesparing: true,
      startOpwekking: true,
      type: null,
      startPrice: 0,
      startKwh: 0,
      selectedTime: "1Y",
      selectedTargetPrice: this.targetPrice,
      selectedTargetKwh: this.targetKwh,
      energyFlashUrl: require("../../../../assets/MainAssets/icon-energy-flash.svg"),
      salderenUrl:
        "https://firebasestorage.googleapis.com/v0/b/online-offerte-tool.appspot.com/o/InfoSheetSalderen%2Fsalderen.pdf?alt=media&token=7acf79b6-eb6a-4c44-86cc-b52ec0ed769c",
    };
  },
  computed: {
    offerte() {
      return this.$store.getters.getCurrentOfferte;
    },
    tarief() {
      return this.offerte.tarief;
    },
    tariefFormat() {
      return this.tarief.toString().replace(".", ",");
    },
    huurprijs() {
      return this.offerte.offerteGekozenHuurPrijs;
    },
    koop() {
      return this.offerte.offerteKoop;
    },
    terugVerdienTijd() {
      return Math.round(
        this.offerte.offertePrijsKoopInclBTW /
          (this.offerte.offerteJaarproductie * this.offerte.tarief)
      );
    },
    maandelijkseBesparing() {
      return this.offerte.offerteBesparing;
    },
    targetPrice() {
      return this.maandelijkseBesparing * 12;
    },
    besparing() {
      if (this.targetPrice > 0) {
        return true;
      } else {
        return false;
      }
    },
    percentage() {
      const perc = this.offerte.percentageEigenOpwek;
      if (perc === 0) {
        return Math.ceil((3500 / this.offerte.offerteJaarproductie) * 100);
      } else {
        return this.offerte.percentageEigenOpwek;
      }
    },
    showEstimate() {
      if (this.offerte.percentageEigenOpwek === 0 && this.besparing === false) {
        return true;
      } else {
        return false;
      }
    },
    targetKwh() {
      return this.offerte.offerteJaarproductie;
    },
    targetKwhFormat() {
      function formatNumber(num) {
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
      }
      return formatNumber(this.targetKwh);
    },
    targetKwhMonth() {
      return Math.ceil(this.targetKwh / 12);
    },
    targetKwhTarief() {
      return Math.ceil(this.targetKwhMonth * this.tarief);
    },
    pakketImage() {
      return this.offerte.productPakketImage;
    },
    selectedTimeLabel() {
      if (this.selectedTime === "1M") {
        return "1 maand";
      } else if (this.selectedTime === "1Y") {
        return "1 jaar";
      } else if (this.selectedTime === "10Y") {
        return "10 jaar";
      } else if (this.selectedTime === "15Y") {
        return "15 jaar";
      } else {
        return "30 jaar";
      }
    },
    hoeveelheidOpwek() {
      if (this.besparing) {
        return "je volledige ";
      } else if (this.percentage > 50) {
        return "een groot deel van je ";
      } else {
        return "een deel van je ";
      }
    },
  },
  watch: {
    startCount() {
      if (this.startCount === true) {
        this.count();
        this.count2();
      }
    },
  },
  methods: {
    openDialogLink() {
      window.open("https://hallostroom.nl/zonnepanelen/salderen/");
    },
    openDialogPDF(type = "") {
      this.type = type;
      this.showPDF = true;
      this.$emit("showBar", false);
    },
    openDialog(type) {
      this.type = type;
      this.show = true;
    },
    tryClose() {
      this.type = null;
      this.show = false;
      this.showPDF = false;
      this.$emit("showBar", true);
    },

    download() {
      fetch(this.salderenUrl, {
        method: "GET",
      })
        .then((response) => response.blob())
        .then((blob) => {
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement("a");
          a.href = url;
          a.download = `salderen.pdf`;
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();
          a.remove(); //afterwards we remove the element again
        });
      this.show = false;
    },

    count(targetPrice = this.targetPrice) {
      if (this.besparing === true) {
        function formatNumber(num) {
          return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
        } // Zet een punt bij duizendtallen
        if (this.startBesparing === true) {
          this.startPrice = 0;
          const speed = 200; // Hoe hoger, hoe langzamer het tellen
          const updateCountPrice = () => {
            const count = this.startPrice;
            const inc = targetPrice / speed;
            if (count < targetPrice) {
              this.startPrice = Math.ceil(count + inc);
              setTimeout(updateCountPrice, 1);
            } else {
              this.startPrice = targetPrice;
              this.startPrice = formatNumber(this.startPrice);
            }
          };
          updateCountPrice();
        }
        this.startBesparing = false;
      } else {
        targetPrice = this.percentage;
        if (this.startBesparing === true) {
          this.startPrice = 0;
          const speed = 200; // Hoe hoger, hoe langzamer het tellen
          const updateCountPrice = () => {
            const count = this.startPrice;
            const inc = targetPrice / speed;
            if (count < targetPrice) {
              this.startPrice = Math.ceil(count + inc);
              setTimeout(updateCountPrice, 1);
            } else {
              this.startPrice = targetPrice;
            }
          };
          updateCountPrice();
        }
        this.startBesparing = false;
      }
    },
    count2(targetKwh = this.targetKwh) {
      function formatNumber(num) {
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
      }
      if (this.startOpwekking === true) {
        this.startKwh = 0;
        const speed = 200;
        const updateCountKwh = () => {
          const count = this.startKwh;
          const inc = targetKwh / speed;
          if (count < targetKwh) {
            this.startKwh = Math.round(count + inc);
            setTimeout(updateCountKwh, 1);
          } else {
            this.startKwh = targetKwh;
            this.startKwh = formatNumber(this.startKwh);
          }
        };
        updateCountKwh();
      }
      this.startOpwekking = false;
    },
    changeSelectedTime(time) {
      if (this.selectedTime === time) {
        return;
      }
      this.startBesparing = true;
      this.startOpwekking = true;
      this.selectedTime = time;
      this.startPrice = 0;
      this.startKwh = 0;
      if (this.selectedTime === "1M") {
        this.count(Math.round(this.targetPrice / 12));
        this.count2(Math.round(this.targetKwh / 12));
      } else if (this.selectedTime === "1Y") {
        this.count(Math.round(this.targetPrice));
        this.count2(Math.round(this.targetKwh));
      } else if (this.selectedTime === "10Y") {
        this.count(Math.round(this.targetPrice * 10));
        this.count2(Math.round(this.targetKwh * 10));
      } else if (this.selectedTime === "15Y") {
        this.count(Math.round(this.targetPrice * 15));
        this.count2(Math.round(this.targetKwh * 15));
      } else {
        this.count(Math.round(this.targetPrice * 30));
        this.count2(Math.round(this.targetKwh * 30));
      }
    },
  },
};
</script>

<style scoped>
.container {
  display: grid;
  grid-template-columns: auto;
  align-items: center;
  justify-content: center;
}
.card {
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  padding: 1rem;
  margin-bottom: 1.5rem;
  margin-top: -1rem;
  margin-right: auto;
  margin-left: auto;
  background-color: #fff;
}
.round {
  position: absolute;
  cursor: pointer;
  top: 2%;
  right: 2%;
  background-color: #00a7e3;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  transition: all 0.2s ease-in-out;
}

.round:hover {
  box-shadow: rgba(255, 255, 255, 1) 0px 0px 0px 3px;
}

.round2 {
  position: absolute;
  cursor: pointer;
  top: 10%;
  right: 2%;
  background-color: #00a7e3;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  transition: all 0.2s ease-in-out;
}
.round2:hover {
  box-shadow: rgba(255, 255, 255, 1) 0px 0px 0px 3px;
}

.iconRound {
  color: white;
  margin-left: 0.75rem;
  margin-top: 0.75rem;
  font-size: 1.5rem;
}

h1 {
  display: block;
  font-size: 2rem;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

.icon-info {
  color: #335fae;
  cursor: pointer;
  float: right;
  transition: color 0.2s ease-in-out;
}
.icon-info:hover {
  background: transparent;
  color: #83c63c;
}

.priceCounter,
.kwhCounter {
  display: inline-block;
}

.benefits .benefit .h2 {
  color: #f48811;
  margin-bottom: 0.5rem;
}

h2,
.h2 {
  margin: 0;
  font-family: "Karla", sans-serif;
  font-weight: 700;
  font-size: 3rem;
}

@media (min-width: 749px) {
  h2,
  .h2 {
    margin-bottom: 2rem;
    font-size: 3.5rem;
    line-height: 1.2;
  }
  .wrapperTime {
    margin-top: 2rem;
    width: 100%;
    margin-bottom: 2rem;
    color: #83c63c;
    font-weight: 700;
    font-size: 0.85rem;
  }

  .timeSelector {
    display: inline-block;
    width: 18%;
    cursor: pointer;
    color: #83c63c;
    font-weight: 900;
    width: 18%;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border: 2px solid transparent;
    background-color: white;
    border-radius: 10%;
    transition: border 0.4s ease-in-out;
  }
  .energyFlashLine img {
    width: 70%;
  }
}
.timeSelector.activeTime {
  color: #83c63c;
  font-weight: 900;
  width: 18%;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border: 2px solid #83c63c;
  background-color: white;
  border-radius: 12px;
}
u {
  text-decoration: underline;
}

a:not(.button) {
  color: inherit;
  text-decoration: none;
  outline: none;
}

img {
  display: flex;
  width: 100%;
  margin: auto;
  height: auto;
}

a,
button,
.button {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
a {
  background-color: transparent;
}

@media (max-width: 748px) {
  .wrapperTime {
    width: 100%;
    margin-bottom: 1rem;
    color: #83c63c;
    font-weight: 700;
    font-size: 0.85rem;
  }
  .timeSelector {
    display: inline-block !important;
    width: 90% !important;
    font-size: 93% !important;
    text-align: center !important;
    padding-top: 0.2rem !important;
    padding-bottom: 0.2rem !important;
    padding-left: 0.6rem !important;
    padding-right: 0.6rem !important;
  }
}

.button.blue {
  color: white;
  background-color: #00a7e3;
  transition: all 0.2s ease-in-out;
}

.button.blue:hover {
  color: white;
  background-color: #44bace;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#43bace),
    to(rgba(68, 186, 206, 0))
  );
  background-image: linear-gradient(
    to bottom,
    #43bace 0%,
    rgba(68, 186, 206, 0) 100%
  );
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.benefits .benefit {
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: 2rem;
  margin-bottom: 2rem;
}

.button.small {
  margin-top: 1rem;
  padding: 1rem 1rem 1rem;
  text-transform: none;
  font-size: 0.9rem;
  line-height: 1;
}
.button {
  display: -webkit-inline-box;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  vertical-align: bottom;
  position: relative;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  cursor: pointer;
  touch-action: manipulation;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-radius: 0.5rem;
  font-family: "Karla", sans-serif;
  width: auto;
  white-space: normal;
  text-align: center !important;
  font-weight: 700;
  -webkit-transition: all 0.15s ease-in-out 0s;
  transition: all 0.15s ease-in-out 0s;
}

@media (min-width: 749px) {
  .benefits .image {
    width: 60%;
    margin: -6rem auto 1rem;
  }
}

/* CSS FOR DIALOG */

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 10;
}

dialog {
  max-width: 40rem;
  max-height: 60rem;
  margin: 0;
  /* z-index: 100; */
  border: none;
  overflow-y: auto;
  background-color: transparent;
}

header {
  color: white;
  width: 100%;
  padding: 1rem;
}

header h3 {
  margin: 0;
}

section {
  padding: 1rem;
}

menu {
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
  margin: 0;
}

.dialog-enter-from,
.dialog-leave-to {
  opacity: 0;
  transform: scale(0.8);
}

.dialog-enter-active {
  transition: all 0.3s ease-out;
}

.dialog-leave-active {
  transition: all 0.3 ease-in;
}

.dialog-enter-to,
.dialog-leave-from {
  opacity: 1;
  transform: scale(1);
}

@media (min-width: 768px) {
  dialog {
    left: calc(50% - 20rem);
    width: 40rem;
  }
}

h3,
.h3 {
  margin: 0;
  font-family: "Karla", sans-serif;
  font-weight: 700;
  text-transform: none;
  color: #00a7e3;
}
*,
*:before,
*:after {
  box-sizing: border-box;
}
h3 {
  display: block;
  font-size: 1rem;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

.math {
  display: inline;
  font-weight: 700;
}

.mathSymbol {
  display: inline;
  font-weight: 700;
  color: #00a7e3;
  padding: 0 0.5rem;
}

h3 strong,
.math {
  color: #f48811;
}
/* END CSS FOR DIALOG */
</style>
