<template>
  <div class="rootDiv">
    <teleport to="body">
      <base-dialog
        @close="tryClose"
        @download="download"
        :open="show"
        :imgDialog="true"
        :showButtons="true"
      >
        <inner-image-zoom :hideHint="true" :src="img" />
        <!-- <img class="dialogImg" :src="img" @error="changeUrl" /> -->
      </base-dialog>
    </teleport>
    <!-- <inner-image-zoom src="/path/to/image.jpg" zoomSrc="/path/to/zoom-image.jpg" /> -->
    <div class="sateliteImagePakket" :class="{ fullWidth: !showDiv }">
      <img @click="openImage" :src="img" @error="changeUrl" />

      <font-awesome-icon
        @click="openImage"
        id="search"
        class="icon-zoom"
        icon="search-plus"
      />
    </div>
  </div>
</template>

<script>
import "vue-inner-image-zoom/lib/vue-inner-image-zoom.css";
import InnerImageZoom from "vue-inner-image-zoom";

export default {
  data() {
    return {
      hideHint: false,
      show: false,
      showDialog: false,
      showPara: false,
      img: "",
    };
  },
  components: {
    "inner-image-zoom": InnerImageZoom,
  },

  computed: {
    offerte() {
      return this.$store.getters.getCurrentOfferte;
    },
    versionAB() {
      return this.offerte.AB;
    },
    showDiv() {
      // if (this.versionAB === 0) {
      return false;
      // } else {
      // return true;
      // }
    },
    pages() {
      return this.$store.getters.getPages;
    },
    imageUrl() {
      return this.offerte.offerteQuoteImageLegPlan;
    },
    backUpImg() {
      return this.pages.LegPlan.backupImg;
    },
    mobile() {
      if (window.innerWidth < 749) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    changeUrl() {
      this.img = this.backUpImg;
      const d = new Date().toLocaleString("nl", "DD-MMMM-YYYY HH:MM");
      const payload = {
        subject: "LegplanUpdate",
        requestDate: d,
        quoteID: this.offerte.ID,
      };
      this.$store.dispatch("saveRequest", payload);
    },
    openImage() {
      if (!this.mobile) {
        this.show = true;
        this.$emit("showBar", false);
      } else {
        window.open(this.imageUrl);
      }
    },
    tryClose() {
      this.show = false;
      this.$emit("showBar", true);
    },
    togglePara() {
      this.showPara = !this.showPara;
    },
    download() {
      fetch(this.img, {
        method: "GET",
      })
        .then((response) => response.blob())
        .then((blob) => {
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement("a");
          a.href = url;
          a.download = `legplan.png`;
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();
          a.remove(); //afterwards we remove the element again
        });
      this.show = false;
    },
  },
  watch: {
    show() {
      if (!!this.show) {
        this.showDialog = true;
      } else {
        this.showDialog = false;
      }
    },
  },
  created() {
    this.img = this.imageUrl;
  },
};
</script>

<style scoped>
.rootDiv {
  padding-bottom: 2em;
}
.dialog-enter-from {
  opacity: 0;
  transform: translateY(-30px);
}
.dialog-enter-active {
  transition: all 0.3s ease-out;
}
.dialog-enter-to {
  opacity: 1;
  transform: translateY(0);
}

.dialog-leave-from {
  opacity: 1;
  transform: translateY(0);
}
.dialog-leave-active {
  transition: all 0.3s ease-in;
}
.dialog-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

.icon-zoom {
  cursor: pointer;
}

.sateliteImagePakket {
  display: block;
  position: relative;
  margin: auto;
  width: 89%;
  padding-bottom: 89%;
  background: transparent;
  transition: all 0.2s ease-in-out;
}

.sateliteImagePakket:hover {
  display: block;
  position: relative;
  /* margin-left: 2rem; */
  width: 89%;
  padding-bottom: 89%;
}

.sateliteImagePakket img {
  position: absolute;
  object-fit: cover;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
img {
  width: 100%;
  height: auto;
  display: block;
  border: 0;
  border-radius: 15px;
}

/* CSS FOR DIALOG */

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 10;
}

dialog {
  position: fixed;
  top: 20vh;
  left: 10%;
  width: 100%;
  z-index: 100;
  border-radius: 2px;
  border: none;
  /* box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26); */
  margin: 0;
  overflow: hidden;
  background-color: transparent;
}

.sateliteImagePakket #search {
  position: absolute;
  right: 1.2rem;
  bottom: 1.2rem;
  width: 1rem;
  color: white;
}

.fullWidth {
  width: 100% !important;
  padding-bottom: 100% !important;
  border-radius: 0px !important;
  margin-top: 2rem;
}

.dialog-enter-from,
.dialog-leave-to {
  opacity: 0;
  transform: scale(0.8);
}

.dialog-enter-active {
  transition: all 0.3s ease-out;
}

.dialog-leave-active {
  transition: all 0.3 ease-in;
}

.dialog-enter-to,
.dialog-leave-from {
  opacity: 1;
  transform: scale(1);
}

@media (min-width: 768px) {
  dialog {
    left: calc(50% - 30rem);
    width: 60rem;
  }
}

/* END CSS FOR DIALOG */
</style>
