<template>
  <div class="rootDiv">
    <!-- <scroll-progress></scroll-progress> -->
    <header>
      <div v-if="expired" class="expired">
        <expired-bar></expired-bar>
      </div>
      <offers-bar v-if="toggleOffertes" :amount="offertes.length"></offers-bar>
      <main-bar></main-bar>
      <component
        v-if="!mobile || showBanner"
        :is="selectedProgressBar"
        @change-step="changeStep"
        :step="step"
        :versie="versie"
      ></component>
      <!-- <progress-bar
        @change-step="changeStep"
        :step="step"
        :versie="versie"
      ></progress-bar> -->
    </header>
    <router-view></router-view>
  </div>
</template>

<script>
import OffersBar from "./OffersBar.vue";
import MainBar from "./MainBar.vue";
import ProgressBar from "./ProgressBar.vue";
import ExpiredBar from "./ExpiredBar.vue";
// import { randomCandidate } from "vue-a2b";

export default {
  components: {
    OffersBar,
    ProgressBar,
    MainBar,
    ExpiredBar,
  },
  data() {
    return {
      toggleOffertes: null,
      step: "",
      showBar: true,
      mobile: false,
    };
  },

  computed: {
    offerte() {
      return this.$store.getters.getCurrentOfferte;
    },
    offertes() {
      return this.$store.getters.getOffertes; // Hier worden alle offertes opgehaald. Nodig voor de offertebar bovenin het scherm.
    },
    expired() {
      const d = new Date().valueOf();
      // console.log(this.offerte);
      // const offerte = this.$store.getters.getCurrentOfferte;

      if (
        this.offerte.offerteAkkoord &&
        this.offerte.quoteStage === "Closed Won"
      ) {
        return false;
      } else {
        if (this.offerte.offerteGeldigheidsDatum < d) {
          return true;
        } else {
          return false;
        }
      }
    },
    versionAB() {
      // const offerte = this.$store.getters.getCurrentOfferte;
      return this.offerte.AB;
    },
    versie() {
      // if (this.versionAB === 0) {
      return "";
      // } else {
      // return "a";
      // }
    },
    showBanner() {
      // if (this.versionAB === 0) {
      return true;
      // } else {
      // return false;
      // }
    },
    selectedProgressBar() {
      // if (this.versionAB === 0) {
      return "ProgressBar";
      // } else {
      // return "ProgressBarA";
      // }
    },
    selectedStap1() {
      // if (this.versionAB === 0) {
      return "/stap1";
      // } else {
      // return "/stap1a";
      // }
    },
    selectedStap2() {
      // if (this.versionAB === 0) {
      return "/stap2";
      // } else {
      // return "/stap2a";
      // }
    },
    selectedStap3() {
      // if (this.versionAB === 0) {
      return "/stap3";
      // } else {
      // return "/stap3a";
      // }
    },
    selectedStep() {
      if (this.step === 1) {
        // if (this.versionAB === 0) {
        return "/stap1";
        // } else {
        // return "/stap1a";
        // }
      }
      if (this.step === 2) {
        // if (this.versionAB === 0) {
        return "/stap2";
        // } else {
        // return "/stap2a";
        // }
      }
      if (this.step === 3) {
        // if (this.versionAB === 0) {
        return "/stap3";
        // } else {
        // return "/stap3a";
        // }
      } else {
        return "/";
      }
    },
  },
  watch: {
    $route() {
      this.changeStep();
    },
  },
  methods: {
    // toggleExpireBar(value) {
    //   this.showBar = value;
    // },
    mobileVisible() {
      if (window.innerWidth <= 748) {
        this.mobile = true;
        if (
          this.$route.path.includes("stap2a") ||
          this.$route.path.includes("stap3a") ||
          this.$route.path.includes("stap4a")
        ) {
          this.$router.push("/offerteDetail/stap1a");
        }
      } else {
        this.mobile = false;
      }
    },
    changeStep() {
      if (this.$route.fullPath === "/offerteDetail") {
        // Dit is het punt waar de versie bepaald wordt. Zie selectedStap1 in computed.
        this.$router.push("/offerteDetail" + this.selectedStap1);
      } else {
        const path = this.$route.fullPath;
        this.$router.push(path);
      }
      if (this.offertes.length > 1) {
        window.scrollTo(0, 189);
      } else {
        window.scrollTo(0, 142);
      }
    },
  },
  created() {
    const offerte = this.$store.getters.getCurrentOfferte;
    this.$store.dispatch("getKorting", offerte);

    this.changeStep(); // router
    if (this.offertes.length > 1) {
      this.toggleOffertes = true;
    } else {
      this.toggleOffertes = false;
    }
    if (window.innerWidth < 749) {
      this.mobile = true;
    }
    window.addEventListener("resize", this.mobileVisible);
  },
  destroyed() {
    window.addEventListener("resize", this.mobileVisible);
  },
};
</script>

<style scoped>
.expired {
  margin-bottom: 4rem;
}

@media (max-width: 748px) {
  header,
  main,
  .mobileNav {
    -webkit-transition: -webkit-transform 0.5s cubic-bezier(0.19, 1, 0.22, 1) 0s;
    transition: -webkit-transform 0.5s cubic-bezier(0.19, 1, 0.22, 1) 0s;
    transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1) 0s;
    transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1) 0s,
      -webkit-transform 0.5s cubic-bezier(0.19, 1, 0.22, 1) 0s;
  }
}

header {
  display: block;
  width: 100%;
}
</style>
