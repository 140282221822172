<template>
  <div class="rootDiv">
    <div class="dialog" v-if="!!show">
      <teleport to="body">
        <div @click="tryClose" class="backdrop">
          <transition name="dia">
            <dialog open>
              <header v-if="type === 0">
                <h3
                  v-html="
                    besparingBlockPage[1].headerForValue +
                    besparingBlockPage[1].headerAfterValue
                  "
                ></h3>
              </header>
              <header v-if="type === 1">
                <h3 v-html="besparingBlockPage[2].header"></h3>
              </header>
              <header v-if="type === 2">
                <h3 v-html="besparingBlockPage[3].header"></h3>
              </header>
              <section v-if="type === 0">
                <p
                  :class="{ active: editor }"
                  @click="
                    edit(
                      besparingBlockPage[1].dialogText,
                      'BesparingBlock',
                      'dialogText',
                      1
                    )
                  "
                >
                  {{ besparingBlockPage[1].dialogText }}
                </p>
                <img
                  :class="{ active: editor }"
                  @click="
                    edit(
                      besparingBlockPage[1].dialogImg,
                      'BesparingBlock',
                      'dialogImg',
                      1
                    )
                  "
                  :src="besparingBlockPage[1].dialogImg"
                />
              </section>
              <section v-if="type === 1">
                <div v-if="editor">
                  <p
                    :class="{ active: editor }"
                    @click="
                      edit(
                        besparingBlockPage[2].dialogText0,
                        'BesparingBlock',
                        'dialogText0',
                        2
                      )
                    "
                  >
                    {{ besparingBlockPage[2].dialogText0 }}
                  </p>
                  <p
                    :class="{ active: editor }"
                    @click="
                      edit(
                        besparingBlockPage[2].dialogText1,
                        'BesparingBlock',
                        'dialogText1',
                        2
                      )
                    "
                  >
                    {{ besparingBlockPage[2].dialogText1 }}
                  </p>
                  <p
                    :class="{ active: editor }"
                    @click="
                      edit(
                        besparingBlockPage[2].dialogText2,
                        'BesparingBlock',
                        'dialogText2',
                        2
                      )
                    "
                  >
                    {{ besparingBlockPage[2].dialogText2 }}
                  </p>
                  <p
                    :class="{ active: editor }"
                    @click="
                      edit(
                        besparingBlockPage[2].dialogText3,
                        'BesparingBlock',
                        'dialogText3',
                        2
                      )
                    "
                  >
                    {{ besparingBlockPage[2].dialogText3 }}
                  </p>
                </div>

                <p
                  v-if="!editor"
                  v-html="
                    besparingBlockPage[2].dialogText0 +
                    besparingBlockPage[2].dialogText1 +
                    besparingBlockPage[2].dialogText2 +
                    besparingBlockPage[2].dialogText3
                  "
                ></p>
                <br /><br />
                <p
                  v-if="editor"
                  :class="{ active: editor }"
                  @click="
                    edit(
                      besparingBlockPage[2].dialogText4,
                      'BesparingBlock',
                      'dialogText4',
                      2
                    )
                  "
                >
                  {{ besparingBlockPage[2].dialogText4 }}
                </p>
                <p
                  v-if="!editor"
                  v-html="besparingBlockPage[2].dialogText4"
                ></p>
              </section>
              <section v-if="editor">
                <p
                  :class="{ active: editor }"
                  @click="
                    edit(
                      besparingBlockPage[3].dialogText,
                      'BesparingBlock',
                      'dialogText',
                      3
                    )
                  "
                >
                  {{ besparingBlockPage[3].dialogText }}
                </p>
              </section>
              <section
                v-if="type === 2 && !editor"
                v-html="besparingBlockPage[3].dialogText"
              ></section>
              <menu>
                <base-button @click="tryClose">Sluiten</base-button>
              </menu>
            </dialog>
          </transition>
        </div>
      </teleport>
    </div>

    <div class="uspBlock container" id="uspBlock">
      <div :class="{ active: editor }">
        <h2
          class="mainHeader"
          @click="
            edit(
              besparingBlockPage[0].MainHeader,
              'BesparingBlock',
              'MainHeader',
              0
            )
          "
          v-html="besparingBlockPage[0].MainHeader"
        ></h2>
      </div>

      <div class="usps" v-if="!flickStatus">
        <div class="usp">
          <div class="image">
            <div :class="{ active: editor }">
              <img
                @click="
                  edit(besparingBlockPage[1].img, 'BesparingBlock', 'img', 1)
                "
                :src="besparingBlockPage[1].img"
                alt=""
              />
            </div>
          </div>
          <div class="text">
            <div v-if="!editor">
              <h3
                v-html="
                  besparingBlockPage[1].headerForValue +
                  besparingBlockPage[1].headerAfterValue
                "
              ></h3>
            </div>
            <div :class="{ active: editor }" v-if="editor">
              <h3
                @click="
                  edit(
                    besparingBlockPage[1].headerForValue,
                    'BesparingBlock',
                    'headerForValue',
                    1
                  )
                "
                v-html="besparingBlockPage[1].headerForValue"
              ></h3>
            </div>
            <div v-if="editor">
              {{ loopTijd }}
            </div>
            <div :class="{ active: editor }" v-if="editor">
              <h3
                @click="
                  edit(
                    besparingBlockPage[1].headerAfterValue,
                    'BesparingBlock',
                    'headerAfterValue',
                    1
                  )
                "
                v-html="besparingBlockPage[1].headerAfterValue"
              ></h3>
            </div>
            <p
              v-if="!editor && !!besparing"
              v-html="
                besparingBlockPage[1].paragraphForValue +
                besparing +
                besparingBlockPage[1].paragraphAfterValue
              "
            ></p>
            <div v-if="editor">
              <strong style="color: red">
                Deze tekst verschijnt als er besparing is.
              </strong>
              <div :class="{ active: editor }">
                <p
                  @click="
                    edit(
                      besparingBlockPage[1].paragraphForValue,
                      'BesparingBlock',
                      'paragraphForValue',
                      1
                    )
                  "
                  v-html="besparingBlockPage[1].paragraphForValue"
                ></p>
              </div>
              {{ besparing }}
              <div :class="{ active: editor }">
                <p
                  @click="
                    edit(
                      besparingBlockPage[1].paragraphAfterValue,
                      'BesparingBlock',
                      'paragraphAfterValue',
                      1
                    )
                  "
                  v-html="besparingBlockPage[1].paragraphAfterValue"
                ></p>
              </div>
            </div>
            <!--TODO - Besparing1015jaar (7.331) nog invullen  -->
            <div
              v-if="!editor && !besparing"
              v-html="
                besparingBlockPage[1].paragraphForValue +
                offerte.offertHuurPrijs +
                besparingBlockPage[1].paragraphAfterValue
              "
            ></div>

            <div v-if="editor">
              <strong style="color: red">
                Deze tekst verschijnt als er GEEN besparing is.
              </strong>
              <div :class="{ active: editor }">
                <p
                  @click="
                    edit(
                      besparingBlockPage[1].paragraphForValue,
                      'BesparingBlock',
                      'paragraphForValue',
                      1
                    )
                  "
                  v-html="besparingBlockPage[1].paragraphForValue"
                ></p>
              </div>
              {{ offerte.offerteHuurPrijs }}
              <div :class="{ active: editor }">
                <p
                  @click="
                    edit(
                      besparingBlockPage[1].paragraphAfterValue,
                      'BesparingBlock',
                      'paragraphAfterValue',
                      1
                    )
                  "
                  v-html="besparingBlockPage[1].paragraphAfterValue"
                ></p>
              </div>
            </div>
            <a class="button medium blueGhost" @click="openDialog(0)">
              <span>Zo werkt het</span>
            </a>
          </div>
        </div>
        <div class="usp">
          <div class="image">
            <div :class="{ active: editor }">
              <img
                @click="
                  edit(besparingBlockPage[2].img, 'BesparingBlock', 'img', 2)
                "
                :src="besparingBlockPage[2].img"
                alt=""
              />
            </div>
          </div>
          <div class="text">
            <div :class="{ active: editor }">
              <h3
                @click="
                  edit(
                    besparingBlockPage[2].header,
                    'BesparingBlock',
                    'header',
                    2
                  )
                "
                v-html="besparingBlockPage[2].header"
              ></h3>
            </div>
            <div v-if="editor">
              <div :class="{ active: editor }">
                <p
                  @click="
                    edit(
                      besparingBlockPage[2].paragraphForValue,
                      'BesparingBlock',
                      'paragraphForValue',
                      2
                    )
                  "
                  v-html="besparingBlockPage[2].paragraphForValue"
                ></p>
              </div>
              <div :class="{ active: editor }">
                <p
                  @click="
                    edit(
                      besparingBlockPage[2].paragraphAfterValue,
                      'BesparingBlock',
                      'paragraphAfterValue',
                      2
                    )
                  "
                  v-html="besparingBlockPage[2].paragraphAfterValue"
                ></p>
              </div>
            </div>
            <div v-if="editor">
              <div :class="{ active: editor }">
                <p
                  @click="
                    edit(
                      besparingBlockPage[2].paragraphForValue,
                      'BesparingBlock',
                      'paragraphForValue',
                      2
                    )
                  "
                  v-html="besparingBlockPage[2].paragraphForValue"
                ></p>
              </div>
              {{ loopTijd }}
              <div :class="{ active: editor }">
                <p
                  @click="
                    edit(
                      besparingBlockPage[2].paragraphAfterValue,
                      'BesparingBlock',
                      'paragraphAfterValue',
                      2
                    )
                  "
                  v-html="besparingBlockPage[2].paragraphAfterValue"
                ></p>
              </div>
            </div>
            <p
              v-if="!editor"
              v-html="
                besparingBlockPage[2].paragraphForValue +
                besparingBlockPage[2].paragraphAfterValue
              "
            ></p>
            <a class="button medium blueGhost" @click="openDialog(1)">
              <span>Zo werkt het</span>
            </a>
          </div>
        </div>
        <div class="usp">
          <div class="image">
            <div :class="{ active: editor }">
              <img
                @click="
                  edit(besparingBlockPage[3].img, 'BesparingBlock', 'img', 3)
                "
                :src="besparingBlockPage[3].img"
                alt=""
              />
            </div>
          </div>
          <div class="text">
            <div :class="{ active: editor }">
              <h3
                @click="
                  edit(
                    besparingBlockPage[3].header,
                    'BesparingBlock',
                    'header',
                    3
                  )
                "
                v-html="besparingBlockPage[3].header"
              ></h3>
            </div>
            <div v-if="editor">
              <div :class="{ active: editor }">
                <p
                  @click="
                    edit(
                      besparingBlockPage[3].paragraph0,
                      'BesparingBlock',
                      'paragraph0',
                      3
                    )
                  "
                  v-html="besparingBlockPage[3].paragraph0"
                ></p>
              </div>
              <div :class="{ active: editor }">
                <p
                  @click="
                    edit(
                      besparingBlockPage[3].paragraph1,
                      'BesparingBlock',
                      'paragraph1',
                      3
                    )
                  "
                  v-html="besparingBlockPage[3].paragraph1"
                ></p>
              </div>
              <div :class="{ active: editor }">
                <p
                  @click="
                    edit(
                      besparingBlockPage[3].paragraph2,
                      'BesparingBlock',
                      'paragraph2',
                      3
                    )
                  "
                  v-html="besparingBlockPage[3].paragraph2"
                ></p>
              </div>
            </div>
            <p
              v-if="!editor"
              v-html="
                besparingBlockPage[3].paragraph0 +
                besparingBlockPage[3].paragraph1 +
                besparingBlockPage[3].paragraph2
              "
            ></p>
            <a class="button medium blueGhost" @click="openDialog(2)">
              <span>Zo werkt het</span>
            </a>
          </div>
        </div>
      </div>
      <flickity
        class="flickity"
        ref="flickity"
        v-if="flickStatus"
        :options="flickityOptions"
      >
        <div class="carousel-cell">
          <div class="text">
            <div class="usp">
              <div class="image">
                <img :src="besparingBlockPage[1].img" alt="" />
              </div>
              <div class="text">
                <h3
                  v-html="
                    besparingBlockPage[1].headerForValue +
                    besparingBlockPage[1].headerAfterValue
                  "
                ></h3>
                <div v-if="!!hasBesparing">
                  <p
                    v-html="
                      besparingBlockPage[1].paragraphForValue +
                      besparing +
                      besparingBlockPage[1].paragraphAfterValue
                    "
                  ></p>
                </div>
                <!--TODO - Besparing1015jaar (7.331) nog invullen  -->
                <div v-if="!hasBesparing">
                  <p
                    v-html="
                      besparingBlockPage[1].paragraphForValue +
                      offerte.offerteHuurPrijs +
                      besparingBlockPage[1].paragraphAfterValue
                    "
                  ></p>
                </div>
                <a class="button medium blueGhost" @click="openDialog(0)">
                  <span>Zo werkt het</span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="carousel-cell">
          <div class="text">
            <div class="usp">
              <div class="image">
                <img :src="besparingBlockPage[2].img" alt="" />
              </div>
              <div class="text">
                <h3 v-html="besparingBlockPage[2].header"></h3>
                <p
                  v-html="
                    besparingBlockPage[2].paragraphForValue +
                    besparingBlockPage[2].paragraphAfterValue
                  "
                ></p>
                <a class="button medium blueGhost" @click="openDialog(1)">
                  <span>Zo werkt het</span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="carousel-cell">
          <div class="text">
            <div class="usp">
              <div class="image">
                <img :src="besparingBlockPage[3].img" alt="" />
              </div>
              <div class="text">
                <h3 v-html="besparingBlockPage[3].header"></h3>
                <p
                  v-html="
                    besparingBlockPage[3].paragraph0 +
                    besparingBlockPage[3].paragraph1 +
                    besparingBlockPage[3].paragraph2
                  "
                ></p>
                <a class="button medium blueGhost" @click="openDialog(2)">
                  <span>Zo werkt het</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </flickity>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/app";
import Flickity from "vue-flickity";

export default {
  components: {
    Flickity,
  },
  data() {
    return {
      dialogText: "",
      dialogTitle: "",
      show: false,
      type: null,
      flickStatus: false,
      flickityOptions: {
        initialIndex: 3,
        freeScroll: false,
        lazyLoad: 1,
        imagesLoaded: true,
        draggable: ">1",
        prevNextButtons: true,
        pageDots: false,
        wrapAround: true,
      },
      showEdit: false,
      valueToEdit: "",
      editedValue: "",
      subColToEdit: "",
      fieldToEdit: "",
      indexToEdit: null,
      imgUrl: "",
      uploadValue: "",
      imageData: "",
    };
  },
  computed: {
    offerte() {
      return this.$store.getters.getCurrentOfferte;
    },
    pages() {
      return this.$store.getters.getPages;
    },

    besparingBlockPage() {
      return this.pages.BesparingBlock;
    },
    editor() {
      return this.$store.getters.getEditor;
    },
    admin() {
      return this.$store.getters.getAdmin;
    },

    loopTijd() {
      if (this.offerte.offerteFinancieringsvorm === "Huur 15 jaar") {
        return "15";
      } else {
        return "10";
      }
    },
    loopTijdRestant() {
      if (this.offerte.offerteFinancieringsvorm === "Huur 15 jaar") {
        return "15";
      } else {
        return "20";
      }
    },
    prijsOvernamePaneel() {
      if (this.offerte.offerteFinancieringsvorm === "Huur 15 jaar") {
        return "35";
      } else {
        return "50";
      }
    },

    besparing() {
      return (this.offerte.offerteBesparing * 12 * +this.loopTijd)
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
      // return this.offerte.offerteBesparing * 12 * +this.loopTijd;
    },
    hasBesparing() {
      // TODO - Geeft aan of er wel een besparing is of niet
      return true;
    },
    CO2Besparing() {
      return Math.round(this.offerte.offerteJaarproductie * 0.46, 0);
    },
    aantalAutoritten() {
      return Math.round(this.offerte.offerteJaarproductie * 0.15, 0);
    },
  },
  emits: ["type-dialog"],
  methods: {
    openDialog(type) {
      this.type = type;
      this.show = true;
    },
    tryClose() {
      this.type = null;
      this.show = false;
    },
    flickVisible() {
      if (window.innerWidth >= 749) {
        this.flickStatus = false;
      }
      if (window.innerWidth < 749) {
        this.flickStatus = true;
      }
    },
    edit(value, subCol, field, index = null) {
      if (this.editor) {
        this.valueToEdit = value;
        this.subColToEdit = subCol;
        this.fieldToEdit = field;
        this.indexToEdit = index;
        this.showEdit = true;
      }
    },
    updateImg(event) {
      this.uploadValue = 0;
      this.imgUrl = null;
      this.imageData = event.target.files[0];
      this.onUpload();
    },

    onUpload() {
      this.imgUrl = null;
      const storageRef = firebase
        .storage()
        .ref(
          `Pages/${this.subColToEdit}/${this.fieldToEdit}/${this.imageData.name}`
        )
        .put(this.imageData);
      storageRef.on(
        `state_changed`,
        (snapshot) => {
          this.uploadValue =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          console.log(error.message);
        },
        () => {
          this.uploadValue = 100;
          storageRef.snapshot.ref.getDownloadURL().then((url) => {
            this.imgUrl = url;
            this.editedValue = this.imgUrl;
          });
        }
      );
    },
    async confirm(res) {
      if (res === "Yes") {
        let i = "";
        let field = "";
        if (this.indexToEdit === null) {
          i = null;
        } else {
          i = this.indexToEdit;
        }
        if (
          this.fieldToEdit.toLowerCase().includes("img") ||
          this.fieldToEdit.toLowerCase().includes("logo")
        ) {
          field = this.imageData.name;
        } else {
          field = this.editedValue;
        }

        const ad = {
          ...this.admin,
        };
        const payload = {
          index: i,
          collection: this.subColToEdit,
          field: this.fieldToEdit,
          value: this.editedValue,
          newValue: field,
          admin: ad,
          oldVal: this.valueToEdit,
        };
        this.showEdit = false;
        await this.$store.dispatch("editRecord", payload);

        this.valueToEdit = "";
        this.editedValue = "";
        this.subColToEdit = "";
        this.fieldToEdit = "";
        this.indexToEdit = null;
        this.showEdit = false;
        this.imgUrl = null;
        this.uploadValue = "";
        this.imageData = "";
      } else {
        this.valueToEdit = "";
        this.editedValue = "";
        this.subColToEdit = "";
        this.fieldToEdit = "";
        this.indexToEdit = null;
        this.showEdit = false;
        this.imgUrl = null;
        this.uploadValue = "";
        this.imageData = "";
      }
    },
  },
  created() {
    if (window.innerWidth < 749) {
      this.flickStatus = true;
    }
    window.addEventListener("resize", this.flickVisible);
  },
  destroyed() {
    window.addEventListener("resize", this.flickVisible);
  },
};
</script>

<style scoped>
.rootDiv {
  background-color: #e1eef3;
  padding-bottom: 2rem;
}
@media (max-width: 748px) {
  h2 {
    display: block;
    font-size: 2.6em;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
  }

  h2,
  .h2 {
    margin-bottom: 2rem;
    font-size: 2rem;
    line-height: 1.2;
  }
  .container {
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    position: relative;
    margin: auto;
    max-width: 80rem;
    display: inline-block;
  }

  .button.medium {
    font-size: 1rem;
    line-height: 2;
  }

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: bottom;
    margin: 0;
    border-radius: 1.5rem;
    font-family: "Karla", sans-serif;
    width: auto;
    white-space: normal;
    font-weight: 700;
  }

  .button.medium.blueGhost {
    color: #44bace;
    background-color: transparent;
    border: 2px solid #44bace;
    transition: all 0.5 ease-in-out;
  }

  dialog {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    border-radius: 2px;
    border: none;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    padding: 2rem;
    margin: 0;
    overflow: hidden;
    background-color: #fff;
  }

  /* START FLICKITY CSS */

  .flickity {
    width: 100%;
    margin: auto;
  }

  .carousel-cell {
    padding: 2rem;
    width: calc(100%);
    height: 25rem;
    max-height: 25rem;
    background: #f4f9fa;
    display: inline-block;
    font-size: 0.9rem;
    border-radius: 12px;
    align-items: center;
    justify-content: center;
    text-align: left;
  }

  .carousel.is-fullscreen .carousel-cell {
    height: 100%;
  }

  .carousel-cell img {
    display: block;
    width: 30%;
    margin-left: auto;
    margin-right: auto;
    max-height: 100%;
  }

  .name {
    font-family: "Bad Script", cursive;
    font-size: 2em;
    margin-bottom: 2rem;
    margin-top: 1rem;
  }

  :deep(.flickity-page-dots .dot) {
    width: 10px;
    height: 10px;
    opacity: 0.25;
    background: #00a7e3;
  }

  :deep(.flickity-page-dots .dot.is-selected) {
    background: #00a7e3;
    width: 10px;
    height: 10px;
    opacity: 1;
    background: #00a7e3;
  }

  :deep(.flickity-button) {
    color: #00a7e3;
    background: transparent;
    opacity: 0.2;
    margin: -1rem;
  }

  /* END CSS FLICKITY */
}

@media (min-width: 749px) {
  .button.blueGhost {
    color: #44bace;
    background-color: transparent;
    border: 2px solid #44bace;
    transition: all 0.5 ease-in-out;
  }

  .button.blueGhost:hover {
    color: white;
    background-color: #44bace;
    border: 2px solid #44bace;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .button.medium {
    padding: 1rem 1rem 1rem;
    font-size: 1.4rem;
    line-height: 1;
  }

  .button {
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    vertical-align: bottom;
    position: relative;
    margin: 0;
    margin-top: 1rem;
    border: none;
    outline: none;
    text-decoration: none;
    cursor: pointer;
    touch-action: manipulation;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-radius: 0.5rem;
    font-family: "Karla", sans-serif;
    width: auto;
    white-space: normal;
    text-align: center !important;
    font-weight: 700;
    -webkit-transition: all 0.15s ease-in-out 0s;
    transition: all 0.15s ease-in-out 0s;
  }

  .uspBlock {
    margin-bottom: 25rem;
  }
  .uspBlock .usps {
    width: 70%;
    margin: auto;
  }
  .uspBlock .usps .usp {
    display: -webkit-box;
    display: flex;
    margin-bottom: 5rem;
  }
  .uspBlock .usps .usp .text {
    width: 75%;
  }
  .uspBlock .usps .usp .image {
    width: 25%;
    margin-right: 3rem;
    transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
  }

  dialog {
    position: fixed;
    top: 10vh;
    left: 33%;
    width: 33%;
    z-index: 100;
    border-radius: 2px;
    border: none;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    padding: 2rem;
    margin: 0;
    overflow: hidden;
    background-color: #fff;
  }
  h3,
  .h3 {
    margin-bottom: 2rem;
    font-size: 2.5rem;
    line-height: 1.3;
  }
  h2,
  .h2 {
    margin: 0;
    font-family: "Karla", sans-serif;
    font-weight: 700;
  }

  h2 {
    display: block;
    font-size: 2.6em;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
  }
  .container {
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    position: relative;
    margin: auto;
    height: 50rem;
    max-width: 80rem;
    display: inline-block;
  }
}

@keyframes dialog {
  from {
    opacity: 0;
    transform: translateY(-50px) scale(0.9);
  }
  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

.dia-enter-active {
  animation: dialog 0.3s ease-out;
}

.uspBlock h2 {
  text-align: center;
  text-transform: uppercase;
  color: #00a7e3;
}

img {
  display: flex;
  width: 100%;
  border: 0;
  height: auto;
}

.uspBlock .usps .usp h3 strong {
  color: #f48811;
}

.htmlStrong {
  color: #f48811;
  font-weight: 700;
}

.uspBlock h2 strong {
  color: #44bace;
}
strong,
b {
  font-weight: 700;
}

a,
button,
.button {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.uspBlock .usps .usp .image {
  opacity: 1 !important;
  -webkit-transform: scale(1) !important;
  transform: scale(1) !important;
  -webkit-transform: translateY(0) !important;
  transform: translateY(0) !important;
}

p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.uspBlock .usps .usp h3 {
  text-transform: none;
  color: #00a7e3;
}

h3,
.h3 {
  margin: 0;
  font-family: "Karla", sans-serif;
  text-transform: uppercase;
  font-weight: 700;
}
*,
*:before,
*:after {
  box-sizing: border-box;
}
h3 {
  display: block;
  font-size: 1.17em;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

/* CSS FOR DIALOG */

/* CSS FOR DIALOG */

.editBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 10;
}

.editDialogView {
  position: absolute;
  margin: auto;
  width: 30%;
  padding: 1rem;
  border-radius: 12px;
  background-color: #fff;
  left: 40%;
  right: auto;
  top: 30%;
}

.editDialogView dialog {
  max-width: 40rem;
  max-height: 60rem;
  /* z-index: 100; */
  border: none;
  overflow-y: auto;
  background-color: transparent;
}

@media (min-width: 768px) {
  dialog {
    left: calc(50% - 30rem);
    width: 60rem;
  }
}

/* END CSS FOR DIALOG */

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 10;
}

header {
  color: white;
  width: 100%;
  padding: 1rem;
}

header h3 {
  margin: 0;
}

section {
  padding: 1rem;
}

menu {
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
  margin: 0;
}

.dialog-enter-from,
.dialog-leave-to {
  opacity: 0;
  transform: scale(0.8);
}

.dialog-enter-active {
  transition: all 0.3s ease-out;
}

.dialog-leave-active {
  transition: all 0.3 ease-in;
}

.dialog-enter-to,
.dialog-leave-from {
  opacity: 1;
  transform: scale(1);
}

h3,
.h3 {
  margin: 0;
  font-family: "Karla", sans-serif;
  font-weight: 700;
  text-transform: none;
  color: #00a7e3;
}
*,
*:before,
*:after {
  box-sizing: border-box;
}
h3 {
  display: block;
  font-size: 1.17em;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

h3 strong {
  color: #f48811;
}

/* END CSS FOR DIALOG */

/* START FLICKITY CSS */
</style>
