<template>
  <div class="rootDiv">
    <div class="offersBar">
      <div class="text">Let op! Deze offerte is verlopen.</div>
      <div class="button" v-if="!quoteAccepted">
        <base-button @click="openCalendly"
          >Plan een nieuwe afspraak</base-button
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    offerte() {
      return this.$store.getters.getCurrentOfferte;
    },
    quoteAccepted() {
      return this.$store.getters.getQuoteAccepted;
    },
  },
  methods: {
    openCalendly() {
      const url = `https://calendly.com/hallostroom/afspraak?name=${this.offerte.klantVoorNaam} ${this.offerte.klantAchterNaam}&email=${this.offerte.klantEmail}&a1=${this.offerte.klantTelefoon}&utm_source=offerte&utm_medium=email&utm_campaign=online-offerte-afspraak`;
      window.open(url);
    },
  },
};
</script>

<style scoped>
@media (max-width: 748px) {
  .offersBar {
    background: darkred;
    z-index: 1000;
    color: white;
    position: fixed;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    top: 0;
    border: none;
    outline: none;
    text-decoration: none;
    cursor: pointer;
    touch-action: manipulation;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    width: 100%;
    text-transform: uppercase;
    font-size: 1rem;
    line-height: 1;
    font-family: "Karla";
    font-weight: 700;
    -webkit-transition: all 0.15s ease-in-out 0s;
    transition: all 0.15s ease-in-out 0s;
  }
}

@media (min-width: 749px) {
  .offersBar {
    background: darkred;
    z-index: 10;
    color: white;
    position: fixed;
    padding-bottom: 1rem;
    top: 0;
    border: none;
    outline: none;
    text-decoration: none;
    cursor: pointer;
    touch-action: manipulation;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    width: 100%;
    text-transform: uppercase;
    /* font-size: 1.4rem; */
    line-height: 1;
    font-family: "Karla";
    font-weight: 700;
    -webkit-transition: all 0.15s ease-in-out 0s;
    transition: all 0.15s ease-in-out 0s;
  }

  .text {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
  }
  .button {
    margin-left: 1rem;
    padding: 0;
    padding-top: 0.4rem;
  }
}
</style>
