<template>
  <div class="rootDiv">
    <div class="footer">
      <div class="container">
        <strong>Heb je vragen?</strong>
        <br />
        <strong> Bel ons op <a href="tel:0307670078">030 767 00 78</a> </strong>
        <div class="openingshours">ma-vr, 09:00-17:30</div>
        <div class="copyright">Copyright © {{ year }} HalloStroom</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FinderFooter",
  computed: {
    year() {
      return new Date().getFullYear();
    },
  },
};
</script>

<style scoped>
@media (max-width: 748px) {
  .container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .footer .container {
    text-align: center;
    padding-top: 0.1rem;
    padding-bottom: 0rem;
  }
  .openingshours {
    color: #4d4d4d;
    font-size: 0.7em;
  }

  .login .footer .container .copyright {
    margin-top: 0.3rem;
    color: #b3b3b3;
    font-size: 0.5em;
  }
  strong,
  b {
    font-size: 0.9rem;
    font-weight: 700;
    font-family: "Karla";
  }
}

@media (min-width: 749px) {
  strong,
  b {
    font-weight: 700;
    font-family: "Karla";
  }
  .container {
    position: relative;
    margin: auto;
    max-width: 80rem;
  }
  .footer .container {
    text-align: left;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .footer .container .openingshours {
    margin-top: 0.7rem;
    color: #4d4d4d;
    font-size: 1rem;
  }

  .login .footer .container .copyright {
    margin-top: 3rem;
    color: #b3b3b3;
    font-size: 0.8em;
  }
}

.footer .container a {
  color: #00a7e3;
}

a:not(.button) {
  text-align: left;
  color: #44bace;
  outline: none;
  cursor: pointer;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  transition: ease-in-out 0.3s all;
  border-radius: 12px;
  font-family: "Karla";
}

a:not(.button):hover {
  text-align: left;
  color: white;
  background-color: #44bace;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  border-radius: 12px;
  outline: none;
  font-family: "Karla";

  cursor: pointer;
}

a,
button,
.button {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-family: "Karla";
}
a {
  background-color: transparent;
  font-family: "Karla";
}
</style>
