<template>
  <div class="rootDiv">
    <div class="dialog" v-if="!!show">
      <teleport to="body">
        <div @click="tryClose" class="backdrop">
          <dialog open>
            <header v-if="type === 0">
              <h3>
                <strong>Betaal achteraf</strong>
                nadat je zonnepanelen werkend zijn opgeleverd
              </h3>
            </header>
            <header v-if="type === 1">
              <h3>
                Stop met eindeloos betalen, start met
                investeren in je huis
              </h3>
            </header>
            <header v-if="type === 2">
              <h3>
                Zet een grote stap richting 100% <strong>duurzaam</strong>
              </h3>
            </header>
            <section v-if="type === 0">
              <p>
                Je ontvangt pas een factuur voor je zonnepanelen wanneer deze
                werkend door onze monteurs zijn opgeleverd. Deze mag je dan
                binnen twee dagen overmaken. Wel zo veilig en zo ben je
                verzekerd dat jouw nieuwe zonnepanelen helemaal naar wens worden
                geïnstalleerd.
              </p>
            </section>
            <section v-if="type === 1">
              <p>
                Onderzoek heeft aangetoond dat een huis gemiddeld 5.000 euro in
                waarde stijgt wanneer er zonnepanelen worden geplaatst. Dat is
                de eerste winst wanneer je kiest voor zonnepanelen.
                <br /><br />
                Na plaatsing wekken je nieuwe zonnepanelen gemiddeld zo'n 30
                jaar duurzame elektriciteit op. Bij je huidige
                energieleverancier zou je voor dezelfde energie in 30 jaar veel
                meer moeten betalen. De investering in zonnepanelen betaalt zich
                dus dubbel en dwars terug.
              </p>
              <img :src="infographic" alt="" />
            </section>
            <section v-if="type === 2">
              <p>
                Je nieuwe zonnepanelen wekken 100% duurzame energie op.
                Die energie hoeft niet meer te worden opgewekt door
                vervuilende kolencentrales. De CO<sub>2</sub> die daarbij
                vrijkomt, bespaar je met jouw zonnepanelen. Samen met duizenden
                andere Nederlanders werk je zo aan een duurzaam Nederland.
              </p>
            </section>
            <menu>
              <base-button @click="tryClose">Sluiten</base-button>
            </menu>
          </dialog>
        </div>
      </teleport>
    </div>
    <div class="uspBlock container" id="uspBlock">
      <h2 class="mainHeader">Duurzaam in jouw voordeel</h2>
      <div class="usps" v-if="!flickStatus">
        <div class="usp">
          <div class="image">
            <img :src="bespaar" alt="" />
          </div>
          <div class="text">
            <h3>Betaal achteraf nadat je systeem werkend is opgeleverd</h3>
            <p>
              Je ontvangt pas een factuur voor je systeem wanneer deze door onze
              eersteklas monteurs werkend is opgeleverd.
            </p>
            <a class="button medium blueGhost" @click="openDialog(0)">
              <span>Zo werkt het</span>
            </a>
          </div>
        </div>
        <div class="usp">
          <div class="image">
            <img :src="investeer" alt="" />
          </div>
          <div class="text">
            <h3>
              Stop met eindeloos betalen, start met
              investeren in je huis
            </h3>
            <p>
              Je huis wordt meer waard en je nieuwe zonnepanelen leveren je
              duizenden euro's op aan duurzaam opgewekte energie.
            </p>
            <a class="button medium blueGhost" @click="openDialog(1)">
              <span>Zo werkt het</span>
            </a>
          </div>
        </div>
        <div class="usp">
          <div class="image">
            <img :src="duurzaam" alt="" />
          </div>
          <div class="text">
            <h3>Zet een grote stap richting 100% <strong>duurzaam</strong></h3>
            <p>
              Je zonnepanelen besparen ieder jaar {{ CO2Besparing }} kg
              CO<sub>2</sub>. Dat is vergelijkbaar met
              {{ aantalAutoritten }} autoritten van Utrecht naar Maastricht.
            </p>
            <a class="button medium blueGhost" @click="openDialog(2)">
              <span>Zo werkt het</span>
            </a>
          </div>
        </div>
      </div>
      <flickity
        class="flickity"
        ref="flickity"
        v-if="flickStatus"
        :options="flickityOptions"
      >
        <div class="carousel-cell">
          <div class="text">
            <div class="usp">
              <div class="image">
                <img :src="bespaar" alt="" />
              </div>
              <div class="text">
                <h3>
                  <strong>Betaal achteraf</strong>
                  nadat je zonnepanelen werkend zijn opgeleverd
                </h3>
                <p>
                  Je ontvangt pas een factuur voor je zonnepanelen wanneer deze
                  werkend door onze monteurs zijn opgeleverd. Deze mag je dan
                  binnen twee dagen overmaken. Wel zo veilig en zo ben je
                  verzekerd dat jouw nieuwe zonnepanelen helemaal naar wens
                  worden geïnstalleerd.
                </p>
                <a class="button medium blueGhost" @click="openDialog(0)">
                  <span>Zo werkt het</span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="carousel-cell">
          <div class="text">
            <div class="usp">
              <div class="image">
                <img :src="investeer" alt="" />
              </div>
              <div class="text">
                <h3>
                  Stop met eindeloos betalen, start met
                  <strong>investeren</strong> in je huis
                </h3>
                <p>
                  Onderzoek heeft aangetoond dat een huis gemiddeld 5.000 euro
                  in waarde stijgt wanneer er zonnepanelen worden geplaatst. Dat
                  is de eerste winst wanneer je kiest voor zonnepanelen.
                  <br /><br />
                  Na plaatsing wekken je nieuwe zonnepanelen gemiddeld zo'n 30
                  jaar duurzame elektriciteit op. Bij je huidige
                  energieleverancier zou je voor dezelfde energie in 30 jaar
                  veel meer moeten betalen. De investering in zonnepanelen
                  betaalt zich dus dubbel en dwars terug.
                </p>
                <a class="button medium blueGhost" @click="openDialog(1)">
                  <span>Zo werkt het</span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="carousel-cell">
          <div class="text">
            <div class="usp">
              <div class="image">
                <img :src="duurzaam" alt="" />
              </div>
              <div class="text">
                <h3>
                  Zet een grote stap richting 100% <strong>duurzaam</strong>
                </h3>
                <p>
                  Je nieuwe zonnepanelen wekken 100% duurzame energie op.
                  Die energie hoeft niet meer worden opgewekt door
                  vervuilende kolencentrales. De CO<sub>2</sub> die daarbij
                  vrijkomt, bespaar je met jouw zonnepanelen. Samen met
                  duizenden andere Nederlanders werk je zo aan een duurzaam
                  Nederland.
                </p>
                <a class="button medium blueGhost" @click="openDialog(2)">
                  <span>Zo werkt het</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </flickity>
    </div>
  </div>
</template>

<script>
import Flickity from "vue-flickity";

export default {
  components: {
    Flickity,
  },
  data() {
    return {
      dialogText: "",
      dialogTitle: "",
      show: false,
      type: null,
      flickStatus: false,
      bespaar: require("../../../../assets/BesparingBlock/icon-bespaar.svg"),
      duurzaam: require("../../../../assets/BesparingBlock/icon-duurzaam.svg"),
      investeer: require("../../../../assets/BesparingBlock/icon-investeer.svg"),
      infographic: require("../../../../assets/BesparingBlock/infographic-bespaar.svg"),
      flickityOptions: {
        initialIndex: 3,
        freeScroll: false,
        lazyLoad: 1,
        imagesLoaded: true,
        draggable: ">1",
        prevNextButtons: true,
        pageDots: false,
        wrapAround: true,
      },
    };
  },
  computed: {
    offerte() {
      return this.$store.getters.getCurrentOfferte;
    },

    CO2Besparing() {
      const math =  Math.round(this.offerte.offerteJaarproductie * 0.46, 0);
      return math.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    },
    aantalAutoritten() {
      const math = Math.round(this.offerte.offerteJaarproductie * 0.15, 0);
      return math.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    },
  },
  emits: ["type-dialog"],
  methods: {
    openDialog(type) {
      this.type = type;
      this.show = true;
    },
    tryClose() {
      this.type = null;
      this.show = false;
    },
    flickVisible() {
      if (window.innerWidth >= 749) {
        this.flickStatus = false;
      }
      if (window.innerWidth < 749) {
        this.flickStatus = true;
      }
    },
  },
  created() {
    if (window.innerWidth < 749) {
      this.flickStatus = true;
    }
    window.addEventListener("resize", this.flickVisible);
  },
  destroyed() {
    window.addEventListener("resize", this.flickVisible);
  },
};
</script>

<style scoped>
.rootDiv {
  background-color: #e1eef3;
  padding-bottom: 2rem;
}
@media (max-width: 748px) {
  .rootDiv {
    margin: 3rem 0;
  }

  h2 {
    display: block;
    font-size: clamp(1.6, 2vw, 2em);
    font-weight: bold;
    line-height: 1;
  }

  .container {
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    position: relative;
    margin: auto;
    max-width: 80rem;
    display: inline-block;
  }

  .button.medium {
    font-size: 1rem;
    line-height: 2;
  }

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: bottom;
    margin: 0;
    border-radius: 1.5rem;
    font-family: "Karla", sans-serif;
    width: auto;
    white-space: normal;
    font-weight: 700;
  }

  .button.medium.blueGhost {
    color: #44bace;
    background-color: transparent;
    border: 2px solid #44bace;
    transition: all 0.5 ease-in-out;
  }

  /* START FLICKITY CSS */

  .flickity {
    width: 100%;
    margin: auto;
  }

  .carousel-cell {
    padding: 0 2rem;
    width: calc(100% - 4rem);
    height: 30rem;
    max-height: 30rem;
    background: #f4f9fa;
    display: inline-block;
    font-size: 0.9rem;
    border-radius: 12px;
    align-items: center;
    justify-content: center;
    text-align: left;
  }

  .carousel.is-fullscreen .carousel-cell {
    height: 100%;
  }

  .carousel-cell img {
    display: block;
    width: 30%;
    margin-left: auto;
    margin-right: auto;
    max-height: 100%;
  }

  :deep(.flickity-page-dots .dot) {
    width: 10px;
    height: 10px;
    opacity: 0.25;
    background: #00a7e3;
  }

  :deep(.flickity-page-dots .dot.is-selected) {
    background: #00a7e3;
    width: 10px;
    height: 10px;
    opacity: 1;
    background: #00a7e3;
  }

  :deep(.flickity-button) {
    color: #00a7e3;
    background: transparent;
    opacity: 0.2;
    margin: -1rem;
  }

  /* END CSS FLICKITY */
}

@media (min-width: 749px) {
  .button.blueGhost {
    color: #44bace;
    background-color: transparent;
    border: 2px solid #44bace;
    transition: all 0.5 ease-in-out;
  }

  .button.blueGhost:hover {
    color: white;
    background-color: #44bace;
    border: 2px solid #44bace;
    box-shadow: rgba(0,0,0,0.24) 0px 3px 8px;
  }

  .button.medium {
    padding: 1rem 1rem 1rem;
    font-size: 1.4rem;
    line-height: 1;
  }

  .button {
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    vertical-align: bottom;
    position: relative;
    margin: 0;
    margin-top: 1rem;
    border: none;
    outline: none;
    text-decoration: none;
    cursor: pointer;
    touch-action: manipulation;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-radius: 0.5rem;
    font-family: "Karla", sans-serif;
    width: auto;
    white-space: normal;
    text-align: center !important;
    font-weight: 700;
    -webkit-transition: all 0.15s ease-in-out 0s;
    transition: all 0.15s ease-in-out 0s;
  }

  .uspBlock {
    margin-bottom: 25rem;
  }
  .uspBlock .usps {
    width: 70%;
    margin: auto;
  }
  .uspBlock .usps .usp {
    display: -webkit-box;
    display: flex;
    margin-bottom: 5rem;
  }
  .uspBlock .usps .usp .text {
    width: 75%;
  }
  .uspBlock .usps .usp .image {
    width: 25%;
    margin-right: 3rem;
    transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
  }

  h3,
  .h3 {
    margin-bottom: 2rem;
    font-size: 2.5rem;
    line-height: 1.3;
  }
  h2,
  .h2 {
    margin: 0;
    font-family: "Karla", sans-serif;
    font-weight: 700;
  }

  h2 {
    display: block;
    font-size: 2.6em;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
  }
  .container {
    /* background-color: #f4f9fa; */
    border-radius: 12px;
    padding-top: 0.8rem;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    position: relative;
    margin: auto;
    height: 50rem;
    max-width: 80rem;
    display: inline-block;
  }
}

@keyframes dialog {
  from {
    opacity: 0;
    transform: translateY(-50px) scale(0.9);
  }
  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

.uspBlock h2 {
  text-align: center;
  text-transform: uppercase;
  color: #00a7e3;
}

img {
  display: flex;
  width: 100%;
  border: 0;
  height: auto;
}

.uspBlock .usps .usp h3 strong {
  color: #f48811;
}

.htmlStrong {
  color: #f48811;
  font-weight: 700;
}

.uspBlock h2 strong {
  color: #44bace;
}
strong,
b {
  font-weight: 700;
}

a,
button,
.button {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/* .uspBlock .usps .usp .image {
  opacity: 1 !important;
  -webkit-transform: scale(1) !important;
  transform: scale(1) !important;
  -webkit-transform: translateY(0) !important;
  transform: translateY(0) !important;
} */

p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.uspBlock .usps .usp h3 {
  text-transform: none;
  color: #00a7e3;
}

h3,
.h3 {
  margin: 0;
  font-family: "Karla", sans-serif;
  text-transform: uppercase;
  font-weight: 700;
}

h3 {
  display: block;
  font-size: 1.17em;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

/* CSS FOR DIALOG */

@media (max-width: 748px) {
  dialog {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    width: calc(100% - 3rem);
    height: calc(100% - 1rem);
    padding-top: 2rem;
    padding-left: 3rem;
    padding-right: 3rem;
    border-radius: 2px;
    border: none;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    margin: -2rem;
    overflow: hidden;
    background-color: #fff;
  }
  .dialogImage {
    width: 95%;
  }
}
@media (min-width: 749px) {
  .dialogImage {
    width: 95%;
  }

  dialog {
    position: fixed;
    top: 10vh;
    left: 33%;
    width: 33%;
    z-index: 100;
    border-radius: 2px;
    border: none;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    padding: 2rem;
    margin: 0;
    overflow: hidden;
    background-color: #fff;
  }
}
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 10;
}


header {
  color: white;
  width: 100%;
  padding: 1rem;
}
section {
  padding: 0rem;
}

menu {
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
  margin: 0;
}

h3,
.h3 {
  margin-top: 0;
  font-size: clamp(1.2rem, 2vw, 1.4rem);
  text-transform: uppercase;
  font-family: "Futura-Hallostroom";
  font-weight: 800;
  margin-bottom: 1rem;
  color: #00a7e3;
}

.mainHeader {
  text-align: center;
}

/* END CSS FOR DIALOG */
</style>
