<template>
  <div class="rootDiv">
    <teleport to="body">
      <berekening-dialog
        v-if="!!show"
        :open="show"
        :showButtons="false"
        @close="tryClose"
      ></berekening-dialog>
    </teleport>
    <div class="priceSummary">
      <div class="line">
        <div class="description">
          <strong>Huurbedrag per maand</strong>

          <p>
            {{ offerte.offerteAantalPanelen }} zonnepanelen inclusief omvormer
            {{ offerte.productOptimizer }}
            <br />
            Huurperiode {{ loopTijdMaanden }} maanden
            <br />

            <i>
              (kWh prijs is € {{ prijsPerKwH }})

              <font-awesome-icon
                id="info"
                class="icon-info"
                icon="info-circle"
                @click="openDialog(0)"
              />
            </i>
          </p>

          <p v-if="!due || acceptedInTime" class="korting">
            {{ korting.omschrijving }}
          </p>
        </div>
        <div class="price">€ {{ offerte.offerteGekozenHuurPrijs }},-</div>
      </div>
      <div v-if="meerwerk" class="line">
        <div class="description">
          <strong>Meerwerk</strong>
          <p v-for="m in meerwerk" :key="m" class="">
            {{ convertContent(m) }}
          </p>
        </div>
        <div class="price"></div>
      </div>
      <div class="line">
        <div class="description">
          <strong>Installatie</strong>
        </div>
        <div class="price">€ 0,-</div>
      </div>
      <div class="line">
        <div class="description">
          <strong>Garantie & verzekering</strong>
        </div>
        <div class="price">€ 0,-</div>
      </div>
      <div class="line" v-if="zakelijk">
        <div class="description">
          <strong>Totaal per maand ex Btw</strong>
        </div>
        <div class="price">€ {{ gekozenHuurPrijsExBTW }}</div>
      </div>
      <div class="line" v-if="zakelijk">
        <div class="description">
          <strong>Btw</strong>
        </div>
        <div class="price">€ {{ BTW }}</div>
      </div>
      <div class="priceLine"></div>
      <div class="line hasNoLine isBlue">
        <div class="description">
          <strong>Totaal per maand</strong>
        </div>
        <div class="price">
          <strong>€ {{ offerte.offerteGekozenHuurPrijs }},-</strong>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BerekeningDialog from "../stepOne/BerekeningDialog.vue";

export default {
  data() {
    return {
      show: null,
      type: null,
    };
  },
  components: { BerekeningDialog },
  computed: {
    offerte() {
      return this.$store.getters.getCurrentOfferte;
    },
    meerwerk() {
      if (this.offerte.offerteMeerwerk) {
        return JSON.parse(this.offerte.offerteMeerwerk);
      } else {
        return "";
      }
    },
    meerwerkAll() {
      return this.$store.getters.getMeerwerkAll;
    },
    accepted() {
      if (
        this.offerte.offerteAkkoord &&
        this.offerte.quoteStage === "Closed Won"
      ) {
        return true;
      } else {
        return false;
      }
    },
    loopTijdMaanden() {
      if (this.offerte.offerteFinancieringsvorm === "Huur 15 jaar") {
        return "180";
      } else {
        return "120";
      }
    },
    jaarProductie() {
      return this.offerte.offerteJaarproductie;
    },
    prijsPerKwH() {
      const price = (
        (this.offerte.offerteGekozenHuurPrijs * 12) /
        this.jaarProductie
      ).toFixed(2);
      return price.replace(".", ",");
    },
    zakelijk() {
      return false;
    },
    korting() {
      return this.offerte.korting;
    },
    due() {
      const d = new Date().valueOf();

      let kortingGeldig = 0;
      if (this.korting.verlengdTot) {
        kortingGeldig = new Date(this.korting.verlengdTot).valueOf();
      } else {
        kortingGeldig = new Date(this.korting.geldigTot).valueOf();
      }
      if (
        d < kortingGeldig
        // && this.offerte.klantKlantSegment === "Particulier"
      ) {
        return false;
      } else {
        return true;
      }
    },

    gekozenHuurPrijsExBTW() {
      const prijs = (this.offerte.offerteGekozenHuurPrijs * 0.79).toFixed(2);
      return prijs.replace(".", ",");
    },
    BTW() {
      const Btw = (this.offerte.offerteGekozenHuurPrijs * 0.21).toFixed(2);
      return Btw.replace(".", ",");
    },
    acceptedInTime() {
      if (this.accepted && this.korting) {
        if (this.offerte.korting.verlengdTot) {
          if (
            this.offerte.offerteAkkoord.offerteAkkoord <=
            new Date(this.offerte.korting.verlengdTot).valueOf()
          ) {
            return true;
          } else {
            return false;
          }
        } else {
          if (
            this.offerte.offerteAkkoord.offerteAkkoord <=
            new Date(this.offerte.korting.geldigTot).valueOf()
          ) {
            return true;
          } else {
            return false;
          }
        }
      } else {
        return false;
      }
    },
  },
  methods: {
    convertContent(value) {
      value = value.toLowerCase();
      let returnVal = "";
      this.meerwerkAll.forEach((val) => {
        if (value.includes(val.name)) {
          returnVal = val.description;
        }
      });
      return returnVal;
    },
    openDialog(type) {
      this.type = type;
      this.show = true;
    },
    tryClose() {
      this.type = null;
      this.show = false;
      this.showPDF = false;
      this.$emit("showBar", true);
    },
  },
};
</script>

<style scoped>
.rootDiv {
  margin-bottom: 2rem;
}

.icon-info {
  margin-left: 0.5rem;
  color: #335fae;
  cursor: pointer;
  transition: color 0.2s ease-in-out;
}
.icon-info:hover {
  margin-left: 0.5rem;
  background: transparent;
  color: #83c63c;
}

@media (max-width: 748px) {
  .korting {
    color: #f48811;
    font-weight: 700;
    text-align: left;
  }
  .description {
    text-align: left;
    font-size: 0.8rem;
  }

  .priceSummary {
    position: relative;
  }
  .priceSummary .line {
    display: -webkit-box;
    display: flex;
    border-bottom: 1px solid #e6e6e6;
    padding: 1rem 0;
  }

  .priceSummary .line .description .customSelect {
    margin: 1rem 0 0;
  }

  .customSelect {
    position: relative;
  }

  strong,
  b {
    font-weight: 700;
  }

  .priceSummary .line .price.alignBottom {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-pack: end;
    justify-content: flex-end;
    padding-bottom: 1.3rem;
  }
  .priceSummary .line .price {
    margin-left: auto;
    padding-left: 2rem;
    font-size: 0.8rem;
  }

  .priceSummary .priceLine {
    position: absolute;
    z-index: 1;
    margin-top: -1px;
    right: 0;
    background: #00a7e3;
    height: 2px;
    width: 25%;
  }

  .priceSummary .line.isBlue {
    color: #00a7e3;
  }
  .priceSummary .line.hasNoLine {
    border: 0;
    padding-bottom: 0;
  }
  .priceSummary .line {
    display: -webkit-box;
    display: flex;
    border-bottom: 1px solid #e6e6e6;
    padding: 1rem 0;
  }
}

@media (min-width: 749px) {
  .korting {
    color: #f48811;
    font-weight: 700;
    text-align: left;
  }
  .description {
    text-align: left;
  }

  .priceSummary {
    position: relative;
  }
  .priceSummary .line {
    display: -webkit-box;
    display: flex;
    border-bottom: 1px solid #e6e6e6;
    padding: 1.5rem 0;
  }

  .priceSummary .line .description .customSelect {
    margin: 1rem 0 0;
  }

  .customSelect {
    position: relative;
  }

  strong,
  b {
    font-weight: 700;
  }

  .priceSummary .line .price.alignBottom {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-pack: end;
    justify-content: flex-end;
    padding-bottom: 1.3rem;
  }
  .priceSummary .line .price {
    margin-left: auto;
    padding-left: 2rem;
  }

  .priceSummary .priceLine {
    position: absolute;
    z-index: 1;
    margin-top: -1px;
    right: 0;
    background: #00a7e3;
    height: 2px;
    width: 25%;
  }

  .priceSummary .line.isBlue {
    color: #00a7e3;
  }
  .priceSummary .line.hasNoLine {
    border: 0;
    padding-bottom: 0;
  }
  .priceSummary .line {
    display: -webkit-box;
    display: flex;
    border-bottom: 1px solid #e6e6e6;
    padding: 1.5rem 0;
  }
}
</style>
