<template>
  <div class="progressBar">
    <span
      class="step hoverActive"
      :class="{ isActive: step === 1 }"
      @click="toStep(1)"
    >
      <div class="number">1</div>
      <div class="text">Jouw details</div>
    </span>
    <span
      class="step hoverActive"
      :class="{ isActive: step === 2 }"
      @click="toStep(2)"
    >
      <div class="number">2</div>
      <div class="text">Jouw pakket</div>
    </span>
    <span
      class="step hoverActive"
      :class="{ isActive: step === 3 }"
      @click="toStep(3)"
    >
      <div class="number">3</div>
      <div class="text">Jouw afspraak</div>
    </span>
  </div>
</template>

<script>
export default {
  data() {
    return {
      chosenStep: null,
    };
  },
  computed: {
    offerte() {
      return this.$store.getters.getCurrentOfferte;
    },
    fullAccess() {
      if (
        this.offerte.offerteAkkoord &&
        this.offerte.quoteStage === "Closed Won"
      ) {
        return true;
      } else {
        return false;
      }
    },
    version() {
      if (this.$route.fullPath.match(/a/g).length === 3) {
        return "a";
      } else {
        return "";
      }
    },
    step() {
      if (this.$route.fullPath.includes("1")) {
        return 1;
      }
      if (this.$route.fullPath.includes("2")) {
        return 2;
      } else {
        return 3;
      }
    },
  },
  methods: {
    toStep(val) {
      if (this.fullAccess) {
        const url = `/offerteDetail/stap${val}${this.version}`;
        this.$router.push(url);
      } else {
        if (val === 1 || val === 2) {
          const url = `/offerteDetail/stap${val}${this.version}`;

          this.$router.push(url);
        }
      }
    },
    // toStep(val) {
    //   if (val < this.step) {
    //     const url = `/offerteDetail/stap${val}${this.version}`;
    //     this.$router.push(url);
    //   }
    // },
  },
};
</script>

<style scoped>
@media (max-width: 748px) {
  .progressBar {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    background: #f4f9fa;
    border-bottom: 1px solid #e6e6e6;
    padding: 0.3rem;
    font-family: "Karla", sans-serif;
  }
  .progressBar .step {
    flex-wrap: wrap;
    margin: 0.2rem;
    width: 33%;
    font-size: 0.8rem;
    line-height: 1;
  }

  .step {
    line-height: 1;
    display: -webkit-box;
    display: block;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    text-transform: none;
    color: #b3b3b3;
    font-weight: 700;
    -webkit-transition: all 0.2s ease-in-out 0s;
    transition: all 0.2s ease-in-out 0s;
  }
  .number {
    display: -webkit-box;
    display: flex;
    margin: auto;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;
    color: white;
    border-radius: 50%;
    background-color: #b3b3b3;
    font-size: 1rem;
    line-height: 1;
    -webkit-transition: all 0.15s ease-in-out 0s;
    transition: all 0.2s ease-in-out 0s;
  }
  .step.isActive {
    color: #44bace;
    cursor: pointer;
  }
  .step.isActive .number {
    background-color: #00a7e3;
  }
  .text {
    display: block;
    font-size: 0.9rem;
    padding-top: 0.2rem;
  }
}

@media (min-width: 749px) {
  .progressBar {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    background: #f4f9fa;
    border-bottom: 1px solid #e6e6e6;
    padding: 0.5rem 1rem;
    font-family: "Karla", sans-serif;
  }

  .progressBar .step {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    text-transform: none;
    color: #b3b3b3;
    font-weight: 700;
    -webkit-transition: all 0.15s ease-in-out 0s;
    transition: all 0.15s ease-in-out 0s;
  }

  .step {
    margin: 0 2rem;
    line-height: 1;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    text-transform: none;
    color: #b3b3b3;
    font-weight: 700;
    -webkit-transition: all 0.2s ease-in-out 0s;
    transition: all 0.2s ease-in-out 0s;
  }

  .number {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    width: 2.5rem;
    height: 2.5rem;
    color: white;
    border-radius: 50%;
    margin-right: 0.7rem;
    background-color: #b3b3b3;
    font-size: 1.2rem;
    line-height: 1;
    -webkit-transition: all 0.15s ease-in-out 0s;
    transition: all 0.2s ease-in-out 0s;
  }

  .hoverActive:hover {
    color: #44bace;
    cursor: pointer;
  }

  .step.hoverActive .number:hover {
    background-color: #00a7e3;
  }

  .step.isActive {
    color: #44bace;
    cursor: pointer;
  }

  .step.isActive .number {
    background-color: #00a7e3;
  }

  a:not(.button) {
    color: inherit;
    text-decoration: none;
    outline: none;
  }
}
</style>
