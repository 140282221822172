<template>
  <div class="rootDiv">
    <div class="block small">
      <div v-if="showLoading">
        <base-card class="spinner">
          <base-spinner></base-spinner>
        </base-card>
      </div>
      <div v-if="!showLoading && !noData && !expired && loggedIn && !noCred">
        <!-- WANNEER JE NAAR DE SITE GAAT ZONDER URL TE GEBRUIKEN -->
        <base-card>
          <h1>Welkom bij hallostroom!</h1>
          <p>Deze site gebruikt jouw unieke link, om je offerte te tonen.</p>
          <p>
            Link niet meer bij je? Vul hieronder je gegevens in en vraag je link
            nogmaals aan.
          </p>
          <div class="shine">
            <h4>Daar ga je toch van stralen?</h4>
          </div>
          <div class="energyFlashLine">
            <img :src="energyFlashUrl" alt="" />
          </div>
          <form @submit.prevent="sendLink">
            <div class="group">
              <label for="password">Jouw postcode:</label><br />
              <input
                placeholder="Voorbeeld: 1234AB"
                type="text"
                id="postcode"
                v-model.trim="postcode"
                @blur="checkPostcode"
                @input="cancelPostCodeError"
              />
              <p class="errorMessage" v-if="!postCodeIsValid">
                {{ errorPostcode }}
              </p>
            </div>
            <div class="group">
              <label for="userCredentials">Jouw huisnummer: </label><br />
              <input
                :placeholder="loginPlaceholder"
                type="text"
                id="userCredentials"
                v-model.trim="userCredentials"
                @blur="checkUserCredentials"
                @input="cancelStateUserCredentials"
              />
              <p class="errorMessage" v-if="!userCredentialsValid">
                {{ errorUserCredential }}
              </p>
            </div>

            <base-button>Stuur me die link!</base-button>
          </form>
        </base-card>
      </div>
      <div v-if="!showLoading && sent && !loggedIn">
        <!-- WANNEER JE EEN SENDLINK AANVRAAG HEBT GEDAAN -->
        <base-card style="height: 30rem">
          <h1>Gelukt!</h1>
          <p>
            De link is naar het bij ons bekende email adres gestuurd. Klik op de
            link in je email om de offerte te bekijken.
          </p>
          <div class="shine">
            <h4>Daar ga je toch van stralen?</h4>
          </div>
          <div class="energyFlashLine">
            <img :src="energyFlashUrl" alt="" />
          </div>
        </base-card>
      </div>
      <div v-if="!showLoading && noData && !sent">
        <!-- WANNEER ER GEEN RECORD IS GEVONDEN -->
        <base-card>
          <h1>We hebben geen offertes kunnen vinden.</h1>
          <p>Deze site gebruikt jouw unieke link, om je offerte te tonen.</p>
          <p>
            Link niet meer bij je? Vul hieronder je gegevens in en vraag je link
            nogmaals aan.
          </p>
          <p>Lukt het nog steeds niet of heb je hulp nodig?</p>
          <p>Bel ons dan met <a href="tel:+31307670078">030 767 00 78</a></p>
          <form @submit.prevent="sendLink">
            <div class="group">
              <label for="password">Jouw postcode:</label><br />
              <input
                placeholder="Voorbeeld: 1234AB"
                type="text"
                id="postcode"
                v-model.trim="postcode"
                @blur="checkPostcode"
                @input="cancelPostCodeError"
              />
              <p class="errorMessage" v-if="!postCodeIsValid">
                {{ errorPostcode }}
              </p>
            </div>
            <div class="group">
              <label for="userCredentials">Jouw huisnummer: </label><br />
              <input
                :placeholder="loginPlaceholder"
                type="text"
                id="userCredentials"
                v-model.trim="userCredentials"
                @blur="checkUserCredentials"
                @input="cancelStateUserCredentials"
              />
              <p class="errorMessage" v-if="!userCredentialsValid">
                {{ errorUserCredential }}
              </p>
            </div>
            <base-button>Stuur me die link!</base-button>
          </form>
        </base-card>
      </div>
      <div v-if="!showLoading && !noData && expired">
        <!-- WANNEER EEN OFFERTE VERLOPEN IS -->
        <base-card>
          <h1>We hebben een offerte gevonden!</h1>
          <p>Deze is helaas <strong>verlopen</strong>.</p>
          <p>
            Bel met <a href="tel:+31307670078">030 767 00 78</a> om een nieuwe
            offerte aanvraag te doen.
          </p>

          <p>
            Wil je deze offerte alsnog bekijken?<br />
            klik
            <base-button style="cursor: pointer" @click="openExpired"
              >hier</base-button
            ><br />
            of <br />
            <base-button @click="openCalendly"
              >plan een <br />nieuwe Afspraak</base-button
            >
          </p>
        </base-card>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/app";
import BaseButton from "../ui/BaseButton.vue";

export default {
  components: { BaseButton },
  data() {
    return {
      url: "",
      field: "",
      userCredentials: "",
      postcode: "",
      huisnummer: "",
      errorPostcode: "Gebruik een postcode met 4 cijfer en daarna 2 letters",
      noData: null,
      noClient: null,
      noCred: null,
      showLoading: null,
      formIsValid: true,
      klantNummerLogin: true,
      postCodeIsValid: true,
      userCredentialsValid: true,
      expired: false,
      showData: false,
      loggedIn: true,
      sent: false,
      email: "",
      password: "",
      showError: "",
      errorMsg: "",
      energyFlashUrl: require("../../assets/MainAssets/icon-energy-flash.svg"),
      currOff: "",
    };
  },
  emits: ["show-data"],
  methods: {
    async loginByUrl() {
      this.showLoading = true;
      const d = new Date().valueOf();
      await this.$store.dispatch("fetchRecordByUrl", this.url); // Haalt record op
      await this.$store.dispatch("FetchPages"); // Haalt de content van de pagina's op
      await this.$store.dispatch("getGenData"); // Haalt "aantal voorgangens" en klantenvertellen cijfer op
      setTimeout(() => {
        const offerte = this.$store.getters.getOffertes;
        if (offerte.length > 0) {
          this.$store.dispatch("fetchMeerwerkAll");

          this.showLoading = false;
          if (offerte.length > 1) {
            this.showLoading = false;
            this.$router.push("/showData"); // Je wordt naar de offerte-keuze pagina gestuurd wanneer er meer dan 1 offerte is gevonden
          } else {
            const date = new Date().getTime();
            const doc = {
              id: offerte[0].id,
              timeStampOpened: date,
            };
            const currentOfferte = {
              ...offerte[0],
              timeStampOpened: date,
            };
            this.currOff = currentOfferte;
            this.$store.dispatch("setCurrentOfferte", currentOfferte); // Deze offerte wordt nu als currentOfferte gebruikt
            if (!this.medewerkerActive) {
              if (!offerte[0].offerteTimeStampOpened) {
                //check of het de eerste keer is dat de offerte geopend wordt
                this.$store.dispatch("updateOfferte", doc); // timestamp geopend wordt opgeslagen in firebase
              } else {
                this.$store.dispatch("updateReOpened", doc); // timestamp heropend wordt opgeslagen in firebase
              }
            }
            this.$router.push("/offerteDetail");
            this.showLoading = false;
          }
        } else {
          this.showLoading = false;
          this.noData = true;
        }
      }, 3000);
    },
    async sendLink() {
      const d = new Date().valueOf();
      this.checkPostcode(); //check of postcode valid is
      this.checkUserCredentials(); // check of huisnummer is ingevuld
      this.showLoading = true;
      if (!this.userCredentialsValid || !this.postCodeIsValid) {
        this.showLoading = false;
        this.noCred = true;
      } else {
        this.showData = true;
        const payload = {
          huisNummer: this.userCredentials.toUpperCase(),
          postCode: this.postcode.toUpperCase(),
        };

        await this.$store.dispatch("createRequest", payload); // Check of er een record op firebase bestaat met het ingevulde postcode en huisnummer.
        setTimeout(() => {
          const requests = this.$store.getters.getRequest;
          if (requests.length > 0) {
            const request = requests[0];
            const payload = {
              subject: "Linkaanvraag",
              potentialNr: request.potential,
              requestDate: d,
              quoteID: request.ID,
            };
            this.$store.dispatch("saveRequest", payload); // Aanmaken van een request record op firebase die een cloud functie triggerd die de mail verstuurd met url.
            this.showLoading = false;
            this.sent = true;
            this.loggedIn = false;
          } else {
            this.noData = true;
            this.showLoading = false;
          }
        }, 2000);
      }
    },
    openCalendly() {
      const url = `https://calendly.com/hallostroom/afspraak?name=${this.currOff.klantVoorNaam} ${this.currOff.klantAchterNaam}&email=${this.currOff.klantEmail}&a1=${this.currOff.klantTelefoon}&utm_source=offerte&utm_medium=email&utm_campaign=online-offerte-afspraak`;
      window.open(url);
    },
    checkLoggedIn() {
      if (
        this.userCredentials === "admin" &&
        this.postcode.toUpperCase() === "EDITOR"
      ) {
        firebase.auth().onAuthStateChanged((user) => {
          if (user) {
            this.$store.dispatch("fetchUser", user.uid); // haalt de firebase user op

            this.loggedIn = true;
          } else {
            this.loggedIn = false;
          }
        });
      }
    },
    checkPostcode() {
      if (this.postcode.toUpperCase() === "EDITOR") {
        this.postCodeIsValid = true;
      } else {
        function hasNumber(myString) {
          return /\d/.test(myString);
        }
        const digits = this.postcode.substring(0, 4);
        const letters = this.postcode.substring(4, 6);
        const checkD = hasNumber(digits);
        const checkL = hasNumber(letters);
        if (this.postcode.length !== 6 || !checkD || checkL) {
          this.postCodeIsValid = false;
        }
      }
    },
    checkUserCredentials() {
      if (this.userCredentials === "admin") {
        this.userCredentialsValid = true;
      } else {
        if (this.userCredentials.length > 0) {
          this.userCredentialsValid = /^\d+$/.test(this.userCredentials);
        } else {
          this.userCredentialsValid = false;
        }
      }
    },

    cancelStateUserCredentials() {
      this.userCredentialsValid = true;
    },
    cancelPostCodeError() {
      this.postCodeIsValid = true;
    },
    openExpired() {
      this.$router.push("/offerteDetail");
    },
  },
  computed: {
    errorUserCredential() {
      return "Vul een geldig huisnummer in.";
    },
    loginPlaceholder() {
      return "Voorbeeld: 12A";
    },
    medewerkerActive() {
      return this.$store.getters.getMedewerkerActive;
    },
  },
  async created() {
    // deze functies wordt uitgevoerd wanneer de component geladen wordt
    this.url = await window.location.href;
    if (this.url.includes("id=")) {
      // de url wordt gechecked. Wanneer deze "id=" in de url heeft staan, wordt de functie loginByUrl geactiveerd
      this.loginByUrl();
    } else {
      this.showLoading = false;
      this.noData = false;
      this.expired = false;
      this.loggedIn = true;
      this.noCred = false;
    }
  },
};
</script>

<style scoped>
@media (max-width: 748px) {
  .shine {
    background-color: transparent;
    width: 100%;
    margin: auto;
    text-align: center;
    transition: all 0.5s ease-in-out;
    background-color: transparent;
  }

  h4 {
    margin: 1rem;
    z-index: -1;
  }
  .group {
    position: relative;
    margin-bottom: 0rem;
    margin-right: 1rem;
  }

  h1 {
    padding: 0;
    margin: 0;
  }
}

@media (min-width: 749px) {
  .container .brand {
    position: absolute;
    width: 18rem;
  }
  .block.small {
    margin-top: -1.5rem;
    padding: 0rem 7rem 10rem;
    width: 50rem;
  }
  .block {
    margin: 5vh auto;
  }
  .energyFlashLine {
    width: 20rem;
    max-width: 28rem;
    margin: auto;
    margin-bottom: 1rem;
  }
  .shine {
    background-color: transparent;
    width: 30%;
    margin: auto;
    text-align: center;
    transition: all 0.5s ease-in-out;
    background-color: transparent;
  }

  .shine:hover {
    z-index: 1;
    background-color: rgba(255, 215, 0, 0.85);
    box-shadow: rgba(255, 215, 0, 1) 0px 22px 500px 200px;
  }

  h4 {
    z-index: -1;
  }
}

.container {
  padding-top: 2rem;
  background-color: #00a7e3;
  padding-bottom: 26rem;
}

.brand {
  position: absolute;
  left: 7%;
  top: 7%;
  width: 12%;
}

.container .brand {
  position: absolute;
  width: 18rem;
}

.group {
  position: relative;
  margin-bottom: 1rem;
}

.group label {
  display: block;
  text-align: left;
  /* margin-bottom: 0.5rem; */
}
.errorMessage {
  font-size: smaller;
  text-align: left;
  font-style: italic;
  color: red;
  margin-left: 2%;
}
input {
  display: block;
  width: 95%;
  border: 1px solid #cccccc;
  padding: 1rem 1rem 1rem;
  border-radius: 0.4rem;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#fafafa),
    to(white)
  );
  background-image: linear-gradient(to bottom, #fafafa 0%, white 100%);
  -webkit-transition: border-color 0.15s ease-in-out 0s;
  transition: border-color 0.15s ease-in-out 0s;
}

.parsleyForm .group > div.full {
  width: calc(100% - 1rem);
}

a:not(.button) {
  text-align: left;
  color: #44bace;
  outline: none;
  cursor: pointer;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  transition: ease-in-out 0.3s all;
  border-radius: 12px;
}

a:not(.button):hover {
  text-align: left;
  color: white;
  background-color: #44bace;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  border-radius: 12px;
  outline: none;
  cursor: pointer;
}

@media (min-width: 749px) {
}
.spinner {
  height: 30rem;
  margin: auto;
}
</style>
