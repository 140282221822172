<template>
  <div class="rootDiv">
    <h3>Logs</h3>
    <div class="log">
      <div v-for="log in logs" :key="log.id">
        -
        {{ reviewDate(log.id) }}
        <br />
        <b style="display: inline">{{ log.user }}</b> heeft in de collectie
        <b style="color: darkorange; display: inline">{{ log.collection }}</b
        >, veld
        <b style="color: darkorange; display: inline">{{ log.field }}</b>
        veranderd
        <div style="display: inline">
          van
          <b style="color: darkred">{{ log.oldVal }}</b>
        </div>
        naar
        <b style="color: darkgreen; display: inline">{{ log.newVal }}</b>
        <br /><br />
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  computed: {
    logs() {
      return this.$store.getters.getLogs;
    },
  },
  methods: {
    close() {
      this.$emit("close-logs");
    },
    reviewDate(date) {
      date = +date;
      return this.moment(date).format("DD.MM.YYYY, HH:mm:ss");
    },
  },
  created() {
    this.moment = moment;
    const l = this.$store.getters.getLogs;
    if (!l) {
      this.$store.dispatch("fetchLogs");
    }
  },
};
</script>

<style scoped>
.log {
  padding: 1rem;
  text-align: left;
  margin-top: 1rem;
  height: 30rem;
  overflow: scroll;
  font-size: 0.8rem;
  border: 1px solid grey;
  border-radius: 8px;
}
</style>
