import { createRouter, createWebHashHistory } from "vue-router";
import OfferteFinder from "./components/search/OfferteFinder.vue";
import PageNotFound from "./components/PageNotFound.vue";
import StressTest from "./components/StressTest.vue";

import DataItem from "./components/data/DataItem.vue";
import OfferteDetail from "./components/OfferteDetail/OfferteDetail.vue";
import StepOne from "./components/OfferteDetail/steps/stepOne/StepOne.vue";
import StepTwo from "./components/OfferteDetail/steps/stepTwo/StepTwo.vue";
import StepThree from "./components/OfferteDetail/steps/stepThree/StepThree.vue";

const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    { path: "/", component: OfferteFinder },
    {
      path: "/loaderio-44578f833e8240b0607dee913d534cf7",
      component: StressTest,
    },
    { path: "/showData", component: DataItem },

    {
      path: "/offerteDetail",
      component: OfferteDetail,

      children: [
        { path: "/offerteDetail/stap1", component: StepOne },
        { path: "/offerteDetail/stap2", component: StepTwo },
        { path: "/offerteDetail/stap3", component: StepThree },
      ],
    },
    { path: "/:notFound(.*)", component: PageNotFound }, //goes last in line!
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return { savedPosition };
    }
    return { left: 0, top: 0 };
  },
});

export default router;
