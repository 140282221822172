<template>
  <div class="rootDiv">
    <div class="card">
      <font-awesome-icon
        v-if="selectedComponent !== 'CheckMain'"
        @click="back"
        id="arrow-left"
        class="arrow-left"
        icon="bars"
      />
      <font-awesome-icon
        v-if="selectedComponent === 'CheckMain'"
        @click="signOut"
        id="power-off"
        class="power-off"
        icon="power-off"
      />

      <component
        @selected-component="changeComponent"
        :is="selectedComponent"
      ></component>
    </div>
  </div>
</template>

<script>
import CheckMain from "./CheckMain.vue";
import CheckMedewerkers from "./CheckMedewerkers.vue";
import CheckMeerwerk from "./CheckMeerwerk.vue";
import CheckProducten from "./CheckProducten.vue";
import CheckReviews from "./CheckReviews.vue";
import CheckGenData from "./CheckGenData.vue";
import ContentEditor from "./ContentEditor.vue";
import AddInfosheets from "./AddInfosheets.vue";
import CheckKorting from "./CheckKorting.vue";
import LogViewer from "./LogViewer.vue";
import firebase from "firebase/app";

export default {
  components: {
    CheckMedewerkers,
    CheckMeerwerk,
    CheckProducten,
    CheckReviews,
    CheckMain,
    CheckKorting,
    ContentEditor,
    CheckGenData,
    AddInfosheets,
    LogViewer,
  },

  data() {
    return {
      selectedComponent: "CheckMain",
    };
  },
  methods: {
    changeComponent(event) {
      this.selectedComponent = event;
    },
    back() {
      this.selectedComponent = "CheckMain";
    },
    async signOut() {
      await firebase.auth().signOut();
      window.location.reload();
    },
  },
};
</script>

<style scoped>
.card {
  border-radius: 12px;
  padding: 2rem;
  /* margin-top: 2rem; */
  width: 85%;
  height: 35rem;
  overflow: scroll;
  max-height: 80rem;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
}

.arrow-left {
  position: absolute;
  left: 0;
  margin-left: 13rem;
  background-color: transparent;
  border-radius: 50%;
  border: 1px solid #44bace;
  padding: 0.8rem;
  color: #44bace;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
}

.arrow-left:hover {
  position: absolute;
  left: 0;
  margin-left: 13rem;
  border-radius: 50%;
  padding: 0.8rem;
  background-color: #44bace;
  color: white;
  cursor: pointer;
}

.power-off {
  position: absolute;
  right: 0;
  margin-right: 5rem;
  background-color: transparent;
  border-radius: 50%;
  border: 1px solid #44bace;
  padding: 0.8rem;
  color: #44bace;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
}

.power-off:hover {
  position: absolute;
  right: 0;
  border-radius: 50%;
  padding: 0.8rem;
  background-color: darkred;
  border: 1px solid darkred;
  color: white;
  cursor: pointer;
  transform: rotate(360deg);
}
</style>
