<template>
  <div class="rootDiv">
    <cropper
      v-if="img"
      class="cropper"
      :src="img"
      ref="cropper"
      :stencil-component="stencil"
      :stencil-props="{
        aspectRatio: 10 / 12,
      }"
    ></cropper>
    <base-button v-if="img" @click="upload">Upload</base-button>
  </div>
</template>

<script>
import { Cropper } from "vue-advanced-cropper";
import { CircleStencil } from "vue-advanced-cropper";
import firebase from "firebase/app";
import "firebase/storage";

export default {
  components: {
    Cropper,
  },
  props: ["img", "id"],
  data() {
    return {
      stencil: CircleStencil,
    };
  },
  methods: {
    upload() {
      let storageRef = firebase.storage().ref();
      let imageRef = storageRef.child("Medewerkers/" + `${this.id}.png`);
      const { canvas } = this.$refs.cropper.getResult();
      canvas.toBlob((result) => {
        var image = new Image();
        image.src = result;
        var uploadTask = imageRef.put(result);
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            var progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            switch (snapshot.state) {
              case firebase.storage.TaskState.PAUSED: // or 'paused'
                break;
              case firebase.storage.TaskState.RUNNING: // or 'running'
                break;
            }
          },
          (error) => {},
          () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
              this.imgUrl = downloadURL;
              const doc = {
                imgUrl: this.imgUrl,
                id: this.id,
              };

              this.$emit("image-url", doc);
            });
          }
        );
      }, "image/png");
    },
  },
};
</script>

<style scoped>
.cropper {
  width: 400px;
  height: 400px;
  background: #ddd;
  margin: auto;
  padding-top: 4rem;
}
</style>
