<template>
  <div class="rootDiv">
    <font-awesome-icon
      v-if="selected"
      @click="back"
      id="arrow-left"
      class="arrow-left"
      icon="arrow-left"
    />
    <h3>Content Editor</h3>
    <p v-if="!selected">Kies welk deel je wilt aanpassen</p>
    <p v-else>{{ selectedButton }}</p>
    <!-- STEP 1 -->
    <div v-if="!selected">
      <div
        v-for="(value, name) in pages"
        :key="name"
        style="display: inline-block"
      >
        <button class="button small green hasIcon" @click="selectSubCol(name)">
          {{ name }}
        </button>
      </div>
    </div>
    <!-- STEP 2 -->
    <div v-if="selected && colIndex === null">
      <div
        v-for="(value, name) in pages[selectedButton]"
        :key="name"
        style="display: inline-block"
      >
        <div class="field" @click="selectedValue(name, value)">
          {{ name }}
        </div>
      </div>
      <div
        v-if="fieldSelected"
        class="valueInfo"
        @click="enableEdit(selectedVal)"
      >
        <p v-if="!showImg">
          {{ selectedVal }}
        </p>
        <div v-else>
          <img @click="inputFile()" :src="selectedVal" />
        </div>
        <input
          type="file"
          id="theFileInput"
          style="display: none"
          @change="updateImg($event, selectedField, selectedValue)"
        />
      </div>
      <div class="valueEdit" v-if="editEnabled">
        <input type="text" v-model="firstValue" @input="toggleEditButton" />
      </div>
      <div v-if="imgUrl" class="valueEdit">
        <img :src="imgUrl" alt="" />
      </div>
      <button class="editButton" v-if="showEditButton" @click="confirmEdit">
        Aanpassen
      </button>
    </div>

    <div v-if="selected && colIndex !== null">
      <div
        v-for="(value, name) in pages[selectedButton][colIndex]"
        :key="name"
        style="display: inline-block"
      >
        <div class="field" @click="selectedValue(name, value)">
          {{ name }}
        </div>
      </div>
      <div
        v-if="fieldSelected"
        class="valueInfo"
        @click="enableEdit(selectedVal)"
      >
        <input
          type="file"
          id="theFileInput"
          style="display: none"
          @change="updateImg($event, selectedField, selectedValue)"
        />
        <p v-if="!showImg">
          {{ selectedVal }}
        </p>
        <div v-else>
          <img @click="inputFile()" :src="selectedVal" />
        </div>
      </div>

      <div class="valueEdit" v-if="editEnabled">
        <input type="text" v-model="firstValue" @input="toggleEditButton" />
      </div>

      <div class="valueEdit" v-if="imgUrl">
        <img :src="imgUrl" alt="" />
      </div>

      <button class="editButton" v-if="showEditButton" @click="confirmEdit">
        Aanpassen
      </button>
    </div>
    <p class="logs" v-if="!selected && !showLogs">view Logs</p>
    <font-awesome-icon
      v-if="!selected && !showLogs"
      @click="viewLogs"
      id="arrow-left"
      class="arrow-left"
      icon="file-alt"
    />
    <log-viewer @close-logs="close" v-if="showLogs && !selected"></log-viewer>
  </div>
</template>

<script>
import LogViewer from "./LogViewer.vue";
import firebase from "firebase/app";

export default {
  components: { LogViewer },
  data() {
    return {
      selected: false,
      fieldSelected: false,
      selectedButton: "",
      selectedVal: "",
      selectedField: "",
      showImg: "",
      showObject: "",
      objectContent: "",
      objectSelected: false,
      showEditButton: false,
      selectedObjectValue: "",
      editEnabled: "",
      firstValue: "",
      path: "",
      colIndex: null,
      showLogs: false,
      imgUrl: "",
      uploadValue: "",
      imageData: "",
    };
  },
  computed: {
    loginPage() {
      return this.$store.getters.getLoginPage;
    },
    pages() {
      let pageTotal = [];
      const page = this.$store.getters.getPages;
      const p = {
        ...page,
        Login: this.loginPage,
      };
      pageTotal.push(p);
      return pageTotal[0];
    },
    admin() {
      return this.$store.getters.getAdmin;
    },
  },
  methods: {
    close() {
      this.showLogs = false;
    },
    viewLogs() {
      this.showLogs = true;
    },
    selectSubCol(name, index = null) {
      this.path = name;
      this.selected = true;
      this.selectedButton = name;
      if (index !== null) {
        this.colIndex = index;
      }
    },
    back() {
      this.selected = false;
      this.selectedButton = "";
      this.selectedVal = "";
      this.fieldSelected = false;
      this.showObject = false;
      this.selectedObjectValue = "";
      this.objectSelected = false;
      this.editEnabled = false;
      this.firstValue = "";
      this.colIndex = null;
      this.showEditButton = false;
      this.showImg = false;
    },
    selectedValue(field, value) {
      if (typeof value === "object" && typeof field === "number") {
        this.selectSubCol(this.selectedButton, field);
      } else {
        let collection = {};
        if (this.colIndex === null) {
          collection = this.pages[this.selectedButton];
        } else {
          collection = this.pages[this.selectedButton][this.colIndex];
        }
        this.selectedField = field;
        this.selectedVal = collection[field];
        this.path = `${this.path}.${field}`;
        const lowerCaseField = field.toLowerCase();
        if (lowerCaseField.includes("img") || lowerCaseField.includes("logo")) {
          this.showImg = true;
        } else {
          this.showImg = false;
        }
        this.fieldSelected = true;
      }
    },

    enableEdit(value) {
      if (this.showImg) {
        return;
      } else {
        this.editEnabled = true;
        this.firstValue = value;
      }
    },
    inputFile() {
      const file = document.getElementById("theFileInput");
      file.click();
    },

    updateImg(event) {
      this.uploadValue = 0;
      this.imgUrl = null;
      this.imageData = event.target.files[0];
      this.onUpload();
    },
    onUpload() {
      this.imgUrl = null;
      const storageRef = firebase
        .storage()
        .ref(
          `Pages/${this.selectedButton}/${this.selectedField}/${this.imageData.name}`
        )
        .put(this.imageData);
      storageRef.on(
        `state_changed`,
        (snapshot) => {
          this.uploadValue =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          console.log(error.message);
        },
        () => {
          this.uploadValue = 100;
          storageRef.snapshot.ref.getDownloadURL().then((url) => {
            this.imgUrl = url;
            this.showEditButton = true;
            this.firstValue = this.imgUrl;
          });
        }
      );
    },
    toggleEditButton() {
      if (this.firstValue.length > 0 && this.firstValue !== this.selectedVal) {
        this.showEditButton = true;
      } else {
        this.showEditButton = false;
      }
    },
    confirmEdit() {
      this.showEditButton = false;
      let field = "";
      if (this.showImg) {
        field = this.imageData.name;
      } else {
        field = this.firstValue;
      }
      if (this.colIndex !== null) {
        const ad = {
          ...this.admin,
        };
        const payload = {
          collection: this.selectedButton,
          field: this.selectedField,
          index: this.colIndex,
          value: this.firstValue,
          newValue: field,
          oldVal: this.selectedVal,
          admin: ad,
        };
        this.$store.dispatch("editRecord", payload);
      } else {
        const ad = {
          ...this.admin,
        };
        const payload = {
          collection: this.selectedButton,
          field: this.selectedField,
          index: null,
          value: this.firstValue,
          newValue: field,
          oldVal: this.selectedVal,
          admin: ad,
        };

        this.$store.dispatch("editRecord", payload);
      }
      this.selectedVal = this.firstValue;
    },
  },
};
</script>

<style scoped>
.editButton {
  display: -webkit-inline-box;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  vertical-align: bottom;
  position: relative;
  background-color: white;
  color: #00a7e3;
  border: 1px solid #00a7e3;
  margin: 0;
  margin-top: 2%;
  outline: none;
  text-decoration: none;
  cursor: pointer;
  touch-action: manipulation;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-radius: 12px;
  font-family: "Karla", sans-serif;
  height: 2rem;
  width: 10rem;
  white-space: normal;
  text-align: center !important;
  font-weight: 700;
  -webkit-transition: all 0.15s ease-in-out 0s;
  transition: all 0.15s ease-in-out 0s;
  margin: 1rem;
}

.editButton:hover {
  display: -webkit-inline-box;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  vertical-align: bottom;
  position: relative;
  /* background-color: #00a7e3; */
  /* color: white; */
  margin: 0;
  margin-top: 2%;
  border: none;
  outline: none;
  text-decoration: none;
  cursor: pointer;
  touch-action: manipulation;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-radius: 12px;
  font-family: "Karla", sans-serif;
  height: 2rem;
  width: 10rem;
  white-space: normal;
  text-align: center !important;
  font-weight: 700;
  -webkit-transition: all 0.15s ease-in-out 0s;
  transition: all 0.15s ease-in-out 0s;
  margin: 1rem;
}

.button {
  display: -webkit-inline-box;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  vertical-align: bottom;
  position: relative;
  margin: 0;
  margin-top: 2%;
  border: none;
  outline: none;
  text-decoration: none;
  cursor: pointer;
  touch-action: manipulation;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-radius: 50%;
  font-family: "Karla", sans-serif;
  height: 8rem;
  width: 8rem;
  white-space: normal;
  text-align: center !important;
  font-weight: 700;
  -webkit-transition: all 0.15s ease-in-out 0s;
  transition: all 0.15s ease-in-out 0s;
  margin: 1rem;
}

.button.small {
  padding: 0.8rem 1.8rem 0.8rem;
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: bolder;
  line-height: 1;
}
.button.green {
  color: white;
  background-color: #60ab2d;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#83c63c),
    to(rgba(131, 198, 60, 0))
  );
  background-image: linear-gradient(
    to bottom,
    #83c63c 0%,
    rgba(131, 198, 60, 0) 100%
  );
}

.button.green:hover {
  background-color: #83c51c;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#83c51c),
    to(rgba(131, 198, 60, 0))
  );
  background-image: linear-gradient(
    to bottom,
    #83c51c 0%,
    rgba(131, 198, 60, 0) 100%
  );
  box-shadow: rgba(0, 0, 0, 0.34) 0px 3px 8px;
}

.button.small.hasIcon {
  padding-right: 2rem !important;
}

.button.small.hasIcon svg {
  width: 1.5rem;
  height: 1.5rem;
  right: 0.5rem;
}

.button svg {
  position: absolute;
  -webkit-transition: all 0.15s ease-in-out 0s;
  transition: all 0.15s ease-in-out 0s;
}

.logs {
  position: absolute;
  right: 0;
  margin-right: 7rem;
  color: #44bace;
  padding: 0.8rem;
  margin-top: -1rem;
}

.arrow-left {
  position: absolute;
  right: 0;
  margin-right: 4rem;
  background-color: transparent;
  border-radius: 50%;
  border: 1px solid #44bace;
  padding: 0.8rem;
  margin-top: -1rem;
  color: #44bace;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
}

.arrow-left:hover {
  position: absolute;
  right: 0;
  border-radius: 50%;
  padding: 0.8rem;
  background-color: #44bace;
  margin-right: 4rem;
  margin-top: -1rem;
  color: white;
  cursor: pointer;
}

.arrow-left {
  position: absolute;
  right: 0;
  margin-right: 4rem;
  background-color: transparent;
  border-radius: 50%;
  border: 1px solid #44bace;
  padding: 0.8rem;
  margin-top: -1rem;
  color: #44bace;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
}

.arrow-left:hover {
  position: absolute;
  right: 0;
  border-radius: 50%;
  padding: 0.8rem;
  background-color: #44bace;
  margin-right: 4rem;
  margin-top: -1rem;
  color: white;
  cursor: pointer;
}

.field {
  text-align: center;
  margin: 0.5rem;
  font-weight: bold;
  width: 20rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-size: 1rem;
  border: 1px solid grey;
  border-radius: 12px;
  cursor: pointer;
  color: #00a7e3;
  background: white;
  transition: all 0.3s ease-in-out;
}

.field:hover {
  text-align: center;
  margin: 0.5rem;
  font-weight: bold;
  width: 20rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-size: 1rem;
  border: 1px solid;
  background-color: lightblue;
  background: linear-gradient(to bottom, #00abde 0%, #00a7e3, 100%);
  border-radius: 12px;
}

img {
  max-width: 15rem;
  max-height: auto;
}

.valueInfo {
  padding: 1rem;
  margin: auto;
  border: 1px solid grey;
  cursor: pointer;
  border-radius: 12px;
  width: 90%;
  height: auto;
  transition: 0.3s all ease-in-out;
}

.valueInfo:hover {
  padding: 1rem;
  margin: auto;
  border: 1px solid grey;
  border-radius: 12px;
  width: 90%;
  height: auto;
  box-shadow: rgba(0, 0, 0, 0.34) 0px 0px 0px 3px;
}

input {
  text-align: center;
  margin-top: 1.5rem;
  font-size: 1rem;
  color: black;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  border: 1px solid #cccccc;
  padding: 1rem 1rem 1rem;
  border-radius: 12px;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#fafafa),
    to(white)
  );
  background-image: linear-gradient(to bottom, #fafafa 0%, white 100%);
  -webkit-transition: border-color 0.15s ease-in-out 0s;
  transition: border-color 0.15s ease-in-out 0s;
}
</style>
