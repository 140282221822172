export default {
  setOffertes(state, payload) {
    state.offertes = "";
    state.offertes = payload;
  },

  // setProductInfo(state, payload) {
  //   state.productPakketImage = payload.productPakketImage;
  //   state.productPakketNaam = payload.productPakketNaam;
  //   state.productOptimizer = payload.productOptimizer;
  //   state.productZonnepaneelpag2 = payload.productZonnepaneelpag2;
  //   state.productInfosheetOmvormer = payload.productInfosheetOmvormer;
  //   state.productInfosheetZonnepanelen = payload.productInfosheetZonnepanelen;
  //   state.productKleurPanelen = payload.productKleurPanelen;
  // },

  setCurrentOfferte(state, payload) {
    state.percentageEigenOpwek = +payload.offertePercentage;
    state.offerteBesparing = payload.offerteBesparing;
    state.offerteAkkoord = payload.offerteAkkoord;
    state.contactMedewerkerEmail = payload.contactMedewerkerEmail;
    state.contactMedewerkerFoto = payload.contactMedewerkerFoto;
    state.contactMedewerkerNaam = payload.contactMedewerkerNaam;
    state.korting = payload.korting;
    state.klantAchterNaam = payload.klantAchterNaam;
    state.klantAdres = payload.klantAdres;
    state.klantEmail = payload.klantEmail;
    state.klantHuisNummer = payload.klantHuisNummer;
    state.klantPostcode = payload.klantPostcode;
    state.klantTelefoon = payload.klantTelefoon;
    state.klantVoorNaam = payload.klantVoorNaam;
    state.klantStad = payload.klantStad;
    state.klantKlantSegment = payload.klantKlantSegment;
    state.offerteMeerwerk = payload.offerteMeerwerk;
    state.offerteOfferteNummer = payload.offerteOfferteNummer;
    state.offerteGeldigheidsDatum = payload.offerteGeldigheidsDatum;
    state.offerteTimeStampOpened = payload.offerteTimeStampOpened;
    state.offerteTimeStampReOpened = payload.offerteTimeStampReOpened;
    state.offerteAantalPanelen = +payload.offerteAantalPanelen;
    state.offerteDakbedekking = payload.offerteDakbedekking;
    state.offerteFinancieringsvorm = payload.offerteFinancieringsvorm;
    state.offerteGekozenHuurPrijs = +payload.offerteGekozenHuurPrijs;
    state.offerteJaarproductie = +payload.offerteJaarproductie;
    state.offerteKoop = payload.offerteKoop;
    state.offertePrijsKoopExclBTW = payload.offertePrijsKoopExclBTW;
    state.offertePrijsKoopInclBTW = payload.offertePrijsKoopInclBTW;
    state.offerteForfait = payload.offerteForfait;
    state.offerteElektraJaarVerbruik = payload.offerteElektraJaarVerbruik;
    state.offerteOfferteDatum = +payload.offerteOfferteDatum;
    state.offerteOpstelling = payload.offerteOpstelling;
    state.offerteOrientatieDak = payload.offerteOrientatieDak;
    state.offerteQuoteImageLegPlan = payload.offerteQuoteImageLegPlan;
    state.offerteSchaduwvrij = payload.offerteSchaduwvrij;
    state.offerteTypeDak = payload.offerteTypeDak;
    state.offerteMerkZonnepanelen = payload.offerteMerkZonnepanelen;
    state.offerteMerkOmvormer = payload.offerteMerkOmvormer;
    state.offerteUrl = payload.offerteUrl;
    state.quoteStage = payload.quoteStage;
    state.productPakketprijsName = payload.productPakketPrijsName;
    state.productPakketImage = payload.productPakketImage;
    state.productPakketNaam = payload.productPakketNaam;
    state.productOptimizer = payload.productOptimizer;
    state.productZonnepaneelpag2 = payload.productZonnepaneelpag2;
    state.productInfosheetOmvormer = payload.productInfosheetOmvormer;
    state.productInfosheetZonnepanelen = payload.productInfosheetZonnepanelen;
    state.productKleurPanelen = payload.productKleurPanelen;
    state.productSpecSheets = payload.productSpecSheets;
    if (payload.tarief) {
      state.tarief = payload.tarief;
    };
    state.ID = payload.ID;
    state.AB = payload.versie; // AANZETTEN VOOR WANNEER ER MEERDERE VERSIES ZIJN.
    // state.AB = 0;
  },

  setKorting(state, payload) {
    state.korting = payload.korting;
  },

  setGenData(state, payload) {
    state.Voorgangers = payload.Voorgangers;
    state.KlantenVertellen = payload.KlantenVertellen;
  },

  setReviewRecs(state, payload) {
    state.reviewRecs = payload;
  },

  setReview(state, payload) {
    state.reviews = payload.reviews;
    state.reviewDate = payload.datum;
  },

  setGenData(state, payload) {
    state.genData = payload;
  },

  setMedewerkers(state, payload) {
    state.medewerkers = payload;
  },

  setProducts(state, payload) {
    state.products = payload;
  },
  setInfosheets(state, payload) {
    state.infosheets = payload;
  },

  setKlantNummer(state, payload) {
    state.klantKlantNummer = payload;
  },

  setLoginPage(state, payload) {
    state.LoginPage = payload;
  },

  setPages(state, payload) {
    state.Pages = payload;
  },

  setUser(state, payload) {
    state.Admin = payload;
  },
  setLogs(state, payload) {
    state.Logs = payload;
  },

  setEditor(state) {
    state.Editor = true;
  },
  updateOfferteAkoord(state, payload) {
    state.offerteAkkoord = payload;
    state.quoteStage = "Closed Won";
  },
  updateOfferteOpened(state, payload) {
    state.offerteTimeStampOpened = payload.timeStampOpened;
  },
  updateOfferteReOpened(state, payload) {
    state.offerteTimeStampReOpened = payload.timeStampOpened;
  },
  setRequest(state, payload) {
    state.request = payload;
  },

  setMedewerkerActive(state) {
    state.medewerkerActive = true;
  },

  setQuoteAccepted(state) {
    state.QuoteAccepted = true;
  },

  ClearAll(state, _) {
    state.tarief = 0.31;
    state.ID = "";
    state.Voorgangers = "";
    state.KlantenVertellen = "";
    state.contactMedewerkerEmail = "";
    state.contactMedewerkerFoto = "";
    state.contactMedewerkerNaam = "";
    state.klantAchterNaam = "";
    state.klantAdres = "";
    state.klantEmail = "";
    state.klantHuisNummer = "";
    state.klantKlantNummer = "";
    state.klantPostcode = "";
    state.klantTelefoon = "";
    state.klantVoorNaam = "";
    state.klantStad = "";
    state.offertes = [];
    state.offerteBesparingIn30J = "";
    state.offerteGeldigheidsDatum = "";
    state.offerteOfferteNummer = "";
    state.offerteOpwekkingPerJaar = "";
    state.offerteTimeStampOpened = "";
    state.offerteTimeStampReOpened = "";
    state.offerteAantalPanelen = "";
    state.offerteBijdrage = "";
    state.offerteDakbedekking = "";
    state.offerteElektraJaarVerbruik = "";
    state.offerteExtraTekstOpOfferte = "";
    state.offerteFinancieringsvorm = "";
    state.offerteGekozenBijdrage = "";
    state.offerteGekozenBijdrageTotaal = "";
    state.offerteGekozenHuurPrijs = "";
    state.offerteHuurPrijs = "";
    state.offerteJaarproductie = "";
    state.offerteKoop = "";
    state.offerteOfferteDatum = "";
    state.offerteOpstelling = "";
    state.offerteOrientatieDak = "";
    state.offerteQuoteImageLegPlan = "";
    state.offerteSchaduwvrij = "";
    state.offerteTotaalBijdrage = "";
    state.offerteTypeDak = "";
    state.offerteMerkZonnepanelen = "";
    state.offerteUrl = "";
    state.offerteMerkOmvormer = "";
    state.productPakketImage = "";
    state.productPakketNaam = "";
    state.productOptimizer = "";
    state.productZonnepaneelpag2 = "";
    state.productInfosheetOmvormer = "";
    state.productInfosheetZonnepanelen = "";
    state.productKleurPanelen = "";
    state.reviews = [];
    state.reviewDate = "";
    state.reviewRecs = "";
    state.genData = "";
    state.medewerkers = "";
    state.products = "";
    state.infosheets = "";
    state.Pages = "";
    state.Admin = "";
    state.Logs = "";
    state.Korting = "";
    state.Kortingen = "";
    state.Editor = "";
    // state.aangepasteOfferte = "";
    state.offerteAkkoord = "";
    state.productSpecSheets = "";
    state.AB = "";
    state.korting = "";
    state.request = "";
    state.medewerkerActive = false;
    state.QuoteAccepted = false;
    state.AllMeerwerk = "";
  },

  setMeerwerkAll(state, payload) {
    state.AllMeerwerk = payload;
  }

};
