<template>
  <base-dialog @close="tryClose" :open="show" :showButtons="false">
    <header style="text-align: left; padding-bottom: 1rem; color: black">
      <h3 style="font-size: 1.5rem">
        <strong>Hoe</strong>
        wordt je besparing berekend?
      </h3>
    </header>
    <section style="padding: 0rem">
      <strong style="font-size: 1rem"
        ><div class="math">Maandelijkse Besparing</div>
        <div class="mathSymbol">=</div>
        (
        <div class="math">Jaarproductie</div>
        <div class="mathSymbol">/</div>
        <div class="math">12</div>
        )
        <div class="mathSymbol">x</div>
        <div class="math">het energietarief</div>
        <div class="mathSymbol">-</div>
        <div class="math">de huurprijs bij Hallostroom</div></strong
      >
      <p style="font-size: 1rem">
        Je besparing per maand wordt berekend door de hoeveelheid die jouw
        zonnepanelen per jaar produceren te delen door 12, die vervolgens te
        vermenigvuldigen met het energietarief en daarvan weer de maandelijkse
        huur aan Hallostroom af te halen.
      </p>
      <div class="wrapper">
        <div class="container1">
          <h3 style="text-align: center; font-size: 1.2rem">
            JOUW <strong>GEGEVENS</strong>
          </h3>
          <i
            >Jouw verwachte jaaropwek =
            <div class="math">{{ targetKwhFormat }} kWh</div></i
          >
          <i
            >Energietarief per kWh =
            <div class="math">€{{ tariefFormat }}</div></i
          >
          <i
            >Jouw gekozen huurprijs =
            <div class="math">€{{ huurprijs }},-</div></i
          >
          <br />
          <h3 style="text-align: center; font-size: 1.2rem">
            DUS IS JOUW <strong>BEREKENING: </strong>
          </h3>
          <b>Jaarproductie delen door 12: </b>
          <i
            ><div class="math">{{ targetKwhFormat }} kWh</div>
            <div class="mathSymbol">/</div>
            <div class="math">12</div>
            <div class="mathSymbol">=</div>
            <div class="math">{{ targetKwhMonth }} kWh</div>
            per maand</i
          >
          <b>Productie per maand vermenigvuldigen met het energietarief: </b>
          <i>
            <div class="math">{{ targetKwhMonth }}</div>
            <div class="mathSymbol">x</div>
            <div class="math">€{{ tariefFormat }}</div>
            (energietarief)
            <div class="mathSymbol">=</div>
            <div class="math">€{{ targetKwhTarief }},-</div>
          </i>
          <b
            >Maandelijkse opbrengst minus de maandelijkse huur bij hallostroom:
          </b>
          <i>
            <div class="math">€{{ targetKwhTarief }},-</div>
            <div class="mathSymbol">-</div>
            <div class="math">€{{ huurprijs }},-</div>
            (Huurprijs)
            <div class="mathSymbol">=</div>
            <div class="math">€{{ maandelijkseBesparing }},-</div>
          </i>
        </div>
        <div class="container1">
          <div class="wrapper" style="margin: 1rem 0 0 0">
            <div
              class="columnLeft"
              :style="{ height: `${this.heightBesparing}rem` }"
            >
              €{{ targetKwhTarief }}
            </div>

            <div class="cRight">
              <div
                class="columnRight"
                :style="{
                  height: `${this.heightBesparing}rem`,
                  paddingTop: `${this.diff1}rem`,
                }"
              >
                €{{ huurprijs }}
              </div>
              <div
                class="columnRight1"
                :style="{
                  height: `${this.diff1}rem`,
                }"
              >
                €{{ maandelijkseBesparing }}
              </div>
            </div>
            <div class="circle">
              <p class="circleTop">Bespaar</p>
              <strong>€{{ maandelijkseBesparing }},- </strong>
              <p class="circleBottom">per maand</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <menu>
      <base-button @click="tryClose">Sluiten</base-button>
    </menu>
  </base-dialog>
</template>

<script>
export default {
  props: ["open", "showButtons"],
  emits: ["close"],
  data() {
    return {
      show: null,
      heightBesparing: 19,
    };
  },

  methods: {
    tryClose() {
      this.$emit("close");
      //   this.$emit("showBar", true);
    },
  },
  computed: {
    offerte() {
      return this.$store.getters.getCurrentOfferte;
    },
    tarief() {
      return this.offerte.tarief;
    },
    tariefFormat() {
      return this.tarief.toString().replace(".", ",");
    },
    huurprijs() {
      return this.offerte.offerteGekozenHuurPrijs.toFixed(0);
    },
    prijsPerKwhHS() {
      let price = (
        (this.huurprijs * 12) /
        this.offerte.offerteJaarproductie
      ).toFixed(2);
      return +price;
    },
    prijsPerKwhHSFormat() {
      const price = (
        (this.huurprijs * 12) /
        this.offerte.offerteJaarproductie
      ).toFixed(2);
      return price.replace(".", ",");
    },
    besparingPerKwh() {
      return this.tarief - this.prijsPerKwhHS;
    },
    besparingPerKwhFormat() {
      const price = this.besparingPerKwh.toFixed(2);
      return price.replace(".", ",");
    },
    koop() {
      return this.offerte.offerteKoop;
    },
    terugVerdienTijd() {
      return Math.ceil(
        this.offerte.offertePrijsKoopInclBTW /
          (this.offerte.offerteJaarproductie * 0.31)
      );
    },
    maandelijkseBesparing() {
      return this.offerte.offerteBesparing;
    },
    targetPrice() {
      return this.maandelijkseBesparing * 12;
    },
    besparing() {
      if (this.targetPrice > 0) {
        return true;
      } else {
        return false;
      }
    },
    percentage() {
      const perc = this.offerte.percentageEigenOpwek;
      if (perc === 0) {
        return Math.ceil((3500 / this.offerte.offerteJaarproductie) * 100);
      } else {
        return this.offerte.percentageEigenOpwek;
      }
    },
    showEstimate() {
      if (this.offerte.percentageEigenOpwek === 0 && this.besparing === false) {
        return true;
      } else {
        return false;
      }
    },
    targetKwh() {
      return this.offerte.offerteJaarproductie;
    },
    targetKwhFormat() {
      function formatNumber(num) {
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
      }
      return formatNumber(this.targetKwh);
    },
    targetKwhMonth() {
      return Math.ceil(this.targetKwh / 12);
    },
    targetKwhTarief() {
      return Math.ceil(this.targetKwhMonth * this.tarief);
    },
    diff2() {
      return this.heightBesparing * (this.prijsPerKwhHS / this.tarief);
    },
    diff1() {
      return this.heightBesparing - this.diff2;
    },
  },
  created() {
    this.show = true;
  },
};
</script>

<style scoped>
.circle {
  display: block;
  justify-content: center;
  align-items: center;
  width: 10rem;
  height: 10rem;
  background-color: #83c63c;
  color: white;
  border-radius: 50%;
  margin-left: 1rem;
}
.circleTop {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  margin-top: 1.5rem;
}
.circleBottom {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  margin-bottom: 1rem;
}
.circle strong {
  font-size: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  font-weight: 700;
}
.columnLeft {
  font-size: 1.5rem;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 4px solid #f48811;
  width: 5.5rem;
  background-color: #f48811;
  color: white;
}

.columnRight {
  position: absolute;
  font-size: 1.5rem;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 4px solid #00a7e3;
  margin-left: 1.5rem;
  width: 5.5rem;
  background-color: #00a7e3;
  color: white;
}

.columnRight1 {
  position: relative;
  left: 0;
  top: 0;
  font-size: auto;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 2px dashed #83c63c;
  border-top: 2px dashed #83c63c;
  border-right: 2px dashed #83c63c;
  margin-left: 1.5rem;
  width: 5.5rem;
  background-color: white;
  color: #83c63c;
}

.wrapper {
  display: flex;
  min-height: 15rem;
  padding: 0.5rem;
}

.container1 {
  width: 50%;
  text-align: left;
}

.container1 h3 {
  text-align: left !important;
  margin: 0;
  margin-bottom: 0.5rem;
}

.container1 i {
  display: block !important;
}
.container {
  display: grid;
  grid-template-columns: auto;
  align-items: center;
  justify-content: center;
}

/* CSS FOR DIALOG */

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 10;
}

dialog {
  max-width: 40rem;
  max-height: 60rem;
  margin: 0;
  /* z-index: 100; */
  border: none;
  overflow-y: auto;
  background-color: transparent;
}

header {
  color: white;
  width: 100%;
  padding: 1rem;
}

header h3 {
  margin: 0;
}

section {
  padding: 1rem;
}

menu {
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
  margin: 0;
}

.dialog-enter-from,
.dialog-leave-to {
  opacity: 0;
  transform: scale(0.8);
}

.dialog-enter-active {
  transition: all 0.3s ease-out;
}

.dialog-leave-active {
  transition: all 0.3 ease-in;
}

.dialog-enter-to,
.dialog-leave-from {
  opacity: 1;
  transform: scale(1);
}

h3,
.h3 {
  margin: 0;
  font-family: "Karla", sans-serif;
  font-weight: 700;
  text-transform: none;
  color: #00a7e3;
}
*,
*:before,
*:after {
  box-sizing: border-box;
}
h3 {
  display: block;
  font-size: 1rem;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

.math {
  display: inline;
  font-weight: 700;
}

.mathSymbol {
  display: inline;
  font-weight: 700;
  color: #00a7e3;
  padding: 0 0.5rem;
}

h3 strong,
.math {
  color: #f48811;
}
/* END CSS FOR DIALOG */
</style>
