<template>
  <button class="button small green hasIcon" v-if="link" :class="mode">
    <slot></slot>
    <svg class="icon-arrow-right">
      <path
        fill="currentColor"
        d="M4,11V13H16L10.5,18.5L11.92,19.92L19.84,12L11.92,4.08L10.5,5.5L16,11H4Z"
      />
    </svg>
  </button>
  <button class="button small green hasIcon" v-else :class="mode">
    <slot></slot>
  </button>
</template>

<script>
export default {
  props: {
    mode: {
      type: String,
      required: false,
      default: null,
    },
    link: {
      type: Boolean,
      required: false,
      default: false,
    },
    to: {
      type: String,
      required: false,
      default: "/",
    },
  },
};
</script>

<style scoped>
.button {
  display: -webkit-inline-box;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  vertical-align: bottom;
  position: relative;
  margin: 0;
  margin-top: 2%;
  border: none;
  outline: none;
  text-decoration: none;
  cursor: pointer;
  touch-action: manipulation;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-radius: 0.5rem;
  font-family: "Karla", sans-serif;
  width: auto;
  white-space: normal;
  text-align: center !important;
  font-weight: 700;
  -webkit-transition: all 0.15s ease-in-out 0s;
  transition: all 0.15s ease-in-out 0s;
}

.button.small {
  padding: 0.8rem 1.5rem 0.8rem;
  text-transform: none;
  font-size: 0.8rem;
  font-weight: bolder;
  line-height: 1;
}
.button.green {
  color: white;
  background-color: #60ab2d;
}

.button.green:hover {
  background-color: #83c51c;
  box-shadow: rgba(0, 0, 0, 0.34) 0px 3px 8px;
}


.button.small.hasIcon svg {
  width: 1.5rem;
  height: 1.5rem;
  right: 0.5rem;
}

.button svg {
  position: absolute;
  -webkit-transition: all 0.15s ease-in-out 0s;
  transition: all 0.15s ease-in-out 0s;
}
</style>
