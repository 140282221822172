<template>
  <div class="rootDiv">
    <div class="container">
      <div class="columnLeft" :class="{ alignLeft: !showDiv }">
        <h4>Jouw gegevens</h4>
        <div class="gegevensWrapper">
          <p class="gegevensText" :class="{ alignLeft: !showDiv }">
            <strong> {{ naam }} </strong>
            <br />
            {{ adres }}
            <br />
            {{ offerte.klantPostcode }} {{ offerte.klantStad }}
            <br />
            {{ offerte.klantTelefoon }}
            <br />
            {{ offerte.klantEmail }}
          </p>

          <p class="gegevensText" v-if="showDiv">
            <strong>{{ woningHeader }}</strong>
            <br />
            Type dak: {{ offerte.offerteTypeDak }}<br />
            Bedekking: {{ offerte.offerteDakbedekking }}<br />
            Oriëntatie: {{ offerte.offerteOrientatieDak }}<br />
            Opstelling: {{ offerte.offerteOpstelling }}<br />
          </p>
        </div>

        <div class="pakket">
          <h4 class="pakketHeader">{{ pakketHeader }}</h4>
          <p class="pakketHeader">{{ offerte.productPakketNaam }}</p>
        </div>
      </div>
      <div class="columnRight" v-if="showDiv">
        <leg-plan></leg-plan>
      </div>
      <hr />
    </div>
  </div>
</template>

<script>
import moment from "moment";
import LegPlan from "./LegPlan.vue";

export default {
  components: { LegPlan },
  computed: {
    offerte() {
      return this.$store.getters.getCurrentOfferte;
    },
    versionAB() {
      return this.offerte.AB;
    },
    showDiv() {
      // if (
      // this.versionAB === 0 &&
      //   this.offerte.klantKlantSegment !== "Zakelijk"
      // ) {
      return false;
      // } else {
      //   return true;
      // }
    },
    nextStep() {
      // if (this.versionAB === 0) {
      return "/offerteDetail/stap2";
      // } else {
      // return "/offerteDetail/stap2b";
      // }
    },
    zakelijk() {
      // if (this.offerte.klantKlantSegment === "Zakelijk") {
      //   return true;
      // } else {
      return false;
      // }
    },
    woningHeader() {
      if (this.offerte.klantKlantSegment === "Zakelijk") {
        return "Jouw Bedrijfspand";
      } else {
        return "Jouw Woning";
      }
    },
    pakketHeader() {
      if (this.offerte.offerteKoop) {
        return "Dit koop je";
      } else {
        return "Dit huur je";
      }
    },
    naam() {
      return this.offerte.klantVoorNaam + " " + this.offerte.klantAchterNaam;
    },
    adres() {
      return this.offerte.klantAdres + " " + this.offerte.klantHuisNummer;
    },
    accepted() {
      if (
        this.offerte.offerteAkkoord &&
        this.offerte.quoteStage === "Closed Won"
      ) {
        return true;
      } else {
        return false;
      }
    },
    expired() {
      if (
        this.offerte.offerteAkkoord &&
        this.offerte.quoteStage === "Closed Won"
      ) {
        return false;
      } else {
        const d = new Date().valueOf();
        if (d > this.offerte.offerteGeldigheidsDatum) {
          return true;
        } else {
          return false;
        }
      }
    },
    timestampStatus() {
      const d = new Date().valueOf();
      if (
        this.offerte.offerteAkkoord &&
        this.offerte.quoteStage === "Closed Won"
      ) {
        return `Deze offerte is geaccepteerd op:  ${this.moment(
          this.offerte.offerteAkkoord.offerteAkkoord
        )
          .locale("nl")
          .format("DD MMMM YYYY")}`;
      } else {
        if (d < this.offerte.offerteGeldigheidsDatum) {
          return `Verloopt op: ${this.moment(
            this.offerte.offerteGeldigheidsDatum
          )
            .locale("nl")
            .format("DD MMMM YYYY")}`;
        } else {
          return `Deze offerte is verlopen op: ${this.moment(
            this.offerte.offerteGeldigheidsDatum
          )
            .locale("nl")
            .format("DD MMMM YYYY")}`;
        }
      }
    },
    timestampCreate() {
      return `Gepubliceerd op: ${this.moment(this.offerte.offerteOfferteDatum)
        .locale("nl")
        .format("DD MMMM YYYY")}`;
    },

    classUnderline() {
      // if (this.offerte.klantKlantSegment === "Zakelijk") {
      //   return "noUnderline";
      // } else {
      return "underline";
      // }
    },
  },
  methods: {
    toStep2() {
      // if (this.offerte.klantKlantSegment === "Particulier") {
      this.$router.push(this.nextStep);
      // }
    },
  },
  created() {
    this.moment = moment;
  },
};
</script>

<style scoped>
.showCard {
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  padding: 1rem;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
  margin-right: auto;
  margin-left: auto;
  /* max-width: 32rem; */
  /* width: 30rem;  */
  background-color: #fff;
}

.container {
  width: 100%;
}

@media (min-width: 749px) {
  .offerNumber {
    display: inline-block;
    background: #ebebeb;
    border-radius: 3rem;
    padding: 0.3rem 0.5rem;
    font-size: 1rem;
    margin-left: 1rem;
    font-weight: 500;
  }

  .alignLeft {
    width: 100% !important;
    text-align: left !important;
  }
  .columnRight {
    vertical-align: bottom;
    display: inline-block;
    width: 57%;
  }
  .columnLeft {
    vertical-align: top;
    display: inline-block;
    width: 41%;
  }
  .textOfferteLeft {
    vertical-align: top;
    display: inline-block;
    width: 30%;
  }
  .textOfferteRight {
    width: 70%;
    display: inline-block;
  }
  .offerteText {
    display: inline-block;
  }

  .pakket {
    text-align: left;
  }
  h4,
  .h4 {
    margin: 0;
    font-family: "Karla", sans-serif;
    text-transform: uppercase;
    margin-bottom: 0.5rem;
    color: #00a7e3;
    font-size: 1.6rem;
    line-height: 1;
    font-weight: 700;
    text-align: left;
  }
  h4 {
    display: block;
    margin-block-start: 1.33em;
    margin-block-end: 1.33em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    font-size: 1rem;
  }

  p {
    font-size: 1rem;
    line-height: 1.3;
    text-align: left;
  }
  .offerNumber {
    display: inline-block;
    background: #ebebeb;
    border-radius: 3rem;
    padding: 0.3rem 1rem;
    margin-left: 1rem;
    font-weight: 700;
  }

  .status {
    font-size: 1rem;
  }
}

@media (max-width: 748px) {
  .offerNumber {
    display: inline-block;
    background: #ebebeb;
    border-radius: 3rem;
    padding: 0.3rem 1rem;
    font-size: 1rem;
    margin-left: 1rem;
    font-weight: 500;
  }

  .alignLeft {
    width: 100% !important;
    text-align: left !important;
  }
  .columnLeft {
    width: 100%;
  }

  .columnRight {
    display: inline-block;
    width: 100%;
  }

  .gegevensWrapper {
    display: block;
    justify-content: space-evenly;
    width: 100%;
  }

  .gegevensText {
    display: block;
  }

  .textOfferteLeft {
    display: block;
    width: 100%;
  }

  .offerteText {
    font-weight: 700;
    display: inline-block;
  }

  .textOfferteRight {
    width: 100%;
    display: block;
    margin-top: 0.5rem;
  }

  h4,
  .h4 {
    margin: 0;
    font-family: "Karla", sans-serif;
    text-transform: uppercase;
    margin-bottom: 0.5rem;
    color: #00a7e3;
    font-size: 1.2rem;
    line-height: 0;
    font-weight: 700;
    text-align: left;
  }
  h4 {
    display: block;
    margin-block-start: 1.33em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    font-size: 0.8rem;
  }

  p {
    font-size: 0.8rem;
    line-height: 1.2;
    text-align: left;
  }

  .status {
    font-size: 0.7rem;
  }
  .pakketHeader {
    display: inline-block;
    font-size: 1rem;
    text-transform: none;
  }

  .pakketHeader + .pakketHeader {
    margin-left: 1rem;
  }

  .pakket {
    width: 100%;
  }
}

.timestampCreate {
  font-size: x-small;
  vertical-align: text-bottom;
}

.header {
  -webkit-box-align: center;
  align-items: center;
  padding-bottom: 1%;
}

.offerExpired {
  background: darkred !important;
  color: white !important;
}
.offerAccepted {
  background: #f39332 !important;
  color: white !important;
}

@media (max-width: 748px) {
  .date {
    width: 100%;
    font-size: 1rem;
    color: grey;
    font-style: italic;
    text-align: right;
    /* margin-left: 6rem; */
  }
}

.expired {
  color: darkred !important;
}
.accepted {
  color: #f39332 !important;
}
hr {
  display: block;
  unicode-bidi: isolate;
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
  margin-inline-start: auto;
  margin-inline-end: auto;
  overflow: hidden;
  border-style: inset;
  border-width: 1px;
  border-top: 1px solid lightgray;
}
.noUnderline {
  text-decoration: none;
  color: grey;
  outline: none;
  background-color: transparent;
  text-align: left;
}

.underline {
  color: #00a7e3;
  text-decoration: underline;
  outline: none;
  background-color: transparent;
  text-align: left;
  cursor: pointer;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}
</style>
