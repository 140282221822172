<template>
  <div>
    <!-- <scroll-progress></scroll-progress> -->
    <router-view> </router-view>
  </div>
</template>

<script>
// import ScrollProgress from "./components/OfferteDetail/ScrollProgress.vue";

export default {
  // components: {
  //   ScrollProgress,
  // },
  created() {

    // Afbeeldingen van de loginpagina uit Firebase opgehaald en geladen
    this.$store.dispatch("FetchLoginPage");
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}
*/ #nav a.router-link-exact-active {
  color: #42b983;
}

a,
strong,
p {
  font-family: "Karla";
}

h1,
h2,
h3,
h4 {
  font-family: "Futura-Hallostroom";
  font-weight: 600 !important;
}

body {
  background: #f4f9fa;
  margin: 0;
  font-family: "Karla", sans-serif;
  color: #1a1a1a;
  overflow-x: hidden;
  width: 100%;
  font-size: 1.1rem;
  line-height: 1.3;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
}

@font-face {
  font-family: "Glitter";
  src: local("Glitter"), url(./fonts/Glitter/Glitter.ttf) format("truetype");
}
@font-face {
  font-family: "Futura-Bold";
  src: local("Futura-Bold"),
    url(./fonts/Futura/Futura-Bold.otf) format("opentype");
}

@font-face {
  font-family: "Futura-Hallostroom";
  src: local("Futura-Hallostroom"),
    url(./fonts/Futura/Futura-Hallostroom.woff2) format("opentype");
}

@font-face {
  font-family: "Gotham";
  src: local("Gotham"), url(./fonts/Gotham/Gotham.woff) format("webopenfont");
}

@font-face {
  font-family: "Karla";
  src: local("Karla"), url(./fonts/Karla/Karla.ttf) format("truetype");
}

@font-face {
  font-family: "Bad Script";
  src: local("BadScript"),
    url(./fonts/Bad_Script/BadScript.ttf) format("truetype");
}

html {
  -webkit-text-size-adjust: 100%;
  width: 100%;
  height: auto;
  display: block;
}
</style>
