<template>
  <div class="rootDiv">
    <div class="grid">
      <div class="header">
        <b>Hoe nu verder?</b>
        <p>
          We gaan razendsnel aan de slag met je aanvraag, des te sneller wek jij
          je eigen zonne-energie op.
        </p>
      </div>
      <div class="steps">
        <div class="progressBar1">
          <span class="step"> <div class="text">Overeenkomst</div> </span>
          <span class="step"><div class="text">Dak inspectie</div> </span>
          <span class="step"> <div class="text">Installatie</div></span>
          <span class="step"><div class="text">Monitoren via app</div></span>
        </div>
        <div class="line"></div>
        <div class="progressBar">
          <span class="step">
            <div class="number">1</div>
          </span>
          <span class="step">
            <div class="number">2</div>
          </span>
          <span class="step">
            <div class="number">3</div>
          </span>
          <span class="step">
            <div class="number">4</div>
          </span>
        </div>
      </div>

      <div class="step1">
        <div class="blueHeader">1. Overeenkomst</div>
        <p>Binnen 7 werkdagen ontvang je de overeenkomst in je mail.</p>
        <div style="line-height: 2"></div>
        <p>
          Is je bestelling nog niet (helemaal) naar wens? Geen zorgen!
          Wijzigingen zijn zo gemaakt.
        </p>
      </div>
      <div class="step2">
        <div class="blueHeader">2. Dak-inspectie</div>
        <p>
          We nemen contact met je op voor het inplannen van een dak-inspectie.
        </p>
        <div style="line-height: 2"></div>
        <p>
          Tijdens dit bezoek bekijkt onze inspecteur of het zonnestroomsysteem
          geplaatst kan worden zoals we dat met elkaar bedacht hebben. Zo
          verkleinen we de kans op verrassingnen tijdens de installatie.
        </p>
      </div>
      <div class="step3">
        <div class="blueHeader">3. Installatie</div>
        <p>Binnen 3 maanden plaatsen we de zonnepanelen op jouw dak.</p>
      </div>
      <div class="step4">
        <div class="blueHeader">4. Monitoren via app</div>
        <p>
          Direct na installatie stroomt er zonne-energie jouw huis binnen en
          geeft de monitoring-app actueel inzicht in de opgewekte zonnestroom.
        </p>
      </div>
    </div>
  </div>
</template>

<style scoped>
@media (min-width: 749px) {
  .grid {
    text-align: left;
    width: 100%;
    display: grid;
    margin: auto;
    grid-template:
      "header header " auto
      "steps steps" auto
      "step1 step2" auto
      "step3 step4" auto /
      1fr 1fr;
  }

  .blueHeader {
    color: #00a7e3;
    font-weight: 700;
  }

  .header {
    grid-area: header;
    padding: 1rem;
  }

  .steps {
    grid-area: steps;
    margin-top: 3.5rem;
    margin-bottom: 1.5rem;
  }

  .step1 {
    grid-area: step1;
    padding: 1rem;
  }

  .step2 {
    grid-area: step2;
    padding: 1rem;
  }

  .step3 {
    grid-area: step3;
    padding: 1rem;
  }

  .step4 {
    grid-area: step4;
    padding: 1rem;
  }

  .progressBar1 {
    width: 100%;
    display: flex;
    align-items: space-between;
    justify-content: space-between;
    font-family: "Karla", sans-serif;
    /* margin-bottom: 6rem; */
  }
  .progressBar {
    width: 100%;
    display: flex;
    align-items: space-between;
    justify-content: space-between;
    font-family: "Karla", sans-serif;
    height: 2px;
    margin-bottom: 1rem;
  }

  .line {
    margin: auto;
    width: 90%;
    height: 2px;
    z-index: -1;
    background-color: #00a7e3;
  }

  .progressBar .step {
    display: flex;
    align-items: space-between;
    justify-content: space-between;
    color: #00a7e3;
    font-weight: 700;
    -webkit-transition: all 0.15s ease-in-out 0s;
    transition: all 0.15s ease-in-out 0s;
  }

  .text {
    width: 4rem;
    font-size: 1rem;
    color: black;
    display: block;
    margin-bottom: 3rem;
  }

  .step {
    margin: 0 2rem;
    line-height: 1;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    color: #b3b3b3;
    font-weight: 700;
    -webkit-transition: all 0.2s ease-in-out 0s;
    transition: all 0.2s ease-in-out 0s;
  }

  .number {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    width: 3rem;
    height: 3rem;
    color: white;
    border-radius: 50%;
    background-color: #00a7e3;
    font-size: 2rem;
    line-height: 1;
  }
}

@media (max-width: 748px) {
  .grid {
    text-align: left;
    width: 96%;
    gap: 4%;
    display: inline-block;
    margin: auto;
    /* margin-left: 1rem; */
    font-size: 0.9rem;
  }

  .blueHeader {
    color: #00a7e3;
    font-weight: 700;
  }
  .progressBar,
  .progressBar1,
  .text,
  .number {
    display: none !important;
  }
}
</style>
