<template>
  <div class="rootDiv">
    <font-awesome-icon
      @click="changeComponent('Check-Main')"
      id="arrow-left"
      class="arrow-left"
      icon="arrow-left"
    />
    <h3>Actieve Data Overzicht</h3>
    <p>Dit is de data die momenteel op de offerte-site gebruikt wordt.</p>
    <table>
      <tr>
        <th>Score Klantenvertellen:</th>
        <td>{{ genData.klantenVertellen.rate }}</td>
        <th>Aantal Reviews:</th>
        <td>{{ genData.klantenVertellen.numberReviews }}</td>
        <base-button @click="getScore">
          <div v-if="!loading">Laatste score ophalen</div>
          <base-spinner v-if="loading"></base-spinner>
        </base-button>
      </tr>
      <tr>
        <th>Klanten gingen je voor:</th>
        <td>{{ genData.voorgangers }}</td>
      </tr>
    </table>
    <h4>Reviews:</h4>
    <table v-for="(review, index) in selectedReview" :key="index">
      <div class="reviewBox">
        <tr>
          Door:
          {{
            review.author
          }}
        </tr>
        <tr>
          Uit:
          {{
            review.city
          }}
        </tr>
        <tr>
          Review:
          {{
            review.rating
          }}
        </tr>
      </div>
    </table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: null,
    };
  },
  methods: {
    changeComponent(selectedComponent) {
      this.$emit("selected-component", selectedComponent);
    },
    async getScore() {
      this.loading = true;
      await this.$store.dispatch("fetchScore");
      this.loading = false;
    },
  },
  computed: {
    genData() {
      return this.$store.getters.getGenData;
    },
    selectedReview() {
      return this.genData.selectedReviews;
    },
  },
  created() {
    const get = this.$store.getters.getGenData;
    if (get) {
      return;
    } else {
      this.$store.dispatch("getGenData");
    }
  },
};
</script>

<style scoped>
th {
  width: 20rem;
  text-align: left;
}
td {
  width: 10rem;
  text-align: left;
}

h4 {
  text-align: left;
}

.arrow-left {
  position: absolute;
  right: 0;
  margin-right: 4rem;
  background-color: transparent;
  border-radius: 50%;
  border: 1px solid #44bace;
  padding: 0.8rem;
  margin-top: -1rem;
  color: #44bace;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
}

.arrow-left:hover {
  position: absolute;
  right: 0;
  border-radius: 50%;
  padding: 0.8rem;
  background-color: #44bace;
  margin-right: 4rem;
  margin-top: -1rem;
  color: white;
  cursor: pointer;
}

.reviewBox {
  text-align: left;
  width: 53rem;
  border-radius: 10px;
  border: 1px solid #44bace;
  transition: 0.2s all ease-in-out;
}
</style>
