<template>
  <div class="rootDiv">
    <div v-if="loading" class="spinner">
      <base-spinner></base-spinner>
    </div>
    <div v-if="!loading">
      <font-awesome-icon
        @click="changeComponent('Check-Main')"
        id="arrow-left"
        class="arrow-left"
        icon="arrow-left"
      />
      <h3>Infosheet Toevoegen</h3>
      <form @submit.prevent="uploadPDF">
        <div class="group">
          <label class="radioLabel" for="InfoSheetOmvormer">Omvormer:</label>
          <input
            class="radio"
            type="radio"
            value="InfoSheetOmvormer"
            v-model="type"
          />
          <br />
          <label class="radioLabel" for="InfoSheetZonnepanelen"
            >Zonnepaneel:</label
          >
          <input
            class="radio"
            type="radio"
            value="InfoSheetZonnepanelen"
            v-model="type"
          />
          <br />
          <label class="radioLabel" for="InfoSheetZonnepanelen"
            >ZonnepaneelSpecsheet:</label
          >
          <input
            class="radio"
            type="radio"
            value="SpecSheetZonnepanelen"
            v-model="type"
          />
        </div>
        <div class="group" v-if="type === 'InfoSheetOmvormer'">
          <label for="pdfVal">Merk:</label><br />
          <input type="text" v-model="pdfVal" />
        </div>
        <div class="group" v-if="type === 'InfoSheetZonnepanelen'">
          <label for="pdfVal">Wattpiek:</label><br />
          <input type="text" v-model="pdfVal" />
        </div>
        <div class="group" v-if="type === 'SpecSheetZonnepanelen'">
          <label for="pdfVal">Wattpiek:</label><br />
          <input type="text" v-model="pdfVal" />
        </div>
        <div class="group">
          <label for="pdfUrl">Upload Pdf:</label><br />
          <input type="file" @change="updatePdf($event)" />
        </div>
        <base-button v-if="okToSubmit && !created">Toevoegen</base-button>
        <p v-if="created">Het Item is toegevoegd!</p>
      </form>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/app";

export default {
  data() {
    return {
      pdfVal: null,
      type: "InfoSheetOmvormer",
      pdfUrl: "",
      selectedPdf: "",
      loading: null,
      created: false,
    };
  },
  computed: {
    okToSubmit() {
      if (this.type && this.pdfVal && this.selectedPdf) {
        return true;
      } else {
        return false;
      }
    },
    infoSheets() {
      return this.$store.getters.getInfosheets;
    },
    infoZon() {
      return this.infoSheets.zonnepanelen;
    },
    infoOmv() {
      return this.infoSheets.omvormer;
    },
    infoSpecZon() {
      if (this.infoSheets.specsheetZon) {
        return this.infoSheets.specsheetZon;
      } else {
        return [];
      }
    },
  },
  methods: {
    changeComponent(selectedComponent) {
      this.$emit("selected-component", selectedComponent);
    },
    updatePdf(event) {
      var input = event.target;
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.selectedPdf = e.target.result;
        };
        reader.readAsDataURL(input.files[0]);
      }
    },

    async uploadPDF() {
      let folder = "";
      let fileName = "";

      if (this.type === "InfoSheetOmvormer") {
        folder = "InfoSheetOmvormer/";
        fileName = `omvormer`;
      } else if (this.type === "InfoSheetZonnepanelen") {
        folder = "InfoSheetZonnepanelen/";
        fileName = `zonnepanelen`;
        this.pdfVal = `${this.pdfVal}wp`;
      } else if (this.type === "SpecSheetZonnepanelen") {
        folder = "SpecSheetsZonnepanelen/";
        fileName = `Zonnepanelen`;
        this.pdfVal = `${this.pdfVal}wp`;
      }
      let storageRef = firebase.storage().ref();
      let imageRef = storageRef.child(
        `${folder}` + `specsheet-${fileName}-${this.pdfVal}.pdf`
      );
      await imageRef
        .putString(this.selectedPdf, "data_url")
        .then((snapshot) => {
          snapshot.ref.getDownloadURL().then((downloadURL) => {
            this.pdfUrl = downloadURL;
            this.create(fileName);
          });
        });
    },

    async create(fileName) {
      let name = "";
      if (fileName === "omvormer" || fileName === "zonnepanelen") {
        name = `infosheet-${fileName}-${this.pdfVal}.pdf`;
      } else if (fileName === "Zonnepanelen") {
        name = `specsheet-${fileName}-${this.pdfVal}.pdf`;
      }

      const doc = {
        name: name,
        url: this.pdfUrl,
      };
      if (fileName === "omvormer") {
        await this.infoOmv.push(doc);
      } else if (fileName === "zonnepanelen") {
        await this.infoZon.push(doc);
      } else if (fileName === "Zonnepanelen") {
        await this.infoSpecZon.push(doc);
      }

      const d = {
        zonnepanelen: this.infoZon,
        omvormer: this.infoOmv,
        specsheetZon: this.infoSpecZon,
      };
      this.$store.dispatch("updateInfoSheets", d);
      this.created = true;
    },
  },
  created() {
    const data = this.$store.getters.getInfosheets;
    if (data) {
      return;
    } else {
      this.$store.dispatch("getInfosheets");
    }
  },
};
</script>

<style scoped>
.group {
  position: relative;
  margin-bottom: 1rem;
  text-align: left;
}

.group label {
  display: block;
  text-align: left;
}

input {
  display: block;
  width: 95%;
  border: 1px solid #cccccc;
  padding: 1rem 1rem 1rem;
  border-radius: 0.4rem;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#fafafa),
    to(white)
  );
  background-image: linear-gradient(to bottom, #fafafa 0%, white 100%);
  -webkit-transition: border-color 0.15s ease-in-out 0s;
  transition: border-color 0.15s ease-in-out 0s;
}

.radio {
  margin: 0;
  text-align: left;
  width: 15rem;
  display: inline-block;
  vertical-align: middle;
}

.radioLabel {
  display: inline-block !important;
  width: 8rem;
}

.arrow-left {
  position: absolute;
  right: 0;
  margin-right: 4rem;
  background-color: transparent;
  border-radius: 50%;
  border: 1px solid #44bace;
  padding: 0.8rem;
  margin-top: -1rem;
  color: #44bace;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
}

.arrow-left:hover {
  position: absolute;
  right: 0;
  border-radius: 50%;
  padding: 0.8rem;
  background-color: #44bace;
  margin-right: 4rem;
  margin-top: -1rem;
  color: white;
  cursor: pointer;
}

.spinner {
  height: 30rem;
  margin: auto;
}
</style>
