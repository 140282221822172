<template>
  <div class="rootDiv">
    <confetti-animation></confetti-animation>
    <div class="hero background-3">
      <div class="visual container">
        <img :src="gmp" alt="" />
        <h1 class="header">Gefeliciteerd {{ offerte.klantVoorNaam }}</h1>
      </div>
      <div class="container">
        <div class="afspraak backgroundcolor white">
          <div class="title">
            <h3>Eindelijk je eigen 100% duurzame energie!</h3>
            <h3>Dit gaat er nu gebeuren</h3>
            <div class="energyFlashLine">
              <img :src="energyFlashUrl" />
            </div>
          </div>
          <!-- <div class="notification">
            <font-awesome-icon id="check" class="icon-check" icon="check" />
            <span>Wij nemen binnen enkele dagen contact met je op</span>
          </div> -->
          <stappen-plan-c></stappen-plan-c>
          <!-- <stappen-plan></stappen-plan> -->
          <h2 class="overviewTitle">Overzicht van je bestelling</h2>

          <div class="divWrapper">
            <bestel-overzicht></bestel-overzicht>
          </div>
          <print-banner v-if="showPrintBanner"></print-banner>
        </div>
      </div>
    </div>
    <div class="footerWrapper">
      <step-footer></step-footer>
    </div>
    <!-- <div class="floaterMargin">
      <step-floater stepInfo="" @next-step="nextStep"></step-floater>
    </div> -->
  </div>
</template>

<script>
import BestelOverzicht from "../stepTwo/BestelOverzicht.vue";
import StepFooter from "../StepFooter.vue";
import ConfettiAnimation from "./ConfettiAnimation.vue";

import PrintBanner from "../PrintBanner.vue";
// import StappenPlan from "./StappenPlan.vue";
import StappenPlanC from "./StappenPlanC.vue";
import firebase from "firebase/app";
// import StepFloater from "../stepOne/StepFloater.vue";

export default {
  data() {
    return {
      // gmp: require("../../../../assets/StepThree/groep-met-paneel.png"),
      energyFlashUrl: require("../../../../assets/MainAssets/icon-energy-flash.svg"),
      showStepButtons: true,
    };
  },
  components: {
    BestelOverzicht,
    StepFooter,
    PrintBanner,
    StappenPlanC,
    // StappenPlan,
    ConfettiAnimation,
    // StepFloater,
  },
  computed: {
    pages() {
      return this.$store.getters.getPages;
    },
    gmp() {
      return this.pages.Stap3.img;
    },
    offerte() {
      return this.$store.getters.getCurrentOfferte;
    },
    showPrintBanner() {
      if (window.innerWidth > 748) {
        return true;
      } else {
        return false;
      }
    },
  },

  methods: {
    changeStep(event) {
      this.$emit("change-step", event);
    },
  },

  created() {
    firebase.analytics().logEvent("page_view - stap 3");
    if (window.innerWidth > 748) {
      this.showStepButtons = true;
    } else {
      this.showStepButtons = false;
    }
  },
};
</script>

<style scoped>
@media (max-width: 748px) {
  span {
    font-size: clamp(0.8em, 2vw, 1rem);
  }
  .afspraak .notification {
    padding: 1rem 1rem;
  }
  .hero.background-3 {
    padding: 0.3rem;
    background-color: #00a7e3;
  }

  .hero .container.visual {
    margin-top: 0.5rem;
    position: relative;
  }

  .container {
    /* padding: 0.3rem; */
    position: relative;
    margin: auto;
    margin-top: -2rem;
    max-width: 110rem;
  }

  .hero .container.visual img {
    width: 75%;
    max-width: 75rem;
    margin: auto;
  }

  img {
    display: block;
    width: 70%;
    margin: auto;
    height: auto;
    border: 0;
  }

  .hero .container.visual h1 {
    display: block;
    position: absolute;
    top: 40%;
    -webkit-transform: translateY(-25%);
    transform: translateY(-25%);
    color: white;
    text-transform: uppercase;
    text-align: center;
  }

  .backgroundcolor.white {
    padding-top: 1rem;
    border-radius: 12px;
    background-color: white;
    box-shadow: 0 3rem 5rem rgb(0 0 0 / 10%);
  }

  h1,
  .h1 {
    margin: 0;
    font-family: "Karla", sans-serif;
    font-weight: 700;
  }

  h1 {
    display: block;
    font-size: 2em;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
  }

  h2,
  .h2 {
    margin: 0;
    font-family: "Karla", sans-serif;
    font-weight: 700;
  }

  .afspraak .title {
    text-align: center;
    margin-bottom: 3rem;
  }

  .afspraak .title h3:first-of-type {
    color: #00a7e3;
    margin-bottom: 1rem;
  }

  h3,
  .h3 {
    margin: 0;
    font-family: "Karla", sans-serif;
    text-transform: uppercase;
    font-weight: 700;
  }

  h3 {
    display: block;
    font-size: clamp(1.4, 2vw, 1.8rem);
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: 700;
  }

  .afspraak .notification {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    color: white;
    margin-bottom: 4rem;
    background-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#f39332),
      to(#f48811)
    );
    background-image: linear-gradient(to bottom, #f39332 0%, #f48811 100%);
    font-weight: 700;
  }

  .afspraak .notification #check {
    margin-right: 1rem;
  }

  [class^="icon-"],
  [class*=" icon-"] {
    position: relative;
    display: inline-block;
    width: clamp(0.8rem, 2vw, 1rem);
    height: clamp(0.8rem, 2vw, 1rem);
    stroke-width: 0;
    stroke: currentColor;
    fill: currentColor;
  }

  p {
    margin: 0;
    margin-bottom: 2rem;
  }

  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }
  p {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }

  .overviewTitle {
    display: -webkit-box;
    padding: 0.2rem;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    color: #00a7e3;
    margin: 1rem 0;
  }

  .divWrapper {
    padding: 1rem;
  }
}

@media (min-width: 749px) {
  .header {
    margin: auto;
    margin-left: 8%;
  }
  .footerWrapper {
    margin-top: -1rem;
  }
  .afspraak {
    margin-top: -10rem;
    padding-top: 5rem;
  }
  .energyFlashLine {
    max-width: 28rem;
    margin: auto;
    margin-bottom: 1rem;
  }
  .afspraak .notification {
    padding: 2.8rem 2rem;
  }
  .hero.background-3 {
    padding-top: 1rem;
    padding-bottom: 2rem;
    background-color: #00a7e3;
  }

  .hero .container.visual {
    position: relative;
  }

  .container {
    position: relative;
    margin: auto;
    max-width: 110rem;
  }

  .hero .container.visual img {
    width: 75%;
    max-width: 75rem;
    margin: auto;
  }

  img {
    display: block;
    width: 70%;
    margin: auto;
    height: auto;
    border: 0;
  }

  .hero .container.visual h1 {
    display: block;
    position: absolute;
    top: 40%;
    -webkit-transform: translateY(-25%);
    transform: translateY(-25%);
    color: white;
    text-transform: uppercase;
    text-align: center;
  }

  .backgroundcolor.white {
    border-radius: 12px;
    background-color: white;
    box-shadow: 0 3rem 5rem rgb(0 0 0 / 10%);
  }
  .container {
    position: relative;
    margin: auto;
    max-width: 72rem;
  }

  h1,
  .h1 {
    margin: 0;
    font-family: "Karla", sans-serif;
    font-weight: 700;
  }

  h1 {
    display: block;
    font-size: 2em;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
  }

  h2,
  .h2 {
    margin: 0;
    font-family: "Karla", sans-serif;
    font-weight: 700;
  }

  .afspraak .title {
    text-align: center;
    margin-bottom: 3rem;
  }

  .afspraak .title h3:first-of-type {
    color: #00a7e3;
    margin-bottom: 1rem;
  }

  h3,
  .h3 {
    margin: 0;
    font-family: "Karla", sans-serif;
    text-transform: uppercase;
    font-weight: 700;
  }

  h3 {
    display: block;
    font-size: 1.8rem;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: 700;
  }

  .afspraak .notification {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    color: white;
    margin-bottom: 4rem;
    background-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#f39332),
      to(#f48811)
    );
    background-image: linear-gradient(to bottom, #f39332 0%, #f48811 100%);
    font-weight: 700;
  }

  .afspraak .notification #check {
    margin-right: 1rem;
  }

  [class^="icon-"],
  [class*=" icon-"] {
    position: relative;
    display: inline-block;
    width: 1rem;
    height: 1rem;
    stroke-width: 0;
    stroke: currentColor;
    fill: currentColor;
  }

  p {
    margin: 0;
    margin-bottom: 2rem;
  }

  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }
  p {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }

  .overviewTitle {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    color: #00a7e3;
    margin: 1rem 0;
  }
}
@media (min-width: 961px) {
  .backgroundcolor {
    padding: 3rem;
  }
}
@media (min-width: 1101px) {
  .container {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .hero .container.visual h1 {
    text-align: center;
    font-size: 5rem;
    line-height: 1.2;
  }
  h1,
  .h1 {
    text-align: center;
    margin-bottom: 3rem;
    font-size: 2.5rem;
    line-height: 1.3;
  }

  h3,
  .h3 {
    margin-bottom: 2rem;
    font-size: 2.8rem;
    line-height: 1.3;
  }
  h2,
  .h2 {
    margin-bottom: 2rem;
    font-size: 3rem;
    line-height: 1.2;
  }
}

/* PRINT BUTTON */

.button.small.isPrintButton {
  padding-left: 1.5rem !important;
}
.button.small.isPrintButton #print {
  margin-right: 1rem;
}

.button.blueGhost {
  color: #44bace;
  background-color: transparent;
  border: 2px solid #44bace;
  transition: all 0.2s ease-in-out;
}

.button.blueGhost:hover {
  color: white;
  background-color: #44bace;
  border: 2px solid #44bace;
}

.button.small {
  padding: 0.5rem 1.5rem 0.5rem;
  text-transform: uppercase;
  font-size: 0.8rem;
  line-height: 1;
}
.button {
  display: -webkit-inline-box;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  vertical-align: bottom;
  position: relative;
  margin: 0;
  margin-left: 3rem;
  border: none;
  outline: none;
  text-decoration: none;
  cursor: pointer;
  touch-action: manipulation;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-radius: 0.5rem;
  font-family: "Karla", sans-serif;
  width: auto;
  white-space: normal;
  text-align: center !important;
  font-weight: 700;
  -webkit-transition: all 0.15s ease-in-out 0s;
  transition: all 0.15s ease-in-out 0s;
}
a,
button,
.button {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
a {
  background-color: transparent;
}
</style>
