export default {
  hasOffertes(_, getters) {
    return getters.getOffertes && getters.getOffertes.length > 0;
  },
  getQuoteAccepted(state) {
    return state.QuoteAccepted;
  },
  getOffertes(state) {
    return state.offertes;
  },
  getCurrentOfferte(state) {
    return state;
  },
  getTimeStamp(state) {
    return state.timeStampOpened;
  },
  getReviewRecs(state) {
    return state.reviewRecs;
  },
  getReviews(state) {
    return state.reviews;
  },
  getReviewDate(state) {
    return state.reviewDate;
  },
  getGenData(state) {
    return state.genData;
  },
  getMedewerkers(state) {
    return state.medewerkers;
  },
  getProducts(state) {
    return state.products;
  },
  getInfosheets(state) {
    return state.infosheets;
  },
  getKlantNummer(state) {
    return state.klantKlantNummer;
  },
  getLoginPage(state) {
    return state.LoginPage;
  },
  getPages(state) {
    return state.Pages;
  },
  getAdmin(state) {
    return state.Admin;
  },
  getLogs(state) {
    return state.Logs;
  },
  getKorting(state) {
    return state.korting;
  },
  getEditor(state) {
    return state.Editor;
  },
  getRequest(state) {
    return state.request;
  },
  getMedewerkerActive(state) {
    return state.medewerkerActive;
  },
  getMeerwerkAll(state) {
    return state.AllMeerwerk;
  },
};
