<template>
  <div class="rootDiv">
    <div class="visueel">
      <div class="image">
        <img :src="pakketImage" alt="" />
      </div>
      <div class="inhoud">
        <ul class="orange">
          <li>
            <font-awesome-icon
              id="checkOrange"
              class="icon-check"
              icon="check"
            />
           <span
              >A-kwaliteit zonnepanelen die voor jou meteen energie
              opwekken</span
            >
            <!-- <span>{{ offerte.productZonnepaneelpag2 }}</span> -->
          </li>
          <li>
            <font-awesome-icon
              id="checkOrange"
              class="icon-check"
              icon="check"
            />
            <span>Omvormer {{ offerte.productOptimizer }}</span>
          </li>
          <li>
            <font-awesome-icon
              id="checkOrange"
              class="icon-check"
              icon="check"
            />
            <span
              >Inzage in de opbrengst van je panelen met iOS & Android app</span
            >
          </li>
        </ul>
        <div class="plus">
          <div class="icon">
            <div class="icon-plus">
              <font-awesome-icon id="plus" icon="plus" />
            </div>
          </div>
        </div>
        <ul class="blue">
          <li>
            <font-awesome-icon
              id="checkOrange"
              class="icon-check"
              icon="check"
            />
            <span>Vakkundige installatie</span>
          </li>
          <li>
            <font-awesome-icon
              id="checkOrange"
              class="icon-check"
              icon="check"
            />
            <span v-if="!koop">Garantie & Verzekering</span>
            <span v-if="koop">Garantie</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    offerte() {
      return this.$store.getters.getCurrentOfferte;
    },
    koop() {
      return this.offerte.offerteKoop;
    },
    pakketImage() {
      return this.offerte.productPakketImage;
    },
  },
};
</script>

<style scoped>
@media (min-width: 749px) {
  .visueel {
    margin-top: -13rem;
    position: relative;
  }
  .visueel .image {
    position: absolute;
    z-index: 9;
    right: -3%;
    top: 0;
    width: 45%;
    margin-top: -10%;
  }
  .visueel .inhoud {
    width: 80%;
    position: relative;
  }
  .visueel .inhoud ul {
    padding: 5rem 3rem;
  }
  .visueel .inhoud ul li {
    max-width: 75%;
    padding: 0rem 0 1rem 1rem;
    font-size: 1rem;
    line-height: 1.2;
    text-align: left;
  }
  .visueel .inhoud .plus .icon {
    width: 8rem;
    height: 8rem;
  }

  .visueel .inhoud ul.orange {
    padding: 2rem;
    background-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#f39332),
      to(#f48811)
    );
    background-image: linear-gradient(to bottom, #f39332 0%, #f48811 100%);
  }

  .visueel .inhoud ul {
    margin: 0;
    padding: 0;
    list-style: none;
    color: white;
    font-weight: 700;
  }
  ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }

  .visueel .inhoud ul li {
    position: relative;
    display: block;
  }

  .visueel .inhoud ul li #checkOrange {
    position: absolute;
    top: 0;
    left: 0;
    width: 0.5rem;
    height: 1rem;
  }

  [class^="icon-"],
  [class*=" icon-"] {
    position: relative;
    display: inline-block;
    width: 2rem;
    height: 2rem;
    stroke-width: 0;
    stroke: currentColor;
    fill: currentColor;
  }

  .visueel .inhoud .plus {
    position: relative;
    height: 0.5rem;
  }

  .visueel .inhoud .plus .icon {
    position: absolute;
    left: 18%;
    width: 5.5rem;
    height: 5.5rem;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    background: white;
    color: #f48811;
    border-radius: 50%;
    -webkit-transform: translate(-50%, -47%);
    transform: translate(-50%, -47%);
  }

  .visueel .inhoud .plus .icon #plus {
    width: 100%;
    height: 100%;
  }

  .visueel .inhoud ul.blue {
    padding: 2rem;
    padding-top: 3.5rem;
    background-color: #00a7e3;
  }

  #plus {
    color: #f48811;
    font-size: 3rem;
  }

  img {
    display: flex;
    width: 100%;
    height: auto;
    border: 0;
  }
}

@media (max-width: 748px) {
  .visueel .inhoud .plus {
    display: none;
  }
  .visueel {
    margin-top: -6.8rem;
  }
  .visueel .inhoud ul:first-child {
    padding-top: 10rem;
  }
  .visueel .inhoud ul {
    padding: 4rem 4rem;
  }
  .visueel .image {
    max-width: 28rem;
  }

  .visueel .inhoud .plus .icon {
    width: 2.5rem;
    height: 2.5rem;
  }

  .visueel .inhoud {
    width: 100%;
    padding-top: 0.5rem;
  }
  .visueel .inhoud ul {
    margin: 0;
    padding: 0;
    list-style: none;
    color: white;
    font-weight: 700;
  }

  .visueel .inhoud ul li {
    max-width: 100%;
    padding: 0rem 0 0.5rem 1rem;
    font-size: 0.8rem;
    line-height: 1;
    text-align: left;
  }

  .visueel .inhoud ul.orange {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 1rem;
    padding-top: 5rem;
    border-radius: 12px;
    background-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#f39332),
      to(#f48811)
    );
    background-image: linear-gradient(to bottom, #f39332 0%, #f48811 100%);
  }

  ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }

  .visueel .inhoud ul li {
    position: relative;
    display: block;
  }

  .visueel .inhoud ul li #checkOrange {
    position: absolute;
    top: 0;
    left: 0;
    width: 0.5rem;
    height: 1rem;
  }

  [class^="icon-"],
  [class*=" icon-"] {
    /* margin-top: -0.2rem; */
    position: relative;
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    stroke-width: 0;
    stroke: currentColor;
    fill: currentColor;
  }

  .visueel .inhoud .plus .icon {
    /* margin: auto; */
    margin-left: auto;
    margin-right: auto;
    width: 3rem;
    height: 3rem;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    background: white;
    color: #f48811;
    border-radius: 50%;
    -webkit-transform: translate(-50%, -47%);
    transform: translate(-50%, -47%);
  }

  .visueel .inhoud .plus .icon #plus {
    width: 100%;
    height: 100%;
  }

  .visueel .inhoud ul.blue {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 1rem;
    padding-top: 3rem;
    margin-top: 0.3rem;
    border-radius: 12px;
    background-color: #00a7e3;
  }

  #plus {
    color: #f48811;
    font-size: 3rem;
  }

  img {
    margin-left: auto;
    margin-right: auto;
    /* margin-top: rem; */
    margin-bottom: -6rem;

    display: block;
    width: 50%;
    height: auto;
    border: 0;
  }
}
</style>
