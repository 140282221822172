<template>
  <div>
    <component v-if="offerteOpened" :is="selectedComponent"></component>
    <div v-if="!offerteOpened">
      <div class="wrapper">
        <div class="card">
          <div class="innerCard">
            <h3>Welkom {{ naam }}! Hieronder jouw offerte(s)</h3>
            <br />
            <div class="energyFlashLine">
              <img :src="energyFlashUrl" alt="" />
            </div>
            <div v-if="hasOffertes">
              <p>
                We hebben {{ offertes.length }} offertes voor je klaar staan.
              </p>
              <ul>
                <offerte-view
                  v-for="offerte in offertes"
                  :key="offerte.id"
                  :id="offerte.id"
                  :offerteNummer="offerte.offerteOfferteNummer"
                  :offerteNaam="offerte.offerteMerkZonnepanelen"
                  :timeStampOpened="offerte.offerteTimeStampOpened"
                  :timeStampVerlopen="offerte.offerteGeldigheidsDatum"
                  :offerte="offerte"
                  @offerte-opened="toggleOpened"
                ></offerte-view>
              </ul>
            </div>
            <p v-else>We hebben geen offertes gevonden voor jou.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OfferteView from "./OfferteView.vue";
import OfferteDetail from "../OfferteDetail/OfferteDetail.vue";
export default {
  components: {
    OfferteView,
    OfferteDetail,
  },
  data() {
    return {
      isLoading: true,
      offerteOpened: false,
      energyFlashUrl: require("../../assets/MainAssets/icon-energy-flash.svg"),
      selectedComponent: "",
    };
  },
  computed: {
    // klantNummer() {
    //   return this.offertes[0].klantKlantNummer;
    // },
    email() {
      return this.offertes[0].klantEmail;
    },
    naam() {
      return (
        this.offertes[0].klantVoorNaam + " " + this.offertes[0].klantAchterNaam
      );
    },
    offertes() {
      return this.$store.getters.getOffertes;
    },
    hasOffertes() {
      return this.$store.getters.hasOffertes;
    },
  },
  methods: {
    toggleOpened() {
      this.$router.push("/offerteDetail");
      this.offerteOpened = true;
    },
  },
};
</script>

<style scoped>
@media (max-width: 748px) {
  .wrapper {
    width: 100%;
    padding-bottom: 1rem;
    height: auto;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background: linear-gradient(to bottom, #44bace 0%, #00a7e3, 100%);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  }
  h3 {
    display: block;
    font-size: 1.17em;
    margin: 0;
  }

  p {
    max-width: 80%;
    margin: auto;
  }
  .card {
    max-width: 32rem;
    margin: auto;
    padding: 0.4rem;
  }

  .innerCard {
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    padding: 1rem;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
    margin-right: auto;
    margin-left: auto;
    background-color: #fff;
  }

  .energyFlashLine img {
    max-width: 80%;
  }

  ul {
    list-style: none;
    margin: auto;
    padding: 0;
    max-width: 30rem;
  }
}

@media (min-width: 749px) {
  h3 {
    display: block;
    font-size: 1.17em;
    margin: 0;
  }

  p {
    max-width: 80%;
    margin: auto;
  }
  .card {
    max-width: 35rem;
    margin: auto;
    padding: 0.5rem;
  }

  .innerCard {
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    padding: 1rem 0;
    margin-bottom: 1.5rem;
    margin-top: 0.2rem;
    margin-right: auto;
    margin-left: auto;
    /* max-width: 32rem; */
    /* width: 30rem;  */
    background-color: #fff;
  }
  .wrapper {
    height: 100%;
    background-color: #00a7e3;
  }

  .energyFlashLine img {
    max-width: 80%;
  }

  ul {
    list-style: none;
    margin: auto;
    padding: 0;
    max-width: 33rem;
  }
}
</style>
