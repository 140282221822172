<template>
  <div class="rootDiv">
    <div class="priceSummary">
      <div class="line">
        <div class="description">
          <strong>Totaalbedrag</strong>
          <p>
            {{ offerte.offerteAantalPanelen }} zonnepanelen inclusief omvormer
            {{ offerte.productOptimizer }}
            <br />
          </p>
        </div>
        <div class="price">€ {{ prijsKoopIncBTW }}</div>
      </div>
      <div class="line">
        <div class="description">
          <strong>Installatie</strong>
        </div>
        <div class="price">€ 0,-</div>
      </div>
      <div class="line">
        <div class="description">
          <strong>Btw 0%</strong>
        </div>
        <div class="price">
          <strong>€ {{ BtwTeruggave }}</strong>
        </div>
      </div>
      <div class="priceLine"></div>
      <div class="line hasNoLine isBlue">
        <div class="description">
          <strong>Netto investering</strong>
        </div>
        <div class="price">
          <strong>€ {{ nettoBedrag }}</strong>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    offerte() {
      return this.$store.getters.getCurrentOfferte;
    },
    prijsKoopIncBTW() {
      const start = (+this.offerte.offertePrijsKoopInclBTW).toFixed(2);
      const inc = start
        .toString()
        .replace(".", ",")
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
      return inc;
    },
    zakelijk() {
      // if (this.offerte.klantSegment === "particulier") {
        return false;
      // } else {
      //   return true;
      // }
    },
    gekozenHuurPrijsExBTW() {
      return this.offerte.offerteGekozenHuurPrijs * 0.79;
    },
    BTW() {
      return this.offerte.offerteGekozenHuurPrijs * 0.21;
    },
    btwKoop() {
      const btw = (this.offerte.offertePrijsKoopInclBTW * 0.21).toFixed(2);
      return btw.replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    },
    forfait() {
      const f = (+this.offerte.offerteForfait).toFixed(2);
      const commaFormat = f
        .replace(".", ",")
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
      return commaFormat;
    },
    BtwTeruggave() {
      // const f = (
      //   this.offerte.offertePrijsKoopInclBTW * 0.21 -
      //   +this.offerte.offerteForfait
      // ).toFixed(2);
      const f = "0,-";
      const commaFormat = f
        .replace(".", ",")
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
      return commaFormat;
    },
    nettoBedrag() {
      // const f = (
      //   this.offerte.offertePrijsKoopInclBTW -
      //   (this.offerte.offertePrijsKoopInclBTW * 0.21 -
      //     +this.offerte.offerteForfait)
      // ).toFixed(2);
      // const commaFormat = f
      //   .replace(".", ",")
      //   .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
      // return commaFormat;
      const start = (+this.offerte.offertePrijsKoopInclBTW).toFixed(2);
      const inc = start
        .toString()
        .replace(".", ",")
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
      return inc;
    },
  },
};
</script>

<style scoped>
.rootDiv {
  margin-bottom: 2rem;
}
@media (max-width: 748px) {
  .description {
    text-align: left;
    font-size: 0.8rem;
  }

  .priceSummary {
    position: relative;
    font-size: 0.8rem;
    margin-bottom: 2rem;
  }
  .priceSummary .line {
    display: -webkit-box;
    display: flex;
    border-bottom: 1px solid #e6e6e6;
    padding: 1.0rem 0;
  }

  .priceSummary .line .description .customSelect {
    margin: 1rem 0 0;
  }

  .customSelect {
    position: relative;
  }

  strong,
  b {
    font-weight: 700;
  }

  .priceSummary .line .price.alignBottom {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-pack: end;
    justify-content: flex-end;
    padding-bottom: 1.3rem;
  }
  .priceSummary .line .price {
    margin-left: auto;
    padding-left: 0rem;
  }

  .priceSummary .priceLine {
    position: absolute;
    z-index: 1;
    margin-top: -1px;
    right: 0;
    background: #00a7e3;
    height: 2px;
    width: 25%;
  }

  .priceSummary .line.isBlue {
    color: #00a7e3;
  }
  .priceSummary .line.hasNoLine {
    border: 0;
    padding-bottom: 0;
  }
}
@media (min-width: 749px) {
  .description {
    text-align: left;
  }

  .priceSummary {
    position: relative;
  }
  .priceSummary .line {
    display: -webkit-box;
    display: flex;
    border-bottom: 1px solid #e6e6e6;
    padding: 1.5rem 0;
  }

  .priceSummary .line .description .customSelect {
    margin: 1rem 0 0;
  }

  .customSelect {
    position: relative;
  }

  strong,
  b {
    font-weight: 700;
  }

  .priceSummary .line .price.alignBottom {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-pack: end;
    justify-content: flex-end;
    padding-bottom: 1.3rem;
  }
  .priceSummary .line .price {
    margin-left: auto;
    padding-left: 2rem;
  }

  .priceSummary .priceLine {
    position: absolute;
    z-index: 1;
    margin-top: -1px;
    right: 0;
    background: #00a7e3;
    height: 2px;
    width: 25%;
  }

  .priceSummary .line.isBlue {
    color: #00a7e3;
  }
  .priceSummary .line.hasNoLine {
    border: 0;
    padding-bottom: 0;
  }
  .priceSummary .line {
    display: -webkit-box;
    display: flex;
    border-bottom: 1px solid #e6e6e6;
    padding: 1.5rem 0;
  }
}
</style>
