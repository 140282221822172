<template>
  <div>
    <div class="container" :class="{ expired: expired, accepted: accepted }">
      <div class="columnLeft">
        <img class="image" :src="productPakketImage" alt="" />
      </div>
      <div class="columnRight">
        <li v-if="!showLoading">
          <br />
          <strong>{{ offerteNaam }} </strong>
          <br /><br />
          <div class="offerNumber">
            <strong>Offertenummer:</strong> {{ offerteNummer }} <br />
            <strong>Panelen:</strong> {{ offerte.offerteAantalPanelen }} x
            {{ offerte.offerteMerkZonnepanelen }} <br />
            <strong>Merk omvormer:</strong> {{ offerte.offerteMerkOmvormer }}
            <div>{{ timestampCreate }}</div>
          </div>
          <br />
          <div class="button">
            <base-button @click="openOfferte" v-if="!expired && !accepted">
              <div v-if="!showLoading">bekijk offerte</div>
            </base-button>
            <base-button @click="openOfferte" v-if="expired">
              <div v-if="!showLoading">bekijk verlopen offerte</div>
            </base-button>
            <base-button @click="openOfferte" v-if="accepted">
              <div v-if="!showLoading">bekijk geaccepteerde offerte</div>
            </base-button>
          </div>
          <p v-if="timeStampOpened">Laatst geopend op {{ timestampOpen }}</p>
        </li>
        <div v-else>
          <base-spinner></base-spinner>
        </div>
        <hr />
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  data() {
    return {
      showLoading: false,
    };
  },
  props: [
    "id",
    "timeStampOpened",
    "timeStampVerlopen",
    "offerteNummer",
    "offerteNaam",
    "offerte",
  ],
  emits: ["offerte-opened"],

  computed: {
    productPakketImage() {
      return this.offerte.productPakketImage;
    },
    timestampOpen() {
      return this.moment(this.timeStampOpened).format("DD.MM.YYYY-HH:mm");
    },
    timestampCreate() {
      const d = new Date().valueOf();
      if (
        this.offerte.offerteAkkoord &&
        this.offerte.quoteStage === "Closed Won"
      ) {
        return `Deze offerte is geaccepteerd op: ${this.moment(
          this.offerte.offerteAkkoord.offerteAkkoord
        )
          .locale("nl")
          .format("DD MMMM YYYY")}`;
      } else if (d < this.timeStampVerlopen) {
        return `Verloopt op: ${this.moment(this.timeStampVerlopen)
          .locale("nl")
          .format("DD MMMM YYYY")}`;
      } else {
        return `Deze offerte is verlopen op: ${this.moment(
          this.timeStampVerlopen
        )
          .locale("nl")
          .format("DD MMMM YYYY")}`;
      }
    },
    expired() {
      const d = new Date().valueOf();
      if (
        this.offerte.offerteAkkoord &&
        this.offerte.quoteStage === "Closed Won"
      ) {
        return false;
      } else {
        if (d < this.timeStampVerlopen) {
          return false;
        } else {
          return true;
        }
      }
    },
    offertes() {
      return this.$store.getters.getOffertes;
    },
    accepted() {
      if (
        this.offerte.offerteAkkoord &&
        this.offerte.quoteStage === "Closed Won"
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    openOfferte() {
      this.showLoading = true;
      const date = new Date().getTime();
      let update = "";
      if (this.offerte.offerteTimeStampOpened) {
        update = "offerteTimeStampReOpened";
      } else {
        update = "offerteTimeStampOpened";
      }
      const doc = {
        id: this.id,
        timeStampOpened: date,
      };
      const currentOfferte = {
        ...this.offerte,
        [update]: date,
      };

      this.$store.dispatch("setCurrentOfferte", currentOfferte);
      if (update === "offerteTimeStampReOpened") {
        this.$store.dispatch("updateReOpened", doc);
      } else {
        this.$store.dispatch("updateOfferte", doc);
      }
      this.$store.dispatch("resetOffertes", {
        offertes: this.offertes,
        currentOfferte: currentOfferte,
      });
      setTimeout(() => {
        this.$emit("offerte-opened");
      }, 3000);
    },
  },
  created() {
    this.moment = moment;
  },
};
</script>

<style scoped>
.button {
  max-width: 95%;
}
.expired {
  border: darkred 2px solid;
  border-radius: 12px;
  color: darkred;
  opacity: 0.5;
  transition: 0.3s all ease-in-out;
}

.expired:hover {
  border: darkred 2px solid;
  border-radius: 12px;
  color: darkred;
  opacity: 1;
}

.accepted {
  border: #60ab2d 2px solid;
  border-radius: 12px;
  color: #60ab2d;
}

hr {
  border: 1px solid lightgrey;
  margin-bottom: 5%;
}

strong {
  font-size: 1rem;
  text-align: left;
}

.offerNumber {
  font-size: 0.9rem;
  text-align: left;
  margin-left: 1.5%;
  color: grey;
}

.container {
  position: relative;
  max-width: 100%;
  margin: auto;
  font-size: 0.9rem;
  margin-top: 0.5rem;
}

.columnLeft img {
  width: 100%;
  height: 100%;
  margin-bottom: 50%;
  /* border: 1px solid black; */
}
.columnLeft {
  width: 20%;
  height: 100%;
  display: inline-block;
}

.columnRight {
  width: 75%;
  height: 100%;
  /* border: 1px solid red; */
  display: inline-block;
}

p {
  font-size: smaller;
  color: grey;
}
</style>
